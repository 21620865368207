import React, {Component} from "react";
import {axios} from "../../Managers";
import {Button, Descriptions, Divider, PageHeader, message, Space, Spin, Typography} from "antd";
import {inject, observer} from "mobx-react";
import {Steps} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {Tabs} from "element-react";
import FilmViewer from "./FilmViewer";
import LabelViewer from "./LabelViewer";
import PositionTable from "./PositionTable";
import Modal from "antd/es/modal/Modal";
import AcceptedPool from "./acceptedPool";
import LogisticTable from "./logisticTable";
import moment from "moment";
import PlanStockMove from "./PlanStockMove";
import PlanPaper from "./PlanPaper";

@inject("Operator")
@observer
class MtoPlanDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			loading: true,
			acceptedModal: false,
			preAdd: [],
			currentTab: "明细行",
			logs: []
		};
		this.pool = React.createRef();
		this.tabs = React.createRef();
		this.logisticTable = React.createRef()
	}

	componentDidMount() {
		this.getDetail();
	}

	getDetail = () => {
		this.setState({loading: true});
		axios.post("/mto/mtoplan/", {action: "search", data: {planId: this.props.planId}}).then(response => {
			if (response.data.status === 200) {
				let tab = "明细行";
				if (response.data.data[0].template.config.flow[response.data.data[0].status].hasOwnProperty("changeTabName")) {
					tab = response.data.data[0].template.config.flow[response.data.data[0].status].changeTabName;
				}
				this.setState({data: response.data.data[0], loading: false, currentTab: tab});
			}
		});
		axios.get(`/mto/mtoplan/?action=logs&planId=${this.props.planId}`).then(res => {
			this.setState({logs: res.data.data})
		})
	};

	cancelPlan = (planId) => {
		this.setState({loading: true})
		axios.post("/mto/mtoplan/", {action: "cancelPlan", data: {planId: planId}}).then(response => {
			if (response.data.status === 200) {
				message.success("计划删除成功");
				this.props.removeTab({props: {label: planId}});
			} else {
				message.error(response.data.message);
				this.setState({loading: false});
			}

		});
	};

	goToFlow = (planId, flow) => {
		this.setState({loading: true});
		axios.post("/mto/mtoplan/", {action: "flow", data: {planId: planId, flow: flow}}).then(response => {
			if (response.data.status === 200) {
				message.success(response.data.message);
			} else {
				message.error(response.data.message);
			}
			this.getDetail();
		});
	};

	distribute = (planId) => {
		this.setState({loading: true});
		axios.post("/mto/mtoplan/", {action: "distribute", data: {planId: planId}}).then(response => {
			if (response.data.status === 200) {
				message.success(response.data.message);
			} else {
				message.error(response.data.message);
			}
		}).catch(error => {
			message.error(error.data.message);
		});
		this.setState({loading: false});
	};

	addRecord = () => {
		this.setState({acceptedModal: true});
	};
	addOrderLine = (orderLine) => {
		let preAdd = [];
		for (let i of this.pool.current.state.selectedRows) {
			preAdd.push({lineId: i.lineId, quantity: i.quantity});
		}
		if (preAdd.length !== 0) {
			this.setState({acceptedModal: false, loading: true});
			axios.post("/mto/mtoplan/",
				{action: "addRecord", data: {planId: this.state.data.planId, lines: preAdd}}).then(response => {
				message.success(response.data.message);
				this.setState({loading: false});
				this.getDetail();
			});
		}
	};
	deployPosition = (planId) => {
		this.setState({loading: true});
		axios.post("/mto/mtoplan/", {action: "deployPosition", data: {planId: planId}}).then(res => {
			if (res.data.status === 200) {
				message.success(res.data.message);
			} else {
				message.error(res.data.message);
			}
			this.getDetail();
		});
	};

	batchSend = (planId, next) => {
		this.goToFlow(planId, next);
	};

	batchPrint = (planId, next) => {
		this.goToFlow(planId, next);
	};

	finishPlan = (planId) => {
		this.setState({loading: true});
		// this.goToFlow(planId, "end")
		axios.post("/mto/mtoplan/", {action: "finishPlan", data: {planId: planId}}).then(res => {
			if (res.data.status === 200) {

				message.success(res.data.message);
			} else {
				message.error(res.data.message);
			}
			this.getDetail();
		});
	};

	printTable = (planId) => {
		console.log(this.state.data)
	}

	exportExcel = () => {
		/*
		* 导出Excel格式的发货清单
		* */
		this.logisticTable.current.exportExcel()
	}

	getFunc = (funcName, parms) => {
		let p = []
		for (let x of parms) {
			if (x === "planId") {
				p.push(this.props.planId)
			} else {
				p.push(x)
			}
		}
		switch (funcName) {
			case "finishPlan":
				return this.finishPlan(...p)
			case "printTable":
				return this.printTable(...p)
			case "goToFlow":
				return this.goToFlow(...p)
			case "cancelPlan":
				return this.cancelPlan(...p)
			case "distributePlan":
				return this.distribute(...p)
			case "exportExcel":
				return this.exportExcel()
			default:
				alert("未知指令" + funcName)
		}
	}

	printLabel = () => {
		for (let x of this.state.data.MTORecord) {
			axios.get("http://127.0.0.1:888/postek/print",
				{
					params: {
						reqParam: 1,
						printparams: `[
							{"PTK_OpenUSBPort": "255"},
							{"PTK_ClearBuffer":""},
							{"PTK_SetLabelHeight" : "150,20,0,false"},
							{"PTK_SetLabelWidth" : "554"},
							{"PTK_SetDirection" : "B"},
							{"PTK_SetDarkness" : "20"},
							{"PTK_PcxGraphicsDel":"logo0"},
							{"PTK_AnyGraphicsPrint":"27,7,logo0,${window.location.origin}${x.orderLine.layoutImage},0,491,129,0"},
							{"PTK_PrintLabel" : "1,1"},
							{"PTK_CloseUSBPort": ""}]`
					}, withCredentials: false
				}).then(res => {
				if (res.data.retval !== "0") {
					message.error(res.data.msg)
				} else {
					message.success("打印完成!")
				}
			})
		}
	}

	render() {
		let size = this.props.Operator.componentSize
		if (this.state.data) {
			let data = this.state.data;
			let _step = "start";
			let _steps = [];
			let _break = true;
			let _process = false;
			while (_break) {
				if (_step === data.status) {
					if (data.finished) {
						_steps.push(<Steps.Step key={Math.random()} status="finish" title={data.template.config.flow[_step].displayName}/>);
					} else {
						_steps.push(<Steps.Step key={Math.random()} status="process" title={data.template.config.flow[_step].displayName}
						                        icon={<LoadingOutlined/>}/>);
					}

					_process = true;
				} else {
					if (_process) {
						_steps.push(<Steps.Step key={Math.random()} status="wait" title={data.template.config.flow[_step].displayName}/>);
					} else {
						_steps.push(<Steps.Step key={Math.random()} status="finish" title={data.template.config.flow[_step].displayName}/>);
					}
				}
				if (data.template.config.flow[_step].hasOwnProperty("next")) {
					_step = data.template.config.flow[_step].next;
				} else {
					_break = false;
				}
			}
			let actions = [
				<Button type={"primary"} size={size} key={Math.random()} onClick={() => this.getDetail()}>刷新</Button>,
				<Button type={"primary"} size={size} key={Math.random()} onClick={() => {this.addRecord();}}
				        disabled={data.MTORecord.length >= data.template.maxCap || data.status !== "start"}>添加记录行</Button>,
				<Button type={"primary"} size={size} key={Math.random()} onClick={this.printLabel} disabled={data.template.shortCode !== "HM3"}>打印标签</Button>,
				<Button size={size} key={Math.random()} disabled={["producing", "finish", "end"].indexOf(data.status) === -1}
				        onClick={() => {window.open(`/mto/mtoplan?action=layoutImage&planId=${data.planId}`)}}>下载生产图</Button>
				// <Button type={"primary"} size={size} key={Math.random()}
				//         onClick={() => this.deployPosition(data.planId)}>重新排布</Button>
			];

			for (let x of data.template.config.flow[data.status].actions) {
				switch (x.actionType) {
					case "button":
						actions.push(<Button key={Math.random()} disabled={data.finished} size={size} onClick={() => {
							let fn = () => this.getFunc(x.actionFunc, x.actionParms);
							fn();
						}}>{x.actionName}</Button>);
						break;
					default:
						break;
				}
			}

			let extraTabs = [];
			switch (data.template.craft) {
				case "ES":
					extraTabs = [
						<Tabs.Pane label={"菲林图"} name={"菲林图"} key={Math.random()}>
							<FilmViewer data={data} type={"film"}/>
						</Tabs.Pane>,
						<Tabs.Pane label={"标签图"} name={"标签图"} key={Math.random()}>
							<LabelViewer data={data} type={"label"}/>
						</Tabs.Pane>];
					break;
				case "HM":
					extraTabs = [
						<Tabs.Pane label={"菲林图"} name={"菲林图"} key={Math.random()}>
							<FilmViewer data={data} type={"film"}/>
						</Tabs.Pane>,
						<Tabs.Pane label={"标签图"} name={"标签图"} key={Math.random()}>
							<LabelViewer data={data} type={"label"}/>
						</Tabs.Pane>];
					break;
				case "LA":
					extraTabs = [
						<Tabs.Pane label={"生产确认书"} name={"生产确认书"} key={Math.random()}></Tabs.Pane>
					];
					break;
				default:
					extraTabs = [];
			}

			return (
				<Spin tip="拉取计划信息..." spinning={this.state.loading} key={Math.random()}>
					<div className={"innerContent"} key={Math.random()}>
						<PageHeader key={Math.random()} title={data.planId} extra={<Space split={<Divider type={"vertical"}/>}>{actions}</Space>}>
							<Descriptions
								key={Math.random()}
								bordered
								labelStyle={{minWidth: 90, textAlign: "center"}}
								size={size}
								column={{xxl: 4, xl: 4, lg: 2, md: 2, sm: 1, xs: 1}}>
								<Descriptions.Item label="计划编号" key={Math.random()}>{data.planId}</Descriptions.Item>
								<Descriptions.Item label="备注信息" key={Math.random()}>{data.note}</Descriptions.Item>
								<Descriptions.Item label="创建时间" key={Math.random()}>{moment(data.createTime).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
								<Descriptions.Item label="最后更新" key={Math.random()}>{moment(data.lastUpdate).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
								<Descriptions.Item
									label="创建人">{data.owner.displayName}/{data.mtoFactory.factoryName}</Descriptions.Item>
								<Descriptions.Item label="子单数量" key={Math.random()}>{data.MTORecordCount}/{data.template.maxCap}</Descriptions.Item>
								<Descriptions.Item label="模板名称" key={Math.random()}>{data.template.name}</Descriptions.Item>
								<Descriptions.Item label="当前状态" key={Math.random()}>{data.template.config.flow[data.status].displayName}</Descriptions.Item>
								<Descriptions.Item label="步骤流程" span={4} key={Math.random()}>
									<Steps size={size} labelPlacement={"vertical"}>
										{_steps.map((t) => {
											return t;
										})}
									</Steps>
								</Descriptions.Item>
							</Descriptions>
							<Tabs type="border-card" value={this.state.currentTab}>
								<Tabs.Pane label={"明细行"} name={"明细行"} key={Math.random()}>
									<PositionTable data={data} extraInfo={data.template} key={Math.random()} getDetail={this.getDetail}/>
								</Tabs.Pane>

								{extraTabs.map(t => {
									return t;
								})}
								<Tabs.Pane label={"作业清单"} name={"作业清单"} key={Math.random()}>
									<PlanPaper data={data}/>
								</Tabs.Pane>
								<Tabs.Pane label={"订单发货"} name={"订单发货"} key={Math.random()}>
									<LogisticTable data={data} key={Math.random()} ref={this.logisticTable} goToFlow={this.goToFlow}/>
								</Tabs.Pane>
								<Tabs.Pane label={"物料调拨单"} name={"物料调拨"} key={Math.random()}>
									<PlanStockMove key={Math.random()} data={data}/>
								</Tabs.Pane>
								<Tabs.Pane label={"计划日志"} name={"计划日志"} key={Math.random()}>
									<div className="logs" key={Math.random()}>
										{this.state.logs.map(log => {
											return <Typography.Text code key={Math.random()}>{log.createTime} |
												{log.operator}| [{log.message}]</Typography.Text>;
										})}
									</div>
								</Tabs.Pane>
							</Tabs>
						</PageHeader>
					</div>
					<Modal visible={this.state.acceptedModal} closable={true} destroyOnClose onOk={() => {this.addOrderLine();}}
					       onCancel={() => {this.setState({acceptedModal: false});}} width={"90vw"} okText={"添加"} cancelText={"取消"}>
						<AcceptedPool planOrderLine={this.planOrderLine} data={data} extraInfo={data.template} addOrderLine={true}
						              ref={this.pool}/>
					</Modal>
				</Spin>
			);
		}
		return (<Spin tip="拉取计划信息..." spinning={this.state.loading}/>);
	}
}

export default MtoPlanDetail;