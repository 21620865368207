import React, {Component} from "react";
import Konva from "konva";
import {Button, Space} from "antd";
import {axios} from "../../Managers";
import jspdf from "jspdf";

class LabelViewer extends Component {
	constructor(props) {
		super(props);
		this.stage = null;
		this.layout = null;
		this.width = null;
		this.height = null;
		this.cid = `konvaContainner-${String(Math.random()).replace(".", "")}`;
		this.xtime = null
	}

	componentDidMount() {
		let container = document.getElementById(this.cid);

		// this.stage = new Konva.Stage({
		// 	container: container
		// 	// width: "auto",
		// 	// height: "auto",
		// });
		this.width = parseInt(getComputedStyle(container)["width"].replace("px", ""));
		this.height = parseInt(getComputedStyle(container)["height"].replace("px", ""));
		this.xtime = this.width / 182;

		this.stage = new Konva.Stage({
			container: container,
			width: 182 * this.xtime,
			height: 257 * this.xtime
		});

		this.layout = new Konva.Layer({
			x: 0,
			y: 0,
			// width: this.width,
			// height: this.height,
			fill: "red"
		});
		this.stage.add(this.layout);

		this.drawLabel();

		this.layout.batchDraw();
	}

	getOrderInfo = (orderId) => {
		return axios.post("/api/saleorder/", {action: "list", data: {orderId: orderId}});
	};

	exportImage = () => {
		this.stage.scale({x: 5, y: 5});
		let _image = this.stage.toDataURL({
			mimeType: "image/png",
			quality: 1,
			pixelRatio: 1,
			width: this.width * 5,
			height: this.height * 5
		});
		// let nw = window.open();
		this.stage.scale({x: 1, y: 1});
		let _pdf = new jspdf({format: "B5"});
		_pdf.addImage(_image, 0, 0, this.width / 4, this.height / 4);
		// _pdf.addPage({format: "a5"});
		// _pdf.setPage(2);
		// _pdf.text("B5", 10, 10);
		_pdf.autoPrint();
		window.open(_pdf.output("bloburl"), "_blank");

		// nw.document.write(
		// 	`<html>
		//     <style type="text/css">
		//     body{margin: 0} @page  {
		// 		  size: A4 landscape;
		// 		  margin:0;
		// 		}
		// 	@media print{
		// 		body{margin: 0;}
		// 		@page  {
		// 		  size: A4;
		// 		  margin:0;
		// 		}
		// 		}
		// 		</style>
		// 		<body>
		// 		<img src="${_image}" width="${this.width}" height="${this.height}"/>
		// 		</body>
		// 		</html>`);
	};

	drawLabel = () => {
		/*
		画标签图
		 */
		this.layout.add(new Konva.Rect({
			x: 88,
			y: 114,
			width: 556,
			height: 812,
			strokeWidth: 1,
			stroke: "black",
			dash: [2, 0, 2]
		}));
		if (this.props.data.template.config.name === "ES-24" || this.props.data.template.config.name === "ES-27") {

			this.layout.add(new Konva.Text({
				text: this.props.data.planId,
				x: 36,
				y: 36
			}));

		}
		const data = this.props.data;
		switch (data.template.config.name) {
			case "ES-24":
			case "HM-24":
				for (let x of data.MTORecord) {
					if (x.position.hasOwnProperty("slot")) {
						let slot = x.position["slot"];
						let slotNum = parseInt(slot.replace("slot", ""));
						if (slotNum <= 12) {
							let border = new Konva.Rect({
								x: 128,
								y: 120 + (67 * (slotNum - 1)),
								border: true,
								width: 204,
								height: 57.2,
								cornerRadius: 57.2,
								stroke: "black",
								strokeWidth: 2
							});
							this.layout.add(border);
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 139,
									y: 126 + (67 * (slotNum - 1)),
									width: 180,
									height: 46.2
								});
								this.layout.add(image);
							});

						} else {
							let border = new Konva.Rect({
								x: 414,
								y: 120 + (67 * (slotNum - 13)),
								border: true,
								width: 204,
								height: 57.2,
								cornerRadius: 57.2,
								stroke: "black",
								strokeWidth: 2
							});
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 424,
									y: 126 + (67 * (slotNum - 13)),
									width: 180,
									height: 46
								});
								this.layout.add(image);
							});
							this.layout.add(border);
						}

					}
				}
				break;
			case "ES-27":
				// this.stage.scale({x: 2, y: 2})
				for (let x of data.MTORecord) {
					if (x.position.hasOwnProperty("slot")) {
						let slot = x.position["slot"];
						let slotNum = parseInt(slot.replace("slot", ""));
						if (slotNum <= 12) {
							let border = new Konva.Rect({
								x: 128,
								y: 120 + (67 * (slotNum - 1)),
								border: true,
								width: 204,
								height: 57.2,
								cornerRadius: 57.2,
								stroke: "black",
								strokeWidth: 2
							});
							this.layout.add(border);
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 139,
									y: 126 + (67 * (slotNum - 1)),
									width: 180,
									height: 46.2
								});
								this.layout.add(image);
							});
						} else if (slotNum >= 13 && slotNum <= 18) {
							let border = new Konva.Rect({
								x: 414,
								y: 120 + (67 * (slotNum - 13)),
								border: true,
								width: 200,
								height: 57.2,
								cornerRadius: 57.2,
								stroke: "black",
								strokeWidth: 2
							});
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 424,
									y: 126 + (67 * (slotNum - 13)),
									width: 180,
									height: 46
								});
								this.layout.add(image);
							});
							this.layout.add(border);
						} else {
							switch (slotNum) {
								case 19:
									this.layout.add(new Konva.Circle({
										x: 406,
										y: 600,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 373,
											y: 568,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});
									break;
								case 20:
									this.layout.add(new Konva.Circle({
										x: 490,
										y: 600,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 457,
											y: 568,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});

									break;
								case 21:
									this.layout.add(new Konva.Circle({
										x: 575,
										y: 600,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 542,
											y: 568,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});
									break;
								case 22:
									this.layout.add(new Konva.Circle({
										x: 406,
										y: 684,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 373,
											y: 651,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});
									break;
								case 23:
									this.layout.add(new Konva.Circle({
										x: 490,
										y: 684,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 457,
											y: 651,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});
									break;
								case 24:
									this.layout.add(new Konva.Circle({
										x: 575,
										y: 684,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 542,
											y: 651,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});

									break;
								case 25:
									this.layout.add(new Konva.Circle({
										x: 406,
										y: 768,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 373,
											y: 735,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});

									break;
								case 26:
									this.layout.add(new Konva.Circle({
										x: 490,
										y: 768,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 457,
											y: 735,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});

									break;
								case 27:
									this.layout.add(new Konva.Circle({
										x: 575,
										y: 768,
										border: true,
										radius: 40,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 542,
											y: 735,
											width: 64,
											height: 64
										});
										this.layout.add(image);
									});
									this.stage.scale({x: 1, y: 1})
									break;
								default:
									break;
							}

						}

					}
				}
				break;
			default:
				break;
		}
		this.layout.batchDraw();
	};

	render() {
		return (

			<div className={"layouteditor"}>
				<div className={"layoutRightButtons"}>
					<Space direction={"vertical"}>
						<Button onClick={this.exportImage}>打印</Button>
					</Space>
				</div>

				<div id={this.cid} className={"konvaLabelContainer"}/>

			</div>
		);
	}
}

export default LabelViewer;