import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {axios} from "../../Managers";
import {Spin, message, Card, Typography, Divider, Space, Table, Modal} from "antd"
import {MaterialLine} from "../Material/MaterialPlot";
import {Tabs} from "element-react";
import StockMove from "./StockMove";
import StockMoveCreator from "./StockMoveCreator";

@inject("Operator")
@observer
class WareHouseCard extends Component {
	constructor(props) {
		super(props);
		this.state = {materials: [], loading: true}
	}

	componentDidMount() {
		this.list_materials()
	}

	list_materials = () => {
		this.setState({loading: true})
		axios.get(`/mto/stockbalance?uuid=${this.props.wh.uuid}`).then(res => {
			this.setState({materials: res.data.data, loading: false})
		})
	}

	recompute_balancing = (uuid) => {
		axios.post(`/mto/stockbalance/`, {action: "recompute", "uuid": uuid}).then(res => {
			this.list_materials()
			message.success("重算成功!")
		})
	}

	extra = (wh) => {
		if (wh.permissions.indexOf("create_stockmove") !== -1) {
			return <Space split={<Divider type={"vertical"}/>}>
				<Typography.Link onClick={this.list_materials}>刷新库位</Typography.Link>
			</Space>
		} else {
			return <Space split={<Divider type={"vertical"}/>}>
				<Typography.Link onClick={this.list_materials}>刷新库位</Typography.Link>
			</Space>
		}
	}

	showModal = () => {
		this.setState({showModal: !this.state.showModal})
	}

	render() {
		const wh = this.props.wh
		const operator = this.props.Operator
		const columns = [
			{title: "物料", dataIndex: "material", width: 200, align: "center"},
			{
				title: "库位", key: "percent", align: "center", render: ((text, record) => {
					return <MaterialLine material={record} key={record.uuid}/>
				})
			},
			{
				title: "最低位", key: "lowest", align: "right", width: 50, render: ((text, record) => {
					return record.config.lowest + "<"
				})
			},
			{
				title: "在手量", key: "order_quantity", align: "center", width: 50, render: ((text, record) => {
					return record.order_quantity
				})
			},
			{
				title: "在途量", key: "shipping_quantity", align: "center", width: 50, render: ((text, record) => {
					if (record.shipping_quantity !== 0) {
						return <span style={{color: "green"}}>+{record.shipping_quantity}</span>
					}
					return "+" + record.shipping_quantity
				})
			},
			{
				title: "生产预扣", key: "plan_quantity", align: "center", width: 60, render: ((text, record) => {
					if (record.plan_quantity !== 0) {
						return <span style={{color: "#9826FB"}}>-{record.plan_quantity}</span>
					}
					return "-" + record.plan_quantity
				})
			},
			{
				title: "成品预扣", key: "product_quantity", align: "center", width: 60, render: ((text, record) => {
					if (record.product_quantity !== 0) {
						return <span style={{color: "#9826FB"}}>-{record.product_quantity}</span>
					}
					return "-" + record.product_quantity
				})
			},
			{
				title: "报废预扣", key: "scrap_quantity", align: "center", width: 60, render: ((text, record) => {
					if (record.scrap_quantity !== 0) {
						return <span style={{color: "red"}}>+{record.scrap_quantity}</span>
					}
					return "-" + record.scrap_quantity
				})
			},
			{
				title: "预估量", key: "percent", align: "center", width: 50, render: ((text, record) => {
					if (record.estimated <= 0 || record.estimated <= record.config.lowest) {
						return <span style={{color: "red"}}>{record.estimated}</span>
					}
					if (record.estimated >= record.config.lowest) {
						return <span style={{color: "green"}}>{record.estimated}</span>
					}
					return "=" + record.estimated
				})
			},

			{
				title: "最高位", key: "maxVolume", align: "center", width: 50, render: ((text, record) => {
					return "<" + record.config.maxVolume
				})
			}
			// {
			// 	title: "操作", key: "opts", align: "center", width: 160, render: ((text, record) => {
			// 		return <Space split={<Divider type={"vertical"}/>}>
			// 			{/*<Typography.Link key={Math.random()}>修改警戒线</Typography.Link>*/}
			// 			<Typography.Link key={Math.random()} onClick={() => this.recompute_balancing(record.uuid)}>重算预估</Typography.Link>
			// 		</Space>
			// 	})
			// }
		]
		if (this.props.name === "preview") {
			return <Card headStyle={{padding: 5}} title={wh.displayName} key={wh.uuid} size={operator.componentSize}
			             bordered={true}
			             extra={this.extra(wh)}>
				<Table dataSource={this.state.materials}
				       columns={columns}
				       pagination={false}
				       size={operator.componentSize}
				/>
			</Card>
		}

		return <Spin spinning={this.state.loading}>
			<Modal visible={this.state.showModal} onCancel={this.showModal} closable={false} width={"60vw"} destroyOnClose footer={null}>
				<StockMoveCreator wh={this.props.wh} whs={this.props.Operator.warehousies}
				                  stockbalance={this.state.materials}
				                  showModal={this.showModal}
				/>
			</Modal>
			<Card headStyle={{padding: 5}} title={"物料明细"} key={wh.uuid} size={operator.componentSize}
			      bordered={true} extra={this.extra(wh)}>
				<Table dataSource={this.state.materials}
				       rowKey={"uuid"}
				       columns={columns}
				       pagination={false}
				       size={operator.componentSize}
				/>
			</Card>
			<Divider/>
			<StockMove key={Math.random()} wh={this.props.wh} move_type={"in_moves"}/>
			<Divider/>
			<StockMove key={Math.random()} wh={this.props.wh} move_type={"out_moves"} showModal={this.showModal}/>
		</Spin>
	}
}

@inject("Operator")
@observer
class WareHouse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
		this.operator = this.props.Operator
	}

	componentDidMount() {
		this.list_warehousies()
	}

	list_warehousies = async () => {
		await this.setState({loading: true})
		await this.operator.list_warehousies()
		await this.setState({loading: false})
	}

	render() {
		if (this.operator.warehousies.length) {
			return (
				<div>
					<Spin spinning={this.state.loading}>
						<Tabs type="border-card">
							<Tabs.Pane label={"物料总览"} name={"物料总览"} key={Math.random()}>
								<Space direction={"vertical"} style={{width: "100%"}} split={<Divider/>}>
									{this.operator.warehousies.map(wh => {
										return <WareHouseCard key={Math.random()} wh={wh} name={"preview"}/>
									})}
								</Space>
							</Tabs.Pane>
							{this.operator.warehousies.map(wh => {
								return <Tabs.Pane label={wh.displayName} name={wh.displayName} key={Math.random()}>
									<WareHouseCard key={Math.random()} wh={wh}/>
								</Tabs.Pane>
							})}
						</Tabs>
						{/*<Space direction={"vertical"} style={{width: "100%"}}>*/}
						{/*	*/}
						{/*</Space>*/}
					</Spin>
				</div>
			);
		} else {
			return ""
		}

	}
}

export {WareHouseCard, WareHouse as default};