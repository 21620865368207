import React, {Component} from "react";
import {
	Descriptions,
	PageHeader,
	Button,
	Tag,
	Form,
	Input,
	Row,
	Col,
	Modal,
	message,
	Typography,
	Divider, Space
} from "antd";
import {inject, observer} from "mobx-react";
import PopOrderDetail from "../Order/PopOrderDetail";

class CommentCard extends Component {

	render() {
		let comment = this.props.comment;
		return <div className="commentCard" key={`commentCard-${comment.uuid}`}>
			<div className="commentCardHeader" key={`commentCardHeader-${comment.uuid}`}>
				<Row>
					<Col xs={23} sm={18} md={10}><span className={"commentCardTitle"}>{comment.title}</span></Col>
					<Col xs={0} sm={4} md={4}><span className={"operator"}>{comment.sender.displayName}</span></Col>
					<Col xs={0} sm={0} md={9}><span className={"createTime"}>{comment.createTime}</span></Col>
				</Row>
			</div>
			<hr/>
			<span className={"commentCardContent"}>{comment.content}</span>
		</div>;
	}
}

@inject("ServiceCaseManager", "Operator")
@observer
class ServiceCaseDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {showModal: false, comments: [], record: this.props.record};
		this.form = React.createRef();
	}

	onFinished = (data) => {
		this.props.ServiceCaseManager.finishCase({caseId: data.caseId}).then(data => {
			message.success(data.message, 3);
			this.setState({record: data.data[0]});
		});
	};

	onCreateComment = () => {
		this.form.current.validateFields().then(values => {
			this.props.ServiceCaseManager.commentCase(values).then(data => {
				if (data.status === 200) {
					message.success(data.message);
					this.setState({showModal: false, comments: data.data});
				}
			});
		});
	};

	render() {
		let data = this.state.record;
		let size = this.props.Operator.componentSize
		return (
			<div className={"innerContent"}>
				<PageHeader key={`header-{data.caseId}`}
				            title={data.caseId}
				            size={size}
				            extra={[
					            <Button key="2" type="primary" size={size} onClick={() => {
						            this.setState({showModal: true});
					            }}>添加注记</Button>,
					            <Button key="1" type="primary" size={size}
					                    onClick={() => this.onFinished(data)}>完成工单</Button>
				            ]}
				>
					<Descriptions
						key={`desc-{data.caseId}`}
						bordered
						labelStyle={{minWidth: 90, textAlign: "center"}}
						size={size}
						column={{xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1}}>
						<Descriptions.Item label="工单号">{data.caseId}</Descriptions.Item>
						<Descriptions.Item label="工单类型">{data.caseType}</Descriptions.Item>
						<Descriptions.Item label="紧急程度">
							{data.urgency === "一般" ? <Tag color="orange">{data.urgency}</Tag> :
								<Tag color="#f50">{data.urgency}</Tag>}
						</Descriptions.Item>
						<Descriptions.Item label="创建人">{data.owner}</Descriptions.Item>
						<Descriptions.Item label="创建时间">{data.createTime}</Descriptions.Item>
						<Descriptions.Item label="工单状态">{data.status}</Descriptions.Item>

						<Descriptions.Item label="相关订单" span={3}>
							<Space split={<Divider type={"vertical"}/>}>
								{data.relatedOrder !== null && data.relatedOrder.map(order => {
									return <Typography.Link key={order.orderId}
									                        onClick={() => PopOrderDetail(order.orderId)}>{order.orderId}</Typography.Link>;
								})}
							</Space>

						</Descriptions.Item>
						<Descriptions.Item label="工单标题" span={3}>{data.title}</Descriptions.Item>
						<Descriptions.Item label="工单详情" span={3}>
							{data.content}
						</Descriptions.Item>
						<Descriptions.Item label="相关截图" span={3}>
							{data.images !== null && data.images.map(image => {
								return <a href={image.imageFileUrl} rel="noreferrer" target={"_blank"}>
									<img className={"previewImage"} alt={image.name} src={image.imageFileUrl}
									     key={image.uuid}/>
								</a>;
							})}
						</Descriptions.Item>
						<Descriptions.Item label="工单注记" span={4}>
							{data.comments.map(comment => {
								return (
									<CommentCard comment={comment} key={comment.uuid}/>
								);
							})}
							{this.state.comments.map(comment => {
								return (
									<CommentCard comment={comment} key={`new-{comment.uuid}`}/>
								);
							})}
						</Descriptions.Item>
					</Descriptions>
				</PageHeader>
				<Modal title={"添加注记"} visible={this.state.showModal}
				       onOk={this.onCreateComment}
				       destroyOnClose={true}
				       onCancel={() => this.setState({showModal: false})}
				>
					<div className="commentForm">
						<Form size={"small"} ref={this.form}>
							<Form.Item name={"caseId"} hidden initialValue={data.caseId}>
								<Input/>
							</Form.Item>
							<Form.Item name={"title"} label={"注记标题"} required>
								<Input size={size}/>
							</Form.Item>
							<Form.Item name={"content"} label={"注记内容"} required>
								<Input.TextArea size={size} showCount={true} autoSize={{minRows: 10}}/>
							</Form.Item>
						</Form>
					</div>
				</Modal>
			</div>
		);
	}
}

export default ServiceCaseDetail;