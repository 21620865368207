import React from "react";
import {Modal} from "antd";
import MtoPlanDetail from "./MTOPlanDetail";
import stores from "../../Managers/store";
import {Provider} from "mobx-react";

const PopMTOPlanDetail = (planId) => {
	Modal.info({
		maskClosable: true,
		closable: true,
		icon: null,
		width: "90vw",
		content: <Provider {...stores}><MtoPlanDetail planId={planId}/></Provider>,
		okText: "关闭",
	});
};

export default PopMTOPlanDetail;