import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {axios, Log, ProdServer} from "../../Managers";
import {Button, Divider, message, Space, Table, Typography, Modal, Input, Tag} from "antd";
import PopOrderDetail from "../Order/PopOrderDetail";
import CreateLogistic from "./createLogistic";

@inject("Operator")
@observer
class LogisticTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: [],
			selectedRowKeys: [],
			...props.Operator.PaginationConfig,
			visible: false
		};
	}

	componentDidMount() {
		this.getLogisticList();
	}

	onChangePage = async (page, pageSize) => {
		await this.setState({
			loading: true,
			current: page,
			pageSize: pageSize
		});
		this.getLogisticList()
	};

	getLogisticList = () => {
		this.setState({loading: true});
		axios.post("/scm/logistic/", {
			action: "listLogistic", data: {status: this.props.status, pageSize: this.state.pageSize, current: this.state.current}
		}).then(res => {
			if (res.data.status === 200) {
				this.setState({
					data: res.data.data,
					fullData: res.data.data,
					loading: false,
					total: res.data.total,
					current: res.data.current,
					pages: res.data.pages,
					pageSize: res.data.pageSize
				});
			} else {
				message.error(res.data.message);
				this.setState({loading: false});
				this.setCount("problemCount", res.data.data.length)
			}
		}).catch(error => {
			Log(error);
		});
	};
	printSelected = () => {
		let logisticUUIDS = [];
		let seq = 1
		for (let x of this.state.selectedRows) {
			logisticUUIDS.push({"seq": seq, "uuid": x.logisticUUID});
			seq += 1
		}
		this.printLogisticPaper(logisticUUIDS);
	};

	printLogisticPaper = (logisticUUIDS) => {
		this.setState({loading: true});
		axios.post("/scm/logistic/", {action: "printLogisticPaper", data: {logisticUUIDS: logisticUUIDS.map(l => {return l.uuid}), orgin: logisticUUIDS}})
			.then(res => {
				if (res.data.status === 200) {
					for (let x of res.data.data) {
						window.open(ProdServer + "scm/logistic/?path=" + x, "_blank");
					}
					this.setState({loading: false});
				} else {
					message.error(res.data.message);
				}
			});
	};

	createLogistic = () => {
		this.setState({visible: true});
	};

	closeLogisticForm = () => {
		this.setState({visible: false});
	};

	onFilter = (event) => {
		let keyword = new RegExp(event.target.value, "i");
		let _data = [];
		if (event.target.value !== "" && !this.state.loading) {
			this.setState({loading: true});
			for (let x of this.state.fullData) {
				if (x.logisticCode.search(keyword) !== -1) {
					_data.push(x);
				}
			}
			this.setState({data: _data, loading: false});
		} else {
			this.setState({data: this.state.fullData, loading: false});
		}
	};

	title = () => {
		return <div className={"headerButton"}>
			<Input.Search size={this.props.Operator.componentSize} onChange={this.onFilter}/>
			<Space spit={<Divider type={"vertical"}/>} style={{marginLeft: 10}}>
				<Button size={this.props.Operator.componentSize} type={"primary"} onClick={this.createLogistic}>创建发货</Button>
				<Button size={this.props.Operator.componentSize} type={"primary"} onClick={this.getLogisticList}>刷新</Button>
				<Button size={this.props.Operator.componentSize} type={"primary"} disabled={this.state.selectedRowKeys.length === 0}
				        onClick={this.printSelected}>批量打印</Button>
			</Space></div>;
	};

	changeSelected = (selectedRowKeys, selectedRows) => {
		this.setState({selectedRowKeys: selectedRowKeys, selectedRows: selectedRows});
	};

	render() {
		const showDetail = this.props.showDetail
		let columns = [
			{title: "序号", align: "center", width: 40, render: (text, record, index) => {return index + 1;}},
			{
				title: "物流商", key: "shipper", align: "center", render: (text, record) => {
					return record.shipper.shipperName;
				}
			},
			{
				title: "单号", dataIndex: "logisticCode", align: "center", render: ((text, record) => {
					return <Typography.Link onClick={() => showDetail(record)}>{record.logisticCode}</Typography.Link>
				})
			},
			{
				title: "订单信息", key: "orders", align: "center", width: 150, render: (text, record) => {
					return record.orders.map(r => {
						return <Typography.Link key={Math.random()} ellipsis={true} onClick={() => PopOrderDetail(r)}>{r}</Typography.Link>;
					});

				}
			},
			{
				title: "发货时间", dataIndex: "createTime", align: "center"
			},

			{
				title: "最新状态", dataIndex: "lastStateDisplay", align: "center", width: 100, render: ((text, record) => {
					switch (record.lastState) {
						case "sent":
							return <Tag color={"#C0C0C0"}>{record.lastStateDisplay}</Tag>
						case "pull":
							return <Tag color={"#0000FF"}>{record.lastStateDisplay}</Tag>
						case "shipping":
						case "delivery":
							return <Tag color={"#FF9999"}>{record.lastStateDisplay}</Tag>
						case "received":
						case "manul":
							return <Tag color={"#00CC00"}>{record.lastStateDisplay}</Tag>
						case "problem":
							return <Tag color={"#FF0033"}>{record.lastStateDisplay}</Tag>
						default:
							return <span>{record.lastStateDisplay}</span>
					}
				})
			},
			{
				title: "收件人", key: "receiver", align: "center", render: (text, record) => {
					return <span
						key={Math.random()}>
						{`
								${record.receiver.ProvinceName}
								${record.receiver.CityName}
								${record.receiver.ExpAreaName}
								-${record.receiver.Name}(${record.receiver.Mobile.slice(0, 3)}****${record.receiver.Mobile.slice(-3)})`}
					</span>;
				}
			},
			{title: "最后更新", dataIndex: "lastUpdate", align: "center"},
			{
				title: "操作", key: "opts", align: "center", render: (text, record) => {
					return <Space split={<Divider type={"vertical"}/>}>
						<Typography.Link onClick={() => {this.printLogisticPaper([{"seq": 1, "uuid": record.logisticUUID}]);}}
						                 disabled={!record.eOrder}>补打面单</Typography.Link>
						<Typography.Link disabled={true}>删除</Typography.Link>
					</Space>;
				}
			}
		];
		return (
			<div className={"innerContent"}>
				<Modal title={"创建发货"} footer={null} visible={this.state.visible} width={"60vw"}>
					<CreateLogistic closeLogisticForm={this.closeLogisticForm}/>
				</Modal>
				<Table
					size={this.props.Operator.componentSize}
					columns={columns}
					loading={this.state.loading}
					dataSource={this.state.data}
					rowKey={"logisticUUID"}
					bordered
					title={this.title}
					pagination={{
						defaultPageSize: this.props.Operator.PaginationConfig.pageSize,
						pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
						onChange: this.onChangePage,
						onShowSizeChange: this.onChangePage,
						total: this.state.total,
						current: this.state.current,
						showSizeChanger: true,
						size: this.props.Operator.componentSize,
						style: {marginRight: 10},
						showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
					}}
					rowSelection={{
						selectedRowKeys: this.state.selectedRowKeys,
						onChange: this.changeSelected
					}}
				/>
			</div>
		);
	}
}

export default LogisticTable;