import React, {Component} from "react";
import {Button, Table, Input, Typography, Divider, Select, InputNumber, Modal, Card, Upload, Popover, Space, message} from "antd";
import {inject, observer} from "mobx-react";
import {getCsrfToken, OrderLine} from "../../Managers";

@inject("SaleOrderManager", "Operator", "CustomerManager", "ProductsManager")
@observer
class OrderlinesTable extends Component {
	constructor(props) {
		super(props);
		this.props.ProductsManager.getProductCate()
		this.state = {
			productCate: [],
			data: [],
			editRowKey: null,
			modal: false,
			productId: null,
			currentRecord: null
		};
		this.sourceorderTxt = React.createRef()
		this.srcode = React.createRef()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		prevProps.onChange(prevState.data);
	}

	componentDidMount() {
		window.addEventListener("message", this.parseMessage);
		if (this.props.dataSource) {
			this.setState({data: this.props.dataSource});
		}
	}

	parseMessage = (event) => {
		/*
		接收来自设计器窗口的消息处理函数
		 */
		if (event.data.source === "designer") {
			let uuid = event.data.data.name;
			event.source.close();
			if (this.state.currentRecord !== null) {
				this.state.currentRecord.setLayoutImage(uuid);
				this.setState({modal: false, currentRecord: null});
			}
		}
	};

	addNewLine = (pk) => {
		let _line = new OrderLine();
		_line.seq = this.state.data.length + 1;
		if (pk) {
			_line.onChangeProduct(pk);
		}
		let nd = this.state.data;
		nd.push(_line);
		this.setState({data: nd, editRowKey: _line.seq});
	};

	addNewLine_bySHT = () => {
		Modal.confirm({
			centered: true, title: "请输入排版码", content: <Input ref={this.srcode}/>, onOk: (close) => {
				let _code = this.srcode.current.state.value
				let _line = new OrderLine();
				_line.seq = this.state.data.length + 1;
				let pcode = _code.substr(10, 2)
				_line.getRemoteImage(_code)
				let nd = this.state.data;
				nd.push(_line);
				let error_flag = true
				_line.note = _code
				switch (pcode) {
					case "TY":
						_line.onChangeProduct("103b8026-68e7-42d3-a064-5dc63ae930b6");
						break;
					case "TP":
						_line.onChangeProduct("45ef615d-dd43-4a8b-8de5-f9ae7695b5e5");
						break;
					case "TB":
						_line.onChangeProduct("7ebc837a-bc15-43d9-8286-453cad2d0a965");
						break;
					case "YY":
						_line.onChangeProduct("6fa90433-2215-4ce4-b610-5f3cf0ff0b0f");
						break;
					case "YP":
						_line.onChangeProduct("36e58158-2f60-46d6-94da-11c3f7d705d9");
						break;
					case "YB":
						_line.onChangeProduct("eac26b6d-f7df-49ac-802c-fabac7868cd6");
						break;
					default:
						message.info("好像没有解析成功,确认一下排版码对不对!")
						error_flag = false
				}
				if (error_flag) {
					this.setState({data: nd, editRowKey: _line.seq});
					this.forceUpdate()
					close()
				}
			}
		})
	}

	deleteLine = (record) => {
		let nd = [];
		for (let i = 0; i < this.state.data.length; i++) {
			let _current = this.state.data[i];
			if (_current.seq !== record.seq) {
				_current.seq = nd.length + 1;
				nd.push(_current);
			}
		}
		this.setState({data: nd});
	};

	onFinished = () => {
		this.setState({
			editRowKey: null
		});
	};

	afterUpload = (event) => {
		/*
		上传自定义图片后处理函数
		 */
		if (event.file.status === "done") {
			let uuid = event.file.response.data[0].name;
			if (this.state.currentRecord !== null) {
				this.state.currentRecord.setLayoutImage(uuid);
				this.setState({modal: false, currentRecord: null});
			}
		}
	};

	goToDesigner = () => {
		/*
		todo:
		 1.这里设置打开新的设计器窗口
		 2.子窗口通过window.opener操作父窗口,或发送消息
		 */
		window.open("http://localhost:3001?from=order&productId=" + this.state.currentRecord.product, "_blank",
			`width=${window.innerWidth},height=${window.innerHeight},menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes`);
	};

	render() {

		const columns = [
			{
				title: "产品", dataIndex: "product", align: "center", width: 200, render: (text, record) => {
					return (
						<Select optionLabelProp={"label"} dropdownMatchSelectWidth={false} defaultValue={record.product}
						        defaultOpen={record.product === null} autoFocus
						        disabled={this.state.editRowKey !== record.seq} onChange={record.onChangeProduct}>
							{this.props.ProductsManager.Cates.map(cate => {
								if (cate.ProductList.length !== 0) {
									return <Select.OptGroup key={cate.pk} label={cate.cateName}>
										{cate.ProductList.map(p => {
											return <Select.Option key={`${cate.pk}-${p.pk}`} value={p.productUUID}
											                      label={p.productName}>
												<div className={"productSelectOption"}>
													<img src={p.productImage} alt={p.productName}
													     className={"productImage"}/>
													<span>{p.productName}</span>
												</div>
											</Select.Option>;
										})}
									</Select.OptGroup>;
								}
								return "";
							})}
						</Select>
					);
				}
			},
			{
				title: "数量", dataIndex: "quantity", align: "center", width: 50, render: (text, record) => {
					if (record.seq === this.state.editRowKey) {
						return <InputNumber defaultValue={text} onChange={record.onChangeQty} min={1}/>;
					}
					return <span>{text}</span>;
				}
			},
			{title: "单价", dataIndex: "price", align: "center", width: 50},
			{
				title: "折扣", dataIndex: "discount", align: "center", width: 50, render: (text, record) => {
					if (record.seq === this.state.editRowKey) {
						return <InputNumber defaultValue={text}
						                    min={0}
						                    max={100}
						                    formatter={value => `${value}%`}
						                    parser={value => value.replace("%", "")}
						                    onChange={record.onChangeDiscount}
						/>;
					}
					return <span>{text}%</span>;
				}
			},
			{title: "小结", dataIndex: "summary", width: 60, align: "center"},
			{
				title:
					"备注", dataIndex:
					"note", align:
					"center", render:
					(text, record) => {
						if (record.seq === this.state.editRowKey) {
							return <Input defaultValue={text} onChange={record.onChangeNote}
							              onPressEnter={this.onFinished}/>;
						}
						return <span>{text}</span>;
					}
			},
			{
				title: "版图", dataIndex: "layoutImage", align: "center", render: (text, record) => {
					if (record.layoutImage === null) {
						if (record.productInfo) {
							if (record.productInfo.productType === "cmp") {
								return <Typography.Link onClick={() => this.setState({modal: true, currentRecord: record})}>点击设置</Typography.Link>;
							} else {
								return <span>{record.productInfo.modOfSupplyDisplay}</span>;
							}
						}
					} else {
						return <Popover title={`生产图-${record.note}`}
						                content={<img src={"/api/imagestore/?uuid=" + record.layoutImage} width={400} alt={"版下图"}/>}
						>
							<img width={"100px"} src={"/api/imagestore/?uuid=" + record.layoutImage} alt={"版下图"}/>
						</Popover>;
					}

				}
			},
			{
				title: "操作", key: "opts", align: "center", render: (text, record) => {
					if (record.seq === this.state.editRowKey) {
						return (<div>
								<Typography.Link onClick={() => {
									this.onFinished();
								}}>保存</Typography.Link>
								<Divider type="vertical"/>
								<Typography.Link onClick={() => {
									this.deleteLine(record);
								}}>删除</Typography.Link>
							</div>
						);
					}
					return (<div>
							<Typography.Link onClick={() => {
								this.setState({editRowKey: record.seq});
							}}>编辑</Typography.Link>
							<Divider type="vertical"/>
							<Typography.Link onClick={() => {
								this.deleteLine(record);
							}}>删除</Typography.Link>
						</div>
					);
				}
			}
		];
		let size = this.props.Operator.componentSize
		return (

			<div>
				<Modal visible={this.state.modal} closable={true} title={"提供制版图片"} destroyOnClose={true}
				       onCancel={() => {this.setState({modal: false});}}
				       onOk={() => {this.setState({modal: false});}}
				>
					<Card className={"pictureCard"}>
						通过排版码提取:<Input ref={this.sourceorderTxt} style={{marginLeft: 10}}/>
						<Button onClick={() => {
							this.state.currentRecord.getRemoteImage(this.sourceorderTxt.current.state.value)
						}}>提取</Button>
						<br/>
					</Card>
					{this.state.currentRecord ? <img src={`"/api/imagestore/?uuid=${this.state.currentRecord.layoutImage}"`} height={100}
					                                 hidden={this.state.currentRecord.layoutImage === null} alt={"版下图"}/> : ""}
					<Card className={"pictureCard"}>
						自定义上传图片:
						<Upload accept={".png,.jpg,.jpeg,.tiff,.tif"} maxCount={1} action={"/api/imagestore/"}
						        listType="picture"
						        headers={{"X-CSRFToken": getCsrfToken()}}
						        onChange={this.afterUpload}
						>
							<Button style={{marginLeft: 10}}>点击上传</Button>
						</Upload>
					</Card>
					<Card className={"pictureCard"}>
						使用Designer设计: <Typography.Link onClick={this.goToDesigner}>开始设计</Typography.Link>
					</Card>
				</Modal>
				<Table
					size={size}
					dataSource={this.state.data.slice()}
					columns={columns}
					pagination={false}
					bordered
					rowKey={"seq"}
					onRow={record => {
						return {
							onClick: event => {
							}, // 点击行
							onDoubleClick: event => {
								this.setState({editRowKey: record.seq});
							},
							onContextMenu: event => {
							},
							onMouseEnter: event => {
								this.forceUpdate()
							}, // 鼠标移入行
							onMouseLeave: event => {
								this.forceUpdate()
							}
						};
					}}
					summary={pageData => {
						let total = 0;
						for (let i of pageData) {
							total += i.summary;
						}
						return (
							<>
								<Table.Summary.Row onClick={(e) => {
									if (this.state.editRowKey !== null) {
										this.onFinished();
									}

								}}>
									<Table.Summary.Cell colSpan={4} align={"right"}>
										<Typography.Text strong>合计</Typography.Text>
									</Table.Summary.Cell>
									<Table.Summary.Cell colSpan={1} align={"center"}>{total}</Table.Summary.Cell>
									<Table.Summary.Cell colSpan={2} align={"center"}>
									</Table.Summary.Cell>
									<Table.Summary.Cell colSpan={1} align={"center"}>
										<Space split={<Divider type="vertical"/>}>
											<Typography.Link onClick={() => {
												this.addNewLine_bySHT();
											}}>解析排版码</Typography.Link>
											<Typography.Link onClick={() => {
												this.addNewLine();
											}}>添加子单</Typography.Link>
										</Space>

									</Table.Summary.Cell>
								</Table.Summary.Row>
							</>
						);
					}}
					scroll={{x: "max-content"}}
				/>
			</div>
		);
	}
}

export default OrderlinesTable;