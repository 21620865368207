import React, {Component} from "react";
import {PageHeader, Descriptions, Typography, message, Spin, Button, Tag, Space, Divider, Modal, Select, Form} from "antd";
import {axios, ProdServer} from "../../Managers";
import {inject, observer} from "mobx-react";
import PopOrderDetail from "../Order/PopOrderDetail";

@inject("Operator", "SaleOrderManager")
@observer
class LogisticDetail extends Component {
	constructor() {
		super();
		this.state = {
			data: null,
			traces: [],
			loading: true,
			visible: false,
			SaleOrders: []
		}
	}

	componentDidMount() {
		this.refresh()
	}

	refresh = () => {
		this.setState({loading: true})
		axios.post("/scm/logistic/", {"action": "listLogistic", "data": {"logisticUUID": this.props.logisticUUID}}).then(res => {
			if (res.data.status === 200) {
				this.setState({data: res.data.data[0], loading: false})
				axios.post("/scm/logistic/", {
					"action": "getTracks", "data": {
						"logisticUUID": this.props.logisticUUID
					}
				}).then(res => {
					if (res.data.status === 200) {
						this.setState({traces: res.data.data, loading: false})
					} else {
						message.error(res.data.message)
						this.setState({loading: false})
					}
				})
			} else {
				message.error(res.data.message)
				this.setState({loading: false})
			}
		})
		this.setState({loading: false})

	}
	onSearch = (keywords) => {
		// 关联订单搜索
		if (!this.state.loading) {
			if (keywords === "") {
				this.setState({SaleOrders: []});
			} else {
				this.setState({loading: true});
				this.props.SaleOrderManager.listSaleOrder({keywords: keywords}).then(response => {
					this.setState({SaleOrders: response.data.data, loading: false});
				})
				this.setState({loading: false});
			}
		}
	};
	pullTraces = () => {
		this.setState({loading: true})
		axios.post("/scm/logistic/", {"action": "pullTraces", "data": {"logisticUUID": this.props.logisticUUID}}).then(res => {
			if (res.data.status === 200) {
				this.setState({traces: res.data.data, loading: false})
			} else {
				message.error(res.data.message)
				this.setState({loading: false})
			}
		})
		this.setState({loading: false})
	}

	bondOrders = (values) => {
		axios.post("/scm/logistic/", {action: "bondOrders", data: {logisticUUID: this.props.logisticUUID, orders: values.orders}}).then(res => {
			if (res.data.status === 200) {
				message.success("更新成功")
				this.refresh()
			} else {
				message.error(res.data.message)
			}
		})
		this.setState({visible: false})
	}

	manual = () => {
		axios.post("/scm/logistic/", {action: "ManualClose", data: {logisticUUID: this.props.logisticUUID}}).then(res => {
			if (res.data.status === 200) {
				message.success("更新成功")
				this.refresh()
			} else {
				message.error(res.data.message)
			}
		})
		this.setState({visible: false})
	}

	printLogisticPaper = (logisticUUIDS) => {
		this.setState({loading: true});
		axios.post("/scm/logistic/", {action: "printLogisticPaper", data: {logisticUUIDS: logisticUUIDS}}).then(res => {
			if (res.data.status === 200) {
				for (let x of res.data.data) {
					window.open(ProdServer + "scm/logistic/?path=" + x, "_blank");
				}
				this.setState({loading: false});
			} else {
				message.error(res.data.message);
			}
		});
	};

	render() {
		const data = this.state.data
		if (data) {
			return (
				<Spin spinning={this.state.loading} tip={"重新拉取数据..."}>
					<div>
						<Modal visible={this.state.visible} title={"绑定订单"} footer={null}>
							<Form onFinish={this.bondOrders}>
								<Form.Item name={"orders"} label={"订单号"} required={true}>
									<Select mode={"multiple"} onSearch={this.onSearch} loading={this.state.loading} style={{minWidth: "100%"}}
									        placeholder={"输入订单号或客户参照进行搜索"}
									        notFoundContent={"找不到相关订单"}
									        filterOption={(i, o) => {
										        return true;
									        }}>
										{this.state.SaleOrders.map(saleorder => {
											return (
												<Select.Option value={saleorder.orderId} key={saleorder.orderId}>
													<span>{saleorder.orderId}({saleorder.customerRef})-{saleorder.statusDisplay}</span>
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
								<div className="headerButton">
									<Button type={"primary"} htmlType={"submit"}>确定</Button>
									<Divider type={"vertical"}/>
									<Button type={"primary"} onClick={() => {this.setState({visible: false})}}>取消</Button>
								</div>

							</Form>
						</Modal>
						<PageHeader title={`${data.shipper.shipperName}-${data.logisticCode}`} extra={[
							<Button size={this.props.Operator.componentSize} onClick={this.refresh}>刷新</Button>,
							<Button size={this.props.Operator.componentSize} onClick={this.pullTraces}>查询轨迹</Button>,
							<Button size={this.props.Operator.componentSize} onClick={() => {this.printLogisticPaper([this.props.logisticUUID])}}>补打面单</Button>,
							<Button size={this.props.Operator.componentSize} onClick={() => {this.setState({visible: true})}}>订单绑定</Button>,
							<Button size={this.props.Operator.componentSize} onClick={this.manual}>人工完结</Button>
						]}>
							<Descriptions
								key={`desc-{data.logisticCode}`}
								bordered
								labelStyle={{minWidth: 90, textAlign: "center"}}
								size={this.props.Operator.componentSize}
								column={{xxl: 4, xl: 4, lg: 2, md: 2, sm: 1, xs: 1}}>
								<Descriptions.Item label="物流商">{data.shipper.shipperName}</Descriptions.Item>
								<Descriptions.Item label="面单号">{data.logisticCode}</Descriptions.Item>
								<Descriptions.Item label="物流状态">
									{data.lastState === "sent" ? <Tag color={"#C0C0C0"}>{data.lastStateDisplay}</Tag> : ""}
									{data.lastState === "pull" ? <Tag color={"#0000FF"}>{data.lastStateDisplay}</Tag> : ""}
									{data.lastState === "shipping" ? <Tag color={"#FF9999"}>{data.lastStateDisplay}</Tag> : ""}
									{data.lastState === "delivery" ? <Tag color={"#FF9999"}>{data.lastStateDisplay}</Tag> : ""}
									{data.lastState === "received" ? <Tag color={"#00CC00"}>{data.lastStateDisplay}</Tag> : ""}
									{data.lastState === "manual" ? <Tag color={"#00CC00"}>{data.lastStateDisplay}</Tag> : ""}
									{data.lastState === "problem" ? <Tag color={"#FF0033"}>{data.lastStateDisplay}</Tag> : ""}
								</Descriptions.Item>
								<Descriptions.Item label="结算方式">{data.payTypeDisplay}</Descriptions.Item>
								<Descriptions.Item label="创建时间">{data.createTime}</Descriptions.Item>
								<Descriptions.Item label="更新时间">{data.lastUpdate}</Descriptions.Item>
								<Descriptions.Item
									label="收件人">{`
								${data.receiver.ProvinceName}
								${data.receiver.CityName}
								${data.receiver.ExpAreaName}
								${data.receiver.Address.slice(0,
									-6)}****-${data.receiver.Name}(${data.receiver.Mobile.slice(0, 3)}****${data.receiver.Mobile.slice(
									-3)})`}</Descriptions.Item>

								<Descriptions.Item label="订单备注" span={2}>{data.note}</Descriptions.Item>


								<Descriptions.Item label="包内订单" span={4}>
									<Space split={<Divider type={"horizontal"}/>}>
										{data.orders.map(order => {
											return <Typography.Link onClick={() => PopOrderDetail(order)}>{order}</Typography.Link>
										})}
									</Space>
								</Descriptions.Item>

								<Descriptions.Item label="物流轨迹" span={4}>
									<div className="logs">
										{this.state.traces.map(t => {
											return (
												<Typography.Text code key={Math.random()}>{t.trackTime} |
													[{t.trackDetail}]</Typography.Text>
											);
										})}
									</div>
								</Descriptions.Item>

							</Descriptions>
						</PageHeader>
					</div>
				</Spin>
			);
		} else {
			return <Spin tip={"加载中..."}/>
		}
	}
}

export default LogisticDetail;