import React, {Component} from "react";
import {axios} from "../../Managers";
import {Table} from "antd";
import {inject, observer} from "mobx-react";

@inject("SaleOrderManager", "Operator", "CustomerManager", "ProductsManager")
@observer
class MTOFactory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: []
		};
	}

	componentDidMount() {
		this.getFactories();
	}

	getFactories = () => {
		this.setState({loading: true});
		axios.get("/mto/mtofactory/").then(response => {
			if (response.status === 200) {
				this.setState({
					data: response.data.data,
					loading: false
				});
			}
		});
	};

	render() {
		let _operator = this.props.Operator;
		let columns = [
			{title: "产线名称", dataIndex: "factoryName", align: "center"},
			{
				title: "控制器", key: "worker", align: "center", render: (text, record) => {
					if (record.config.host) {
						return record.config.host;
					} else {
						return "未注册";
					}
				}
			},
			{title: "UUID", dataIndex: "factoryUUID", align: "center"},
			{title: "产线管理员", dataIndex: "factoryManager", align: "center"},
			{title: "当前状态", dataIndex: "statusDisplay", align: "center"}
		];
		return (
			<div className={"main"}>
				<Table
					size={_operator.componentSize}
					rowKey={"factoryUUID"}
					columns={columns}
					dataSource={this.state.data}
					bordered
					loading={this.state.loading}
				/>
			</div>
		);
	}
}

export default MTOFactory;