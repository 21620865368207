import React, {Component} from "react";
import {Form, Input, Select, Modal, Button, Typography, DatePicker, Row, Col, Switch} from "antd";
import {inject, observer} from "mobx-react";
import Address from "../Address/index";
import OrderlinesTable from "../Order/OrderlinesTable";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import {axios, Log} from "../../Managers";

@inject("SaleOrderManager", "Operator", "CustomerManager")
@observer
class CreateOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalVisible: false,
			currentAddress: null,
			addressBook: [this.props.Operator.parent.defaultAddress],
			loading: false,
			currentPartner: this.props.Operator.parent,
			shippers: []
		};
		this.form = React.createRef();
		this.linesTable = React.createRef();

	}

	componentDidMount() {
		axios.get("/scm/shipper/").then(res => {
			this.setState({shippers: res.data.data});
		});
	}

	onEditAddress = (record) => {
		this.setState({
			currentAddress: record,
			isModalVisible: true
		});
	};

	onFinishEditAddress = (values) => {
		let threeCode = values.threeCode;
		delete values["threeCode"];
		values["province"] = threeCode[0];
		values["city"] = threeCode[1];
		values["district"] = threeCode[2];
		this.setState({loading: true});
		this.props.CustomerManager.createCustomer(values).then(data => {
			let _book = [data.data[0]];
			for (let i of this.state.addressBook) {
				if (i.pk !== data.data[0].pk) {
					_book.push(i);
				}
			}
			this.setState({isModalVisible: false, currentAddress: null, loading: false, addressBook: _book},
				this.form.current.setFieldsValue({
					address: data.data[0].pk
				})
			);
		});
	};

	onChangePartner = (value) => {
		// let defaultAddress = this.props.Operator.getPartnerDefaultAddress(value);
		// this.setState({addressBook: [defaultAddress]});
		let _partner = this.props.Operator.parent;
		for (let x of this.props.Operator.partners) {
			if (x.pk === value) {
				_partner = x;
			}
		}
		this.setState({currentPartner: _partner, addressBook: [_partner.defaultAddress]});
		this.form.current.setFieldsValue({"address": _partner.defaultAddress.pk, "payType": _partner.defaultPayType});
	};

	onSearch = (value) => {
		if (!this.state.loading) {
			this.setState({loading: true});
			this.props.CustomerManager.listCustomer({keywords: value}).then(response => {
				if (response.data.status === 200 && response.data.total >= 1) {
					this.setState({addressBook: response.data.data, loading: false},
						this.form.current.setFieldsValue({
							address: response.data.data[0].pk
						}));
				} else {
					this.setState({loading: false});
				}
			}).catch(error => {
				Log(error);
				this.setState({loading: false});
			});
		}
	};

	render() {
		let size = this.props.Operator.componentSize
		const _operator = this.props.Operator;
		const layout = {
			labelCol: {span: 3},
			wrapperCol: {span: 21}
		};
		let _ed = new Date();
		_ed.setDate(_ed.getDate() + 3);
		let _partners = [this.props.Operator.parent];
		let _plist = [this.props.Operator.parent.pk];
		for (let x of this.props.Operator.partners) {
			if (_plist.indexOf(x.pk) === -1) {
				_partners.push(x);
				_plist.push(x.pk);
			}
		}

		return (
			<div className={"main"}>
				<Modal title={"联系人信息"} visible={this.state.isModalVisible}
				       destroyOnClose={true}
				       footer={null} closable={false}
				       onOk={this.onFinishEditAddress}
				>
					<Address onFinished={this.onFinishEditAddress}
					         currentAddress={this.state.currentAddress}
					         onCancel={() => {
						         this.setState({isModalVisible: false});
					         }}/>
				</Modal>

				<Form size={size} {...layout} ref={this.form}>
					<Row>
						<Col span={8}>
							<Form.Item name={"partner"} label={"订单渠道"}
							           initialValue={_operator.parent.pk}
							           rules={[
								           {
									           required: true,
									           message: "渠道为必选项"
								           }]}
							           labelCol={{span: 10}}
							           wrapperCol={{span: 14}}
							>
								<Select onChange={this.onChangePartner} showSearch
								        filterOption={(input, option) => {
									        return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								        }}>
									{_partners.map(partner => {
										return (
											<Select.Option value={partner.pk} disabled={!partner.isActive}
											               key={partner.shortCode}>{partner.displayName}({partner.shortCode})</Select.Option>
										);
									})}
								</Select>

							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name={"company"} label={"指定专户"} labelCol={{span: 10}}
							           initialValue={_operator.company.companyUUID}
							           wrapperCol={{span: 14}} rules={[
								{
									required: true,
									message: "必须指定生产专户"
								}
							]}>
								<Select optionLabelProp={"label"}>
									{_operator.companies.map(c => {
										return <Select.Option value={c.companyUUID} key={c.companyUUID} label={c.companyName}>{c.companyName}</Select.Option>;
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={10}>
							<Form.Item name={"customerRef"} label={"客户参照"} labelCol={{span: 10}}
							           wrapperCol={{span: 14}} rules={[
								{
									required: true,
									message: "客户参照为必填项,可以输入客户订单号,或天猫京东订单号"
								}]}>
								<Input/>
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col span={7}>
							<Form.Item name={"expectedDate"} label={"期望交期"} initialValue={moment(_ed)} required
							           labelCol={{span: 11}} wrapperCol={{span: 12}}>
								<DatePicker picker={"date"} disabledDate={current => {
									return current && current < moment().endOf("day");
								}} locale={locale} format="YYYY-MM-DD"/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name={"urgent"} label={"加急"} initialValue={false} valuePropName={"checked"} required labelCol={{span: 12}}
							           wrapperCol={{span: 12}}>
								<Switch checkedChildren="加急" unCheckedChildren="正常"/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name={"deliver"} label={"指定发货方式"} initialValue={this.props.Operator.defaultShipper} required labelCol={{span: 11}}
							           wrapperCol={{span: 12}}>
								<Select>
									{this.state.shippers.map(s => {
										return (
											<Select.Option value={s.shipperCode} key={s.shipperCode}>{s.shipperName}</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item name={"address"} label={"收货地址"} initialValue={_operator.parent.defaultAddress.pk}
							           rules={[
								           {
									           required: true,
									           message: "收货地址为必填项"
								           }]}
							>
								<Select optionLabelProp="label"
								        loading={this.state.loading}
								        dropdownRender={menu => (
									        <div>
										        {menu}
										        <div className="addressOption">
											        <Input.Search size={this.props.Operator.componentSize} addonBefore={"手机号获取信息:"}
											                      onSearch={this.onSearch}
											                      onChange={event => this.onSearch(event.target.value)}/>
											        <Button type={"primary"} size={this.props.Operator.componentSize}
											                onClick={() => this.onEditAddress(null)}>添加新联系人</Button>
										        </div>
									        </div>
								        )}
								        onSelect={(value, option) => {
									        if (value === "new") {
										        this.onEditAddress(null);
									        }
								        }}>
									{this.state.addressBook.map(address => {
										return (
											<Select.Option value={address.pk}
											               key={address.pk}
											               label={`${address.province}
                                                   ${address.city}
                                                   ${address.district}
                                                   ${address.address}-
                                                   ${address.name}
                                                       (${address.mobileNumber === "" ? address.phoneNumber : address.mobileNumber})`}
											>
												<div className="addressOption">
                                            <span>
                                                {address.province}
	                                            {address.city}
	                                            {address.district}
	                                            {address.address}-
	                                            {address.name}
	                                            ({address.mobileNumber === "" ? address.phoneNumber : address.mobileNumber})
                                            </span>
													<Typography.Link
														onClick={() => this.onEditAddress(_operator.parent.defaultAddress)}>修改</Typography.Link>
												</div>
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>


					<Form.Item name={"orderlines"} label={"订单明细"} rules={[
						{
							required: true,
							message: "必须至少包含一个订单行",
							type: "array"
						},
						{
							validator: async (rule, values) => {
								for (let x of values) {
									if (x.product !== null) {
										if (x.productInfo.productType === "cmp") {
											if (x.layoutImage === null) {
												throw new Error(`第${values.indexOf(x) + 1} 行未设置版下图`);
											}
										}
									} else {
										throw new Error(`第${values.indexOf(x) + 1} 行未选择产品`);
									}
								}
							}
						}
					]}>
						<OrderlinesTable currentPartner={this.state.currentPartner}/>
					</Form.Item>
					<Form.Item name={"note"} label={"订单备注"}>
						<Input.TextArea/>
					</Form.Item>
				</Form>

			</div>
		);
	}
}

export default CreateOrder;
