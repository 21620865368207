import React from "react";
import {Modal} from "antd";
import SaleOrderDetail from "./SaleOrderDetail";
import {Provider} from "mobx-react";
import stores from "../../Managers/store";

const PopOrderDetail = (orderId) => {
	Modal.info({
		centered: true,
		maskClosable: true,
		closable: true,
		icon: null,
		width: "90vw",
		content: <Provider {...stores}><SaleOrderDetail orderId={orderId}/></Provider>,
		okText: "关闭",
	});
};

export default PopOrderDetail;