import React, {Component} from "react";
import {axios} from "../../Managers"
import {Button, Descriptions, Divider, Space, Table, message} from "antd";
import moment from "moment";
import ReactToPrint from "react-to-print"

class StockMoveDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			stockmove: undefined
		}
		this.printContent = React.createRef()
	}

	componentDidMount() {
		this.list_stockmove()
	}

	list_stockmove = () => {
		axios.get(`/mto/stockmove?moveid=${this.props.moveid}`).then(res => {
			if (res.data.status === 200) {
				this.setState({
					stockmove: res.data.data[0],
					loading: false
				})
			}
		})
	}

	printPaper = () => {

	}

	accept_stockmove = (moveid) => {
		this.setState({loading: true})
		axios.post("/mto/stockmove/", {action: "accept_stockmove", moveid: moveid}).then(res => {
			if (res.data.status === 200) {
				this.setState({stockmove: res.data.data, loading: false}, this.forceUpdate())
				message.success("确认成功")
			}
		})
	}
	send_stockmove = (moveid) => {
		this.setState({loading: true})
		axios.post("/mto/stockmove/", {action: "send_stockmove", moveid: moveid}).then(res => {
			if (res.data.status === 200) {
				this.setState({stockmove: res.data.data, loading: false}, this.forceUpdate())
				message.success("确认成功")
			}
		})
	}

	render() {
		let operator = this.props.operator
		let stockmove = this.state.stockmove
		if (this.state.stockmove !== undefined) {
			let columns = [
				{title: "拣货ID", dataIndex: "pickingId", align: "center"},
				{title: "物料名称", dataIndex: "material", align: "center"},
				{title: "拣货数量", dataIndex: "quantity", align: "center"},
				{title: "触发源", dataIndex: "trigger", align: "center"}
			]
			return (
				<div ref={this.printContent}>
					<Descriptions bordered={true} size={operator.componentSize} key={Math.random()}
					              labelStyle={{minWidth: 90, textAlign: "center"}}
					              column={{xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1}}
					              title={stockmove.moveId}
					              extra={<Space split={<Divider type={"vertical"}/>} className={"hiddenOnPrint"}>
						              <ReactToPrint content={() => {return this.printContent.current}}
						                            trigger={() => {return <Button key={Math.random()} size={operator.componentSize}>打印调拨单</Button>}}/>

						              <Button key={Math.random()} size={operator.componentSize}>添加物流信息</Button>
						              <Button key={Math.random()} size={operator.componentSize} onClick={() => {this.send_stockmove(stockmove.moveId)}}
						                      disabled={stockmove.permissions.indexOf("send_stockmove") === -1 || stockmove.status !==
							                      "draft" || stockmove.source.uuid !== this.props.warehouse_uuid || stockmove.moveType === "BMO" ||
							                      stockmove.moveType === "PDO"}>确认发出</Button>
						              <Button key={Math.random()} size={operator.componentSize} onClick={() => {this.accept_stockmove(stockmove.moveId)}}
						                      disabled={stockmove.permissions.indexOf("accept_stockmove") === -1 || stockmove.status ===
							                      "accepted" || stockmove.status ===
							                      "draft" || (stockmove.moveType === "BMO" || stockmove.moveType === "PDO" ||
								                      stockmove.moveType === "SYS")}>确认到货</Button>
					              </Space>}
					>
						<Descriptions.Item label={"来源库"}>{stockmove.source.displayName}</Descriptions.Item>
						<Descriptions.Item label={"目标库"}>{stockmove.destination.displayName}</Descriptions.Item>
						<Descriptions.Item label={"创建时间"}>{moment(stockmove.createTime).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
						<Descriptions.Item label={"创建人"}>{stockmove.createOwner}</Descriptions.Item>
						<Descriptions.Item label={"调拨类型"}>{stockmove.moveTypeDisplay}</Descriptions.Item>
						<Descriptions.Item label={"当前状态"}>{stockmove.statusDisplay}</Descriptions.Item>
						<Descriptions.Item label={"拣货明细"} span={3}>
							<Table columns={columns} dataSource={stockmove.pickings} pagination={false}
							       size={operator.componentSize}/>
						</Descriptions.Item>
						<Descriptions.Item label={"物流信息"} span={3}>物流信息</Descriptions.Item>
						<Descriptions.Item label={"操作日志"} span={3}>{stockmove.logs.map(log => {
							return <div key={Math.random()}>{log.createTime}-{log.operator}-{log.message}</div>
						})}</Descriptions.Item>
					</Descriptions>
				</div>
			);
		}
		return ""
	}
}

export default StockMoveDetail;