import React, {Component} from "react";
import {Row, Col, Card, Divider, DatePicker} from "antd";
import {Line} from "@ant-design/charts";
import moment from "moment";
import {axios} from "../../Managers";
import {Scene} from "@antv/l7";
import {CountryLayer} from "@antv/l7-district";
import {Mapbox} from "@antv/l7-maps";
import {inject, observer} from "mobx-react";

class MyCard extends Component {
	constructor(props) {
		super(props);
		const dateFormat = "YYYY-MM-DD";
		let st = new Date(new Date().setMonth((new Date().getMonth() - 3)));
		let et = new Date();
		this.state = {
			data: [],
			dateFrom: moment(st, dateFormat),
			dateTo: moment(et, dateFormat)

		};
	}

	componentDidMount() {
		this.onUpdateData();
	}

	onUpdateData = () => {
		axios.get("/analyze/analyze/",
			{params: {type: this.props.type, dateFrom: this.state.dateFrom.format("YYYY-MM-DD"), dateTo: this.state.dateTo.format("YYYY-MM-DD")}}).then(res => {
			this.setState({data: res.data.data});
		});
	};

	ChangeDate = async (date, dateStr) => {
		const dateFormat = "YYYY-MM-DD";
		await this.setState({
			dateFrom: moment(date[0], dateFormat), dateTo: moment(date[1], dateFormat)
		});
		await this.onUpdateData()
	};

	render() {

		let css = "analyzeCard40";
		if (this.props.size === 24) {
			css = "analyzeCard";
		}
		let config = {};

		switch (this.props.type) {
			case "salehistory":
				config = {
					data: this.state.data,
					xField: "date",
					padding: "auto",
					yField: "value",
					xAxis: {tickCount: 5},
					slider: {
						start: 0,
						end: 1
					},
					meta: {
						"value": {
							alias: "订单数"
						}
					}
				};
				return <Card title={this.props.title} size={"small"} bordered={true}
				             extra={<DatePicker.RangePicker size={"small"} onChange={this.ChangeDate} defaultValue={
					             [this.state.dateFrom, this.state.dateTo]
				             }/>}>
					<div className={css}>
						<Line {...config}/>
					</div>
				</Card>
			case "producthistory":
				config = {
					data: this.state.data,
					padding: "auto",
					xField: "date",
					yField: "count",
					seriesField: "productName",
					slider: {
						start: 0,
						end: 1
					},
					yAxis: {
						label: {
							formatter: function formatter(v) {
								return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
									return "".concat(s, ",");
								});
							}
						}
					},
					color: ["#1979C9", "#D62A0D", "#FAA219"]
				};
				return <Card title={this.props.title} size={"small"} bordered={true}
				             extra={<DatePicker.RangePicker size={"small"} defaultValue={
					             [this.state.dateFrom, this.state.dateTo]
				             } onChange={this.ChangeDate}/>}>
					<div className={css}>
						<Line {...config}/>
					</div>
				</Card>;
			default:
				break;
		}
		return <Card title={this.props.title} size={"small"} bordered={true}
		             extra={<DatePicker.RangePicker size={"small"} defaultValue={
			             [this.state.dateFrom, this.state.dateTo]
		             } onChange={this.ChangeDate}/>}>
			<div className={css}/>
		</Card>;
	}
}

@inject("ProductsManager")
@observer
class Dashboard extends Component {
	constructor(props) {
		super(props);
		let st = new Date(new Date().setMonth((new Date().getMonth() - 3)));
		let et = new Date();
		this.state = {
			mapData: [],
			dateFrom: st,
			dateTo: et,
			neworder: 0,
			production: 0,
			sent: 0,
			problem: 0
		};
	}

	componentDidMount() {
		axios.get("/analyze/analyze/", {params: {type: "usermap"}}).then(res => {
			this.setState({mapData: res.data.data});
			const mapColors = ["#EFF2FB", "#E0E6F8"];
			const scene = new Scene({
				id: "usermap",
				logoVisible: false,
				map: new Mapbox({
					center: [116.2825, 39.9],
					pitch: 0,
					style: "blank",
					zoom: 3,
					minZoom: 0,
					maxZoom: 10
				})
			});

			scene.on("loaded", () => {
				new CountryLayer(scene, {
					data: this.state.mapData,
					joinBy: ["NAME_CHN", "name"],
					depth: 1,
					provinceStroke: "#fff",
					cityStroke: "#EBCCB4",
					// cityStrokeWidth: 1,
					fill: {
						color: {
							field: "value",
							values: mapColors
						}
					},
					popup: {
						enable: true,
						Html: (props) => {
							return `<span>${props.NAME_CHN}-${props.value}票</span>`;
						}
					}
				});
			});
		});
		axios.get("/analyze/analyze/?type=today").then(resp => {
			this.setState({...resp.data.data});
		});
	}

	render() {
		return (
			<div className={"main"}>
				<Row align={"top"} justify={"space-between"}>
					<Col sm={24} md={24} lg={7}>
						<Row align={"top"} justify={"space-around"}>
							<Col span={11}>
								<Card size={"small"} title={"当日新进"}>
									<div className={"digital"}>
										<span>{this.state.neworder}</span>
									</div>
								</Card>
							</Col>
							<Col span={11}>
								<Card size={"small"} title={"当日生产"}>
									<div className={"digital"}>
										<span>{this.state.production}</span>
									</div>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Divider/>
							</Col>
						</Row>
						<Row align={"bottom"} justify={"space-around"}>
							<Col span={11}>
								<Card size={"small"} title={"当日发出"}>
									<div className={"digital"}>
										<span>{this.state.sent}</span>
									</div>
								</Card>
							</Col>
							<Col span={11}>
								<Card size={"small"} title={"问题物流"}>
									<div className={"digitalAlert"}>
										<span>{this.state.problem}</span>
									</div>
								</Card>
							</Col>
						</Row>
					</Col>
					<Col sm={24} md={24} lg={17}>
						<MyCard title={"订单量历史"} size={24} type={"salehistory"}/>
					</Col>

				</Row>
				<Divider/>
				<Row align={"top"} justify={"space-between"}>
					<Col sm={24} md={24} lg={7}>
						<Card title={"客户分布"} size={"small"} bordered={true}>
							<div className={"analyzeCard40"} id={"usermap"}/>
						</Card>
					</Col>
					<Col sm={24} md={24} lg={17}>
						<MyCard title={"产品销量历史"} size={40} type={"producthistory"}/>
					</Col>
					{/*<Col sm={24} md={24} lg={12}>*/}
					{/*	<MyCard title={"产销历史"} size={40}/>*/}
					{/*</Col>*/}
				</Row>
			</div>
		);
	}
}

export default Dashboard;
