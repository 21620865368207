import axios from "axios"
import {message} from "antd"

axios.defaults.withCredentials = true;

let getCsrfToken = () => {
	let cookieValue = null;
	if (document.cookie && document.cookie !== "") {
		let cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, "csrftoken".length + 1) === (`csrftoken=`)) {
				cookieValue = decodeURIComponent(cookie.substring("csrftoken".length + 1));
				break;
			}
		}
	}
	return cookieValue;
}
axios.interceptors.request.use(
	function(config) {
		// 在post请求前统一添加X-CSRFToken的header信息
		let cookie = document.cookie;
		if (cookie && config.method === "post") {
			config.headers["X-CSRFToken"] = getCsrfToken();
		}
		return config;
	},
	function(error) {
		// Do something with request error

		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => {
		return response
	}
)

class OrderLine {
	seq
	product = null
	quantity = 1
	price = 0
	discount = 100
	summary = 0
	note = ""
	config = null
	productInfo = null
	layoutImage = null

	getRemoteImage = (sourceOrderNo) => {
		axios.post("/api/saleorder/", {action: "getRemoteImage", data: {"sourceOrderNo": sourceOrderNo}}).then(res => {
			if (res.status === 200) {
				message.success("图片提取成功!")
				this.setLayoutImage(res.data.data[0].name)
			} else {
				message.warning("图片提取失败!")
			}
		})
	}

	setLayoutImage = (uuid) => {
		this.layoutImage = uuid
	}

	onChangeProduct = (productUUID) => {
		axios.get("/api/product/?productUUID=" + productUUID).then(response => {
			let data = response.data.data[0]
			this.product = productUUID
			this.price = data.productPrice
			this.summary = this.quantity * this.price * (this.discount / 100)
			this.productInfo = data
		})

	}

	onChangeQty = (qty) => {
		this.quantity = qty
		this.summary = this.quantity * this.price * (this.discount / 100)
	}

	onChangeDiscount = (discount) => {
		this.discount = discount
		this.summary = this.quantity * this.price * (this.discount / 100)
	}

	onChangeNote = (note) => {
		this.note = note.target.value
	}
}

let Log = (message) => {
}

const DownFile = (blob, fileName) => {
	if (window.navigator.msSaveOrOpenBlob) {
		navigator.msSaveBlob(blob, fileName);
	} else {
		let link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.setAttribute("target", "_blank")
		link.click();
		window.URL.revokeObjectURL(link.href);

	}
}

const ProdServer = `${window.document.location.origin}/`

export {axios, getCsrfToken, OrderLine, Log, DownFile, ProdServer}