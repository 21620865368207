import React, {Component} from "react";
import {Table, Button, Input, Divider, Tag, Space, Badge, message, Popover} from "antd";
import {inject, observer} from "mobx-react";
import {Typography} from "antd";
import moment from "moment";
import {AlertTwoTone, LockTwoTone} from "@ant-design/icons";
import {axios} from "../../Managers";

const {Link} = Typography;

@inject("SaleOrderManager", "Operator")
@observer
class SaleOrderTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			fullData: [],
			total: 0,
			current: 1,
			loading: false,
			selectedRows: []
		};
	}

	componentDidMount() {
		this.onChangePage(1, this.props.Operator.PaginationConfig.pageSize);
	}

	onRefresh = () => {
		this.onChangePage(this.state.current, this.state.pageSize);
	};

	onChangePage = (page, pageSize) => {
		this.setState({loading: true});
		this.props.SaleOrderManager.listSaleOrder({
			current: page,
			pageSize: pageSize,
			status: this.props.status
		}).then(response => {
			let _data = response.data;
			if (_data.status === 200) {
				this.setState({
					data: _data.data,
					fullData: _data.data,
					total: _data.total,
					pages: _data.pages,
					pageSize: _data.pageSize,
					current: _data.current,
					loading: false
				});
			}
		});
	};

	onFilter = (event) => {
		let keyword = new RegExp(event.target.value, "i");
		let _data = [];
		if (event.target.value !== "" && !this.state.loading) {
			this.setState({loading: true});
			for (let x of this.state.fullData) {
				if (x.orderId.search(keyword) !== -1 ||
					x.customerRef.search(keyword) !== -1 ||
					x.note.search(keyword) !== -1 ||
					x.address.name.search(keyword) !== -1
				) {
					_data.push(x);
				}
			}
			this.setState({data: _data, loading: false});
		} else {
			this.setState({data: this.state.fullData, loading: false});
		}

	};

	acceptOrder = async () => {
		this.setState({loading: true})
		for (let x of this.state.selectedRows) {
			await this.props.SaleOrderManager.toggleStatus(x.orderId, "accepted").then(response => {
				if (response.data.status === 200) {
					message.success(`订单${response.data.data[0].orderId}接受成功!`)
				} else {
					message.error(response.data.message, 3);
				}
			});
		}
		this.onRefresh();
	}

	recomputeStatus = async () => {
		this.setState({loading: true})
		for (let x of this.state.selectedRows) {
			await this.props.SaleOrderManager.orderAction(x.orderId, "recomputeStatus").then(response => {
				if (response.data.status === 200) {
					message.success(`订单${x.orderId}重算成功，重算订单会改变草稿状态到接受，请到当前订单中查看!`)
				} else {
					message.error(response.data.message, 3);
				}
			});
		}
		this.onRefresh();
	}

	pickUp = (orderId) => {
		this.setState({loading: true})
		for (let x of this.state.selectedRows) {
			axios.post("/api/saleorder/", {
				action: "pickUp",
				data: {orderId: x.orderId}
			}).then(response => {
				if (response.data.status === 200) {
					message.success(`订单${x.orderId}取货确认成功！`)
				} else {
					message.error(response.data.message, 3);
				}
			})
		}
		this.onRefresh();
	}

	send_pickUp_message = (orderId) => {
		this.setState({loading: true})
		for (let x of this.state.selectedRows) {
			axios.post("/api/saleorder/", {
				action: "send_pickUp_message",
				data: {orderId: x.orderId}
			}).then(response => {
				if (response.data.status === 200) {
					message.success(response.data.data)
				} else {
					message.error(response.data.message, 3);
				}
			})
		}
		this.onRefresh();
	}

	tableHeader = () => {
		return (
			<div className={"headerButton"}>
				<Input.Search onChange={this.onFilter} allowClear={true} size={this.props.Operator.componentSize}/>
				<Space spit={<Divider type={"vertical"}/>} style={{marginLeft: 10}}>
					{this.props.status[0] === "draft" ? <Button type={"primary"} onClick={this.acceptOrder} disabled={this.state.selectedRows.length === 0}
					                                            size={this.props.Operator.componentSize}>接受订单</Button> : ""}

					<Button onClick={this.recomputeStatus} size={this.props.Operator.componentSize}>重算状态</Button>
					<Button onClick={this.pickUp} size={this.props.Operator.componentSize}>现场取货</Button>
					<Button onClick={this.send_pickUp_message} size={this.props.Operator.componentSize}>发送取货通知</Button>
					<Button type={"primary"} onClick={this.onRefresh} size={this.props.Operator.componentSize}>刷新</Button>
					{this.props.createOrder ? <Button onClick={this.props.createOrder} size={this.props.Operator.componentSize}>创建订单</Button> : null}
				</Space>
			</div>

		);
	};

	render() {
		let pfilter = [];
		this.props.Operator.partners.map(p => {
			pfilter.push({text: p.displayName, value: p.displayName});
			return "";
		});
		const columns = [
			{
				title: "订单号",
				dataIndex: "orderId",
				align: "center",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"],
				render: (text, record) => {
					if (record.urgent) {
						return <Badge count={"急"} offset={[10, 3]} size={"small"}><Link
							onClick={() => this.props.showDetail(record)}>{text}</Link>
							<div className={"flotRight"}> {record.lockCase
								? <AlertTwoTone/>
								: ""}
								{record.status ===
								"locked" ? <LockTwoTone/> : ""}</div>
						</Badge>;
					}
					return <Link onClick={() => this.props.showDetail(record)}>{text}
						<div className={"flotRight"}> {record.lockCase
							? <AlertTwoTone/>
							: ""}
							{record.status ===
							"locked" ? <LockTwoTone/> : ""}</div>
					</Link>;
				}
			},

			{title: "客户参照", dataIndex: "customerRef", align: "center", width: 180, ellipsis: true, responsive: ["md", "lg", "xl", "xxl"]},
			{
				title: "订单状态",
				dataIndex: "statusDisplay",
				align: "center",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"],
				render: (text, record) => {
					switch (record.status) {
						case "draft":
							return <Tag color={"gray"}>{text}</Tag>;
						case "accepted":
							return <Tag color={"lime"}>{text}</Tag>;
						case "rejected":
							return <Tag color={"black"}>{text}</Tag>;
						case "cancelled":
							return <Tag color={"black"}>{text}</Tag>;
						case "producing":
							return <Tag color={"blue"}>{text}</Tag>;
						case "sent":
							return <Tag color={"purple"}>{text}</Tag>;
						case "transport":
							return <Tag color={"purple"}>{text}</Tag>;
						case "received":
							return <Tag color={"geekblue"}>{text}</Tag>;
						case "locked":
							return <Tag color={"red"}>{text}</Tag>;
						default:
							return <Tag color={"purple"}>{text}</Tag>;
					}
				},
				filters: [
					{text: "草稿", value: "draft"},
					{text: "已接受", value: "accepted"},
					{text: "已拒绝", value: "rejected"},
					{text: "已取消", value: "cancelled"},
					{text: "生产中", value: "producing"},
					{text: "已发货", value: "sent"},
					{text: "运输中", value: "transport"},
					{text: "已签收", value: "received"},
					{text: "锁定中", value: "locked"}
				],
				onFilter: (value, record) => record.status.indexOf(value) === 0
			},
			{
				title: "子单数量",
				align: "center",
				key: "orderlinesCount",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"],
				render: (text, record) => {
					return <span>{record.orderlines.length}</span>;
				}
			},
			{
				title: "订单渠道", dataIndex: "partner", align: "center", responsive: ["lg", "xl", "xxl"], filters: pfilter,
				onFilter: (value, record) => record.partner.indexOf(value) === 0
			},
			{
				title: "收货地址",
				key: "address",
				align: "center",
				width: 180,
				ellipsis: true,
				responsive: ["lg", "xl", "xxl"],
				render: (text, record) => {
					return <Popover title={"收件人"}
					                content={`${record.address.province}${record.address.city}${record.address.district}-${record.address.name}(${record.address.mobileNumber ===
					                "" ? record.address.phoneNumber : record.address.mobileNumber.slice(
						                0, 3) + "****" + record.address.mobileNumber.slice(-3)})`}><span
						key={Math.random()}>
						{`${record.address.province.substring(0, 2)}-${record.address.name}`}
					</span></Popover>
				}
			},
			{
				title: "创建时间", dataIndex: "createTime", align: "center", responsive: ["md", "lg", "xl", "xxl"],
				defaultSortOrder: "descend", render: ((text, record) => {return moment(record.createTime).format("YYYY-MM-DD HH:mm:ss")}),
				sorter: (a, b) => {
					let atime = new Date(Date.parse(a.createTime));
					let btime = new Date(Date.parse(b.createTime));
					return atime.getTime() - btime.getTime();
				}
			},
			{
				title: "期望交期", dataIndex: "expectedDate", align: "center", responsive: ["xl", "xxl"],
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					let atime = new Date(Date.parse(a.createTime));
					let btime = new Date(Date.parse(b.createTime));
					return atime.getTime() - btime.getTime();
				}
			}
		];
		if (this.props.status[0] === "draft" || this.props.status[0] === "waitdeliver") {
			return (
				<div className={"innerContent"}>
					<Table
						rowKey={"orderId"}
						className={"innerTable"}
						bordered
						columns={columns}
						size={this.props.Operator.componentSize}
						dataSource={this.state.data}
						loading={this.state.loading}
						pagination={{
							defaultPageSize: this.props.Operator.PaginationConfig.pageSize,
							pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
							onChange: this.onChangePage,
							onShowSizeChange: this.onChangePage,
							total: this.state.total,
							current: this.state.current,
							showSizeChanger: true,
							size: this.props.Operator.componentSize,
							style: {marginRight: 10},
							showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
						}}
						onRow={record => {
							return {
								onDoubleClick: event => {
									this.props.showDetail(record);
								}
							};
						}}
						rowSelection={{
							selectedRowKeys: this.state.selectedRowKeys,
							onChange: (selectedRowKeys, selectedRows) => {
								this.setState({selectedRowKeys: selectedRowKeys, selectedRows: selectedRows});
							}
						}}
						title={this.tableHeader}
					/>
				</div>
			);
		} else {
			return (
				<div className={"innerContent"}>
					<Table
						rowKey={"orderId"}
						className={"innerTable"}
						bordered
						columns={columns}
						size={this.props.Operator.componentSize}
						dataSource={this.state.data}
						loading={this.state.loading}
						pagination={{
							defaultPageSize: this.props.Operator.PaginationConfig.pageSize,
							pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
							onChange: this.onChangePage,
							onShowSizeChange: this.onChangePage,
							total: this.state.total,
							current: this.state.current,
							showSizeChanger: true,
							size: this.props.Operator.componentSize,
							style: {marginRight: 10},
							showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
						}}
						onRow={record => {
							return {
								onDoubleClick: event => {
									this.props.showDetail(record);
								}
							};
						}}
						title={this.tableHeader}
					/>
				</div>
			);
		}

	}
}

export default SaleOrderTable;