import React, {Component} from "react";
import {Button, Form, Input, Select, Row, Col, Space, message, Divider, Badge, Spin} from "antd";
import {Tag} from "element-react";
import {axios} from "../../Managers";
import AcceptedPool from "./acceptedPool";

class OrderLinesView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.planned
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		prevProps.onChange(prevState.data);
	}

	render() {
		return <Space split={<Divider type={"vertical"}/>} wrap={true}>
			{this.state.data.map(line => {
				if (line.quantity === 1) {
					return <Tag key={line.lineId}>{line.lineId}</Tag>;
				} else {
					return <Badge count={line.quantity} key={`badge-${line.lineId}`}><Tag key={line.lineId}>{line.lineId}</Tag></Badge>;
				}
			})}
		</Space>;
	}
}

class CreateMtoPlan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			mtoFactorys: [],
			templates: [],
			extraInfo: null,
			planned: [],
			disabled: true,
			submitloading: false,
			factory: undefined,
			cap: 0
		};
		this.form = React.createRef();
		this.pool = React.createRef();
	}

	componentDidMount() {
		this.getMTOFactorys();
	}

	getMTOFactorys = () => {
		this.setState({submitloading: true});
		axios.get("/mto/mtofactory/").then((response) => {
			if (response.data.status === 200) {
				let templates = new Array(0);
				for (let x of response.data.data) {
					templates = templates.concat(x.templates);
				}
				this.setState({mtoFactorys: response.data.data, templates, submitloading: false});
			}
		});
	};

	changeTemplate = async (pk) => {
		for (let x of this.state.templates) {
			if (x.pk === pk) {
				this.setState({extraInfo: x, limit: x.config.limit});
				this.pool.current.filterProducts(x.usableProduct_PKS);
			}
		}
	};

	changeFactory = async (value) => {
		let factory = parseInt(value.split("_")[0])
		let template = parseInt(value.split("_")[1])
		for (let x of this.state.mtoFactorys) {
			if (x.pk === factory) {
				// this.form.current.setFieldsValue({template: undefined})
				this.setState({factory: x.pk})
				await this.changeTemplate(template)
			}
		}
	}

	submitCreateMTOPlan = (values) => {
		this.setState({submitloading: true});
		values["mtoFactory"] = parseInt(values["planTemplate"].split("_")[0])
		values["template"] = parseInt(values["planTemplate"].split("_")[1])
		axios.post("/mto/mtoplan/", {action: "create", data: {...values}}).then(response => {
			if (response.data.status === 200) {
				message.success(response.data.message, 3);
				this.props.closeModal();
				this.setState({submitloading: false});
			} else {
				message.error(response.data.message, 5);
				this.setState({submitloading: false});
			}
		}).catch(error => {
			message.error(error.data, 5);
			this.setState({submitloading: false});
		});

	};

	planOrderLine = (orderLine) => {
		return new Promise((resolve, reject) => {
			if (this.state.cap + orderLine.quantity > this.state.extraInfo.maxCap) {
				reject("已达到最大产量!");
			} else {
				if (this.state.extraInfo.usableProduct_PKS.indexOf(orderLine.product.pk) !== -1) {
					let _planned = this.state.planned;
					let _cap = this.state.cap;
					if (this.state.planned.indexOf({lineId: orderLine.lineId, quantity: orderLine.quantity}) === -1) {
						_planned.push({lineId: orderLine.lineId, quantity: orderLine.quantity});
						this.setState({planned: _planned, cap: _cap + orderLine.quantity}, () => {
							resolve(orderLine.lineId);
						});
					} else {
						reject(`${orderLine.lineId} 已经在排产序列!`);
					}
				} else {
					reject("该计划不支持排产此产品");
				}
			}

		});
	};

	render() {
		return (
			<Spin spinning={this.state.submitloading}>
				<Form onFinish={this.submitCreateMTOPlan} ref={this.form}>
					<Form.Item name={"planTemplate"} label={"生产计划"} rules={[{required: true}]} hasFeedback>
						<Select onChange={this.changeFactory}>
							{this.state.mtoFactorys.map(fac => {
								return <Select.OptGroup label={fac.factoryName}>
									{fac.templates.map(
										t => {
											return <Select.Option value={`${fac.pk}_${t.pk}`}
											                      key={Math.random()}>{fac.factoryName}-{t.name}</Select.Option>
										})}
								</Select.OptGroup>
								// return <Select.Option value={fac.pk} key={fac.pk}>{fac.factoryName}</Select.Option>;
							})}
						</Select>
					</Form.Item>
					<Form.Item name={"note"} label={"备注信息"} rules={[{required: true}]} hasFeedback>
						<Input.TextArea/>
					</Form.Item>

					<Form.Item name={"orderLines"} label={"订单行"} hasFeedback rules={[
						{
							required: true,
							message: "必须至少包含一个订单行",
							type: "array"
						}
					]}>
						<OrderLinesView planned={this.state.planned}/>
					</Form.Item>


					<AcceptedPool planOrderLine={this.planOrderLine} ref={this.pool} extraInfo={this.state.extraInfo}/>
					<Row justify={"end"}>
						<Col>
							<Space split={<Divider type={"vertical"}/>}>
								<Button htmlType={"submit"} type={"primary"} size={this.props.Operator.componentSize}>创建</Button>
								<Button onClick={this.props.closeModal} size={this.props.Operator.componentSize}>取消</Button>
							</Space>
						</Col>

					</Row>


				</Form>
			</Spin>
		);
	}
}

export default CreateMtoPlan;