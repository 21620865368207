import React, {Component} from "react";
import {Card, Table, Typography, Modal} from "antd"
import {inject, observer} from "mobx-react";
import {axios} from "../../Managers"
import StockMoveDetail from "./StockMoveDetail";
import moment from "moment";

@inject("Operator")
@observer
class MoveTable extends Component {
	constructor(props) {
		super(props);
		this.operator = this.props.Operator
		let pgconfig = {...props.Operator.PaginationConfig}
		pgconfig.pageSize = 20
		this.state = {
			moves: [],
			loading: false,
			...pgconfig
		}
	}

	list_stockmove = (current = this.state.current, pageSize = this.state.pageSize) => {
		this.setState({loading: true})
		axios.post("/mto/stockmove/", {
			action: "list", warehouse_uuid: this.props.warehouse_uuid, data: {
				move_type: this.props.move_type,
				pageSize: pageSize, current: current
			}
		}).then(res => {
			if (res.data.status === 200) {
				this.setState({moves: res.data.data, current: res.data.current, pageSize: res.data.pageSize, total: res.data.total, loading: false})
			}
		})
	}

	componentDidMount() {
		this.list_stockmove()
	}

	popMoveDetail = (warehouse_uuid, moveid) => {
		Modal.info({
			title: "调拨详情",
			width: "80vw",
			content: <StockMoveDetail warehouse_uuid={warehouse_uuid} moveid={moveid} operator={this.props.Operator}/>
		})
	}
	// popMoveDetail = async (warehouse_uuid, moveid) => {
	// 	let w = window.open()
	// 	let el = w.document.createElement("div");
	// 	w.document.body.appendChild(el)
	// 	ReactDOM.createPortal(<StockMoveDetail warehouse_uuid={warehouse_uuid} moveid={moveid} operator={this.props.Operator}/>,
	// 		el)
	//
	// }

	render() {
		let operator = this.props.Operator
		let columns = []
		if (this.props.move_type === "in_moves") {
			columns = [
				{title: "序号", key: "index", align: "center", width: 100, render: ((text, record, index) => {return index + 1})},
				{
					title: "调拨ID", dataIndex: "moveId", align: "center", width: 280, render: ((text, record) => {
						return <Typography.Link onClick={() => this.popMoveDetail(this.props.warehouse_uuid, record.moveId)}>{text}</Typography.Link>
					})
				},
				{title: "创建人", dataIndex: "createOwner", align: "center"},
				{title: "调拨项目", dataIndex: "moveTypeDisplay", align: "center"},
				{
					title: "创建时间",
					dataIndex: "createTime",
					align: "center",
					defaultSortOrder: "descend",
					render: ((text, record) => {return moment(record.createTime).format("YYYY-MM-DD HH:mm:ss")}),
					sorter: (a, b) => {
						let atime = new Date(Date.parse(a.createTime));
						let btime = new Date(Date.parse(b.createTime));
						return atime.getTime() - btime.getTime();
					}
				},
				{title: "调拨状态", dataIndex: "statusDisplay", align: "center"},

				{
					title: "来源库", key: "source", align: "center", render: ((text, record) => {
						return record.source.displayName
					})
				}
			]
		} else {
			columns = [
				{title: "序号", key: "index", align: "center", width: 100, render: ((text, record, index) => {return index + 1})},
				{
					title: "调拨ID", dataIndex: "moveId", align: "center", width: 280, render: ((text, record) => {
						return <Typography.Link onClick={() => this.popMoveDetail(this.props.warehouse_uuid, record.moveId)}>{text}</Typography.Link>
					})
				},
				{title: "创建人", dataIndex: "createOwner", align: "center"},
				{title: "调拨项目", dataIndex: "moveTypeDisplay", align: "center"},
				{
					title: "创建时间",
					dataIndex: "createTime",
					align: "center",
					defaultSortOrder: "descend",
					render: ((text, record) => {return moment(record.createTime).format("YYYY-MM-DD HH:mm:ss")}),
					sorter: (a, b) => {
						let atime = new Date(Date.parse(a.createTime));
						let btime = new Date(Date.parse(b.createTime));
						return atime.getTime() - btime.getTime();
					}
				},
				{title: "调拨状态", dataIndex: "statusDisplay", align: "center"},
				{
					title: "目标库", key: "destination", align: "center", render: ((text, record) => {
						return record.destination.displayName
					})
				}

			]
		}

		return <Table dataSource={this.state.moves}
		              columns={columns}
		              pagination={{
			              defaultPageSize: this.state.pageSize,
			              pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
			              onChange: this.list_stockmove,
			              onShowSizeChange: this.list_stockmove,
			              total: this.state.total,
			              current: this.state.current,
			              showSizeChanger: true,
			              size: this.props.Operator.componentSize,
			              style: {marginRight: 10},
			              showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
		              }}
		              size={operator.componentSize}
		/>
	}
}

@inject("Operator")
@observer
class StockMove
	extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		let operator = this.props.Operator
		let wh = this.props.wh
		let move_type = this.props.move_type
		if (move_type === "in_moves") {
			return <Card headStyle={{padding: 5}} title={"入库调拨"} key={Math.random()} size={operator.componentSize}
			             bordered={true}
			>
				<MoveTable warehouse_uuid={wh.uuid} move_type={"in_moves"}/>
			</Card>
		} else {
			return <Card headStyle={{padding: 5}} title={"出库调拨"} key={Math.random()} size={operator.componentSize}
			             bordered={true} extra={<Typography.Link key={Math.random()} onClick={this.props.showModal}
			                                                     disabled={wh.permissions.indexOf("create_stockmove") === -1}>创建调拨</Typography.Link>}
			>
				<MoveTable warehouse_uuid={wh.uuid} move_type={"out_moves"}/>
			</Card>
		}

	}
}

export default StockMove;