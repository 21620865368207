import React, {Component} from "react";
import Konva from "konva";
import {Button, Space} from "antd";
import jspdf from "jspdf";

class FilmViewer extends Component {
	constructor(props) {
		super(props);
		this.stage = null;
		this.layout = null;
		this.width = null;
		this.height = null;
		this.cid = `konvaContainner-${String(Math.random()).replace(".", "")}`;
	}

	componentDidMount() {
		let container = document.getElementById(this.cid);

		// this.stage = new Konva.Stage({
		// 	container: container
		// 	// width: "auto",
		// 	// height: "auto",
		// });
		this.width = parseInt(getComputedStyle(container)["width"].replace("px", ""));
		this.height = parseInt(getComputedStyle(container)["height"].replace("px", ""));
		let xTime = this.width / 210;

		if (this.props.type === "film") {
			this.stage = new Konva.Stage({
				container: container,
				width: 210 * xTime,
				height: 297 * xTime
			});
		} else if (this.props.type === "label") {
			this.stage = new Konva.Stage({
				container: container,
				width: 182 * xTime,
				height: 257 * xTime
			});
		}

		this.layout = new Konva.Layer({
			x: 0,
			y: 0,
			// width: this.width,
			// height: this.height,
			fill: "red"
		});
		this.stage.add(this.layout);
		if (this.props.type === "film") {
			this.drawFilm();
		} else {
			this.drawLabel();
		}
		this.layout.batchDraw();
	}

	drawFilm = () => {
		/*
		画菲林
		 */
		if (this.props.data.template.config.name === "ES-24" || this.props.data.template.config.name === "ES-27") {
			let hLine = new Konva.Line({
				points: [20, 600, 820, 600],
				strokeWidth: 1,
				stroke: "black",
				dash: [2, 0, 2],
				scale: {x: 1, y: 1}
			});
			let vLine = new Konva.Line({
				points: [420, 20, 420, 1168],
				strokeWidth: 1,
				stroke: "black",
				dash: [2, 0, 2]
			});
			let ltPlanId = new Konva.Text({
				text: this.props.data.planId,
				x: 20,
				y: 20
			});
			let rtPlanId = new Konva.Text({
				text: this.props.data.planId,
				x: 715,
				y: 20
			});
			let lbPlanId = new Konva.Text({
				text: this.props.data.planId,
				x: 130,
				y: 1170,
				rotation: 180
			});
			let rbPlanId = new Konva.Text({
				text: this.props.data.planId,
				x: 820,
				y: 1170,
				rotation: 180
			});
			this.layout.add(ltPlanId);
			this.layout.add(rtPlanId);
			this.layout.add(lbPlanId);
			this.layout.add(rbPlanId);
			this.layout.add(vLine);
			this.layout.add(hLine);
		}
		const data = this.props.data;
		switch (data.template.config.name) {
			case "ES-24":
			case "HM-24":
				for (let x of data.MTORecord) {
					if (x.position.hasOwnProperty("slot")) {
						let slot = x.position["slot"];
						let slotNum = parseInt(slot.replace("slot", ""));
						if (slotNum <= 6) {
							let border = new Konva.Rect({
								x: 164,
								y: 80 + (86 * (slotNum - 1)),
								border: true,
								width: 218,
								height: 72,
								cornerRadius: 72,
								stroke: "black",
								strokeWidth: 2
							});
							this.layout.add(border);
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 181,
									y: 93 + (86 * (slotNum - 1)),
									width: 184,
									height: 46
								});
								this.layout.add(image);
							});
							if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n蓝",
									x: 164 - 30,
									y: 80 + (86 * (slotNum - 1)) + 20
								}));
							} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n粉",
									x: 164 - 30,
									y: 80 + (86 * (slotNum - 1)) + 20
								}));
							} else {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n黄",
									x: 164 - 30,
									y: 80 + (86 * (slotNum - 1)) + 20
								}));
							}
						} else if (slotNum >= 7 && slotNum <= 12) {
							let border = new Konva.Rect({
								x: 164,
								y: 616 + (86 * (slotNum - 7)),
								border: true,
								width: 218,
								height: 72,
								cornerRadius: 72,
								stroke: "black",
								strokeWidth: 2
							});
							this.layout.add(border);
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 181,
									y: 630 + (85.5 * (slotNum - 7)),
									width: 184,
									height: 46
								});
								this.layout.add(image);
							});
							if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n蓝",
									x: 164 - 30,
									y: 644 + (86 * (slotNum - 7)) - 15
								}));
							} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n粉",
									x: 164 - 30,
									y: 644 + (86 * (slotNum - 7)) - 15
								}));
							} else {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n黄",
									x: 164 - 30,
									y: 644 + (86 * (slotNum - 7)) - 15
								}));
							}
						} else if (slotNum >= 13 && slotNum <= 18) {
							let border = new Konva.Rect({
								x: 460,
								y: 80 + (86 * (slotNum - 13)),
								border: true,
								width: 218,
								height: 72,
								cornerRadius: 72,
								stroke: "black",
								strokeWidth: 2
							});
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 477,
									y: 93 + (86 * (slotNum - 13)),
									width: 184,
									height: 46
								});
								this.layout.add(image);
							});
							this.layout.add(border);
							if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n蓝",
									x: 460 + 240,
									y: 80 + (86 * (slotNum - 13)) + 20
								}));
							} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n粉",
									x: 460 + 240,
									y: 80 + (86 * (slotNum - 13)) + 20
								}));
							} else {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n黄",
									x: 460 + 240,
									y: 80 + (86 * (slotNum - 13)) + 20
								}));
							}

						} else {
							let border = new Konva.Rect({
								x: 460,
								y: 616 + (86 * (slotNum - 19)),
								border: true,
								width: 218,
								height: 72,
								cornerRadius: 72,
								stroke: "black",
								strokeWidth: 2
							});
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 477,
									y: 630 + (85.5 * (slotNum - 19)),
									width: 184,
									height: 46
								});
								this.layout.add(image);
							});
							this.layout.add(border);
							if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n蓝",
									x: 460 + 240,
									y: 633 + (86 * (slotNum - 19))
								}));
							} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n粉",
									x: 460 + 240,
									y: 633 + (86 * (slotNum - 19))
								}));
							} else {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n黄",
									x: 460 + 240,
									y: 633 + (86 * (slotNum - 19))
								}));
							}
						}

					}
				}
				break;
			case "ES-27":
				for (let x of data.MTORecord) {
					let _yMargin = 85
					let _yMargin2 = 80
					if (x.position.hasOwnProperty("slot")) {
						let slot = x.position["slot"];
						let slotNum = parseInt(slot.replace("slot", ""));
						if (slotNum <= 6) {
							let border = new Konva.Rect({
								x: 160,
								y: _yMargin2 + (_yMargin * (slotNum - 1)),
								border: true,
								width: 218,
								height: 72,
								cornerRadius: 72,
								stroke: "black",
								strokeWidth: 2
							});
							this.layout.add(border);
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 180,
									y: 95 + (_yMargin * (slotNum - 1)) - 2,
									width: 184,
									height: 46
								});
								this.layout.add(image);
							});
							if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n蓝",
									x: 160 - 30,
									y: _yMargin2 + (_yMargin * (slotNum - 1)) + 15
								}));
							} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n粉",
									x: 160 - 30,
									y: _yMargin2 + (_yMargin * (slotNum - 1)) + 15
								}));
							} else {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n黄",
									x: 160 - 30,
									y: _yMargin2 + (_yMargin * (slotNum - 1)) + 15
								}));
							}
						} else if (slotNum >= 7 && slotNum <= 12) {
							let border = new Konva.Rect({
								x: 160,
								y: 615 + (_yMargin * (slotNum - 7)),
								border: true,
								width: 218,
								height: 72,
								cornerRadius: 72,
								stroke: "black",
								strokeWidth: 2
							});
							this.layout.add(border);
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 180,
									y: 630 + (_yMargin * (slotNum - 7)) - 2,
									width: 184,
									height: 46
								});
								this.layout.add(image);
							});
							if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n蓝",
									x: 135,
									y: 630 + (_yMargin * (slotNum - 7))
								}));
							} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n粉",
									x: 135,
									y: 630 + (_yMargin * (slotNum - 7))
								}));
							} else {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n黄",
									x: 135,
									y: 630 + (_yMargin * (slotNum - 7))
								}));
							}
						} else if (slotNum >= 13 && slotNum <= 18) {
							let border = new Konva.Rect({
								x: 450,
								y: _yMargin2 + (_yMargin * (slotNum - 13)),
								border: true,
								width: 218,
								height: 72,
								cornerRadius: 72,
								stroke: "black",
								strokeWidth: 2
							});
							Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
								image.setAttrs({
									x: 470,
									y: 95 + (_yMargin * (slotNum - 13)) - 2,
									width: 184,
									height: 46
								});
								this.layout.add(image);
							});
							this.layout.add(border);
							if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n蓝",
									x: 680,
									y: _yMargin2 + (_yMargin * (slotNum - 13)) + 15
								}));
							} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n粉",
									x: 680,
									y: _yMargin2 + (_yMargin * (slotNum - 13)) + 15
								}));
							} else {
								this.layout.add(new Konva.Text({
									text: x.seq + "\n\n黄",
									x: 680,
									y: _yMargin2 + (_yMargin * (slotNum - 13)) + 15
								}));
							}

						} else {
							let _radius = 41
							switch (slotNum) {

								case 19:
									this.layout.add(new Konva.Circle({
										x: 520,
										y: 670,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 520 - 60 / 2,
											y: 672 - 60 / 2 - 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 460,
											y: 630
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 460,
											y: 630
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n\n黄",
											x: 460,
											y: 630
										}));
									}
									break;
								case 20:
									this.layout.add(new Konva.Circle({
										x: 622.5,
										y: 670,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 622.5 - 60 / 2,
											y: 670 - 60 / 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 565,
											y: 630
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 565,
											y: 630
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 565,
											y: 630
										}));
									}
									break;
								case 21:
									this.layout.add(new Konva.Circle({
										x: 725,
										y: 670,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 725 - 60 / 2,
											y: 670 - 60 / 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 725 - 60,
											y: 630
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 725 - 60,
											y: 630
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 725 - 60,
											y: 630
										}));
									}
									break;
								case 22:
									this.layout.add(new Konva.Circle({
										x: 520,
										y: 792,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 520 - 60 / 2,
											y: 792 - 60 / 2 - 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 520 - 60,
											y: 792 - 42
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 460,
											y: 792 - 42
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 460,
											y: 792 - 42
										}));
									}
									break;
								case 23:
									this.layout.add(new Konva.Circle({
										x: 622.5,
										y: 792,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 622.5 - 60 / 2,
											y: 792 - 60 / 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 622.5 - 55,
											y: 792 - 42
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 622.5 - 55,
											y: 792 - 42
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 622.5 - 55,
											y: 792 - 42
										}));
									}
									break;
								case 24:
									this.layout.add(new Konva.Circle({
										x: 725,
										y: 792,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 725 - 60 / 2,
											y: 792 - 60 / 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 725 - 55,
											y: 792 - 42
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 725 - 55,
											y: 792 - 42
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 725 - 55,
											y: 792 - 42
										}));
									}
									break;
								case 25:
									this.layout.add(new Konva.Circle({
										x: 520,
										y: 915,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 520 - 60 / 2,
											y: 915 - 60 / 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 520 - 55,
											y: 915 - 42
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 520 - 55,
											y: 915 - 42
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 520 - 55,
											y: 915 - 42
										}));
									}
									break;
								case 26:
									this.layout.add(new Konva.Circle({
										x: 622.5,
										y: 912.5,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 622.5 - 60 / 2,
											y: 915 - 60 / 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 622.5 - 55,
											y: 915 - 42
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 622.5 - 55,
											y: 915 - 42
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 622.5 - 55,
											y: 915 - 42
										}));
									}
									break;
								case 27:
									this.layout.add(new Konva.Circle({
										x: 725,
										y: 915,
										border: true,
										radius: _radius,
										stroke: "black",
										strokeWidth: 2
									}));
									Konva.Image.fromURL(x.orderLine.layoutImage, (image) => {
										image.setAttrs({
											x: 725 - 60 / 2,
											y: 915 - 60 / 2,
											width: 60,
											height: 60
										});
										this.layout.add(image);
									});

									if (x.orderLine.product.productName.indexOf("蓝") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n蓝",
											x: 725 - 55,
											y: 915 - 42
										}));
									} else if (x.orderLine.product.productName.indexOf("粉") !== -1) {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n粉",
											x: 725 - 55,
											y: 915 - 42
										}));
									} else {
										this.layout.add(new Konva.Text({
											text: x.seq + "\n黄",
											x: 725 - 55,
											y: 915 - 42
										}));
									}
									break;
								default:
									break;
							}

						}

					}
				}
				break;
			default:
				break

		}
		this.layout.batchDraw();
	};

	savePDF = () => {
		let _image = this.stage.toDataURL({
			mimeType: "image/png",
			quality: 1,
			pixelRatio: 1,
			width: this.width,
			height: this.height
		})
		let doc = new jspdf("p", "mm")
		doc.addImage(_image, 0, 0, 210, 297);
		doc.save(`FILM-${this.props.data.planId}.pdf`)
	}
	exportImage = () => {
		this.stage.scale({x: 5, y: 5});
		let _image = this.stage.toDataURL({
			mimeType: "image/png",
			quality: 1,
			pixelRatio: 1,
			width: this.width * 5,
			height: this.height * 5
		});
		// let nw = window.open();
		this.stage.scale({x: 1, y: 1});
		let _pdf = new jspdf({format: "a4"});
		_pdf.addImage(_image, 0, 0, 210, 297);

		_pdf.autoPrint();
		window.open(_pdf.output("bloburl"), "_blank");

		// nw.document.write(
		// 	`<html>
		//     <style type="text/css">
		//     body{margin: 0} @page  {
		// 		  size: A4 landscape;
		// 		  margin:0;
		// 		}
		// 	@media print{
		// 		body{margin: 0;}
		// 		@page  {
		// 		  size: A4;
		// 		  margin:0;
		// 		}
		// 		}
		// 		</style>
		// 		<body>
		// 		<img src="${_image}" width="${this.width}" height="${this.height}"/>
		// 		</body>
		// 		</html>`);
	};

	render() {
		return (
			<div className={"layouteditor"}>
				<div className={"layoutRightButtons"}>
					<Space direction={"vertical"}>
						<Button onClick={this.exportImage}>打印</Button>
						{/*<Button onClick={this.savePDF}>PDF</Button>*/}
					</Space>
				</div>
				<div id={this.cid} className={"konvaContainer"}/>
			</div>
		);
	}
}

export default FilmViewer;