import React, {Component} from "react";
import {Tabs} from "element-react";
import LogisticTable from "./LogisticTable";
import LogisticDetail from "./LogisticDetail";
import StockPool from "./StockPool";
import {Badge} from "antd";

class SCM extends Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			active: "运输在途",
			lastActive: "运输在途",
			stockPoolCount: 0,
			problemCount: 0
		};

	}

	showDetail = (logistic) => {
		if (this.state.details.indexOf(logistic) === -1) {
			let od = this.state.details;
			od.push(logistic);
			this.setState({details: od, active: logistic.logisticCode});
		} else {
			this.setState({active: logistic.logisticCode});
		}

	};

	removeTab = (obj) => {
		let od = [];
		for (let i of this.state.details) {
			if (i.logisticCode !== obj.props.label) {
				od.push(i);
			}
		}
		this.setState({details: od, active: this.state.lastActive});
	};

	setCount = (countType, count) => {
		switch (countType) {
			case "stockPoolCount":
				this.setState({stockPoolCount: count})
				break
			case "problemCount":
				this.setState({problemCount: count})
				break
			default:
				break
		}

	}

	changeTab = (obj) => {
		this.setState({
			lastActive: obj.props.label
		});
	};

	render() {
		return (
			<Tabs type="border-card" activeName={this.state.active}
			      onTabClick={this.changeTab}
			      onTabRemove={this.removeTab} style={{height: "100%"}}>
				<Tabs.Pane label={<span>现货发货<Badge
					size={"small"}
					className="site-badge-count-109"
					count={this.state.stockPoolCount}
					style={{backgroundColor: "#52c41a"}}
					offset={[3, -10]}
				/></span>} name={"现货发货"}>
					<StockPool setCount={this.setCount}/>
				</Tabs.Pane>
				<Tabs.Pane label={"待揽收"} name={"待揽收"}>
					<LogisticTable status={"sent"} showDetail={this.showDetail}/>
				</Tabs.Pane>
				<Tabs.Pane label={"运输在途"} name={"运输在途"}>
					<LogisticTable status={"shipping"} showDetail={this.showDetail}/>
				</Tabs.Pane>
				<Tabs.Pane label={<span>疑难包裹<Badge
					size={"small"}
					className="site-badge-count-109"
					count={this.state.problemCount}
					style={{backgroundColor: "red"}}
					offset={[3, -10]}
				/></span>} name={"疑难包裹"}>
					<LogisticTable status={"problem"} showDetail={this.showDetail} setCount={this.setCount}/>
				</Tabs.Pane>
				<Tabs.Pane label={"历史包裹"} name={"历史包裹"}>
					<LogisticTable status={"received"} showDetail={this.showDetail}/>
				</Tabs.Pane>
				{this.state.details.map(logistic => {
					console.log(logistic)
					return (
						<Tabs.Pane label={logistic.logisticCode} name={logistic.logisticCode} closable={true} key={logistic.logisticCode}>
							<LogisticDetail logisticUUID={logistic.logisticUUID}/>
						</Tabs.Pane>
					);
				})}
			</Tabs>
		);
	}
}

export default SCM;