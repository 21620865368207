import React, {Component} from "react";
import ReactToPrint from "react-to-print"
import {Badge, Button, Descriptions, Divider, Popover, Space, Table} from "antd";
import {inject, observer} from "mobx-react";
import {axios} from "../../Managers";
import moment from "moment";

@inject("Operator")
@observer
class PlanPaper extends Component {
	constructor(props) {
		super(props);
		this.state = {data: []}
		this.printContent = React.createRef()
	}

	componentDidMount() {
		this.getMTOInfo()
	}

	getMTOInfo = () => {
		axios.post("/mto/mtoplan/", {action: "planPaper", data: {"planId": this.props.data.planId}}).then(res => {
			if (res.status === 200) {
				this.setState({data: res.data.data})
			}
		})

	}

	render() {
		let operator = this.props.Operator

		let columns = [
			{
				title: "序号", dataIndex: "slot", align: "center", width: 40, render: (text, record) => {
					return <span key={Math.random()}>{record.slot}</span>;
				}
			},
			{
				title: "客户信息", key: "customer", align: "center", width: 150, ellipsis: true, render: (text, record) => {
					if (record.hasOwnProperty("orderInfo")) {
						return <span key={Math.random()}>{record.customerInfo}</span>
					} else {
						return record.orderAlias
					}
				}
			},
			{
				title: "父单参照", key: "parentCustomerRef", align: "center", width: 200, ellipsis: true, render: (text, record) => {
					let _ref = record.orderLine.parentCustomerRef
					if (record.orderLine.parentCustomerRef.split("_").length >= 2) {
						_ref = record.orderLine.parentCustomerRef.split("_")[1]
					}
					return <span key={Math.random()}>{_ref}{record.orderLine.parentLockCase
						? <Badge dot={true} title={"有紧急工单未关闭"} offset={[5, 5]}/>
						: ""}</span>;
				}
			},
			{
				title: "制版图", key: "layoutImage", align: "center", width: 100, responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					return (
						<Popover content={<img src={record.orderLine.layoutImage} key={Math.random()} alt={"版下图"} style={{height: "100%"}}/>}
						         title="版下图">
							<img src={record.orderLine.layoutImage} key={Math.random()} alt={"layoutImage"} style={{maxWidth: 90, maxHeight: 50}}/>
						</Popover>
					);
				}
			},

			{
				title: "颜色", key: "product", align: "center", width: 50, render: (text, record) => {
					if (record.orderLine.product.productName.indexOf("黄") !== -1) {return "黄"}
					if (record.orderLine.product.productName.indexOf("蓝") !== -1) {return "蓝"}
					if (record.orderLine.product.productName.indexOf("粉") !== -1) {return "粉"}
					return record.orderLine.product.productName
				}
			},
			{
				title: "备注信息", key: "note", align: "center", responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					if (record.hasOwnProperty("orderInfo")) {
						return <span key={Math.random()}>{record.orderInfo.note}</span>
					}
				}
			},
			{
				title: "成品信息", key: "product", align: "center", width: 420, render: (text, record) => {
					if (record.hasOwnProperty("orderInfo")) {
						return <Space wrap={true} split={<Divider type={"vertical"} style={{margin: 0}}/>}>
							{record.orderInfo.orderlines.map(line => {
								if (line.product.productType === "product") {return `${line.product.productName} * ${line.quantity}#`} else {return ""}
							})}
						</Space>
					}
				}
			}]
		let data = this.props.data
		return (
			<div ref={this.printContent}>
				<Descriptions bordered column={{xxl: 4, xl: 4, lg: 2, md: 2, sm: 1, xs: 1}} size={operator.componentSize}
				              extra={<Space className={"hiddenOnPrint"} split={<Divider type={"verital"}/>}>
					              <ReactToPrint content={() => {return this.printContent.current}}
					                            pageStyle={"@page {size: A4  !important;}.ant-table-cell{border: 1px solid;}"}
					                            trigger={() => {
						                            return <Button key={Math.random()} className={"hiddenOnPrint"}
						                                           size={operator.componentSize}>打印作业单</Button>
					                            }}/>
				              </Space>}>
					<Descriptions.Item label={"计划编号"}>{data.planId}</Descriptions.Item>
					<Descriptions.Item label={"计划备注"}>{data.note}</Descriptions.Item>
					<Descriptions.Item label={"创建时间"}>{moment(data.createTime).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
				</Descriptions>
				<Table size={operator.componentSize} columns={columns} dataSource={this.state.data} pagination={false}/>
			</div>
		);
	}
}

export default PlanPaper;