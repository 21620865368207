import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {Button, Divider, Select, Space, Table, Typography, message, Badge} from "antd";
import {axios, ProdServer} from "../../Managers";

import PopOrderDetail from "../Order/PopOrderDetail";

@inject("Operator")
@observer
class LogisticTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			selectedRowKeys: [],
			selectedRows: [],
			loading: true,
			duplicatesCheck: [],
			shippers: [],
			senders: [],
			sent: false
		};
	}

	componentDidMount() {
		this.initData();
		this.getMetaInfo();
	}

	getMetaInfo = () => {
		axios.get("/scm/shipper/").then(res => {
			this.setState({shippers: res.data.data});
		});
		axios.get("/scm/sender/").then(res => {
			this.setState({senders: res.data.data});
		});
	};

	send = (record) => {
		return new Promise((resolve, reject) => {
			axios.post("/scm/logistic/",
				{
					action: "sendLogistic",
					data: {
						orderId: record.orderId,
						shipper: record.deliver,
						sender: this.props.Operator.defaultSender,
						records: record.records.map(r => {return r.recordId;}),
						trigger_class: "mtoplan",
						trigger_id: this.props.data.planId
					}
				}).then(res => {
				if (res.data.status === 200) {
					record.logisticCode = res.data.data[0].logisticCode;
					record.logisticUUID = res.data.data[0].logisticUUID;
					record.sent = true;
					this.forceUpdate();
					resolve();
				} else {
					message.error(res.data.message);
					reject(res.data.message);
				}

			});
		});

	};

	sendSelected = () => {
		let present = [];
		for (let x of this.state.selectedRows) {
			present.push(x.send(x));
		}
		Promise.all(present).then((rets => {
				this.setState({sent: true, selectedRowKeys: [], selectedRows: []});
				this.props.goToFlow(this.props.data.planId, "printLogistics");
			})
		);
	};

	printSelected = () => {
		let logisticUUIDS = [];
		for (let x of this.state.selectedRows) {
			if (x.logisticUUID) {
				logisticUUIDS.push({"seq": x.seq, "uuid": x.logisticUUID});
			}
		}
		if (logisticUUIDS.length === this.state.data.length) {
			this.props.goToFlow(this.props.data.planId, "finish");
		}
		this.printLogisticPaper(logisticUUIDS);

	};

	printPackList = () => {
		axios.post("/scm/logistic/", {action: "sortByMTOPlan", data: {planId: this.props.data.planId, HTML: true}}).then(res => {
			let w = window.open("#", "_blank", "menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes")
			w.document.write(res.data)
			w.open()
		})
	}

	initData = () => {
		axios.post("/scm/logistic/", {action: "sortByMTOPlan", data: {planId: this.props.data.planId}}).then(res => {
			let data = [];
			for (let x of res.data.data) {
				if (x.hasOwnProperty("logistic")) {
					x.logisticCode = x.logistic.logisticCode
					x.logisticUUID = x.logistic.logisticUUID
					x.sent = true
					x.deliver = x.logistic.shipperCode
				} else {
					x.send = this.send;
					x.logisticCode = "待请求";
					x.sent = false;
				}
				data.push(x);
			}
			this.setState({data: data, loading: false});
		});
	};

	title = () => {
		return <div className={"headerButton"}>
			<Button size={this.props.Operator.componentSize} type={"primary"} disabled={this.state.selectedRowKeys.length === 0 || this.state.sent}
			        onClick={this.sendSelected}>批量发货</Button>
			<Divider type={"vertical"}/>
			<Button size={this.props.Operator.componentSize} type={"primary"} disabled={this.state.selectedRowKeys.length === 0 || !this.state.sent}
			        onClick={this.printSelected}>批量打印面单</Button>
			<Divider type={"vertical"}/>
			<Button size={this.props.Operator.componentSize} type={"primary"}
			        onClick={this.printPackList}>打印发货清单</Button>
		</div>;
	};

	changeSelected = (selectedRowKeys, selectedRows) => {
		if (selectedRowKeys.length === 0) {
			this.setState({selectedRowKeys: selectedRowKeys, selectedRows: selectedRows, sent: false});
		} else {
			this.setState({selectedRowKeys: selectedRowKeys, selectedRows: selectedRows, sent: selectedRows[0].sent});
		}
	};

	printLogisticPaper = (logisticUUIDS) => {
		this.setState({loading: true});
		axios.post("/scm/logistic/", {action: "printLogisticPaper", data: {logisticUUIDS: logisticUUIDS.map(l => {return l.uuid}), "origin": logisticUUIDS}})
			.then(res => {
				if (res.data.status === 200) {
					for (let x of res.data.data) {
						window.open(ProdServer + "scm/logistic/?path=" + x, "_blank");
					}
					this.setState({loading: false});
				} else {
					this.setState({loading: false});
				}
			});
	};

	render() {
		let columns = [
			{
				title: "序号", dataIndex: "seq", align: "center", width: 60, render: (text, record) => {
					return <Space split={"、"}>
						{record.records.map(r => {
							return <span key={Math.random()}>{r.seq}</span>;
						})}
					</Space>;
				}
			},
			{
				title: "订单号", dataIndex: "orderId", align: "center", width: 160, render: (text, record) => {
					return <Typography.Link onClick={() => PopOrderDetail(text)}>{text}{record.orderInfo.lockCase
						? <Badge dot={true} title={"有紧急工单未关闭"} offset={[10, 10]}/>
						: ""}</Typography.Link>
				}
			},
			{
				title: "订单备注", key: "note", align: "center", width: 250, responsive: ["md", "lg", "xl", "xxl"], ellipsis: true, render: (text, record) => {
					return <span key={Math.random()}>{record.orderInfo.note}</span>;
				}
			},
			// {
			// 	title: "订单明细", key: "orderInfo", align: "center", render: (text, record) => {
			// 		return <React.Fragment>
			// 			<Table.Summary.Row>
			// 				<Table.Summary.Cell>子单号</Table.Summary.Cell>
			// 				<Table.Summary.Cell>产品</Table.Summary.Cell>
			// 				<Table.Summary.Cell>数量</Table.Summary.Cell>
			// 				<Table.Summary.Cell>版下图</Table.Summary.Cell>
			// 			</Table.Summary.Row>
			// 			{record.orderInfo.orderlines.map(line => {
			// 				return <Table.Summary.Row>
			// 					<Table.Summary.Cell>{line.lineId}</Table.Summary.Cell>
			// 					<Table.Summary.Cell>{line.product.productName}</Table.Summary.Cell>
			// 					<Table.Summary.Cell>{line.quantity}</Table.Summary.Cell>
			// 					<Table.Summary.Cell>版下图</Table.Summary.Cell>
			// 				</Table.Summary.Row>
			// 			})}
			// 		</React.Fragment>
			//
			// 	}
			// },
			{
				title: "物流商", dataIndex: "deliver", align: "center", width: 100, render: (text, record) => {
					return <Select defaultValue={text} key={Math.random()} size={this.props.Operator.componentSize}
					               disabled={record.logisticCode !== "待请求"}
					               onChange={value => {
						               record.deliver = value;
					               }}
					>
						{this.state.shippers.map(shipper => {
							return <Select.Option key={Math.random()} value={shipper.shipperCode}>{shipper.shipperName}</Select.Option>;
						})}
					</Select>;
				}
			},
			{
				title: "单号", dataIndex: "logisticCode", align: "center", width: 130, render: (text, record) => {
					return <span key={Math.random()}>{text}</span>;
				}
			},
			{
				title: "发件人", dataIndex: "sender", align: "center", width: 180, ellipsis: true, render: (text, record) => {
					return <Select defaultValue={this.props.Operator.defaultSender} key={Math.random()} size={this.props.Operator.componentSize}
					               disabled={record.logisticCode !== "待请求"}
					               onChange={value => {
						               record.sender = value;
					               }}
					>
						{this.state.senders.map(sender => {
							return <Select.Option key={Math.random()} value={sender.pk}>{sender.senderName}</Select.Option>;
						})}
					</Select>;
				}
			},
			{
				title: "收件人", dataIndex: "receiver", align: "center", ellipsis: true, width: 180, render: (text, record) => {
					return <span key={Math.random()}>{record.orderInfo.address.province.substring(0,
						2)}-{record.orderInfo.address.name}({record.orderInfo.address.mobileNumber === ""
						? record.orderInfo.address.phoneNumber
						: record.orderInfo.address.mobileNumber})</span>;
				}
			},

			{
				title: "操作", key: "opts", align: "center", width: 100, render: (text, record) => {
					return <Space split={<Divider type={"vertical"}/>}>
						<Typography.Link key={Math.random()} disabled={record.sent} onClick={() => record.send(record)}>发货</Typography.Link>
						<Typography.Link key={Math.random()} disabled={!record.sent}
						                 onClick={() => this.printLogisticPaper([{"seq": 1, "uuid": record.logisticUUID}])}>打印</Typography.Link>
					</Space>;
				}
			}
		];
		return (
			<div>
				<Table
					columns={columns}
					dataSource={this.state.data}
					size={this.props.Operator.componentSize}
					rowKey={"orderId"}
					title={this.title}
					bordered
					pagination={false}
					loading={this.state.loading}
					rowSelection={{
						selectedRowKeys: this.state.selectedRowKeys,
						onChange: this.changeSelected,
						getCheckboxProps: record => {
							// if (record.orderInfo.lockCase) {
							// 	return {disabled: true}
							// }
							if (this.state.selectedRowKeys.length === 0) {
								return {disabled: false};
							} else {
								if (this.state.selectedRows[0].sent === record.sent) {
									return {disabled: false};
								}
								return {disabled: true};
							}
						}
					}}
				/>
			</div>
		);
	}
}

export default LogisticTable;