import React, {Component} from "react";
import {Select, Table, Form, InputNumber, Typography, Space, Button, Row, Col, message} from "antd";
import {axios} from "../../Managers";

class picking {
	constructor() {
		this.seq = undefined
		this.material_uuid = undefined
		this.material = undefined
		this.order_quantity = 0
		this.quantity = 0
		this.stockbalance = undefined
	}

	changeMaterial = (value) => {
		console.log(value)
		for (let x of this.stockbalance) {
			if (value === x.material_uuid) {
				this.material_uuid = value
				this.material = x.material
				this.order_quantity = x.order_quantity
			}
		}
	}
	setQuantity = (value) => {
		this.quantity = value
	}

}

class StockMoveCreator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editRowKey: undefined,
			stockmove: {},
			pickings: []
		}
	}

	addPicking = (x = Math.random()) => {
		let p = new picking()
		p.seq = x
		p.stockbalance = this.props.stockbalance
		let pickings = this.state.pickings
		pickings.push(p)
		this.setState({pickings: pickings, editRowKey: x})
	}

	deletePicking = (record) => {
		let _p = []
		for (let x of this.state.pickings) {
			if (x !== record) {
				_p.push(x)
			}
		}
		this.setState({pickings: _p})
	}

	componentDidMount() {
		this.addPicking()
	}

	_get_pickings = () => {
		let p = []
		for (let x of this.state.pickings) {
			x.stockbalance = undefined
			p.push(x)
		}
		return p
	}

	createStockMove = (values) => {
		if (values.destination) {
			console.log(values)
			axios.post("/mto/stockmove/", {action: "create_stockmove", ...values, pickings: this._get_pickings()}).then(res => {
				if (res.data.status === 200) {
					message.success("调拨创建成功,调拨ID: " + res.data.data[0]["moveId"])
					this.props.showModal()
				} else {
					message.error(res.data.message)
				}
			})
		}

	}

	render() {
		let wh = this.props.wh
		let whs = this.props.whs
		let stockbalance = this.props.stockbalance
		let columns = [
			{
				title: "物料", key: "material", align: "center", render: ((text, record) => {
					if (this.state.editRowKey === record.seq) {
						return <Select onChange={record.changeMaterial} defaultValue={record.material} optionLabelProp={"label"} size={"small"}>
							{stockbalance.map(m => {
								return <Select.Option key={m.material_uuid} value={m.material_uuid} label={m.material}>{m.material}</Select.Option>
							})
							})
						</Select>
					} else {
						return <span onClick={() => this.setState({editRowKey: record.seq})}
						             onMouseEnter={() => this.setState({editRowKey: record.seq})}>{record.material}</span>
					}

				})
			},
			{title: "在手量", dataIndex: "order_quantity", align: "center"},
			{
				title: "调拨数量", dataIndex: "quantity", align: "center", render: ((text, record) => {
					if (this.state.editRowKey === record.seq) {
						return <InputNumber min={1} max={record.order_quantity} size={"small"} defaultValue={record.quantity} onChange={record.setQuantity}/>
					} else {
						return <span onClick={() => this.setState({editRowKey: record.seq})}
						             onMouseEnter={() => this.setState({editRowKey: record.seq})}>{record.quantity}</span>
					}
				})
			},
			{
				title: "操作", key: "opts", align: "center", render: (record => {
					return <Space onMouseEnter={() => this.setState({editRowKey: record.seq})}>
						<Typography.Link onClick={() => this.setState({editRowKey: record.seq})}>编辑</Typography.Link>
						<Typography.Link onClick={() => this.deletePicking(record)}>删除</Typography.Link>
					</Space>
				})
			}

		]
		if (whs) {
			return (
				<div>
					<Form onMouseLeave={() => this.setState({editRowKey: false})} onFinish={this.createStockMove}>
						<Form.Item name={"source"} hidden initialValue={wh.uuid}>
							<input value={wh.uuid}/>
						</Form.Item>
						<Form.Item label={"目标仓库"} name={"destination"} required>
							<Select size={"small"}>
								{whs.map(w => {
									if (w.uuid === this.props.wh.uuid) {
										return <Select.Option key={w.uuid} value={w.uuid} disabled={true}>{w.displayName}</Select.Option>
									} else {
										return <Select.Option key={w.uuid} value={w.uuid}>{w.displayName}</Select.Option>
									}
								})}
							</Select>
						</Form.Item>
						<Form.Item label={"拣货明细"}>
							<Table
								size={"small"}
								dataSource={this.state.pickings.slice()}
								columns={columns}
								pagination={false}
								bordered
								rowKey={"seq"}
								onRow={record => {
									return {
										onClick: event => {
										}, // 点击行
										onDoubleClick: event => {
											this.setState({editRowKey: record.seq});
										},
										onContextMenu: event => {
										},
										onMouseEnter: event => {
											this.forceUpdate()
										}, // 鼠标移入行
										onMouseLeave: event => {
											this.forceUpdate()
										}
									};
								}}
								footer={() => {
									return <Row><Col offset={20}><Typography.Link onClick={() => this.addPicking()}>添加拣货</Typography.Link></Col></Row>
								}}
							/>
						</Form.Item>
						<Row>
							<Col span={2} offset={19}>
								<Button block onClick={this.props.showModal}>取消</Button>
							</Col>
							<Col span={2} offset={1}>
								<Button block htmlType={"submit"} type={"primary"}>创建</Button>
							</Col>
						</Row>
					</Form>
				</div>
			);
		} else {
			return ""
		}
	}
}

export default StockMoveCreator;