import React, {Component} from "react";
import {Form, Row, Col, Input, Cascader, Select, Button, Switch, message, Spin} from "antd";
import {Log, axios, ProdServer} from "../../Managers";
import {inject, observer} from "mobx-react";
import Position from "../Address/regions";

@inject("Operator")
@observer
class CreateLogistic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shippers: [],
			senders: [],
			searchResults: [],
			searching: false,
			filterValue: "",
			eOrder: false,
			loading: false,
			logisticUUID: undefined
		};
		this.form = React.createRef();
	}

	submit = (values) => {
		this.form.current.validateFields().then(values => {
			let data = values;
			data.province = values.threeCode[0];
			data.city = values.threeCode[1];
			if (values.threeCode.length === 3) {
				data.district = values.threeCode[2];
			}
			this.setState({loading: true});
			axios.post("/scm/logistic/", {action: "sendByManual", data}).then(res => {
				if (res.data.status === 200) {
					this.form.current.setFieldsValue({logisticCode: res.data.data[0].logisticCode});
					message.success(res.data.message);
					this.props.closeLogisticForm();
				}
				this.setState({loading: false});
			});
		}).catch(error => {
			Log(error);
		});
	};

	componentDidMount() {
		axios.get("/scm/shipper/").then(res => {
			this.setState({shippers: res.data.data});
		});
		axios.get("/scm/sender/").then(res => {
			this.setState({senders: res.data.data});
		});
		if (this.props.order) {
			let data = this.props.order
			this.form.current.setFieldsValue({
				orders: [data.orderId],
				name: data.address.name,
				phoneNumber: data.address.phoneNumber,
				mobileNumber: data.address.mobileNumber,
				threeCode: [data.address.province, data.address.city, data.address.district],
				address: data.address.address
			})
		}
	}

	filterAddress = (inputValue, path) => {
		return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
	};

	searchOrder = (value) => {
		this.setState({filterValue: value});
		if (!this.state.searching && value !== "") {
			this.setState({searching: true});
			axios.post("/api/saleorder/", {action: "list", data: {keywords: value}}).then(res => {
				this.setState({
					searchResults: res.data.data,
					searching: false
				});
			});

		}
	};

	selectOrder = (value, options) => {
		if (options.length >= 1) {
			let _address = options[0].orderInfo.address;
			let _note = "";
			for (let x of options) {
				_note += `${x.orderInfo.note}\r\n`;
			}
			this.form.current.setFieldsValue(_address);
			this.form.current.setFieldsValue({note: _note});
			this.form.current.setFieldsValue({threeCode: [_address.province, _address.city, _address.district]});
			this.setState({filterValue: _address.mobileNumber});
			this.searchOrder(_address.mobileNumber);
		}

	};

	switchEOrder = (value) => {
		this.setState({eOrder: value});
	};

	requestEOrderCode = () => {
		this.form.current.validateFields().then(values => {
			let data = values;
			data.province = values.threeCode[0];
			data.city = values.threeCode[1];
			if (values.threeCode.length === 3) {
				data.district = values.threeCode[2];
			}
			this.setState({loading: true});
			axios.post("/scm/logistic/", {action: "sendByManual", data}).then(res => {
				if (res.data.status === 200) {
					this.form.current.setFieldsValue({logisticCode: res.data.data[0].logisticCode});
					message.success(res.data.message);
					this.setState({loading: false, logisticUUID: res.data.data[0].logisticUUID});
				} else {
					message.error(res.data.message);
				}
				this.setState({loading: false});
			});
		}).catch(error => {
			Log(error);
		});
	};

	printEPaper = () => {
		this.setState({loading: true});
		axios.post("/scm/logistic/", {action: "printLogisticPaper", data: {logisticUUIDS: [this.state.logisticUUID]}}).then(res => {
			if (res.data.status === 200) {
				for (let x of res.data.data) {
					window.open(ProdServer + "scm/logistic/?path=" + x, "_blank");
				}
				this.setState({loading: false});
			} else {
				message.error(res.data.message);
			}
		});
	};

	render() {
		let deliver = this.props.Operator.defaultShipper
		let data = this.props.order
		if (data) {
			deliver = data.config.deliver.toUpperCase()
		}
		return (
			<div>
				<Spin spinning={this.state.loading}>
					<Form onFinish={this.submit} size={this.props.Operator.componentSize} ref={this.form}>
						<Row>
							<Col span={16}>
								<Form.Item name={"orders"} label={"绑定订单"}>
									<Select allowClear={true} disabled={this.props.order} dropdownRender={(menu) => {
										return <div>{menu}
											<Input addonBefore={"输入订单信息搜索订单:"} onChange={(event) => this.searchOrder(event.target.value)}
											       value={this.state.filterValue}/>
										</div>;
									}} loading={this.state.searching} mode={"multiple"} onChange={this.selectOrder} optionLabelProp={"label"}>
										{this.state.searchResults.map(o => {
											return <Select.Option value={o.orderId} orderInfo={o} label={o.orderId}>
												{o.orderId}-({o.customerRef})[{o.statusDisplay}]{o.address.name}({o.address.mobileNumber})
											</Select.Option>;
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={7} offset={1}>
								<Form.Item name={"sender"} label={"发件人"} initialValue={this.props.Operator.defaultSender}>
									<Select>
										{this.state.senders.map(s => {
											return <Select.Option value={s.pk} key={Math.random()}>{s.senderName}</Select.Option>;
										})}
									</Select>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={8} disabled={this.props.order}>
								<Form.Item name={"name"} label={"收件人"} rules={[
									{
										required: true,
										message: "姓名为必填项"
									}
								]}>
									<Input disabled={this.props.order}/>
								</Form.Item>
							</Col>
							<Col span={6} offset={1}>
								<Form.Item name={"phoneNumber"} label={"电话"} rules={[
									({getFieldValue}) => ({
										validator(_, value) {
											if (value || getFieldValue("mobileNumber")) {
												return Promise.resolve();
											}

											return Promise.reject(new Error("电话号码或手机号必须至少填写一个"));
										}
									})
								]}>
									<Input disabled={this.props.order}/>
								</Form.Item>
							</Col>
							<Col span={8} offset={1}>
								<Form.Item name={"mobileNumber"} label={"手机"} disabled={this.props.order} rules={[
									({getFieldValue}) => ({
										validator(_, value) {
											if (value || getFieldValue("phoneNumber")) {
												return Promise.resolve();
											}

											return Promise.reject(new Error("电话号码或手机号必须至少填写一个"));
										}
									})
								]}>
									<Input disabled={this.props.order}/>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={8}>
								<Form.Item name={"threeCode"} label={"省市区"} rules={[
									{
										required: true,
										type: "array",
										message: "省市区为必选项"
									}
								]} disabled={this.props.order}>
									<Cascader disabled={this.props.order}
									          options={Position} showSearch={this.filterAddress}/>
								</Form.Item>
							</Col>
							<Col span={15} offset={1}>
								<Form.Item name={"address"} label={"详细地址"} rules={[
									{
										required: true,
										message: "详细地址为必填项",
										min: 3
									}
								]}>
									<Input.TextArea/>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={4}>
								<Form.Item name={"shipper"} label={"物流商"} initialValue={deliver} rules={[
									{
										required: true,
										message: "必选"
									}
								]}>
									<Select>
										{this.state.shippers.map(p => {
											return <Select.Option value={p.shipperCode} key={Math.random()}>{p.shipperName}</Select.Option>;
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name={"payType"} label={"支付方式"} initialValue={"3"} rules={[
									{
										required: true,
										message: "必选"
									}
								]}>
									<Select>
										<Select.Option value={"1"} key={Math.random()}>现付</Select.Option>
										<Select.Option value={"2"} key={Math.random()}>到付</Select.Option>
										<Select.Option value={"3"} key={Math.random()}>月结</Select.Option>
										<Select.Option value={"4"} key={Math.random()}>第三方支付(仅限顺丰)</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={3}>
								<Form.Item name={"eOrder"} label={"电子单"} valuePropName="checked" initialValue={false}>
									<Switch onText={"电子面单"} offText={"线下面单"} onChange={this.switchEOrder}/>
								</Form.Item>
							</Col>
							<Col span={5}>
								<Form.Item name={"logisticCode"} label={"单号"} dependencies={["eOrder"]} rules={[

									({getFieldValue}) => ({
										validator(_, value) {
											if (value === undefined && getFieldValue("eOrder")) {
												return Promise.resolve();
											}
											if (value) {
												return Promise.resolve();
											}
											return Promise.reject(new Error("线下面单必须手工填写"));
										}
									})
								]}>
									<Input disabled={this.state.eOrder}/>
								</Form.Item>
							</Col>
							<Col span={2} offset={1}>
								<Button disabled={!this.state.eOrder || this.state.logisticUUID} type="primary"
								        onClick={this.requestEOrderCode}>申请单号</Button>
							</Col>
							<Col span={2} offset={0}>
								<Button disabled={!this.state.logisticUUID} onClick={this.printEPaper}>打印面单</Button>
							</Col>
						</Row>
						<Form.Item name={"note"} label={"备注信息"} initialValue={""}>
							<Input.TextArea/>
						</Form.Item>
						<Row justify={"end"}>
							<Col span={2}><Button block type={"primary"} htmlType={"submit"}>提交</Button></Col>
							<Col offset={1} span={2}><Button block onClick={this.props.closeLogisticForm}>关闭</Button></Col>
						</Row>
					</Form>
				</Spin>
			</div>
		);
	}
}

export default CreateLogistic;