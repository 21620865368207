import React, {Component} from "react";
import {Tabs} from "element-react";
import SaleOrderTable from "./SaleOrderTable";
import SaleOrderDetail from "./SaleOrderDetail";
import {Modal, message} from "antd";
import CreateOrder from "./CreateOrder";
import {inject, observer} from "mobx-react";

@inject("SaleOrderManager", "Operator", "CustomerManager")
@observer
class Order extends Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			active: "新进订单",
			lastActive: "新进订单",
			showModal: false
		};
		this.createModal = React.createRef();
		this.newOrderTable = React.createRef();
	}

	showDetail = (record) => {
		if (this.state.details.indexOf(record) === -1) {
			let od = this.state.details;
			od.push(record);
			this.setState({details: od, active: record.orderId});
		} else {
			this.setState({active: record.orderId});
		}

	};

	removeTab = (obj) => {
		let od = [];
		for (let i of this.state.details) {
			if (i.orderId !== obj.props.label) {
				od.push(i);
			}
		}
		this.setState({details: od, active: this.state.lastActive});
	};

	changeTab = (obj) => {
		this.setState({
			lastActive: obj.props.label
		});
	};

	createOrder = () => {
		this.setState({
			showModal: true
		});
	};

	onCreateOrder = () => {
		this.createModal.current.form.current.validateFields().then(values => {
			values["expectedDate"] = values["expectedDate"].format("YYYY-MM-DD");
			this.props.SaleOrderManager.createSaleOrder(values).then(response => {
				if (response.data.status === 200) {
					message.success(`订单:${response.data.data[0].orderId}(${response.data.data[0].customerRef})创建成功!`);
					this.setState({showModal: false}, this.newOrderTable.current.onRefresh());
				} else {
					message.error(response.data.message);
				}
			}).catch(error => {
				message.error(error);
			});
		}).catch(error => {
			console.error(error);
		});
	};

	render() {
		let size = this.props.Operator.componentSize
		return (
			<div>
				<Tabs type="border-card" activeName={this.state.active}
				      onTabClick={this.changeTab}
				      onTabRemove={this.removeTab} style={{height: "100%"}}>
					<Tabs.Pane label={"新进订单"} name={"新进订单"}>
						<SaleOrderTable status={["draft"]} showDetail={this.showDetail}
						                ref={this.newOrderTable}
						                createOrder={this.createOrder}
						/>
					</Tabs.Pane>
					<Tabs.Pane label={"当前订单"} name={"当前订单"}>
						<SaleOrderTable status={["accepted", "locked"]} showDetail={this.showDetail}/>
					</Tabs.Pane>
					<Tabs.Pane label={"生产中"} name={"生产中"}>
						<SaleOrderTable status={["producing", "planned"]} showDetail={this.showDetail}/>
					</Tabs.Pane>
					<Tabs.Pane label={"待发货"} name={"待发货"}>
						<SaleOrderTable status={["waitdeliver"]} showDetail={this.showDetail}/>
					</Tabs.Pane>
					<Tabs.Pane label={"已发货"} name={"已发货"}>
						<SaleOrderTable status={["sent", "transport"]} showDetail={this.showDetail}/>
					</Tabs.Pane>
					<Tabs.Pane label="历史订单" name={"历史订单"}>
						<SaleOrderTable status={["rejected", "cancelled", "received"]} showDetail={this.showDetail}/>
					</Tabs.Pane>
					{this.state.details.map(record => {
						return (
							<Tabs.Pane label={record.orderId} name={record.orderId} closable={true} key={record.orderId}>
								<SaleOrderDetail orderId={record.orderId}/>
							</Tabs.Pane>
						);
					})}

				</Tabs>
				<Modal visible={this.state.showModal}
				       title={"创建新订单"}
				       width={"80vw"}
				       onOk={this.onCreateOrder}
				       onCancel={() => {this.setState({showModal: false});}}
				       okButtonProps={{size: size}}
				       cancelButtonProps={{size: size}}
				       destroyOnClose={true}
				>
					<CreateOrder ref={this.createModal}/>
				</Modal>
			</div>
		);
	}
}

export default Order;