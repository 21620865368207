import './App.css';
import MainLayout from './Components/Layout/index'
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import React from "react";
import Login from './Components/Login/index'
import { Provider } from 'mobx-react';
import stores from './Managers/store'
import './theme/index.less'
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';

function App() {
  return (
      <ConfigProvider locale={zhCN}>
          <Provider {...stores}>
              <Router>
                  <Switch>
                      <Route path={"/login/"} component={Login}/>
                      <Route path={"/"} component={MainLayout} />
                  </Switch>
              </Router>
          </Provider>
      </ConfigProvider>


  );
}

export default App;
