import React, {Component} from "react";
import {Input, Modal, List, Divider, Typography} from "antd";
import {axios} from "../../Managers";
import PopOrderDetail from "../Order/PopOrderDetail";
import PopLogisticDetail from "../SCM/PopLogisticDetail";
import PopMTOPlanDetail from "../MTO/PopMTOPlanDetail";

class GlobalSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: {
				SaleOrder: [],
				Logistics: [],
				MTOPlan: []
			},
			visible: false

		};
	}

	onSearch = (value) => {
		axios.post("/api/search/", {keyword: value}).then(response => {
			if (response.data.status === 200) {
				console.log(response.data.data);
				this.setState({data: response.data.data, visible: true});
			}
		});
	};

	showDetail = (tp, id) => {
		this.setState({visible: false});
		switch (tp) {
			case "saleorder":
				PopOrderDetail(id);
				break;
			case "logistic":
				PopLogisticDetail(id)
				break
			case "mtoplan":
				PopMTOPlanDetail(id)
				break
			default:
				return;
		}
	};

	render() {
		return (
			<div>
				<Input.Search addonBefore={"全局检索:"} onSearch={this.onSearch} loading={this.state.loading} allowClear={true}/>
				<Modal visible={this.state.visible} footer={null} width={"80vw"} destroyOnClose={true} centered
				       onCancel={() => {this.setState({visible: false});}}>
					<div style={{marginLeft: 30}}>
						<Divider orientation="left">销售订单</Divider>
						{this.state.data.SaleOrder.length >= 1 ?
							<List
								size={"small"}
								dataSource={this.state.data.SaleOrder}
								renderItem={item => (
									<div style={{display: "block"}}>
										订单号:<Typography.Link onClick={() => this.showDetail("saleorder", item.orderId)}>{item.orderId}</Typography.Link>
										<span style={{marginLeft: 20}}>客户参照: {item.customerRef}</span>
										<hr style={{marginTop: 5, marginBottom: 0}}/>
									</div>
								)}
							/> : ""}
						<Divider orientation="left">生产计划</Divider>
						{this.state.data.MTOPlan.length >= 1 ? <List
							size={"small"}
							dataSource={this.state.data.MTOPlan}
							renderItem={item => (
								<div style={{display: "block"}}>
									计划编号:<Typography.Link onClick={() => this.showDetail("mtoplan", item.planId)}>{item.planId}</Typography.Link>
									<span style={{marginLeft: 20}}>计划备注: {item.note}</span>
									<hr style={{marginTop: 5, marginBottom: 0}}/>
								</div>
							)}
						/> : ""}
						<Divider orientation="left">物流</Divider>
						{this.state.data.Logistics.length >= 1 ? <List
							size={"small"}
							dataSource={this.state.data.Logistics}
							renderItem={item => (
								<div style={{display: "block"}}>
									物流单号:<Typography.Link
									onClick={() => this.showDetail("logistic",
										item.logisticUUID)}>{item.shipper.shipperName}-{item.logisticCode}-({item.lastStateDisplay})</Typography.Link>
									<hr style={{marginTop: 5, marginBottom: 0}}/>
								</div>
							)}
						/> : ""}


					</div>
				</Modal>
			</div>
		);
	}
}

export default GlobalSearch;