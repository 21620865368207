import React, {Component} from "react";
import {Badge, Button, Descriptions, Divider, Input, message, Popover, Space, Table, Typography, Checkbox} from "antd";
import PopOrderDetail from "../Order/PopOrderDetail";
import {Tag} from "element-react";
import {axios} from "../../Managers";
import {inject, observer} from "mobx-react";

@inject("Operator")
@observer
class AcceptedPool extends Component {
	constructor(props) {
		super(props);
		let filterProducts = [];
		if (this.props.extraInfo) {
			filterProducts = this.props.extraInfo.usableProduct_PKS;
		}
		this.planed_qty = 0;
		if (this.props.data) {
			this.planed_qty = this.props.data.MTORecord.length;
		}

		this.state = {
			details: [],
			orderLines: [],
			products: [],
			partners: [],
			keyword: null,
			filterProducts: filterProducts,
			filterPartners: [],
			loading: false,
			planned: [],
			selectedRows: [],
			isFull: false,
			qty: 0,
			selectedRowKeys: [],
			urgent: false,
			...this.props.Operator.PaginationConfig,
			limit: []
		};

	}

	componentDidMount() {
		this.getPreProduct();
	}

	getPreProduct = () => {
		this.setState({loading: true});
		axios.post("/mto/mtoplan/", {
			action: "listPreProduct", data: {
				filterProducts: this.state.filterProducts,
				filterPartners: this.state.filterPartners,
				current: this.state.current,
				pageSize: this.state.pageSize,
				keyword: this.state.keyword,
				urgent: this.state.urgent
			}
		}).then(response => {
			if (response.status === 200) {
				let data = response.data;
				if (data.status === 200) {
					this.setState({
						orderLines: data.data.lines,
						products: data.data.products,
						partners: data.data.partners,
						current: data.data.current,
						pageSize: data.data.pageSize,
						total: data.data.total,
						loading: false
					});
				} else {
					message.error(data.message);
					this.setState({loading: false});
				}
			}
		});
	};

	changePage = async (current, pageSize) => {
		await this.setState({current: current, pageSize: pageSize});
		this.getPreProduct();
	};

	addFilter = async (target, type = "partner") => {

		if (type === "partner") {
			let _filter = this.state.filterPartners;
			_filter.push(target.pk);
			await this.setState({filterPartners: _filter});
			this.getPreProduct();
		} else {
			let _filter = this.state.filterProducts;
			_filter.push(target.pk);
			await this.setState({filterProducts: _filter});
			this.getPreProduct();
		}
	};

	removeFilter = async (target, type = "partner") => {
		let _filter = [];
		if (type === "partner") {
			for (let x of this.state.filterPartners) {
				if (x !== target.pk) {
					_filter.push(x);
				}
			}
			await this.setState({filterPartners: _filter});
			this.getPreProduct();
		} else {
			for (let x of this.state.filterProducts) {
				if (x !== target.pk) {
					_filter.push(x);
				}
			}
			await this.setState({filterProducts: _filter});
			this.getPreProduct();
		}

	};

	onSearch = async (value) => {
		if (value !== "") {
			await this.setState({keyword: value, current: 1});
		} else {
			await this.setState({keyword: null, current: 1});
		}
		this.getPreProduct();
	};

	filterProducts = async (pks) => {
		if (pks.length !== 0) {
			await this.setState({filterProducts: pks});
			this.getPreProduct();
		}
	};

	planOrderLine = async (orderLine) => {
		let qty = this.state.qty;
		qty += orderLine.quantity;
		await this.props.planOrderLine(orderLine).then(lineId => {
			let _planned = this.state.planned;
			if (_planned.indexOf(lineId) === -1) {
				_planned.push(lineId);
				this.setState({planned: _planned, qty});
				return true;
			}

		}).catch(error => {
			message.error(error, 3);
			return false;
		});

	};

	batchPlanOrderLine = async () => {
		for (let x of this.state.selectedRows) {
			await this.planOrderLine(x);
		}
	};

	changeSelected = async (rowKeys, rows) => {
		console.log(this.props)
		if (this.props.extraInfo) {
			let _keys = [];
			let _rows = [];
			let qty = 0;
			let isFull = false;
			let limit = {}
			if (this.props.data) {
				if (this.props.data.hasOwnProperty("limit")) {
					limit = this.props.data.limit
				}
			}
			for (let x of rows) {
				if (!limit.hasOwnProperty(x.product.productSeriesCode)) {
					limit[x.product.productSeriesCode] = 0
				}

				if ((qty + x.quantity + this.planed_qty) <= this.props.extraInfo.maxCap) {
					
					if (this.props.extraInfo.config.limit[x.product.productSeriesCode] >
						limit[x.product.productSeriesCode]) {

						await _keys.push(x.lineId);
						await _rows.push(x);
						qty += x.quantity;
						limit[x.product.productSeriesCode] += x.quantity
					}
					continue
				} else {
					isFull = true;
				}
			}
			this.setState({selectedRowKeys: _keys, selectedRows: _rows, qty, isFull, limit});
		} else {
			this.setState({selectedRowKeys: rowKeys, selectedRows: rows});
		}

	};

	smartPlan = () => {
		// 智能排产
		axios.post("/mto/mtoplan/", {action: "smartPlan", data: {orderLines: this.state.selectedRowKeys}}).then(response => {
			message.success(response.data.message, 3);
		});
	};

	changeUrgent = async (target) => {
		await this.setState({urgent: target.target.checked});
		this.getPreProduct();
	};

	render() {

		const columns = [
			{title: "序号", key: "index", align: "center", width: 40, render: (text, record, index) => {return index + 1;}},
			{
				title: "父单号", key: "parentOrderId", align: "center", width: "12%",
				responsive: ["xxl"], render: (text, record) => {
					if (record.urgent) {
						return <Badge count={"急"} offset={[10, 3]} size={"small"}><Typography.Link
							onClick={() => PopOrderDetail(record.parentOrderId)}>{record.parentOrderId}{record.parentOrderId}{record.parentLockCase
							? <Badge dot={true} title={"有紧急工单未关闭"} offset={[5, 5]}/>
							: ""}</Typography.Link></Badge>;
					} else {
						return <Typography.Link key={`
		{record.index}
		-{record.parentOrderId}`}
						                        onClick={() => PopOrderDetail(record.parentOrderId)}>{record.parentOrderId}{record.parentLockCase
							? <Badge dot={true} title={"有紧急工单未关闭"} offset={[5, 5]}/>
							: ""}</Typography.Link>;
					}
				}
			},
			{
				title: "父单参照", key: "parentCustomerRef", align: "center", width: "10%", ellipsis: true, render: ((text, record, index) => {
					return <span key={`${index}
		_$
		{record.parentCustomerRef}
		`}>{record.parentCustomerRef}</span>;
				})
			},
			{title: "子单号", dataIndex: "lineId", align: "center", width: "15%"},
			{title: "数量", dataIndex: "quantity", align: "center", width: 40},
			{title: "状态", dataIndex: "statusDisplay", align: "center", width: 60, responsive: ["xl", "xxl"]},
			{title: "收件人", dataIndex: "customerInfo", align: "center", width: 150, responsive: ["md"]},
			{
				title: "创建时间", dataIndex: "createTime", align: "center", width: 120, responsive: ["lg", "xl", "xxl"]
			},
			{
				title: "产品", key: "product", align: "center", width: 150, render: (text, record) => {
					return <span key={`
		{record.index}
		-{record.product.productUUID}`}>{record.product.productName}</span>;
				}
			},
			{
				title: "制版图", dataIndex: "layoutImage", align: "center", width: "15%",
				responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					return (
						<Popover content={<img src={record.layoutImage} alt={"版下图"} style={{height: 300}}/>}
						         title="版下图">
							<img src={record.layoutImage} alt={"layoutImage"} style={{height: 30}}/>
						</Popover>
					);
				}
			},
			{
				title: "备注", dataIndex: "note", align: "center", width: "10%",
				responsive: ["xl", "xxl"], ellipsis: true
			},
			{
				title: "操作", key: "opts", align: "center", width: 80, render: (text, record) => {
					if (this.props.planOrderLine) {
						return <Typography.Link onClick={() => this.planOrderLine(record)}
						                        disabled={this.state.planned.indexOf(record.lineId) !== -1}>排产</Typography.Link>;
					}
				}
			}
		];
		let size = this.props.Operator.componentSize
		return (
			<div style={{visibility: this.props.extraInfo === null ? "hidden" : "visible"}}>
				<Descriptions bordered size={size}
				              column={{xxl: 4, xl: 4, lg: 4, md: 2, sm: 1, xs: 1}}
				>
					<Descriptions.Item label={"渠道"} labelStyle={{width: "5vw"}} span={4}>
						<Space split={<Divider type="vertical"/>} wrap={true}>
							{this.state.partners.map(p => {
								if (this.state.filterPartners.indexOf(p.pk) === -1) {
									return (
										<Badge size={size} count={p.count} offset={[10, 0]} style={{backgroundColor: "#00cc00"}}
										       key={Math.random()}>
											<Typography.Link key={p.shortCode} onClick={() => {
												this.addFilter(p, "partner");
											}}>{p.displayName}</Typography.Link>
										</Badge>);
								}
								return false;
							})}
						</Space>
					</Descriptions.Item>
					<Descriptions.Item label={"产品"} span={4}>
						<Space split={<Divider type="vertical"/>} wrap={true}>
							{this.state.products.map(p => {
								if (this.state.filterProducts.indexOf(p.pk) === -1) {
									if (this.props.extraInfo) {
										return (
											<Badge size={size} count={p.count} offset={[10, 0]} style={{backgroundColor: "#00cc00"}}
											       key={Math.random()}>
												<Typography.Link key={p.productUUID} disabled={this.props.extraInfo.usableProduct_PKS.indexOf(p.pk) === -1}
												                 onClick={() => {
													                 this.addFilter(p, "product");
												                 }}>{p.productName}</Typography.Link>
											</Badge>);
									}

									return (
										<Badge size={size} count={p.count} offset={[10, 0]} style={{backgroundColor: "#00cc00"}}
										       key={Math.random()}>
											<Typography.Link key={p.productUUID}
											                 onClick={() => {
												                 this.addFilter(p, "product");
											                 }}>{p.productName}</Typography.Link>
										</Badge>);
								}
								return false;
							})}
						</Space>
					</Descriptions.Item>
					<Descriptions.Item label={"筛选"} span={3}>
						<Space split={<Divider type="vertical"/>} wrap={true}>
							{this.state.partners.map(p => {
								if (this.state.filterPartners.indexOf(p.pk) !== -1) {
									return <Tag closable={true} onClose={() => this.removeFilter(p)} key={p.pk}>
										{p.displayName}</Tag>;
								}
								return false;
							})}

							{this.state.products.map(p => {
								if (this.state.filterProducts.indexOf(p.pk) !== -1) {
									return <Tag closable={true} onClose={() => this.removeFilter(p, "product")}
									            key={p.pk} type="primary">
										{p.productName}</Tag>;
								}
								return false;
							})}
							<Checkbox onChange={this.changeUrgent} checked={this.state.urgent}>筛选加急</Checkbox>
						</Space>
					</Descriptions.Item>

					<Descriptions.Item span={1} label={"搜索"}>
						<Space split={<Divider type={"vertical"}/>}>
							<Input.Search size={size} allowClear
							              onSearch={this.onSearch}/>
							<Button size={size}
							        onClick={() => {
								        this.setState({keyword: null}, this.getPreProduct());
							        }}>刷新</Button>
							<Button size={size}
							        onClick={() => {this.batchPlanOrderLine();}
							        } disabled={this.state.selectedRows.length === 0 || !this.props.hasOwnProperty("extraInfo") ||
								this.props.addOrderLine}>批量排入</Button>
							<Button size={size} type={"primary"}
							        onClick={() => {this.smartPlan();}
							        } disabled={this.state.selectedRows.length === 0 || this.props.hasOwnProperty("extraInfo")}>智能排产</Button>
						</Space>

					</Descriptions.Item>
				</Descriptions>
				<Table
					columns={columns}
					bordered
					rowKey={"lineId"}
					dataSource={this.state.orderLines}
					loading={this.state.loading}
					size={size}
					pagination={{
						size: size,
						pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
						showQuickJumper: true,
						showSizeChanger: true,
						defaultCurrent: this.state.current,
						defaultPageSize: this.state.pageSize,
						total: this.state.total,
						onChange: this.changePage,
						showTotal: (total, range) => `
		当前显示:${range[0]}-${range[1]}条, 总 ${total}
		条`
					}}
					rowSelection={{
						onChange: this.changeSelected,
						selectedRowKeys: this.state.selectedRowKeys,
						getCheckboxProps: (record) => {
							if (this.state.isFull) {
								if (this.state.selectedRowKeys.indexOf(record.lineId) === -1) {
									return {disabled: this.state.isFull};
								} else {
									return {disabled: false};
								}
							} else {
								if (this.props.extraInfo) {
									if (this.state.limit.hasOwnProperty(record.product.productSeriesCode)) {
										return {
											disabled: this.props.extraInfo.config.limit[record.product.productSeriesCode] <=
												this.state.limit[record.product.productSeriesCode]
										}
									}
									if (this.state.selectedRowKeys.indexOf(record.lineId) !== -1) {
										return {disabled: false};
									} else {
										return {disabled: !(this.state.qty + record.quantity + this.planed_qty <= this.props.extraInfo.maxCap)};
									}
								}

								return {disabled: false};
							}
						}
					}}
				/>
			</div>
		);
	}

	;
}

export default AcceptedPool;