import React, {Component} from "react";
import {Form, Input, Select, Radio, Upload, Button, Typography, Row, Col, message} from "antd";
import {inject, observer} from "mobx-react";
import {getCsrfToken} from "../../Managers/index";

@inject("SaleOrderManager", "ServiceCaseManager", "Operator")
@observer
class ServiceCaseCreator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SaleOrders: [],
			loading: false,
			images: []
		};
	}

	createServiceCase = (data) => {
		let _image = [];
		for (let i of this.state.images) {
			_image.push(i.name);
		}
		data["images"] = _image;
		this.props.ServiceCaseManager.createServiceCase(data).then(response => {
			if (response.data.status === 200) {
				message.success(response.data.message, 3);
				if (this.props.onDestroyModal) {
					this.props.onDestroyModal();
				}
			} else {
				message.error(response.data.message, 5);
			}
		}).catch(error => {
			console.log(error);
		});
	};

	onSearch = (keywords) => {
		// 关联订单搜索
		if (!this.state.loading) {
			if (keywords === "") {
				this.setState({SaleOrders: []});
			} else {
				this.setState({loading: true});
				this.props.SaleOrderManager.listSaleOrder({keywords: keywords}).then(response => {
					this.setState({SaleOrders: response.data.data, loading: false});
				}).catch(error => {
					this.setState({loading: false});
				});
			}
		}
	};

	render() {
		let size = this.props.Operator.componentSize
		const uploadProps = {
			action: "/api/imagestore/",
			method: "post",
			headers: {"X-CSRFToken": getCsrfToken()},
			withCredentials: true,
			onChange: (data) => {
				if (data.file.status === "done" && data.file.response.message === "上传成功") {
					let _nlist = this.state.images;
					_nlist.push(data.file.response.data[0]);
					this.setState({images: _nlist});
				}
			}
		};
		return (
			<div>
				<Form labelAlign={"right"} size={size} onFinish={this.createServiceCase} preserve={false}>
					<Form.Item name={"title"} label={"工单标题"} rules={[
						{required: true, message: "标题为必填项"}
					]}>
						<Input/>
					</Form.Item>
					<Form.Item name={"caseType"} label={"工单类型"} rules={[
						{required: true, message: "工单类型为必选项"}
					]}>
						<Select>
							<Select.Option key="设计问题" value={"设计问题"}>设计问题</Select.Option>
							<Select.Option key="订单问题" value={"订单问题"}>订单问题</Select.Option>
							<Select.Option key="生产问题" value={"生产问题"}>生产问题</Select.Option>
							<Select.Option key="交期问题" value={"交期问题"}>交期问题</Select.Option>
							<Select.Option key="质量问题" value={"质量问题"}>质量问题</Select.Option>
							<Select.Option key="物流问题" value={"物流问题"}>物流问题</Select.Option>
							<Select.Option key="其它问题" value={"其它问题"}>其它问题</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name={"urgency"} label={"紧急程度"} initialValue={"一般"} rules={[
						{required: true, message: "紧急程度为必选项"}
					]}>
						<Radio.Group>
							<Radio value={"一般"}><Typography.Text type={"warning"}>一般</Typography.Text></Radio>
							<Radio value={"紧急"}><Typography.Text type={"danger"}>紧急</Typography.Text></Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item name={"content"} label={"问题内容"} rules={[
						{required: true, message: "请详细描述问题内容"}
					]}>
						<Input.TextArea/>
					</Form.Item>
					<Form.Item name={"relatedOrder"} label={"相关订单"}>
						<Select mode={"multiple"} onSearch={this.onSearch} loading={this.state.loading}
						        placeholder={"输入订单号或客户参照进行搜索"}
						        notFoundContent={"找不到相关订单"}
						        filterOption={(i, o) => {
							        return true;
						        }}>
							{this.state.SaleOrders.map(saleorder => {
								return (
									<Select.Option value={saleorder.orderId} key={saleorder.orderId}>
										<span>{saleorder.orderId}({saleorder.customerRef})-{saleorder.statusDisplay}</span>
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
					<Row>
						<Col><span>相关图片:</span></Col>
						<Col offset={1}><Upload {...uploadProps}>
							<Button size={size}>上传</Button>
						</Upload></Col>
					</Row>
					<Row gutter={{xs: 8}} justify={"end"}>
						<Col span={4}><Button type={"primary"} block htmlType={"submit"}>提交</Button></Col>
						<Col span={4} offset={1}><Button block onClick={this.props.onDestroyModal}>取消</Button></Col>
					</Row>
				</Form>
			</div>
		);
	}
}

export default ServiceCaseCreator;