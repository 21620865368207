import React, {Component} from "react";
import {Form, Input, Switch, Row, Col, Button, message} from "antd";
import {inject, observer} from "mobx-react";

@inject("SaleOrderManager", "Operator")
@observer
class OrderCommentForm extends Component {

	onFinish = (values) => {
		this.props.SaleOrderManager.createOrderComment(values).then(data => {
			if (data.status === 200) {
				message.success(data.message, 3)
				this.props.close()
			}
		})
	}

	render() {
		return (
			<div className="commentForm">
				<Form size={"small"} onFinish={this.onFinish}>
					<Form.Item name={"orderId"} hidden initialValue={this.props.orderId}>
						<Input/>
					</Form.Item>
					<Form.Item name={"title"} label={"注记标题"} required>
						<Input size={"small"}/>
					</Form.Item>
					{this.props.status === "locked" ?
						<Form.Item name={["config", "lockOrder"]} label={"锁定订单"} valuePropName="checked"
								   initialValue={true}>
							<Switch/>
						</Form.Item>
						:
						<Form.Item name={["config", "lockOrder"]} label={"锁定订单"} valuePropName="checked"
								   initialValue={false}>
							<Switch/>
						</Form.Item>
					}

					<Form.Item name={"content"} label={"注记内容"} required>
						<Input.TextArea size={"small"} showCount={true} autoSize={{minRows: 10}}/>
					</Form.Item>
					<Row justify={"end"}>
						<Col span={3} offset={1}><Button onClick={this.props.close} block size={this.props.Operator.componentSize}>取消</Button> </Col>
						<Col span={3} offset={1}><Button type={"primary"} block htmlType={"submit"} size={this.props.Operator.componentSize}>确定</Button></Col>
					</Row>
				</Form>
			</div>
		);
	}
}

export default OrderCommentForm;