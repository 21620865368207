const Position = [
	{
		"code": 110000000000,
		"children": [
			{
				"code": 110100000000,
				"children": [
					{
						"code": 110101000000,
						"value": "东城区",
						"label": "东城区"
					},
					{
						"code": 110102000000,
						"value": "西城区",
						"label": "西城区"
					},
					{
						"code": 110105000000,
						"value": "朝阳区",
						"label": "朝阳区"
					},
					{
						"code": 110106000000,
						"value": "丰台区",
						"label": "丰台区"
					},
					{
						"code": 110107000000,
						"value": "石景山区",
						"label": "石景山区"
					},
					{
						"code": 110108000000,
						"value": "海淀区",
						"label": "海淀区"
					},
					{
						"code": 110109000000,
						"value": "门头沟区",
						"label": "门头沟区"
					},
					{
						"code": 110111000000,
						"value": "房山区",
						"label": "房山区"
					},
					{
						"code": 110112000000,
						"value": "通州区",
						"label": "通州区"
					},
					{
						"code": 110113000000,
						"value": "顺义区",
						"label": "顺义区"
					},
					{
						"code": 110114000000,
						"value": "昌平区",
						"label": "昌平区"
					},
					{
						"code": 110115000000,
						"value": "大兴区",
						"label": "大兴区"
					},
					{
						"code": 110116000000,
						"value": "怀柔区",
						"label": "怀柔区"
					},
					{
						"code": 110117000000,
						"value": "平谷区",
						"label": "平谷区"
					},
					{
						"code": 110118000000,
						"value": "密云区",
						"label": "密云区"
					},
					{
						"code": 110119000000,
						"value": "延庆区",
						"label": "延庆区"
					}
				],
				"value": "市辖区",
				"label": "市辖区"
			}
		],
		"value": "北京市",
		"label": "北京市"
	},
	{
		"code": 82000700000,
		"children": [
			{
				"value": "澳门半岛",
				"label": "澳门半岛",
				"code": 82000700010,
				"children": [
					{
						"code": 82000700011,
						"value": "花地玛堂区",
						"label": "花地玛堂区"
					},
					{
						"code": 82000700012,
						"value": "圣安多尼堂区",
						"label": "圣安多尼堂区"
					},
					{
						"code": 82000700013,
						"value": "大堂区",
						"label": "大堂区"
					},
					{
						"code": 82000700014,
						"value": "望德堂区",
						"label": "望德堂区"
					},
					{
						"code": 82000700015,
						"value": "风顺堂区",
						"label": "风顺堂区"
					}
				]
			},
			{
				"value": "离岛",
				"label": "离岛",
				"code": 82000700020,
				"children": [
					{
						"code": 82000700021,
						"value": "嘉模堂区",
						"label": "嘉模堂区"
					},
					{
						"code": 82000700022,
						"value": "圣方济各堂区",
						"label": "圣方济各堂区"
					}
				]
			}
		],
		"value": "澳门特别行政区",
		"label": "澳门特别行政区"
	},
	{
		"code": 99000,
		"value": "台湾省",
		"label": "台湾省"
	},
	{
		"code": 852000000000,
		"children": [
			{
				"value": "香港岛",
				"label": "香港岛",
				"code": 852000000010,
				"children": [
					{
						"code": 852000000011,
						"value": "中西区",
						"label": "中西区"
					},
					{
						"code": 852000000012,
						"value": "湾仔区",
						"label": "湾仔区"
					},
					{
						"code": 852000000013,
						"value": "东区",
						"label": "东区"
					},
					{
						"code": 852000000014,
						"value": "南区",
						"label": "南区"
					}
				]
			},
			{
				"value": "九龙半岛",
				"label": "九龙半岛",
				"code": 852000000020,
				"children": [
					{
						"code": 852000000021,
						"value": "油尖旺区",
						"label": "油尖旺区"
					},
					{
						"code": 852000000022,
						"value": "深水埗区",
						"label": "深水埗区"
					},
					{
						"code": 852000000023,
						"value": "九龙城区",
						"label": "九龙城区"
					},
					{
						"code": 852000000023,
						"value": "黄大仙区",
						"label": "黄大仙区"
					},
					{
						"code": 852000000024,
						"value": "观塘区",
						"label": "观塘区"
					}
				]
			},
			{
				"value": "新界",
				"label": "新界",
				"code": 852000000030,
				"children": [
					{
						"code": 852000000031,
						"value": "北区",
						"label": "北区"
					},
					{
						"code": 852000000032,
						"value": "大埔区",
						"label": "大埔区"
					},
					{
						"code": 852000000033,
						"value": "沙田区",
						"label": "沙田区"
					},
					{
						"code": 852000000034,
						"value": "西贡区",
						"label": "西贡区"
					},
					{
						"code": 852000000035,
						"value": "荃湾区",
						"label": "荃湾区"
					},
					{
						"code": 852000000036,
						"value": "屯门区",
						"label": "屯门区"
					},
					{
						"code": 852000000037,
						"value": "元朗区",
						"label": "元朗区"
					},
					{
						"code": 852000000038,
						"value": "葵青区",
						"label": "葵青区"
					},
					{
						"code": 852000000039,
						"value": "离岛区",
						"label": "离岛区"
					}
				]
			}
		],
		"value": "香港特别行政区",
		"label": "香港特别行政区"
	},
	{
		"code": 120000000000,
		"children": [
			{
				"code": 120100000000,
				"children": [
					{
						"code": 120101000000,
						"value": "和平区",
						"label": "和平区"
					},
					{
						"code": 120102000000,
						"value": "河东区",
						"label": "河东区"
					},
					{
						"code": 120103000000,
						"value": "河西区",
						"label": "河西区"
					},
					{
						"code": 120104000000,
						"value": "南开区",
						"label": "南开区"
					},
					{
						"code": 120105000000,
						"value": "河北区",
						"label": "河北区"
					},
					{
						"code": 120106000000,
						"value": "红桥区",
						"label": "红桥区"
					},
					{
						"code": 120110000000,
						"value": "东丽区",
						"label": "东丽区"
					},
					{
						"code": 120111000000,
						"value": "西青区",
						"label": "西青区"
					},
					{
						"code": 120112000000,
						"value": "津南区",
						"label": "津南区"
					},
					{
						"code": 120113000000,
						"value": "北辰区",
						"label": "北辰区"
					},
					{
						"code": 120114000000,
						"value": "武清区",
						"label": "武清区"
					},
					{
						"code": 120115000000,
						"value": "宝坻区",
						"label": "宝坻区"
					},
					{
						"code": 120116000000,
						"value": "滨海新区",
						"label": "滨海新区"
					},
					{
						"code": 120117000000,
						"value": "宁河区",
						"label": "宁河区"
					},
					{
						"code": 120118000000,
						"value": "静海区",
						"label": "静海区"
					},
					{
						"code": 120119000000,
						"value": "蓟州区",
						"label": "蓟州区"
					}
				],
				"value": "市辖区",
				"label": "市辖区"
			}
		],
		"value": "天津市",
		"label": "天津市"
	},
	{
		"code": 130000000000,
		"children": [
			{
				"code": 130100000000,
				"children": [
					{
						"code": 130101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130102000000,
						"value": "长安区",
						"label": "长安区"
					},
					{
						"code": 130104000000,
						"value": "桥西区",
						"label": "桥西区"
					},
					{
						"code": 130105000000,
						"value": "新华区",
						"label": "新华区"
					},
					{
						"code": 130107000000,
						"value": "井陉矿区",
						"label": "井陉矿区"
					},
					{
						"code": 130108000000,
						"value": "裕华区",
						"label": "裕华区"
					},
					{
						"code": 130109000000,
						"value": "藁城区",
						"label": "藁城区"
					},
					{
						"code": 130110000000,
						"value": "鹿泉区",
						"label": "鹿泉区"
					},
					{
						"code": 130111000000,
						"value": "栾城区",
						"label": "栾城区"
					},
					{
						"code": 130121000000,
						"value": "井陉县",
						"label": "井陉县"
					},
					{
						"code": 130123000000,
						"value": "正定县",
						"label": "正定县"
					},
					{
						"code": 130125000000,
						"value": "行唐县",
						"label": "行唐县"
					},
					{
						"code": 130126000000,
						"value": "灵寿县",
						"label": "灵寿县"
					},
					{
						"code": 130127000000,
						"value": "高邑县",
						"label": "高邑县"
					},
					{
						"code": 130128000000,
						"value": "深泽县",
						"label": "深泽县"
					},
					{
						"code": 130129000000,
						"value": "赞皇县",
						"label": "赞皇县"
					},
					{
						"code": 130130000000,
						"value": "无极县",
						"label": "无极县"
					},
					{
						"code": 130131000000,
						"value": "平山县",
						"label": "平山县"
					},
					{
						"code": 130132000000,
						"value": "元氏县",
						"label": "元氏县"
					},
					{
						"code": 130133000000,
						"value": "赵县",
						"label": "赵县"
					},
					{
						"code": 130171000000,
						"value": "石家庄高新技术产业开发区",
						"label": "石家庄高新技术产业开发区"
					},
					{
						"code": 130172000000,
						"value": "石家庄循环化工园区",
						"label": "石家庄循环化工园区"
					},
					{
						"code": 130181000000,
						"value": "辛集市",
						"label": "辛集市"
					},
					{
						"code": 130183000000,
						"value": "晋州市",
						"label": "晋州市"
					},
					{
						"code": 130184000000,
						"value": "新乐市",
						"label": "新乐市"
					}
				],
				"value": "石家庄市",
				"label": "石家庄市"
			},
			{
				"code": 130200000000,
				"children": [
					{
						"code": 130201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130202000000,
						"value": "路南区",
						"label": "路南区"
					},
					{
						"code": 130203000000,
						"value": "路北区",
						"label": "路北区"
					},
					{
						"code": 130204000000,
						"value": "古冶区",
						"label": "古冶区"
					},
					{
						"code": 130205000000,
						"value": "开平区",
						"label": "开平区"
					},
					{
						"code": 130207000000,
						"value": "丰南区",
						"label": "丰南区"
					},
					{
						"code": 130208000000,
						"value": "丰润区",
						"label": "丰润区"
					},
					{
						"code": 130209000000,
						"value": "曹妃甸区",
						"label": "曹妃甸区"
					},
					{
						"code": 130224000000,
						"value": "滦南县",
						"label": "滦南县"
					},
					{
						"code": 130225000000,
						"value": "乐亭县",
						"label": "乐亭县"
					},
					{
						"code": 130227000000,
						"value": "迁西县",
						"label": "迁西县"
					},
					{
						"code": 130229000000,
						"value": "玉田县",
						"label": "玉田县"
					},
					{
						"code": 130271000000,
						"value": "河北唐山芦台经济开发区",
						"label": "河北唐山芦台经济开发区"
					},
					{
						"code": 130272000000,
						"value": "唐山市汉沽管理区",
						"label": "唐山市汉沽管理区"
					},
					{
						"code": 130273000000,
						"value": "唐山高新技术产业开发区",
						"label": "唐山高新技术产业开发区"
					},
					{
						"code": 130274000000,
						"value": "河北唐山海港经济开发区",
						"label": "河北唐山海港经济开发区"
					},
					{
						"code": 130281000000,
						"value": "遵化市",
						"label": "遵化市"
					},
					{
						"code": 130283000000,
						"value": "迁安市",
						"label": "迁安市"
					},
					{
						"code": 130284000000,
						"value": "滦州市",
						"label": "滦州市"
					}
				],
				"value": "唐山市",
				"label": "唐山市"
			},
			{
				"code": 130300000000,
				"children": [
					{
						"code": 130301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130302000000,
						"value": "海港区",
						"label": "海港区"
					},
					{
						"code": 130303000000,
						"value": "山海关区",
						"label": "山海关区"
					},
					{
						"code": 130304000000,
						"value": "北戴河区",
						"label": "北戴河区"
					},
					{
						"code": 130306000000,
						"value": "抚宁区",
						"label": "抚宁区"
					},
					{
						"code": 130321000000,
						"value": "青龙满族自治县",
						"label": "青龙满族自治县"
					},
					{
						"code": 130322000000,
						"value": "昌黎县",
						"label": "昌黎县"
					},
					{
						"code": 130324000000,
						"value": "卢龙县",
						"label": "卢龙县"
					},
					{
						"code": 130371000000,
						"value": "秦皇岛市经济技术开发区",
						"label": "秦皇岛市经济技术开发区"
					},
					{
						"code": 130372000000,
						"value": "北戴河新区",
						"label": "北戴河新区"
					}
				],
				"value": "秦皇岛市",
				"label": "秦皇岛市"
			},
			{
				"code": 130400000000,
				"children": [
					{
						"code": 130401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130402000000,
						"value": "邯山区",
						"label": "邯山区"
					},
					{
						"code": 130403000000,
						"value": "丛台区",
						"label": "丛台区"
					},
					{
						"code": 130404000000,
						"value": "复兴区",
						"label": "复兴区"
					},
					{
						"code": 130406000000,
						"value": "峰峰矿区",
						"label": "峰峰矿区"
					},
					{
						"code": 130407000000,
						"value": "肥乡区",
						"label": "肥乡区"
					},
					{
						"code": 130408000000,
						"value": "永年区",
						"label": "永年区"
					},
					{
						"code": 130423000000,
						"value": "临漳县",
						"label": "临漳县"
					},
					{
						"code": 130424000000,
						"value": "成安县",
						"label": "成安县"
					},
					{
						"code": 130425000000,
						"value": "大名县",
						"label": "大名县"
					},
					{
						"code": 130426000000,
						"value": "涉县",
						"label": "涉县"
					},
					{
						"code": 130427000000,
						"value": "磁县",
						"label": "磁县"
					},
					{
						"code": 130430000000,
						"value": "邱县",
						"label": "邱县"
					},
					{
						"code": 130431000000,
						"value": "鸡泽县",
						"label": "鸡泽县"
					},
					{
						"code": 130432000000,
						"value": "广平县",
						"label": "广平县"
					},
					{
						"code": 130433000000,
						"value": "馆陶县",
						"label": "馆陶县"
					},
					{
						"code": 130434000000,
						"value": "魏县",
						"label": "魏县"
					},
					{
						"code": 130435000000,
						"value": "曲周县",
						"label": "曲周县"
					},
					{
						"code": 130471000000,
						"value": "邯郸经济技术开发区",
						"label": "邯郸经济技术开发区"
					},
					{
						"code": 130473000000,
						"value": "邯郸冀南新区",
						"label": "邯郸冀南新区"
					},
					{
						"code": 130481000000,
						"value": "武安市",
						"label": "武安市"
					}
				],
				"value": "邯郸市",
				"label": "邯郸市"
			},
			{
				"code": 130500000000,
				"children": [
					{
						"code": 130501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130502000000,
						"value": "襄都区",
						"label": "襄都区"
					},
					{
						"code": 130503000000,
						"value": "信都区",
						"label": "信都区"
					},
					{
						"code": 130505000000,
						"value": "任泽区",
						"label": "任泽区"
					},
					{
						"code": 130506000000,
						"value": "南和区",
						"label": "南和区"
					},
					{
						"code": 130522000000,
						"value": "临城县",
						"label": "临城县"
					},
					{
						"code": 130523000000,
						"value": "内丘县",
						"label": "内丘县"
					},
					{
						"code": 130524000000,
						"value": "柏乡县",
						"label": "柏乡县"
					},
					{
						"code": 130525000000,
						"value": "隆尧县",
						"label": "隆尧县"
					},
					{
						"code": 130528000000,
						"value": "宁晋县",
						"label": "宁晋县"
					},
					{
						"code": 130529000000,
						"value": "巨鹿县",
						"label": "巨鹿县"
					},
					{
						"code": 130530000000,
						"value": "新河县",
						"label": "新河县"
					},
					{
						"code": 130531000000,
						"value": "广宗县",
						"label": "广宗县"
					},
					{
						"code": 130532000000,
						"value": "平乡县",
						"label": "平乡县"
					},
					{
						"code": 130533000000,
						"value": "威县",
						"label": "威县"
					},
					{
						"code": 130534000000,
						"value": "清河县",
						"label": "清河县"
					},
					{
						"code": 130535000000,
						"value": "临西县",
						"label": "临西县"
					},
					{
						"code": 130571000000,
						"value": "河北邢台经济开发区",
						"label": "河北邢台经济开发区"
					},
					{
						"code": 130581000000,
						"value": "南宫市",
						"label": "南宫市"
					},
					{
						"code": 130582000000,
						"value": "沙河市",
						"label": "沙河市"
					}
				],
				"value": "邢台市",
				"label": "邢台市"
			},
			{
				"code": 130600000000,
				"children": [
					{
						"code": 130601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130602000000,
						"value": "竞秀区",
						"label": "竞秀区"
					},
					{
						"code": 130606000000,
						"value": "莲池区",
						"label": "莲池区"
					},
					{
						"code": 130607000000,
						"value": "满城区",
						"label": "满城区"
					},
					{
						"code": 130608000000,
						"value": "清苑区",
						"label": "清苑区"
					},
					{
						"code": 130609000000,
						"value": "徐水区",
						"label": "徐水区"
					},
					{
						"code": 130623000000,
						"value": "涞水县",
						"label": "涞水县"
					},
					{
						"code": 130624000000,
						"value": "阜平县",
						"label": "阜平县"
					},
					{
						"code": 130626000000,
						"value": "定兴县",
						"label": "定兴县"
					},
					{
						"code": 130627000000,
						"value": "唐县",
						"label": "唐县"
					},
					{
						"code": 130628000000,
						"value": "高阳县",
						"label": "高阳县"
					},
					{
						"code": 130629000000,
						"value": "容城县",
						"label": "容城县"
					},
					{
						"code": 130630000000,
						"value": "涞源县",
						"label": "涞源县"
					},
					{
						"code": 130631000000,
						"value": "望都县",
						"label": "望都县"
					},
					{
						"code": 130632000000,
						"value": "安新县",
						"label": "安新县"
					},
					{
						"code": 130633000000,
						"value": "易县",
						"label": "易县"
					},
					{
						"code": 130634000000,
						"value": "曲阳县",
						"label": "曲阳县"
					},
					{
						"code": 130635000000,
						"value": "蠡县",
						"label": "蠡县"
					},
					{
						"code": 130636000000,
						"value": "顺平县",
						"label": "顺平县"
					},
					{
						"code": 130637000000,
						"value": "博野县",
						"label": "博野县"
					},
					{
						"code": 130638000000,
						"value": "雄县",
						"label": "雄县"
					},
					{
						"code": 130671000000,
						"value": "保定高新技术产业开发区",
						"label": "保定高新技术产业开发区"
					},
					{
						"code": 130672000000,
						"value": "保定白沟新城",
						"label": "保定白沟新城"
					},
					{
						"code": 130681000000,
						"value": "涿州市",
						"label": "涿州市"
					},
					{
						"code": 130682000000,
						"value": "定州市",
						"label": "定州市"
					},
					{
						"code": 130683000000,
						"value": "安国市",
						"label": "安国市"
					},
					{
						"code": 130684000000,
						"value": "高碑店市",
						"label": "高碑店市"
					}
				],
				"value": "保定市",
				"label": "保定市"
			},
			{
				"code": 130700000000,
				"children": [
					{
						"code": 130701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130702000000,
						"value": "桥东区",
						"label": "桥东区"
					},
					{
						"code": 130703000000,
						"value": "桥西区",
						"label": "桥西区"
					},
					{
						"code": 130705000000,
						"value": "宣化区",
						"label": "宣化区"
					},
					{
						"code": 130706000000,
						"value": "下花园区",
						"label": "下花园区"
					},
					{
						"code": 130708000000,
						"value": "万全区",
						"label": "万全区"
					},
					{
						"code": 130709000000,
						"value": "崇礼区",
						"label": "崇礼区"
					},
					{
						"code": 130722000000,
						"value": "张北县",
						"label": "张北县"
					},
					{
						"code": 130723000000,
						"value": "康保县",
						"label": "康保县"
					},
					{
						"code": 130724000000,
						"value": "沽源县",
						"label": "沽源县"
					},
					{
						"code": 130725000000,
						"value": "尚义县",
						"label": "尚义县"
					},
					{
						"code": 130726000000,
						"value": "蔚县",
						"label": "蔚县"
					},
					{
						"code": 130727000000,
						"value": "阳原县",
						"label": "阳原县"
					},
					{
						"code": 130728000000,
						"value": "怀安县",
						"label": "怀安县"
					},
					{
						"code": 130730000000,
						"value": "怀来县",
						"label": "怀来县"
					},
					{
						"code": 130731000000,
						"value": "涿鹿县",
						"label": "涿鹿县"
					},
					{
						"code": 130732000000,
						"value": "赤城县",
						"label": "赤城县"
					},
					{
						"code": 130771000000,
						"value": "张家口经济开发区",
						"label": "张家口经济开发区"
					},
					{
						"code": 130772000000,
						"value": "张家口市察北管理区",
						"label": "张家口市察北管理区"
					},
					{
						"code": 130773000000,
						"value": "张家口市塞北管理区",
						"label": "张家口市塞北管理区"
					}
				],
				"value": "张家口市",
				"label": "张家口市"
			},
			{
				"code": 130800000000,
				"children": [
					{
						"code": 130801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130802000000,
						"value": "双桥区",
						"label": "双桥区"
					},
					{
						"code": 130803000000,
						"value": "双滦区",
						"label": "双滦区"
					},
					{
						"code": 130804000000,
						"value": "鹰手营子矿区",
						"label": "鹰手营子矿区"
					},
					{
						"code": 130821000000,
						"value": "承德县",
						"label": "承德县"
					},
					{
						"code": 130822000000,
						"value": "兴隆县",
						"label": "兴隆县"
					},
					{
						"code": 130824000000,
						"value": "滦平县",
						"label": "滦平县"
					},
					{
						"code": 130825000000,
						"value": "隆化县",
						"label": "隆化县"
					},
					{
						"code": 130826000000,
						"value": "丰宁满族自治县",
						"label": "丰宁满族自治县"
					},
					{
						"code": 130827000000,
						"value": "宽城满族自治县",
						"label": "宽城满族自治县"
					},
					{
						"code": 130828000000,
						"value": "围场满族蒙古族自治县",
						"label": "围场满族蒙古族自治县"
					},
					{
						"code": 130871000000,
						"value": "承德高新技术产业开发区",
						"label": "承德高新技术产业开发区"
					},
					{
						"code": 130881000000,
						"value": "平泉市",
						"label": "平泉市"
					}
				],
				"value": "承德市",
				"label": "承德市"
			},
			{
				"code": 130900000000,
				"children": [
					{
						"code": 130901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 130902000000,
						"value": "新华区",
						"label": "新华区"
					},
					{
						"code": 130903000000,
						"value": "运河区",
						"label": "运河区"
					},
					{
						"code": 130921000000,
						"value": "沧县",
						"label": "沧县"
					},
					{
						"code": 130922000000,
						"value": "青县",
						"label": "青县"
					},
					{
						"code": 130923000000,
						"value": "东光县",
						"label": "东光县"
					},
					{
						"code": 130924000000,
						"value": "海兴县",
						"label": "海兴县"
					},
					{
						"code": 130925000000,
						"value": "盐山县",
						"label": "盐山县"
					},
					{
						"code": 130926000000,
						"value": "肃宁县",
						"label": "肃宁县"
					},
					{
						"code": 130927000000,
						"value": "南皮县",
						"label": "南皮县"
					},
					{
						"code": 130928000000,
						"value": "吴桥县",
						"label": "吴桥县"
					},
					{
						"code": 130929000000,
						"value": "献县",
						"label": "献县"
					},
					{
						"code": 130930000000,
						"value": "孟村回族自治县",
						"label": "孟村回族自治县"
					},
					{
						"code": 130971000000,
						"value": "河北沧州经济开发区",
						"label": "河北沧州经济开发区"
					},
					{
						"code": 130972000000,
						"value": "沧州高新技术产业开发区",
						"label": "沧州高新技术产业开发区"
					},
					{
						"code": 130973000000,
						"value": "沧州渤海新区",
						"label": "沧州渤海新区"
					},
					{
						"code": 130981000000,
						"value": "泊头市",
						"label": "泊头市"
					},
					{
						"code": 130982000000,
						"value": "任丘市",
						"label": "任丘市"
					},
					{
						"code": 130983000000,
						"value": "黄骅市",
						"label": "黄骅市"
					},
					{
						"code": 130984000000,
						"value": "河间市",
						"label": "河间市"
					}
				],
				"value": "沧州市",
				"label": "沧州市"
			},
			{
				"code": 131000000000,
				"children": [
					{
						"code": 131001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 131002000000,
						"value": "安次区",
						"label": "安次区"
					},
					{
						"code": 131003000000,
						"value": "广阳区",
						"label": "广阳区"
					},
					{
						"code": 131022000000,
						"value": "固安县",
						"label": "固安县"
					},
					{
						"code": 131023000000,
						"value": "永清县",
						"label": "永清县"
					},
					{
						"code": 131024000000,
						"value": "香河县",
						"label": "香河县"
					},
					{
						"code": 131025000000,
						"value": "大城县",
						"label": "大城县"
					},
					{
						"code": 131026000000,
						"value": "文安县",
						"label": "文安县"
					},
					{
						"code": 131028000000,
						"value": "大厂回族自治县",
						"label": "大厂回族自治县"
					},
					{
						"code": 131071000000,
						"value": "廊坊经济技术开发区",
						"label": "廊坊经济技术开发区"
					},
					{
						"code": 131081000000,
						"value": "霸州市",
						"label": "霸州市"
					},
					{
						"code": 131082000000,
						"value": "三河市",
						"label": "三河市"
					}
				],
				"value": "廊坊市",
				"label": "廊坊市"
			},
			{
				"code": 131100000000,
				"children": [
					{
						"code": 131101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 131102000000,
						"value": "桃城区",
						"label": "桃城区"
					},
					{
						"code": 131103000000,
						"value": "冀州区",
						"label": "冀州区"
					},
					{
						"code": 131121000000,
						"value": "枣强县",
						"label": "枣强县"
					},
					{
						"code": 131122000000,
						"value": "武邑县",
						"label": "武邑县"
					},
					{
						"code": 131123000000,
						"value": "武强县",
						"label": "武强县"
					},
					{
						"code": 131124000000,
						"value": "饶阳县",
						"label": "饶阳县"
					},
					{
						"code": 131125000000,
						"value": "安平县",
						"label": "安平县"
					},
					{
						"code": 131126000000,
						"value": "故城县",
						"label": "故城县"
					},
					{
						"code": 131127000000,
						"value": "景县",
						"label": "景县"
					},
					{
						"code": 131128000000,
						"value": "阜城县",
						"label": "阜城县"
					},
					{
						"code": 131171000000,
						"value": "河北衡水高新技术产业开发区",
						"label": "河北衡水高新技术产业开发区"
					},
					{
						"code": 131172000000,
						"value": "衡水滨湖新区",
						"label": "衡水滨湖新区"
					},
					{
						"code": 131182000000,
						"value": "深州市",
						"label": "深州市"
					}
				],
				"value": "衡水市",
				"label": "衡水市"
			}
		],
		"value": "河北省",
		"label": "河北省"
	},
	{
		"code": 140000000000,
		"children": [
			{
				"code": 140100000000,
				"children": [
					{
						"code": 140101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140105000000,
						"value": "小店区",
						"label": "小店区"
					},
					{
						"code": 140106000000,
						"value": "迎泽区",
						"label": "迎泽区"
					},
					{
						"code": 140107000000,
						"value": "杏花岭区",
						"label": "杏花岭区"
					},
					{
						"code": 140108000000,
						"value": "尖草坪区",
						"label": "尖草坪区"
					},
					{
						"code": 140109000000,
						"value": "万柏林区",
						"label": "万柏林区"
					},
					{
						"code": 140110000000,
						"value": "晋源区",
						"label": "晋源区"
					},
					{
						"code": 140121000000,
						"value": "清徐县",
						"label": "清徐县"
					},
					{
						"code": 140122000000,
						"value": "阳曲县",
						"label": "阳曲县"
					},
					{
						"code": 140123000000,
						"value": "娄烦县",
						"label": "娄烦县"
					},
					{
						"code": 140171000000,
						"value": "山西转型综合改革示范区",
						"label": "山西转型综合改革示范区"
					},
					{
						"code": 140181000000,
						"value": "古交市",
						"label": "古交市"
					}
				],
				"value": "太原市",
				"label": "太原市"
			},
			{
				"code": 140200000000,
				"children": [
					{
						"code": 140201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140212000000,
						"value": "新荣区",
						"label": "新荣区"
					},
					{
						"code": 140213000000,
						"value": "平城区",
						"label": "平城区"
					},
					{
						"code": 140214000000,
						"value": "云冈区",
						"label": "云冈区"
					},
					{
						"code": 140215000000,
						"value": "云州区",
						"label": "云州区"
					},
					{
						"code": 140221000000,
						"value": "阳高县",
						"label": "阳高县"
					},
					{
						"code": 140222000000,
						"value": "天镇县",
						"label": "天镇县"
					},
					{
						"code": 140223000000,
						"value": "广灵县",
						"label": "广灵县"
					},
					{
						"code": 140224000000,
						"value": "灵丘县",
						"label": "灵丘县"
					},
					{
						"code": 140225000000,
						"value": "浑源县",
						"label": "浑源县"
					},
					{
						"code": 140226000000,
						"value": "左云县",
						"label": "左云县"
					},
					{
						"code": 140271000000,
						"value": "山西大同经济开发区",
						"label": "山西大同经济开发区"
					}
				],
				"value": "大同市",
				"label": "大同市"
			},
			{
				"code": 140300000000,
				"children": [
					{
						"code": 140301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140302000000,
						"value": "城区",
						"label": "城区"
					},
					{
						"code": 140303000000,
						"value": "矿区",
						"label": "矿区"
					},
					{
						"code": 140311000000,
						"value": "郊区",
						"label": "郊区"
					},
					{
						"code": 140321000000,
						"value": "平定县",
						"label": "平定县"
					},
					{
						"code": 140322000000,
						"value": "盂县",
						"label": "盂县"
					}
				],
				"value": "阳泉市",
				"label": "阳泉市"
			},
			{
				"code": 140400000000,
				"children": [
					{
						"code": 140401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140403000000,
						"value": "潞州区",
						"label": "潞州区"
					},
					{
						"code": 140404000000,
						"value": "上党区",
						"label": "上党区"
					},
					{
						"code": 140405000000,
						"value": "屯留区",
						"label": "屯留区"
					},
					{
						"code": 140406000000,
						"value": "潞城区",
						"label": "潞城区"
					},
					{
						"code": 140423000000,
						"value": "襄垣县",
						"label": "襄垣县"
					},
					{
						"code": 140425000000,
						"value": "平顺县",
						"label": "平顺县"
					},
					{
						"code": 140426000000,
						"value": "黎城县",
						"label": "黎城县"
					},
					{
						"code": 140427000000,
						"value": "壶关县",
						"label": "壶关县"
					},
					{
						"code": 140428000000,
						"value": "长子县",
						"label": "长子县"
					},
					{
						"code": 140429000000,
						"value": "武乡县",
						"label": "武乡县"
					},
					{
						"code": 140430000000,
						"value": "沁县",
						"label": "沁县"
					},
					{
						"code": 140431000000,
						"value": "沁源县",
						"label": "沁源县"
					},
					{
						"code": 140471000000,
						"value": "山西长治高新技术产业园区",
						"label": "山西长治高新技术产业园区"
					}
				],
				"value": "长治市",
				"label": "长治市"
			},
			{
				"code": 140500000000,
				"children": [
					{
						"code": 140501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140502000000,
						"value": "城区",
						"label": "城区"
					},
					{
						"code": 140521000000,
						"value": "沁水县",
						"label": "沁水县"
					},
					{
						"code": 140522000000,
						"value": "阳城县",
						"label": "阳城县"
					},
					{
						"code": 140524000000,
						"value": "陵川县",
						"label": "陵川县"
					},
					{
						"code": 140525000000,
						"value": "泽州县",
						"label": "泽州县"
					},
					{
						"code": 140581000000,
						"value": "高平市",
						"label": "高平市"
					}
				],
				"value": "晋城市",
				"label": "晋城市"
			},
			{
				"code": 140600000000,
				"children": [
					{
						"code": 140601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140602000000,
						"value": "朔城区",
						"label": "朔城区"
					},
					{
						"code": 140603000000,
						"value": "平鲁区",
						"label": "平鲁区"
					},
					{
						"code": 140621000000,
						"value": "山阴县",
						"label": "山阴县"
					},
					{
						"code": 140622000000,
						"value": "应县",
						"label": "应县"
					},
					{
						"code": 140623000000,
						"value": "右玉县",
						"label": "右玉县"
					},
					{
						"code": 140671000000,
						"value": "山西朔州经济开发区",
						"label": "山西朔州经济开发区"
					},
					{
						"code": 140681000000,
						"value": "怀仁市",
						"label": "怀仁市"
					}
				],
				"value": "朔州市",
				"label": "朔州市"
			},
			{
				"code": 140700000000,
				"children": [
					{
						"code": 140701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140702000000,
						"value": "榆次区",
						"label": "榆次区"
					},
					{
						"code": 140703000000,
						"value": "太谷区",
						"label": "太谷区"
					},
					{
						"code": 140721000000,
						"value": "榆社县",
						"label": "榆社县"
					},
					{
						"code": 140722000000,
						"value": "左权县",
						"label": "左权县"
					},
					{
						"code": 140723000000,
						"value": "和顺县",
						"label": "和顺县"
					},
					{
						"code": 140724000000,
						"value": "昔阳县",
						"label": "昔阳县"
					},
					{
						"code": 140725000000,
						"value": "寿阳县",
						"label": "寿阳县"
					},
					{
						"code": 140727000000,
						"value": "祁县",
						"label": "祁县"
					},
					{
						"code": 140728000000,
						"value": "平遥县",
						"label": "平遥县"
					},
					{
						"code": 140729000000,
						"value": "灵石县",
						"label": "灵石县"
					},
					{
						"code": 140781000000,
						"value": "介休市",
						"label": "介休市"
					}
				],
				"value": "晋中市",
				"label": "晋中市"
			},
			{
				"code": 140800000000,
				"children": [
					{
						"code": 140801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140802000000,
						"value": "盐湖区",
						"label": "盐湖区"
					},
					{
						"code": 140821000000,
						"value": "临猗县",
						"label": "临猗县"
					},
					{
						"code": 140822000000,
						"value": "万荣县",
						"label": "万荣县"
					},
					{
						"code": 140823000000,
						"value": "闻喜县",
						"label": "闻喜县"
					},
					{
						"code": 140824000000,
						"value": "稷山县",
						"label": "稷山县"
					},
					{
						"code": 140825000000,
						"value": "新绛县",
						"label": "新绛县"
					},
					{
						"code": 140826000000,
						"value": "绛县",
						"label": "绛县"
					},
					{
						"code": 140827000000,
						"value": "垣曲县",
						"label": "垣曲县"
					},
					{
						"code": 140828000000,
						"value": "夏县",
						"label": "夏县"
					},
					{
						"code": 140829000000,
						"value": "平陆县",
						"label": "平陆县"
					},
					{
						"code": 140830000000,
						"value": "芮城县",
						"label": "芮城县"
					},
					{
						"code": 140881000000,
						"value": "永济市",
						"label": "永济市"
					},
					{
						"code": 140882000000,
						"value": "河津市",
						"label": "河津市"
					}
				],
				"value": "运城市",
				"label": "运城市"
			},
			{
				"code": 140900000000,
				"children": [
					{
						"code": 140901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 140902000000,
						"value": "忻府区",
						"label": "忻府区"
					},
					{
						"code": 140921000000,
						"value": "定襄县",
						"label": "定襄县"
					},
					{
						"code": 140922000000,
						"value": "五台县",
						"label": "五台县"
					},
					{
						"code": 140923000000,
						"value": "代县",
						"label": "代县"
					},
					{
						"code": 140924000000,
						"value": "繁峙县",
						"label": "繁峙县"
					},
					{
						"code": 140925000000,
						"value": "宁武县",
						"label": "宁武县"
					},
					{
						"code": 140926000000,
						"value": "静乐县",
						"label": "静乐县"
					},
					{
						"code": 140927000000,
						"value": "神池县",
						"label": "神池县"
					},
					{
						"code": 140928000000,
						"value": "五寨县",
						"label": "五寨县"
					},
					{
						"code": 140929000000,
						"value": "岢岚县",
						"label": "岢岚县"
					},
					{
						"code": 140930000000,
						"value": "河曲县",
						"label": "河曲县"
					},
					{
						"code": 140931000000,
						"value": "保德县",
						"label": "保德县"
					},
					{
						"code": 140932000000,
						"value": "偏关县",
						"label": "偏关县"
					},
					{
						"code": 140971000000,
						"value": "五台山风景名胜区",
						"label": "五台山风景名胜区"
					},
					{
						"code": 140981000000,
						"value": "原平市",
						"label": "原平市"
					}
				],
				"value": "忻州市",
				"label": "忻州市"
			},
			{
				"code": 141000000000,
				"children": [
					{
						"code": 141001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 141002000000,
						"value": "尧都区",
						"label": "尧都区"
					},
					{
						"code": 141021000000,
						"value": "曲沃县",
						"label": "曲沃县"
					},
					{
						"code": 141022000000,
						"value": "翼城县",
						"label": "翼城县"
					},
					{
						"code": 141023000000,
						"value": "襄汾县",
						"label": "襄汾县"
					},
					{
						"code": 141024000000,
						"value": "洪洞县",
						"label": "洪洞县"
					},
					{
						"code": 141025000000,
						"value": "古县",
						"label": "古县"
					},
					{
						"code": 141026000000,
						"value": "安泽县",
						"label": "安泽县"
					},
					{
						"code": 141027000000,
						"value": "浮山县",
						"label": "浮山县"
					},
					{
						"code": 141028000000,
						"value": "吉县",
						"label": "吉县"
					},
					{
						"code": 141029000000,
						"value": "乡宁县",
						"label": "乡宁县"
					},
					{
						"code": 141030000000,
						"value": "大宁县",
						"label": "大宁县"
					},
					{
						"code": 141031000000,
						"value": "隰县",
						"label": "隰县"
					},
					{
						"code": 141032000000,
						"value": "永和县",
						"label": "永和县"
					},
					{
						"code": 141033000000,
						"value": "蒲县",
						"label": "蒲县"
					},
					{
						"code": 141034000000,
						"value": "汾西县",
						"label": "汾西县"
					},
					{
						"code": 141081000000,
						"value": "侯马市",
						"label": "侯马市"
					},
					{
						"code": 141082000000,
						"value": "霍州市",
						"label": "霍州市"
					}
				],
				"value": "临汾市",
				"label": "临汾市"
			},
			{
				"code": 141100000000,
				"children": [
					{
						"code": 141101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 141102000000,
						"value": "离石区",
						"label": "离石区"
					},
					{
						"code": 141121000000,
						"value": "文水县",
						"label": "文水县"
					},
					{
						"code": 141122000000,
						"value": "交城县",
						"label": "交城县"
					},
					{
						"code": 141123000000,
						"value": "兴县",
						"label": "兴县"
					},
					{
						"code": 141124000000,
						"value": "临县",
						"label": "临县"
					},
					{
						"code": 141125000000,
						"value": "柳林县",
						"label": "柳林县"
					},
					{
						"code": 141126000000,
						"value": "石楼县",
						"label": "石楼县"
					},
					{
						"code": 141127000000,
						"value": "岚县",
						"label": "岚县"
					},
					{
						"code": 141128000000,
						"value": "方山县",
						"label": "方山县"
					},
					{
						"code": 141129000000,
						"value": "中阳县",
						"label": "中阳县"
					},
					{
						"code": 141130000000,
						"value": "交口县",
						"label": "交口县"
					},
					{
						"code": 141181000000,
						"value": "孝义市",
						"label": "孝义市"
					},
					{
						"code": 141182000000,
						"value": "汾阳市",
						"label": "汾阳市"
					}
				],
				"value": "吕梁市",
				"label": "吕梁市"
			}
		],
		"value": "山西省",
		"label": "山西省"
	},
	{
		"code": 150000000000,
		"children": [
			{
				"code": 150100000000,
				"children": [
					{
						"code": 150101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150102000000,
						"value": "新城区",
						"label": "新城区"
					},
					{
						"code": 150103000000,
						"value": "回民区",
						"label": "回民区"
					},
					{
						"code": 150104000000,
						"value": "玉泉区",
						"label": "玉泉区"
					},
					{
						"code": 150105000000,
						"value": "赛罕区",
						"label": "赛罕区"
					},
					{
						"code": 150121000000,
						"value": "土默特左旗",
						"label": "土默特左旗"
					},
					{
						"code": 150122000000,
						"value": "托克托县",
						"label": "托克托县"
					},
					{
						"code": 150123000000,
						"value": "和林格尔县",
						"label": "和林格尔县"
					},
					{
						"code": 150124000000,
						"value": "清水河县",
						"label": "清水河县"
					},
					{
						"code": 150125000000,
						"value": "武川县",
						"label": "武川县"
					},
					{
						"code": 150172000000,
						"value": "呼和浩特经济技术开发区",
						"label": "呼和浩特经济技术开发区"
					}
				],
				"value": "呼和浩特市",
				"label": "呼和浩特市"
			},
			{
				"code": 150200000000,
				"children": [
					{
						"code": 150201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150202000000,
						"value": "东河区",
						"label": "东河区"
					},
					{
						"code": 150203000000,
						"value": "昆都仑区",
						"label": "昆都仑区"
					},
					{
						"code": 150204000000,
						"value": "青山区",
						"label": "青山区"
					},
					{
						"code": 150205000000,
						"value": "石拐区",
						"label": "石拐区"
					},
					{
						"code": 150206000000,
						"value": "白云鄂博矿区",
						"label": "白云鄂博矿区"
					},
					{
						"code": 150207000000,
						"value": "九原区",
						"label": "九原区"
					},
					{
						"code": 150221000000,
						"value": "土默特右旗",
						"label": "土默特右旗"
					},
					{
						"code": 150222000000,
						"value": "固阳县",
						"label": "固阳县"
					},
					{
						"code": 150223000000,
						"value": "达尔罕茂明安联合旗",
						"label": "达尔罕茂明安联合旗"
					},
					{
						"code": 150271000000,
						"value": "包头稀土高新技术产业开发区",
						"label": "包头稀土高新技术产业开发区"
					}
				],
				"value": "包头市",
				"label": "包头市"
			},
			{
				"code": 150300000000,
				"children": [
					{
						"code": 150301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150302000000,
						"value": "海勃湾区",
						"label": "海勃湾区"
					},
					{
						"code": 150303000000,
						"value": "海南区",
						"label": "海南区"
					},
					{
						"code": 150304000000,
						"value": "乌达区",
						"label": "乌达区"
					}
				],
				"value": "乌海市",
				"label": "乌海市"
			},
			{
				"code": 150400000000,
				"children": [
					{
						"code": 150401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150402000000,
						"value": "红山区",
						"label": "红山区"
					},
					{
						"code": 150403000000,
						"value": "元宝山区",
						"label": "元宝山区"
					},
					{
						"code": 150404000000,
						"value": "松山区",
						"label": "松山区"
					},
					{
						"code": 150421000000,
						"value": "阿鲁科尔沁旗",
						"label": "阿鲁科尔沁旗"
					},
					{
						"code": 150422000000,
						"value": "巴林左旗",
						"label": "巴林左旗"
					},
					{
						"code": 150423000000,
						"value": "巴林右旗",
						"label": "巴林右旗"
					},
					{
						"code": 150424000000,
						"value": "林西县",
						"label": "林西县"
					},
					{
						"code": 150425000000,
						"value": "克什克腾旗",
						"label": "克什克腾旗"
					},
					{
						"code": 150426000000,
						"value": "翁牛特旗",
						"label": "翁牛特旗"
					},
					{
						"code": 150428000000,
						"value": "喀喇沁旗",
						"label": "喀喇沁旗"
					},
					{
						"code": 150429000000,
						"value": "宁城县",
						"label": "宁城县"
					},
					{
						"code": 150430000000,
						"value": "敖汉旗",
						"label": "敖汉旗"
					}
				],
				"value": "赤峰市",
				"label": "赤峰市"
			},
			{
				"code": 150500000000,
				"children": [
					{
						"code": 150501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150502000000,
						"value": "科尔沁区",
						"label": "科尔沁区"
					},
					{
						"code": 150521000000,
						"value": "科尔沁左翼中旗",
						"label": "科尔沁左翼中旗"
					},
					{
						"code": 150522000000,
						"value": "科尔沁左翼后旗",
						"label": "科尔沁左翼后旗"
					},
					{
						"code": 150523000000,
						"value": "开鲁县",
						"label": "开鲁县"
					},
					{
						"code": 150524000000,
						"value": "库伦旗",
						"label": "库伦旗"
					},
					{
						"code": 150525000000,
						"value": "奈曼旗",
						"label": "奈曼旗"
					},
					{
						"code": 150526000000,
						"value": "扎鲁特旗",
						"label": "扎鲁特旗"
					},
					{
						"code": 150571000000,
						"value": "通辽经济技术开发区",
						"label": "通辽经济技术开发区"
					},
					{
						"code": 150581000000,
						"value": "霍林郭勒市",
						"label": "霍林郭勒市"
					}
				],
				"value": "通辽市",
				"label": "通辽市"
			},
			{
				"code": 150600000000,
				"children": [
					{
						"code": 150601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150602000000,
						"value": "东胜区",
						"label": "东胜区"
					},
					{
						"code": 150603000000,
						"value": "康巴什区",
						"label": "康巴什区"
					},
					{
						"code": 150621000000,
						"value": "达拉特旗",
						"label": "达拉特旗"
					},
					{
						"code": 150622000000,
						"value": "准格尔旗",
						"label": "准格尔旗"
					},
					{
						"code": 150623000000,
						"value": "鄂托克前旗",
						"label": "鄂托克前旗"
					},
					{
						"code": 150624000000,
						"value": "鄂托克旗",
						"label": "鄂托克旗"
					},
					{
						"code": 150625000000,
						"value": "杭锦旗",
						"label": "杭锦旗"
					},
					{
						"code": 150626000000,
						"value": "乌审旗",
						"label": "乌审旗"
					},
					{
						"code": 150627000000,
						"value": "伊金霍洛旗",
						"label": "伊金霍洛旗"
					}
				],
				"value": "鄂尔多斯市",
				"label": "鄂尔多斯市"
			},
			{
				"code": 150700000000,
				"children": [
					{
						"code": 150701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150702000000,
						"value": "海拉尔区",
						"label": "海拉尔区"
					},
					{
						"code": 150703000000,
						"value": "扎赉诺尔区",
						"label": "扎赉诺尔区"
					},
					{
						"code": 150721000000,
						"value": "阿荣旗",
						"label": "阿荣旗"
					},
					{
						"code": 150722000000,
						"value": "莫力达瓦达斡尔族自治旗",
						"label": "莫力达瓦达斡尔族自治旗"
					},
					{
						"code": 150723000000,
						"value": "鄂伦春自治旗",
						"label": "鄂伦春自治旗"
					},
					{
						"code": 150724000000,
						"value": "鄂温克族自治旗",
						"label": "鄂温克族自治旗"
					},
					{
						"code": 150725000000,
						"value": "陈巴尔虎旗",
						"label": "陈巴尔虎旗"
					},
					{
						"code": 150726000000,
						"value": "新巴尔虎左旗",
						"label": "新巴尔虎左旗"
					},
					{
						"code": 150727000000,
						"value": "新巴尔虎右旗",
						"label": "新巴尔虎右旗"
					},
					{
						"code": 150781000000,
						"value": "满洲里市",
						"label": "满洲里市"
					},
					{
						"code": 150782000000,
						"value": "牙克石市",
						"label": "牙克石市"
					},
					{
						"code": 150783000000,
						"value": "扎兰屯市",
						"label": "扎兰屯市"
					},
					{
						"code": 150784000000,
						"value": "额尔古纳市",
						"label": "额尔古纳市"
					},
					{
						"code": 150785000000,
						"value": "根河市",
						"label": "根河市"
					}
				],
				"value": "呼伦贝尔市",
				"label": "呼伦贝尔市"
			},
			{
				"code": 150800000000,
				"children": [
					{
						"code": 150801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150802000000,
						"value": "临河区",
						"label": "临河区"
					},
					{
						"code": 150821000000,
						"value": "五原县",
						"label": "五原县"
					},
					{
						"code": 150822000000,
						"value": "磴口县",
						"label": "磴口县"
					},
					{
						"code": 150823000000,
						"value": "乌拉特前旗",
						"label": "乌拉特前旗"
					},
					{
						"code": 150824000000,
						"value": "乌拉特中旗",
						"label": "乌拉特中旗"
					},
					{
						"code": 150825000000,
						"value": "乌拉特后旗",
						"label": "乌拉特后旗"
					},
					{
						"code": 150826000000,
						"value": "杭锦后旗",
						"label": "杭锦后旗"
					}
				],
				"value": "巴彦淖尔市",
				"label": "巴彦淖尔市"
			},
			{
				"code": 150900000000,
				"children": [
					{
						"code": 150901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 150902000000,
						"value": "集宁区",
						"label": "集宁区"
					},
					{
						"code": 150921000000,
						"value": "卓资县",
						"label": "卓资县"
					},
					{
						"code": 150922000000,
						"value": "化德县",
						"label": "化德县"
					},
					{
						"code": 150923000000,
						"value": "商都县",
						"label": "商都县"
					},
					{
						"code": 150924000000,
						"value": "兴和县",
						"label": "兴和县"
					},
					{
						"code": 150925000000,
						"value": "凉城县",
						"label": "凉城县"
					},
					{
						"code": 150926000000,
						"value": "察哈尔右翼前旗",
						"label": "察哈尔右翼前旗"
					},
					{
						"code": 150927000000,
						"value": "察哈尔右翼中旗",
						"label": "察哈尔右翼中旗"
					},
					{
						"code": 150928000000,
						"value": "察哈尔右翼后旗",
						"label": "察哈尔右翼后旗"
					},
					{
						"code": 150929000000,
						"value": "四子王旗",
						"label": "四子王旗"
					},
					{
						"code": 150981000000,
						"value": "丰镇市",
						"label": "丰镇市"
					}
				],
				"value": "乌兰察布市",
				"label": "乌兰察布市"
			},
			{
				"code": 152200000000,
				"children": [
					{
						"code": 152201000000,
						"value": "乌兰浩特市",
						"label": "乌兰浩特市"
					},
					{
						"code": 152202000000,
						"value": "阿尔山市",
						"label": "阿尔山市"
					},
					{
						"code": 152221000000,
						"value": "科尔沁右翼前旗",
						"label": "科尔沁右翼前旗"
					},
					{
						"code": 152222000000,
						"value": "科尔沁右翼中旗",
						"label": "科尔沁右翼中旗"
					},
					{
						"code": 152223000000,
						"value": "扎赉特旗",
						"label": "扎赉特旗"
					},
					{
						"code": 152224000000,
						"value": "突泉县",
						"label": "突泉县"
					}
				],
				"value": "兴安盟",
				"label": "兴安盟"
			},
			{
				"code": 152500000000,
				"children": [
					{
						"code": 152501000000,
						"value": "二连浩特市",
						"label": "二连浩特市"
					},
					{
						"code": 152502000000,
						"value": "锡林浩特市",
						"label": "锡林浩特市"
					},
					{
						"code": 152522000000,
						"value": "阿巴嘎旗",
						"label": "阿巴嘎旗"
					},
					{
						"code": 152523000000,
						"value": "苏尼特左旗",
						"label": "苏尼特左旗"
					},
					{
						"code": 152524000000,
						"value": "苏尼特右旗",
						"label": "苏尼特右旗"
					},
					{
						"code": 152525000000,
						"value": "东乌珠穆沁旗",
						"label": "东乌珠穆沁旗"
					},
					{
						"code": 152526000000,
						"value": "西乌珠穆沁旗",
						"label": "西乌珠穆沁旗"
					},
					{
						"code": 152527000000,
						"value": "太仆寺旗",
						"label": "太仆寺旗"
					},
					{
						"code": 152528000000,
						"value": "镶黄旗",
						"label": "镶黄旗"
					},
					{
						"code": 152529000000,
						"value": "正镶白旗",
						"label": "正镶白旗"
					},
					{
						"code": 152530000000,
						"value": "正蓝旗",
						"label": "正蓝旗"
					},
					{
						"code": 152531000000,
						"value": "多伦县",
						"label": "多伦县"
					},
					{
						"code": 152571000000,
						"value": "乌拉盖管委会",
						"label": "乌拉盖管委会"
					}
				],
				"value": "锡林郭勒盟",
				"label": "锡林郭勒盟"
			},
			{
				"code": 152900000000,
				"children": [
					{
						"code": 152921000000,
						"value": "阿拉善左旗",
						"label": "阿拉善左旗"
					},
					{
						"code": 152922000000,
						"value": "阿拉善右旗",
						"label": "阿拉善右旗"
					},
					{
						"code": 152923000000,
						"value": "额济纳旗",
						"label": "额济纳旗"
					},
					{
						"code": 152971000000,
						"value": "内蒙古阿拉善经济开发区",
						"label": "内蒙古阿拉善经济开发区"
					}
				],
				"value": "阿拉善盟",
				"label": "阿拉善盟"
			}
		],
		"value": "内蒙古自治区",
		"label": "内蒙古自治区"
	},
	{
		"code": 210000000000,
		"children": [
			{
				"code": 210100000000,
				"children": [
					{
						"code": 210101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210102000000,
						"value": "和平区",
						"label": "和平区"
					},
					{
						"code": 210103000000,
						"value": "沈河区",
						"label": "沈河区"
					},
					{
						"code": 210104000000,
						"value": "大东区",
						"label": "大东区"
					},
					{
						"code": 210105000000,
						"value": "皇姑区",
						"label": "皇姑区"
					},
					{
						"code": 210106000000,
						"value": "铁西区",
						"label": "铁西区"
					},
					{
						"code": 210111000000,
						"value": "苏家屯区",
						"label": "苏家屯区"
					},
					{
						"code": 210112000000,
						"value": "浑南区",
						"label": "浑南区"
					},
					{
						"code": 210113000000,
						"value": "沈北新区",
						"label": "沈北新区"
					},
					{
						"code": 210114000000,
						"value": "于洪区",
						"label": "于洪区"
					},
					{
						"code": 210115000000,
						"value": "辽中区",
						"label": "辽中区"
					},
					{
						"code": 210123000000,
						"value": "康平县",
						"label": "康平县"
					},
					{
						"code": 210124000000,
						"value": "法库县",
						"label": "法库县"
					},
					{
						"code": 210181000000,
						"value": "新民市",
						"label": "新民市"
					}
				],
				"value": "沈阳市",
				"label": "沈阳市"
			},
			{
				"code": 210200000000,
				"children": [
					{
						"code": 210201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210202000000,
						"value": "中山区",
						"label": "中山区"
					},
					{
						"code": 210203000000,
						"value": "西岗区",
						"label": "西岗区"
					},
					{
						"code": 210204000000,
						"value": "沙河口区",
						"label": "沙河口区"
					},
					{
						"code": 210211000000,
						"value": "甘井子区",
						"label": "甘井子区"
					},
					{
						"code": 210212000000,
						"value": "旅顺口区",
						"label": "旅顺口区"
					},
					{
						"code": 210213000000,
						"value": "金州区",
						"label": "金州区"
					},
					{
						"code": 210214000000,
						"value": "普兰店区",
						"label": "普兰店区"
					},
					{
						"code": 210224000000,
						"value": "长海县",
						"label": "长海县"
					},
					{
						"code": 210281000000,
						"value": "瓦房店市",
						"label": "瓦房店市"
					},
					{
						"code": 210283000000,
						"value": "庄河市",
						"label": "庄河市"
					}
				],
				"value": "大连市",
				"label": "大连市"
			},
			{
				"code": 210300000000,
				"children": [
					{
						"code": 210301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210302000000,
						"value": "铁东区",
						"label": "铁东区"
					},
					{
						"code": 210303000000,
						"value": "铁西区",
						"label": "铁西区"
					},
					{
						"code": 210304000000,
						"value": "立山区",
						"label": "立山区"
					},
					{
						"code": 210311000000,
						"value": "千山区",
						"label": "千山区"
					},
					{
						"code": 210321000000,
						"value": "台安县",
						"label": "台安县"
					},
					{
						"code": 210323000000,
						"value": "岫岩满族自治县",
						"label": "岫岩满族自治县"
					},
					{
						"code": 210381000000,
						"value": "海城市",
						"label": "海城市"
					}
				],
				"value": "鞍山市",
				"label": "鞍山市"
			},
			{
				"code": 210400000000,
				"children": [
					{
						"code": 210401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210402000000,
						"value": "新抚区",
						"label": "新抚区"
					},
					{
						"code": 210403000000,
						"value": "东洲区",
						"label": "东洲区"
					},
					{
						"code": 210404000000,
						"value": "望花区",
						"label": "望花区"
					},
					{
						"code": 210411000000,
						"value": "顺城区",
						"label": "顺城区"
					},
					{
						"code": 210421000000,
						"value": "抚顺县",
						"label": "抚顺县"
					},
					{
						"code": 210422000000,
						"value": "新宾满族自治县",
						"label": "新宾满族自治县"
					},
					{
						"code": 210423000000,
						"value": "清原满族自治县",
						"label": "清原满族自治县"
					}
				],
				"value": "抚顺市",
				"label": "抚顺市"
			},
			{
				"code": 210500000000,
				"children": [
					{
						"code": 210501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210502000000,
						"value": "平山区",
						"label": "平山区"
					},
					{
						"code": 210503000000,
						"value": "溪湖区",
						"label": "溪湖区"
					},
					{
						"code": 210504000000,
						"value": "明山区",
						"label": "明山区"
					},
					{
						"code": 210505000000,
						"value": "南芬区",
						"label": "南芬区"
					},
					{
						"code": 210521000000,
						"value": "本溪满族自治县",
						"label": "本溪满族自治县"
					},
					{
						"code": 210522000000,
						"value": "桓仁满族自治县",
						"label": "桓仁满族自治县"
					}
				],
				"value": "本溪市",
				"label": "本溪市"
			},
			{
				"code": 210600000000,
				"children": [
					{
						"code": 210601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210602000000,
						"value": "元宝区",
						"label": "元宝区"
					},
					{
						"code": 210603000000,
						"value": "振兴区",
						"label": "振兴区"
					},
					{
						"code": 210604000000,
						"value": "振安区",
						"label": "振安区"
					},
					{
						"code": 210624000000,
						"value": "宽甸满族自治县",
						"label": "宽甸满族自治县"
					},
					{
						"code": 210681000000,
						"value": "东港市",
						"label": "东港市"
					},
					{
						"code": 210682000000,
						"value": "凤城市",
						"label": "凤城市"
					}
				],
				"value": "丹东市",
				"label": "丹东市"
			},
			{
				"code": 210700000000,
				"children": [
					{
						"code": 210701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210702000000,
						"value": "古塔区",
						"label": "古塔区"
					},
					{
						"code": 210703000000,
						"value": "凌河区",
						"label": "凌河区"
					},
					{
						"code": 210711000000,
						"value": "太和区",
						"label": "太和区"
					},
					{
						"code": 210726000000,
						"value": "黑山县",
						"label": "黑山县"
					},
					{
						"code": 210727000000,
						"value": "义县",
						"label": "义县"
					},
					{
						"code": 210781000000,
						"value": "凌海市",
						"label": "凌海市"
					},
					{
						"code": 210782000000,
						"value": "北镇市",
						"label": "北镇市"
					}
				],
				"value": "锦州市",
				"label": "锦州市"
			},
			{
				"code": 210800000000,
				"children": [
					{
						"code": 210801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210802000000,
						"value": "站前区",
						"label": "站前区"
					},
					{
						"code": 210803000000,
						"value": "西市区",
						"label": "西市区"
					},
					{
						"code": 210804000000,
						"value": "鲅鱼圈区",
						"label": "鲅鱼圈区"
					},
					{
						"code": 210811000000,
						"value": "老边区",
						"label": "老边区"
					},
					{
						"code": 210881000000,
						"value": "盖州市",
						"label": "盖州市"
					},
					{
						"code": 210882000000,
						"value": "大石桥市",
						"label": "大石桥市"
					}
				],
				"value": "营口市",
				"label": "营口市"
			},
			{
				"code": 210900000000,
				"children": [
					{
						"code": 210901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 210902000000,
						"value": "海州区",
						"label": "海州区"
					},
					{
						"code": 210903000000,
						"value": "新邱区",
						"label": "新邱区"
					},
					{
						"code": 210904000000,
						"value": "太平区",
						"label": "太平区"
					},
					{
						"code": 210905000000,
						"value": "清河门区",
						"label": "清河门区"
					},
					{
						"code": 210911000000,
						"value": "细河区",
						"label": "细河区"
					},
					{
						"code": 210921000000,
						"value": "阜新蒙古族自治县",
						"label": "阜新蒙古族自治县"
					},
					{
						"code": 210922000000,
						"value": "彰武县",
						"label": "彰武县"
					}
				],
				"value": "阜新市",
				"label": "阜新市"
			},
			{
				"code": 211000000000,
				"children": [
					{
						"code": 211001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 211002000000,
						"value": "白塔区",
						"label": "白塔区"
					},
					{
						"code": 211003000000,
						"value": "文圣区",
						"label": "文圣区"
					},
					{
						"code": 211004000000,
						"value": "宏伟区",
						"label": "宏伟区"
					},
					{
						"code": 211005000000,
						"value": "弓长岭区",
						"label": "弓长岭区"
					},
					{
						"code": 211011000000,
						"value": "太子河区",
						"label": "太子河区"
					},
					{
						"code": 211021000000,
						"value": "辽阳县",
						"label": "辽阳县"
					},
					{
						"code": 211081000000,
						"value": "灯塔市",
						"label": "灯塔市"
					}
				],
				"value": "辽阳市",
				"label": "辽阳市"
			},
			{
				"code": 211100000000,
				"children": [
					{
						"code": 211101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 211102000000,
						"value": "双台子区",
						"label": "双台子区"
					},
					{
						"code": 211103000000,
						"value": "兴隆台区",
						"label": "兴隆台区"
					},
					{
						"code": 211104000000,
						"value": "大洼区",
						"label": "大洼区"
					},
					{
						"code": 211122000000,
						"value": "盘山县",
						"label": "盘山县"
					}
				],
				"value": "盘锦市",
				"label": "盘锦市"
			},
			{
				"code": 211200000000,
				"children": [
					{
						"code": 211201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 211202000000,
						"value": "银州区",
						"label": "银州区"
					},
					{
						"code": 211204000000,
						"value": "清河区",
						"label": "清河区"
					},
					{
						"code": 211221000000,
						"value": "铁岭县",
						"label": "铁岭县"
					},
					{
						"code": 211223000000,
						"value": "西丰县",
						"label": "西丰县"
					},
					{
						"code": 211224000000,
						"value": "昌图县",
						"label": "昌图县"
					},
					{
						"code": 211281000000,
						"value": "调兵山市",
						"label": "调兵山市"
					},
					{
						"code": 211282000000,
						"value": "开原市",
						"label": "开原市"
					}
				],
				"value": "铁岭市",
				"label": "铁岭市"
			},
			{
				"code": 211300000000,
				"children": [
					{
						"code": 211301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 211302000000,
						"value": "双塔区",
						"label": "双塔区"
					},
					{
						"code": 211303000000,
						"value": "龙城区",
						"label": "龙城区"
					},
					{
						"code": 211321000000,
						"value": "朝阳县",
						"label": "朝阳县"
					},
					{
						"code": 211322000000,
						"value": "建平县",
						"label": "建平县"
					},
					{
						"code": 211324000000,
						"value": "喀喇沁左翼蒙古族自治县",
						"label": "喀喇沁左翼蒙古族自治县"
					},
					{
						"code": 211381000000,
						"value": "北票市",
						"label": "北票市"
					},
					{
						"code": 211382000000,
						"value": "凌源市",
						"label": "凌源市"
					}
				],
				"value": "朝阳市",
				"label": "朝阳市"
			},
			{
				"code": 211400000000,
				"children": [
					{
						"code": 211401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 211402000000,
						"value": "连山区",
						"label": "连山区"
					},
					{
						"code": 211403000000,
						"value": "龙港区",
						"label": "龙港区"
					},
					{
						"code": 211404000000,
						"value": "南票区",
						"label": "南票区"
					},
					{
						"code": 211421000000,
						"value": "绥中县",
						"label": "绥中县"
					},
					{
						"code": 211422000000,
						"value": "建昌县",
						"label": "建昌县"
					},
					{
						"code": 211481000000,
						"value": "兴城市",
						"label": "兴城市"
					}
				],
				"value": "葫芦岛市",
				"label": "葫芦岛市"
			}
		],
		"value": "辽宁省",
		"label": "辽宁省"
	},
	{
		"code": 220000000000,
		"children": [
			{
				"code": 220100000000,
				"children": [
					{
						"code": 220101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220102000000,
						"value": "南关区",
						"label": "南关区"
					},
					{
						"code": 220103000000,
						"value": "宽城区",
						"label": "宽城区"
					},
					{
						"code": 220104000000,
						"value": "朝阳区",
						"label": "朝阳区"
					},
					{
						"code": 220105000000,
						"value": "二道区",
						"label": "二道区"
					},
					{
						"code": 220106000000,
						"value": "绿园区",
						"label": "绿园区"
					},
					{
						"code": 220112000000,
						"value": "双阳区",
						"label": "双阳区"
					},
					{
						"code": 220113000000,
						"value": "九台区",
						"label": "九台区"
					},
					{
						"code": 220122000000,
						"value": "农安县",
						"label": "农安县"
					},
					{
						"code": 220171000000,
						"value": "长春经济技术开发区",
						"label": "长春经济技术开发区"
					},
					{
						"code": 220172000000,
						"value": "长春净月高新技术产业开发区",
						"label": "长春净月高新技术产业开发区"
					},
					{
						"code": 220173000000,
						"value": "长春高新技术产业开发区",
						"label": "长春高新技术产业开发区"
					},
					{
						"code": 220174000000,
						"value": "长春汽车经济技术开发区",
						"label": "长春汽车经济技术开发区"
					},
					{
						"code": 220182000000,
						"value": "榆树市",
						"label": "榆树市"
					},
					{
						"code": 220183000000,
						"value": "德惠市",
						"label": "德惠市"
					},
					{
						"code": 220184000000,
						"value": "公主岭市",
						"label": "公主岭市"
					}
				],
				"value": "长春市",
				"label": "长春市"
			},
			{
				"code": 220200000000,
				"children": [
					{
						"code": 220201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220202000000,
						"value": "昌邑区",
						"label": "昌邑区"
					},
					{
						"code": 220203000000,
						"value": "龙潭区",
						"label": "龙潭区"
					},
					{
						"code": 220204000000,
						"value": "船营区",
						"label": "船营区"
					},
					{
						"code": 220211000000,
						"value": "丰满区",
						"label": "丰满区"
					},
					{
						"code": 220221000000,
						"value": "永吉县",
						"label": "永吉县"
					},
					{
						"code": 220271000000,
						"value": "吉林经济开发区",
						"label": "吉林经济开发区"
					},
					{
						"code": 220272000000,
						"value": "吉林高新技术产业开发区",
						"label": "吉林高新技术产业开发区"
					},
					{
						"code": 220273000000,
						"value": "吉林中国新加坡食品区",
						"label": "吉林中国新加坡食品区"
					},
					{
						"code": 220281000000,
						"value": "蛟河市",
						"label": "蛟河市"
					},
					{
						"code": 220282000000,
						"value": "桦甸市",
						"label": "桦甸市"
					},
					{
						"code": 220283000000,
						"value": "舒兰市",
						"label": "舒兰市"
					},
					{
						"code": 220284000000,
						"value": "磐石市",
						"label": "磐石市"
					}
				],
				"value": "吉林市",
				"label": "吉林市"
			},
			{
				"code": 220300000000,
				"children": [
					{
						"code": 220301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220302000000,
						"value": "铁西区",
						"label": "铁西区"
					},
					{
						"code": 220303000000,
						"value": "铁东区",
						"label": "铁东区"
					},
					{
						"code": 220322000000,
						"value": "梨树县",
						"label": "梨树县"
					},
					{
						"code": 220323000000,
						"value": "伊通满族自治县",
						"label": "伊通满族自治县"
					},
					{
						"code": 220382000000,
						"value": "双辽市",
						"label": "双辽市"
					}
				],
				"value": "四平市",
				"label": "四平市"
			},
			{
				"code": 220400000000,
				"children": [
					{
						"code": 220401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220402000000,
						"value": "龙山区",
						"label": "龙山区"
					},
					{
						"code": 220403000000,
						"value": "西安区",
						"label": "西安区"
					},
					{
						"code": 220421000000,
						"value": "东丰县",
						"label": "东丰县"
					},
					{
						"code": 220422000000,
						"value": "东辽县",
						"label": "东辽县"
					}
				],
				"value": "辽源市",
				"label": "辽源市"
			},
			{
				"code": 220500000000,
				"children": [
					{
						"code": 220501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220502000000,
						"value": "东昌区",
						"label": "东昌区"
					},
					{
						"code": 220503000000,
						"value": "二道江区",
						"label": "二道江区"
					},
					{
						"code": 220521000000,
						"value": "通化县",
						"label": "通化县"
					},
					{
						"code": 220523000000,
						"value": "辉南县",
						"label": "辉南县"
					},
					{
						"code": 220524000000,
						"value": "柳河县",
						"label": "柳河县"
					},
					{
						"code": 220581000000,
						"value": "梅河口市",
						"label": "梅河口市"
					},
					{
						"code": 220582000000,
						"value": "集安市",
						"label": "集安市"
					}
				],
				"value": "通化市",
				"label": "通化市"
			},
			{
				"code": 220600000000,
				"children": [
					{
						"code": 220601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220602000000,
						"value": "浑江区",
						"label": "浑江区"
					},
					{
						"code": 220605000000,
						"value": "江源区",
						"label": "江源区"
					},
					{
						"code": 220621000000,
						"value": "抚松县",
						"label": "抚松县"
					},
					{
						"code": 220622000000,
						"value": "靖宇县",
						"label": "靖宇县"
					},
					{
						"code": 220623000000,
						"value": "长白朝鲜族自治县",
						"label": "长白朝鲜族自治县"
					},
					{
						"code": 220681000000,
						"value": "临江市",
						"label": "临江市"
					}
				],
				"value": "白山市",
				"label": "白山市"
			},
			{
				"code": 220700000000,
				"children": [
					{
						"code": 220701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220702000000,
						"value": "宁江区",
						"label": "宁江区"
					},
					{
						"code": 220721000000,
						"value": "前郭尔罗斯蒙古族自治县",
						"label": "前郭尔罗斯蒙古族自治县"
					},
					{
						"code": 220722000000,
						"value": "长岭县",
						"label": "长岭县"
					},
					{
						"code": 220723000000,
						"value": "乾安县",
						"label": "乾安县"
					},
					{
						"code": 220771000000,
						"value": "吉林松原经济开发区",
						"label": "吉林松原经济开发区"
					},
					{
						"code": 220781000000,
						"value": "扶余市",
						"label": "扶余市"
					}
				],
				"value": "松原市",
				"label": "松原市"
			},
			{
				"code": 220800000000,
				"children": [
					{
						"code": 220801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 220802000000,
						"value": "洮北区",
						"label": "洮北区"
					},
					{
						"code": 220821000000,
						"value": "镇赉县",
						"label": "镇赉县"
					},
					{
						"code": 220822000000,
						"value": "通榆县",
						"label": "通榆县"
					},
					{
						"code": 220871000000,
						"value": "吉林白城经济开发区",
						"label": "吉林白城经济开发区"
					},
					{
						"code": 220881000000,
						"value": "洮南市",
						"label": "洮南市"
					},
					{
						"code": 220882000000,
						"value": "大安市",
						"label": "大安市"
					}
				],
				"value": "白城市",
				"label": "白城市"
			},
			{
				"code": 222400000000,
				"children": [
					{
						"code": 222401000000,
						"value": "延吉市",
						"label": "延吉市"
					},
					{
						"code": 222402000000,
						"value": "图们市",
						"label": "图们市"
					},
					{
						"code": 222403000000,
						"value": "敦化市",
						"label": "敦化市"
					},
					{
						"code": 222404000000,
						"value": "珲春市",
						"label": "珲春市"
					},
					{
						"code": 222405000000,
						"value": "龙井市",
						"label": "龙井市"
					},
					{
						"code": 222406000000,
						"value": "和龙市",
						"label": "和龙市"
					},
					{
						"code": 222424000000,
						"value": "汪清县",
						"label": "汪清县"
					},
					{
						"code": 222426000000,
						"value": "安图县",
						"label": "安图县"
					}
				],
				"value": "延边朝鲜族自治州",
				"label": "延边朝鲜族自治州"
			}
		],
		"value": "吉林省",
		"label": "吉林省"
	},
	{
		"code": 230000000000,
		"children": [
			{
				"code": 230100000000,
				"children": [
					{
						"code": 230101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230102000000,
						"value": "道里区",
						"label": "道里区"
					},
					{
						"code": 230103000000,
						"value": "南岗区",
						"label": "南岗区"
					},
					{
						"code": 230104000000,
						"value": "道外区",
						"label": "道外区"
					},
					{
						"code": 230108000000,
						"value": "平房区",
						"label": "平房区"
					},
					{
						"code": 230109000000,
						"value": "松北区",
						"label": "松北区"
					},
					{
						"code": 230110000000,
						"value": "香坊区",
						"label": "香坊区"
					},
					{
						"code": 230111000000,
						"value": "呼兰区",
						"label": "呼兰区"
					},
					{
						"code": 230112000000,
						"value": "阿城区",
						"label": "阿城区"
					},
					{
						"code": 230113000000,
						"value": "双城区",
						"label": "双城区"
					},
					{
						"code": 230123000000,
						"value": "依兰县",
						"label": "依兰县"
					},
					{
						"code": 230124000000,
						"value": "方正县",
						"label": "方正县"
					},
					{
						"code": 230125000000,
						"value": "宾县",
						"label": "宾县"
					},
					{
						"code": 230126000000,
						"value": "巴彦县",
						"label": "巴彦县"
					},
					{
						"code": 230127000000,
						"value": "木兰县",
						"label": "木兰县"
					},
					{
						"code": 230128000000,
						"value": "通河县",
						"label": "通河县"
					},
					{
						"code": 230129000000,
						"value": "延寿县",
						"label": "延寿县"
					},
					{
						"code": 230183000000,
						"value": "尚志市",
						"label": "尚志市"
					},
					{
						"code": 230184000000,
						"value": "五常市",
						"label": "五常市"
					}
				],
				"value": "哈尔滨市",
				"label": "哈尔滨市"
			},
			{
				"code": 230200000000,
				"children": [
					{
						"code": 230201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230202000000,
						"value": "龙沙区",
						"label": "龙沙区"
					},
					{
						"code": 230203000000,
						"value": "建华区",
						"label": "建华区"
					},
					{
						"code": 230204000000,
						"value": "铁锋区",
						"label": "铁锋区"
					},
					{
						"code": 230205000000,
						"value": "昂昂溪区",
						"label": "昂昂溪区"
					},
					{
						"code": 230206000000,
						"value": "富拉尔基区",
						"label": "富拉尔基区"
					},
					{
						"code": 230207000000,
						"value": "碾子山区",
						"label": "碾子山区"
					},
					{
						"code": 230208000000,
						"value": "梅里斯达斡尔族区",
						"label": "梅里斯达斡尔族区"
					},
					{
						"code": 230221000000,
						"value": "龙江县",
						"label": "龙江县"
					},
					{
						"code": 230223000000,
						"value": "依安县",
						"label": "依安县"
					},
					{
						"code": 230224000000,
						"value": "泰来县",
						"label": "泰来县"
					},
					{
						"code": 230225000000,
						"value": "甘南县",
						"label": "甘南县"
					},
					{
						"code": 230227000000,
						"value": "富裕县",
						"label": "富裕县"
					},
					{
						"code": 230229000000,
						"value": "克山县",
						"label": "克山县"
					},
					{
						"code": 230230000000,
						"value": "克东县",
						"label": "克东县"
					},
					{
						"code": 230231000000,
						"value": "拜泉县",
						"label": "拜泉县"
					},
					{
						"code": 230281000000,
						"value": "讷河市",
						"label": "讷河市"
					}
				],
				"value": "齐齐哈尔市",
				"label": "齐齐哈尔市"
			},
			{
				"code": 230300000000,
				"children": [
					{
						"code": 230301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230302000000,
						"value": "鸡冠区",
						"label": "鸡冠区"
					},
					{
						"code": 230303000000,
						"value": "恒山区",
						"label": "恒山区"
					},
					{
						"code": 230304000000,
						"value": "滴道区",
						"label": "滴道区"
					},
					{
						"code": 230305000000,
						"value": "梨树区",
						"label": "梨树区"
					},
					{
						"code": 230306000000,
						"value": "城子河区",
						"label": "城子河区"
					},
					{
						"code": 230307000000,
						"value": "麻山区",
						"label": "麻山区"
					},
					{
						"code": 230321000000,
						"value": "鸡东县",
						"label": "鸡东县"
					},
					{
						"code": 230381000000,
						"value": "虎林市",
						"label": "虎林市"
					},
					{
						"code": 230382000000,
						"value": "密山市",
						"label": "密山市"
					}
				],
				"value": "鸡西市",
				"label": "鸡西市"
			},
			{
				"code": 230400000000,
				"children": [
					{
						"code": 230401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230402000000,
						"value": "向阳区",
						"label": "向阳区"
					},
					{
						"code": 230403000000,
						"value": "工农区",
						"label": "工农区"
					},
					{
						"code": 230404000000,
						"value": "南山区",
						"label": "南山区"
					},
					{
						"code": 230405000000,
						"value": "兴安区",
						"label": "兴安区"
					},
					{
						"code": 230406000000,
						"value": "东山区",
						"label": "东山区"
					},
					{
						"code": 230407000000,
						"value": "兴山区",
						"label": "兴山区"
					},
					{
						"code": 230421000000,
						"value": "萝北县",
						"label": "萝北县"
					},
					{
						"code": 230422000000,
						"value": "绥滨县",
						"label": "绥滨县"
					}
				],
				"value": "鹤岗市",
				"label": "鹤岗市"
			},
			{
				"code": 230500000000,
				"children": [
					{
						"code": 230501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230502000000,
						"value": "尖山区",
						"label": "尖山区"
					},
					{
						"code": 230503000000,
						"value": "岭东区",
						"label": "岭东区"
					},
					{
						"code": 230505000000,
						"value": "四方台区",
						"label": "四方台区"
					},
					{
						"code": 230506000000,
						"value": "宝山区",
						"label": "宝山区"
					},
					{
						"code": 230521000000,
						"value": "集贤县",
						"label": "集贤县"
					},
					{
						"code": 230522000000,
						"value": "友谊县",
						"label": "友谊县"
					},
					{
						"code": 230523000000,
						"value": "宝清县",
						"label": "宝清县"
					},
					{
						"code": 230524000000,
						"value": "饶河县",
						"label": "饶河县"
					}
				],
				"value": "双鸭山市",
				"label": "双鸭山市"
			},
			{
				"code": 230600000000,
				"children": [
					{
						"code": 230601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230602000000,
						"value": "萨尔图区",
						"label": "萨尔图区"
					},
					{
						"code": 230603000000,
						"value": "龙凤区",
						"label": "龙凤区"
					},
					{
						"code": 230604000000,
						"value": "让胡路区",
						"label": "让胡路区"
					},
					{
						"code": 230605000000,
						"value": "红岗区",
						"label": "红岗区"
					},
					{
						"code": 230606000000,
						"value": "大同区",
						"label": "大同区"
					},
					{
						"code": 230621000000,
						"value": "肇州县",
						"label": "肇州县"
					},
					{
						"code": 230622000000,
						"value": "肇源县",
						"label": "肇源县"
					},
					{
						"code": 230623000000,
						"value": "林甸县",
						"label": "林甸县"
					},
					{
						"code": 230624000000,
						"value": "杜尔伯特蒙古族自治县",
						"label": "杜尔伯特蒙古族自治县"
					},
					{
						"code": 230671000000,
						"value": "大庆高新技术产业开发区",
						"label": "大庆高新技术产业开发区"
					}
				],
				"value": "大庆市",
				"label": "大庆市"
			},
			{
				"code": 230700000000,
				"children": [
					{
						"code": 230701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230717000000,
						"value": "伊美区",
						"label": "伊美区"
					},
					{
						"code": 230718000000,
						"value": "乌翠区",
						"label": "乌翠区"
					},
					{
						"code": 230719000000,
						"value": "友好区",
						"label": "友好区"
					},
					{
						"code": 230722000000,
						"value": "嘉荫县",
						"label": "嘉荫县"
					},
					{
						"code": 230723000000,
						"value": "汤旺县",
						"label": "汤旺县"
					},
					{
						"code": 230724000000,
						"value": "丰林县",
						"label": "丰林县"
					},
					{
						"code": 230725000000,
						"value": "大箐山县",
						"label": "大箐山县"
					},
					{
						"code": 230726000000,
						"value": "南岔县",
						"label": "南岔县"
					},
					{
						"code": 230751000000,
						"value": "金林区",
						"label": "金林区"
					},
					{
						"code": 230781000000,
						"value": "铁力市",
						"label": "铁力市"
					}
				],
				"value": "伊春市",
				"label": "伊春市"
			},
			{
				"code": 230800000000,
				"children": [
					{
						"code": 230801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230803000000,
						"value": "向阳区",
						"label": "向阳区"
					},
					{
						"code": 230804000000,
						"value": "前进区",
						"label": "前进区"
					},
					{
						"code": 230805000000,
						"value": "东风区",
						"label": "东风区"
					},
					{
						"code": 230811000000,
						"value": "郊区",
						"label": "郊区"
					},
					{
						"code": 230822000000,
						"value": "桦南县",
						"label": "桦南县"
					},
					{
						"code": 230826000000,
						"value": "桦川县",
						"label": "桦川县"
					},
					{
						"code": 230828000000,
						"value": "汤原县",
						"label": "汤原县"
					},
					{
						"code": 230881000000,
						"value": "同江市",
						"label": "同江市"
					},
					{
						"code": 230882000000,
						"value": "富锦市",
						"label": "富锦市"
					},
					{
						"code": 230883000000,
						"value": "抚远市",
						"label": "抚远市"
					}
				],
				"value": "佳木斯市",
				"label": "佳木斯市"
			},
			{
				"code": 230900000000,
				"children": [
					{
						"code": 230901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 230902000000,
						"value": "新兴区",
						"label": "新兴区"
					},
					{
						"code": 230903000000,
						"value": "桃山区",
						"label": "桃山区"
					},
					{
						"code": 230904000000,
						"value": "茄子河区",
						"label": "茄子河区"
					},
					{
						"code": 230921000000,
						"value": "勃利县",
						"label": "勃利县"
					}
				],
				"value": "七台河市",
				"label": "七台河市"
			},
			{
				"code": 231000000000,
				"children": [
					{
						"code": 231001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 231002000000,
						"value": "东安区",
						"label": "东安区"
					},
					{
						"code": 231003000000,
						"value": "阳明区",
						"label": "阳明区"
					},
					{
						"code": 231004000000,
						"value": "爱民区",
						"label": "爱民区"
					},
					{
						"code": 231005000000,
						"value": "西安区",
						"label": "西安区"
					},
					{
						"code": 231025000000,
						"value": "林口县",
						"label": "林口县"
					},
					{
						"code": 231071000000,
						"value": "牡丹江经济技术开发区",
						"label": "牡丹江经济技术开发区"
					},
					{
						"code": 231081000000,
						"value": "绥芬河市",
						"label": "绥芬河市"
					},
					{
						"code": 231083000000,
						"value": "海林市",
						"label": "海林市"
					},
					{
						"code": 231084000000,
						"value": "宁安市",
						"label": "宁安市"
					},
					{
						"code": 231085000000,
						"value": "穆棱市",
						"label": "穆棱市"
					},
					{
						"code": 231086000000,
						"value": "东宁市",
						"label": "东宁市"
					}
				],
				"value": "牡丹江市",
				"label": "牡丹江市"
			},
			{
				"code": 231100000000,
				"children": [
					{
						"code": 231101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 231102000000,
						"value": "爱辉区",
						"label": "爱辉区"
					},
					{
						"code": 231123000000,
						"value": "逊克县",
						"label": "逊克县"
					},
					{
						"code": 231124000000,
						"value": "孙吴县",
						"label": "孙吴县"
					},
					{
						"code": 231181000000,
						"value": "北安市",
						"label": "北安市"
					},
					{
						"code": 231182000000,
						"value": "五大连池市",
						"label": "五大连池市"
					},
					{
						"code": 231183000000,
						"value": "嫩江市",
						"label": "嫩江市"
					}
				],
				"value": "黑河市",
				"label": "黑河市"
			},
			{
				"code": 231200000000,
				"children": [
					{
						"code": 231201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 231202000000,
						"value": "北林区",
						"label": "北林区"
					},
					{
						"code": 231221000000,
						"value": "望奎县",
						"label": "望奎县"
					},
					{
						"code": 231222000000,
						"value": "兰西县",
						"label": "兰西县"
					},
					{
						"code": 231223000000,
						"value": "青冈县",
						"label": "青冈县"
					},
					{
						"code": 231224000000,
						"value": "庆安县",
						"label": "庆安县"
					},
					{
						"code": 231225000000,
						"value": "明水县",
						"label": "明水县"
					},
					{
						"code": 231226000000,
						"value": "绥棱县",
						"label": "绥棱县"
					},
					{
						"code": 231281000000,
						"value": "安达市",
						"label": "安达市"
					},
					{
						"code": 231282000000,
						"value": "肇东市",
						"label": "肇东市"
					},
					{
						"code": 231283000000,
						"value": "海伦市",
						"label": "海伦市"
					}
				],
				"value": "绥化市",
				"label": "绥化市"
			},
			{
				"code": 232700000000,
				"children": [
					{
						"code": 232701000000,
						"value": "漠河市",
						"label": "漠河市"
					},
					{
						"code": 232721000000,
						"value": "呼玛县",
						"label": "呼玛县"
					},
					{
						"code": 232722000000,
						"value": "塔河县",
						"label": "塔河县"
					},
					{
						"code": 232761000000,
						"value": "加格达奇区",
						"label": "加格达奇区"
					},
					{
						"code": 232762000000,
						"value": "松岭区",
						"label": "松岭区"
					},
					{
						"code": 232763000000,
						"value": "新林区",
						"label": "新林区"
					},
					{
						"code": 232764000000,
						"value": "呼中区",
						"label": "呼中区"
					}
				],
				"value": "大兴安岭地区",
				"label": "大兴安岭地区"
			}
		],
		"value": "黑龙江省",
		"label": "黑龙江省"
	},
	{
		"code": 310000000000,
		"children": [
			{
				"code": 310100000000,
				"children": [
					{
						"code": 310101000000,
						"value": "黄浦区",
						"label": "黄浦区"
					},
					{
						"code": 310104000000,
						"value": "徐汇区",
						"label": "徐汇区"
					},
					{
						"code": 310105000000,
						"value": "长宁区",
						"label": "长宁区"
					},
					{
						"code": 310106000000,
						"value": "静安区",
						"label": "静安区"
					},
					{
						"code": 310107000000,
						"value": "普陀区",
						"label": "普陀区"
					},
					{
						"code": 310109000000,
						"value": "虹口区",
						"label": "虹口区"
					},
					{
						"code": 310110000000,
						"value": "杨浦区",
						"label": "杨浦区"
					},
					{
						"code": 310112000000,
						"value": "闵行区",
						"label": "闵行区"
					},
					{
						"code": 310113000000,
						"value": "宝山区",
						"label": "宝山区"
					},
					{
						"code": 310114000000,
						"value": "嘉定区",
						"label": "嘉定区"
					},
					{
						"code": 310115000000,
						"value": "浦东新区",
						"label": "浦东新区"
					},
					{
						"code": 310116000000,
						"value": "金山区",
						"label": "金山区"
					},
					{
						"code": 310117000000,
						"value": "松江区",
						"label": "松江区"
					},
					{
						"code": 310118000000,
						"value": "青浦区",
						"label": "青浦区"
					},
					{
						"code": 310120000000,
						"value": "奉贤区",
						"label": "奉贤区"
					},
					{
						"code": 310151000000,
						"value": "崇明区",
						"label": "崇明区"
					}
				],
				"value": "市辖区",
				"label": "市辖区"
			}
		],
		"value": "上海市",
		"label": "上海市"
	},
	{
		"code": 320000000000,
		"children": [
			{
				"code": 320100000000,
				"children": [
					{
						"code": 320101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320102000000,
						"value": "玄武区",
						"label": "玄武区"
					},
					{
						"code": 320104000000,
						"value": "秦淮区",
						"label": "秦淮区"
					},
					{
						"code": 320105000000,
						"value": "建邺区",
						"label": "建邺区"
					},
					{
						"code": 320106000000,
						"value": "鼓楼区",
						"label": "鼓楼区"
					},
					{
						"code": 320111000000,
						"value": "浦口区",
						"label": "浦口区"
					},
					{
						"code": 320113000000,
						"value": "栖霞区",
						"label": "栖霞区"
					},
					{
						"code": 320114000000,
						"value": "雨花台区",
						"label": "雨花台区"
					},
					{
						"code": 320115000000,
						"value": "江宁区",
						"label": "江宁区"
					},
					{
						"code": 320116000000,
						"value": "六合区",
						"label": "六合区"
					},
					{
						"code": 320117000000,
						"value": "溧水区",
						"label": "溧水区"
					},
					{
						"code": 320118000000,
						"value": "高淳区",
						"label": "高淳区"
					}
				],
				"value": "南京市",
				"label": "南京市"
			},
			{
				"code": 320200000000,
				"children": [
					{
						"code": 320201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320205000000,
						"value": "锡山区",
						"label": "锡山区"
					},
					{
						"code": 320206000000,
						"value": "惠山区",
						"label": "惠山区"
					},
					{
						"code": 320211000000,
						"value": "滨湖区",
						"label": "滨湖区"
					},
					{
						"code": 320213000000,
						"value": "梁溪区",
						"label": "梁溪区"
					},
					{
						"code": 320214000000,
						"value": "新吴区",
						"label": "新吴区"
					},
					{
						"code": 320281000000,
						"value": "江阴市",
						"label": "江阴市"
					},
					{
						"code": 320282000000,
						"value": "宜兴市",
						"label": "宜兴市"
					}
				],
				"value": "无锡市",
				"label": "无锡市"
			},
			{
				"code": 320300000000,
				"children": [
					{
						"code": 320301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320302000000,
						"value": "鼓楼区",
						"label": "鼓楼区"
					},
					{
						"code": 320303000000,
						"value": "云龙区",
						"label": "云龙区"
					},
					{
						"code": 320305000000,
						"value": "贾汪区",
						"label": "贾汪区"
					},
					{
						"code": 320311000000,
						"value": "泉山区",
						"label": "泉山区"
					},
					{
						"code": 320312000000,
						"value": "铜山区",
						"label": "铜山区"
					},
					{
						"code": 320321000000,
						"value": "丰县",
						"label": "丰县"
					},
					{
						"code": 320322000000,
						"value": "沛县",
						"label": "沛县"
					},
					{
						"code": 320324000000,
						"value": "睢宁县",
						"label": "睢宁县"
					},
					{
						"code": 320371000000,
						"value": "徐州经济技术开发区",
						"label": "徐州经济技术开发区"
					},
					{
						"code": 320381000000,
						"value": "新沂市",
						"label": "新沂市"
					},
					{
						"code": 320382000000,
						"value": "邳州市",
						"label": "邳州市"
					}
				],
				"value": "徐州市",
				"label": "徐州市"
			},
			{
				"code": 320400000000,
				"children": [
					{
						"code": 320401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320402000000,
						"value": "天宁区",
						"label": "天宁区"
					},
					{
						"code": 320404000000,
						"value": "钟楼区",
						"label": "钟楼区"
					},
					{
						"code": 320411000000,
						"value": "新北区",
						"label": "新北区"
					},
					{
						"code": 320412000000,
						"value": "武进区",
						"label": "武进区"
					},
					{
						"code": 320413000000,
						"value": "金坛区",
						"label": "金坛区"
					},
					{
						"code": 320481000000,
						"value": "溧阳市",
						"label": "溧阳市"
					}
				],
				"value": "常州市",
				"label": "常州市"
			},
			{
				"code": 320500000000,
				"children": [
					{
						"code": 320501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320505000000,
						"value": "虎丘区",
						"label": "虎丘区"
					},
					{
						"code": 320506000000,
						"value": "吴中区",
						"label": "吴中区"
					},
					{
						"code": 320507000000,
						"value": "相城区",
						"label": "相城区"
					},
					{
						"code": 320508000000,
						"value": "姑苏区",
						"label": "姑苏区"
					},
					{
						"code": 320509000000,
						"value": "吴江区",
						"label": "吴江区"
					},
					{
						"code": 320571000000,
						"value": "苏州工业园区",
						"label": "苏州工业园区"
					},
					{
						"code": 320581000000,
						"value": "常熟市",
						"label": "常熟市"
					},
					{
						"code": 320582000000,
						"value": "张家港市",
						"label": "张家港市"
					},
					{
						"code": 320583000000,
						"value": "昆山市",
						"label": "昆山市"
					},
					{
						"code": 320585000000,
						"value": "太仓市",
						"label": "太仓市"
					}
				],
				"value": "苏州市",
				"label": "苏州市"
			},
			{
				"code": 320600000000,
				"children": [
					{
						"code": 320601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320602000000,
						"value": "崇川区",
						"label": "崇川区"
					},
					{
						"code": 320611000000,
						"value": "港闸区",
						"label": "港闸区"
					},
					{
						"code": 320612000000,
						"value": "通州区",
						"label": "通州区"
					},
					{
						"code": 320623000000,
						"value": "如东县",
						"label": "如东县"
					},
					{
						"code": 320671000000,
						"value": "南通经济技术开发区",
						"label": "南通经济技术开发区"
					},
					{
						"code": 320681000000,
						"value": "启东市",
						"label": "启东市"
					},
					{
						"code": 320682000000,
						"value": "如皋市",
						"label": "如皋市"
					},
					{
						"code": 320684000000,
						"value": "海门市",
						"label": "海门市"
					},
					{
						"code": 320685000000,
						"value": "海安市",
						"label": "海安市"
					}
				],
				"value": "南通市",
				"label": "南通市"
			},
			{
				"code": 320700000000,
				"children": [
					{
						"code": 320701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320703000000,
						"value": "连云区",
						"label": "连云区"
					},
					{
						"code": 320706000000,
						"value": "海州区",
						"label": "海州区"
					},
					{
						"code": 320707000000,
						"value": "赣榆区",
						"label": "赣榆区"
					},
					{
						"code": 320722000000,
						"value": "东海县",
						"label": "东海县"
					},
					{
						"code": 320723000000,
						"value": "灌云县",
						"label": "灌云县"
					},
					{
						"code": 320724000000,
						"value": "灌南县",
						"label": "灌南县"
					},
					{
						"code": 320771000000,
						"value": "连云港经济技术开发区",
						"label": "连云港经济技术开发区"
					},
					{
						"code": 320772000000,
						"value": "连云港高新技术产业开发区",
						"label": "连云港高新技术产业开发区"
					}
				],
				"value": "连云港市",
				"label": "连云港市"
			},
			{
				"code": 320800000000,
				"children": [
					{
						"code": 320801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320803000000,
						"value": "淮安区",
						"label": "淮安区"
					},
					{
						"code": 320804000000,
						"value": "淮阴区",
						"label": "淮阴区"
					},
					{
						"code": 320812000000,
						"value": "清江浦区",
						"label": "清江浦区"
					},
					{
						"code": 320813000000,
						"value": "洪泽区",
						"label": "洪泽区"
					},
					{
						"code": 320826000000,
						"value": "涟水县",
						"label": "涟水县"
					},
					{
						"code": 320830000000,
						"value": "盱眙县",
						"label": "盱眙县"
					},
					{
						"code": 320831000000,
						"value": "金湖县",
						"label": "金湖县"
					},
					{
						"code": 320871000000,
						"value": "淮安经济技术开发区",
						"label": "淮安经济技术开发区"
					}
				],
				"value": "淮安市",
				"label": "淮安市"
			},
			{
				"code": 320900000000,
				"children": [
					{
						"code": 320901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 320902000000,
						"value": "亭湖区",
						"label": "亭湖区"
					},
					{
						"code": 320903000000,
						"value": "盐都区",
						"label": "盐都区"
					},
					{
						"code": 320904000000,
						"value": "大丰区",
						"label": "大丰区"
					},
					{
						"code": 320921000000,
						"value": "响水县",
						"label": "响水县"
					},
					{
						"code": 320922000000,
						"value": "滨海县",
						"label": "滨海县"
					},
					{
						"code": 320923000000,
						"value": "阜宁县",
						"label": "阜宁县"
					},
					{
						"code": 320924000000,
						"value": "射阳县",
						"label": "射阳县"
					},
					{
						"code": 320925000000,
						"value": "建湖县",
						"label": "建湖县"
					},
					{
						"code": 320971000000,
						"value": "盐城经济技术开发区",
						"label": "盐城经济技术开发区"
					},
					{
						"code": 320981000000,
						"value": "东台市",
						"label": "东台市"
					}
				],
				"value": "盐城市",
				"label": "盐城市"
			},
			{
				"code": 321000000000,
				"children": [
					{
						"code": 321001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 321002000000,
						"value": "广陵区",
						"label": "广陵区"
					},
					{
						"code": 321003000000,
						"value": "邗江区",
						"label": "邗江区"
					},
					{
						"code": 321012000000,
						"value": "江都区",
						"label": "江都区"
					},
					{
						"code": 321023000000,
						"value": "宝应县",
						"label": "宝应县"
					},
					{
						"code": 321071000000,
						"value": "扬州经济技术开发区",
						"label": "扬州经济技术开发区"
					},
					{
						"code": 321081000000,
						"value": "仪征市",
						"label": "仪征市"
					},
					{
						"code": 321084000000,
						"value": "高邮市",
						"label": "高邮市"
					}
				],
				"value": "扬州市",
				"label": "扬州市"
			},
			{
				"code": 321100000000,
				"children": [
					{
						"code": 321101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 321102000000,
						"value": "京口区",
						"label": "京口区"
					},
					{
						"code": 321111000000,
						"value": "润州区",
						"label": "润州区"
					},
					{
						"code": 321112000000,
						"value": "丹徒区",
						"label": "丹徒区"
					},
					{
						"code": 321171000000,
						"value": "镇江新区",
						"label": "镇江新区"
					},
					{
						"code": 321181000000,
						"value": "丹阳市",
						"label": "丹阳市"
					},
					{
						"code": 321182000000,
						"value": "扬中市",
						"label": "扬中市"
					},
					{
						"code": 321183000000,
						"value": "句容市",
						"label": "句容市"
					}
				],
				"value": "镇江市",
				"label": "镇江市"
			},
			{
				"code": 321200000000,
				"children": [
					{
						"code": 321201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 321202000000,
						"value": "海陵区",
						"label": "海陵区"
					},
					{
						"code": 321203000000,
						"value": "高港区",
						"label": "高港区"
					},
					{
						"code": 321204000000,
						"value": "姜堰区",
						"label": "姜堰区"
					},
					{
						"code": 321271000000,
						"value": "泰州医药高新技术产业开发区",
						"label": "泰州医药高新技术产业开发区"
					},
					{
						"code": 321281000000,
						"value": "兴化市",
						"label": "兴化市"
					},
					{
						"code": 321282000000,
						"value": "靖江市",
						"label": "靖江市"
					},
					{
						"code": 321283000000,
						"value": "泰兴市",
						"label": "泰兴市"
					}
				],
				"value": "泰州市",
				"label": "泰州市"
			},
			{
				"code": 321300000000,
				"children": [
					{
						"code": 321301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 321302000000,
						"value": "宿城区",
						"label": "宿城区"
					},
					{
						"code": 321311000000,
						"value": "宿豫区",
						"label": "宿豫区"
					},
					{
						"code": 321322000000,
						"value": "沭阳县",
						"label": "沭阳县"
					},
					{
						"code": 321323000000,
						"value": "泗阳县",
						"label": "泗阳县"
					},
					{
						"code": 321324000000,
						"value": "泗洪县",
						"label": "泗洪县"
					},
					{
						"code": 321371000000,
						"value": "宿迁经济技术开发区",
						"label": "宿迁经济技术开发区"
					}
				],
				"value": "宿迁市",
				"label": "宿迁市"
			}
		],
		"value": "江苏省",
		"label": "江苏省"
	},
	{
		"code": 330000000000,
		"children": [
			{
				"code": 330100000000,
				"children": [
					{
						"code": 330101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330102000000,
						"value": "上城区",
						"label": "上城区"
					},
					{
						"code": 330103000000,
						"value": "下城区",
						"label": "下城区"
					},
					{
						"code": 330104000000,
						"value": "江干区",
						"label": "江干区"
					},
					{
						"code": 330105000000,
						"value": "拱墅区",
						"label": "拱墅区"
					},
					{
						"code": 330106000000,
						"value": "西湖区",
						"label": "西湖区"
					},
					{
						"code": 330108000000,
						"value": "滨江区",
						"label": "滨江区"
					},
					{
						"code": 330109000000,
						"value": "萧山区",
						"label": "萧山区"
					},
					{
						"code": 330110000000,
						"value": "余杭区",
						"label": "余杭区"
					},
					{
						"code": 330111000000,
						"value": "富阳区",
						"label": "富阳区"
					},
					{
						"code": 330112000000,
						"value": "临安区",
						"label": "临安区"
					},
					{
						"code": 330122000000,
						"value": "桐庐县",
						"label": "桐庐县"
					},
					{
						"code": 330127000000,
						"value": "淳安县",
						"label": "淳安县"
					},
					{
						"code": 330182000000,
						"value": "建德市",
						"label": "建德市"
					}
				],
				"value": "杭州市",
				"label": "杭州市"
			},
			{
				"code": 330200000000,
				"children": [
					{
						"code": 330201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330203000000,
						"value": "海曙区",
						"label": "海曙区"
					},
					{
						"code": 330205000000,
						"value": "江北区",
						"label": "江北区"
					},
					{
						"code": 330206000000,
						"value": "北仑区",
						"label": "北仑区"
					},
					{
						"code": 330211000000,
						"value": "镇海区",
						"label": "镇海区"
					},
					{
						"code": 330212000000,
						"value": "鄞州区",
						"label": "鄞州区"
					},
					{
						"code": 330213000000,
						"value": "奉化区",
						"label": "奉化区"
					},
					{
						"code": 330225000000,
						"value": "象山县",
						"label": "象山县"
					},
					{
						"code": 330226000000,
						"value": "宁海县",
						"label": "宁海县"
					},
					{
						"code": 330281000000,
						"value": "余姚市",
						"label": "余姚市"
					},
					{
						"code": 330282000000,
						"value": "慈溪市",
						"label": "慈溪市"
					}
				],
				"value": "宁波市",
				"label": "宁波市"
			},
			{
				"code": 330300000000,
				"children": [
					{
						"code": 330301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330302000000,
						"value": "鹿城区",
						"label": "鹿城区"
					},
					{
						"code": 330303000000,
						"value": "龙湾区",
						"label": "龙湾区"
					},
					{
						"code": 330304000000,
						"value": "瓯海区",
						"label": "瓯海区"
					},
					{
						"code": 330305000000,
						"value": "洞头区",
						"label": "洞头区"
					},
					{
						"code": 330324000000,
						"value": "永嘉县",
						"label": "永嘉县"
					},
					{
						"code": 330326000000,
						"value": "平阳县",
						"label": "平阳县"
					},
					{
						"code": 330327000000,
						"value": "苍南县",
						"label": "苍南县"
					},
					{
						"code": 330328000000,
						"value": "文成县",
						"label": "文成县"
					},
					{
						"code": 330329000000,
						"value": "泰顺县",
						"label": "泰顺县"
					},
					{
						"code": 330371000000,
						"value": "温州经济技术开发区",
						"label": "温州经济技术开发区"
					},
					{
						"code": 330381000000,
						"value": "瑞安市",
						"label": "瑞安市"
					},
					{
						"code": 330382000000,
						"value": "乐清市",
						"label": "乐清市"
					},
					{
						"code": 330383000000,
						"value": "龙港市",
						"label": "龙港市"
					}
				],
				"value": "温州市",
				"label": "温州市"
			},
			{
				"code": 330400000000,
				"children": [
					{
						"code": 330401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330402000000,
						"value": "南湖区",
						"label": "南湖区"
					},
					{
						"code": 330411000000,
						"value": "秀洲区",
						"label": "秀洲区"
					},
					{
						"code": 330421000000,
						"value": "嘉善县",
						"label": "嘉善县"
					},
					{
						"code": 330424000000,
						"value": "海盐县",
						"label": "海盐县"
					},
					{
						"code": 330481000000,
						"value": "海宁市",
						"label": "海宁市"
					},
					{
						"code": 330482000000,
						"value": "平湖市",
						"label": "平湖市"
					},
					{
						"code": 330483000000,
						"value": "桐乡市",
						"label": "桐乡市"
					}
				],
				"value": "嘉兴市",
				"label": "嘉兴市"
			},
			{
				"code": 330500000000,
				"children": [
					{
						"code": 330501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330502000000,
						"value": "吴兴区",
						"label": "吴兴区"
					},
					{
						"code": 330503000000,
						"value": "南浔区",
						"label": "南浔区"
					},
					{
						"code": 330521000000,
						"value": "德清县",
						"label": "德清县"
					},
					{
						"code": 330522000000,
						"value": "长兴县",
						"label": "长兴县"
					},
					{
						"code": 330523000000,
						"value": "安吉县",
						"label": "安吉县"
					}
				],
				"value": "湖州市",
				"label": "湖州市"
			},
			{
				"code": 330600000000,
				"children": [
					{
						"code": 330601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330602000000,
						"value": "越城区",
						"label": "越城区"
					},
					{
						"code": 330603000000,
						"value": "柯桥区",
						"label": "柯桥区"
					},
					{
						"code": 330604000000,
						"value": "上虞区",
						"label": "上虞区"
					},
					{
						"code": 330624000000,
						"value": "新昌县",
						"label": "新昌县"
					},
					{
						"code": 330681000000,
						"value": "诸暨市",
						"label": "诸暨市"
					},
					{
						"code": 330683000000,
						"value": "嵊州市",
						"label": "嵊州市"
					}
				],
				"value": "绍兴市",
				"label": "绍兴市"
			},
			{
				"code": 330700000000,
				"children": [
					{
						"code": 330701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330702000000,
						"value": "婺城区",
						"label": "婺城区"
					},
					{
						"code": 330703000000,
						"value": "金东区",
						"label": "金东区"
					},
					{
						"code": 330723000000,
						"value": "武义县",
						"label": "武义县"
					},
					{
						"code": 330726000000,
						"value": "浦江县",
						"label": "浦江县"
					},
					{
						"code": 330727000000,
						"value": "磐安县",
						"label": "磐安县"
					},
					{
						"code": 330781000000,
						"value": "兰溪市",
						"label": "兰溪市"
					},
					{
						"code": 330782000000,
						"value": "义乌市",
						"label": "义乌市"
					},
					{
						"code": 330783000000,
						"value": "东阳市",
						"label": "东阳市"
					},
					{
						"code": 330784000000,
						"value": "永康市",
						"label": "永康市"
					}
				],
				"value": "金华市",
				"label": "金华市"
			},
			{
				"code": 330800000000,
				"children": [
					{
						"code": 330801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330802000000,
						"value": "柯城区",
						"label": "柯城区"
					},
					{
						"code": 330803000000,
						"value": "衢江区",
						"label": "衢江区"
					},
					{
						"code": 330822000000,
						"value": "常山县",
						"label": "常山县"
					},
					{
						"code": 330824000000,
						"value": "开化县",
						"label": "开化县"
					},
					{
						"code": 330825000000,
						"value": "龙游县",
						"label": "龙游县"
					},
					{
						"code": 330881000000,
						"value": "江山市",
						"label": "江山市"
					}
				],
				"value": "衢州市",
				"label": "衢州市"
			},
			{
				"code": 330900000000,
				"children": [
					{
						"code": 330901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 330902000000,
						"value": "定海区",
						"label": "定海区"
					},
					{
						"code": 330903000000,
						"value": "普陀区",
						"label": "普陀区"
					},
					{
						"code": 330921000000,
						"value": "岱山县",
						"label": "岱山县"
					},
					{
						"code": 330922000000,
						"value": "嵊泗县",
						"label": "嵊泗县"
					}
				],
				"value": "舟山市",
				"label": "舟山市"
			},
			{
				"code": 331000000000,
				"children": [
					{
						"code": 331001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 331002000000,
						"value": "椒江区",
						"label": "椒江区"
					},
					{
						"code": 331003000000,
						"value": "黄岩区",
						"label": "黄岩区"
					},
					{
						"code": 331004000000,
						"value": "路桥区",
						"label": "路桥区"
					},
					{
						"code": 331022000000,
						"value": "三门县",
						"label": "三门县"
					},
					{
						"code": 331023000000,
						"value": "天台县",
						"label": "天台县"
					},
					{
						"code": 331024000000,
						"value": "仙居县",
						"label": "仙居县"
					},
					{
						"code": 331081000000,
						"value": "温岭市",
						"label": "温岭市"
					},
					{
						"code": 331082000000,
						"value": "临海市",
						"label": "临海市"
					},
					{
						"code": 331083000000,
						"value": "玉环市",
						"label": "玉环市"
					}
				],
				"value": "台州市",
				"label": "台州市"
			},
			{
				"code": 331100000000,
				"children": [
					{
						"code": 331101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 331102000000,
						"value": "莲都区",
						"label": "莲都区"
					},
					{
						"code": 331121000000,
						"value": "青田县",
						"label": "青田县"
					},
					{
						"code": 331122000000,
						"value": "缙云县",
						"label": "缙云县"
					},
					{
						"code": 331123000000,
						"value": "遂昌县",
						"label": "遂昌县"
					},
					{
						"code": 331124000000,
						"value": "松阳县",
						"label": "松阳县"
					},
					{
						"code": 331125000000,
						"value": "云和县",
						"label": "云和县"
					},
					{
						"code": 331126000000,
						"value": "庆元县",
						"label": "庆元县"
					},
					{
						"code": 331127000000,
						"value": "景宁畲族自治县",
						"label": "景宁畲族自治县"
					},
					{
						"code": 331181000000,
						"value": "龙泉市",
						"label": "龙泉市"
					}
				],
				"value": "丽水市",
				"label": "丽水市"
			}
		],
		"value": "浙江省",
		"label": "浙江省"
	},
	{
		"code": 340000000000,
		"children": [
			{
				"code": 340100000000,
				"children": [
					{
						"code": 340101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340102000000,
						"value": "瑶海区",
						"label": "瑶海区"
					},
					{
						"code": 340103000000,
						"value": "庐阳区",
						"label": "庐阳区"
					},
					{
						"code": 340104000000,
						"value": "蜀山区",
						"label": "蜀山区"
					},
					{
						"code": 340111000000,
						"value": "包河区",
						"label": "包河区"
					},
					{
						"code": 340121000000,
						"value": "长丰县",
						"label": "长丰县"
					},
					{
						"code": 340122000000,
						"value": "肥东县",
						"label": "肥东县"
					},
					{
						"code": 340123000000,
						"value": "肥西县",
						"label": "肥西县"
					},
					{
						"code": 340124000000,
						"value": "庐江县",
						"label": "庐江县"
					},
					{
						"code": 340171000000,
						"value": "合肥高新技术产业开发区",
						"label": "合肥高新技术产业开发区"
					},
					{
						"code": 340172000000,
						"value": "合肥经济技术开发区",
						"label": "合肥经济技术开发区"
					},
					{
						"code": 340173000000,
						"value": "合肥新站高新技术产业开发区",
						"label": "合肥新站高新技术产业开发区"
					},
					{
						"code": 340181000000,
						"value": "巢湖市",
						"label": "巢湖市"
					}
				],
				"value": "合肥市",
				"label": "合肥市"
			},
			{
				"code": 340200000000,
				"children": [
					{
						"code": 340201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340202000000,
						"value": "镜湖区",
						"label": "镜湖区"
					},
					{
						"code": 340203000000,
						"value": "弋江区",
						"label": "弋江区"
					},
					{
						"code": 340207000000,
						"value": "鸠江区",
						"label": "鸠江区"
					},
					{
						"code": 340208000000,
						"value": "三山区",
						"label": "三山区"
					},
					{
						"code": 340221000000,
						"value": "芜湖县",
						"label": "芜湖县"
					},
					{
						"code": 340222000000,
						"value": "繁昌县",
						"label": "繁昌县"
					},
					{
						"code": 340223000000,
						"value": "南陵县",
						"label": "南陵县"
					},
					{
						"code": 340271000000,
						"value": "芜湖经济技术开发区",
						"label": "芜湖经济技术开发区"
					},
					{
						"code": 340272000000,
						"value": "安徽芜湖长江大桥经济开发区",
						"label": "安徽芜湖长江大桥经济开发区"
					},
					{
						"code": 340281000000,
						"value": "无为市",
						"label": "无为市"
					}
				],
				"value": "芜湖市",
				"label": "芜湖市"
			},
			{
				"code": 340300000000,
				"children": [
					{
						"code": 340301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340302000000,
						"value": "龙子湖区",
						"label": "龙子湖区"
					},
					{
						"code": 340303000000,
						"value": "蚌山区",
						"label": "蚌山区"
					},
					{
						"code": 340304000000,
						"value": "禹会区",
						"label": "禹会区"
					},
					{
						"code": 340311000000,
						"value": "淮上区",
						"label": "淮上区"
					},
					{
						"code": 340321000000,
						"value": "怀远县",
						"label": "怀远县"
					},
					{
						"code": 340322000000,
						"value": "五河县",
						"label": "五河县"
					},
					{
						"code": 340323000000,
						"value": "固镇县",
						"label": "固镇县"
					},
					{
						"code": 340371000000,
						"value": "蚌埠市高新技术开发区",
						"label": "蚌埠市高新技术开发区"
					},
					{
						"code": 340372000000,
						"value": "蚌埠市经济开发区",
						"label": "蚌埠市经济开发区"
					}
				],
				"value": "蚌埠市",
				"label": "蚌埠市"
			},
			{
				"code": 340400000000,
				"children": [
					{
						"code": 340401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340402000000,
						"value": "大通区",
						"label": "大通区"
					},
					{
						"code": 340403000000,
						"value": "田家庵区",
						"label": "田家庵区"
					},
					{
						"code": 340404000000,
						"value": "谢家集区",
						"label": "谢家集区"
					},
					{
						"code": 340405000000,
						"value": "八公山区",
						"label": "八公山区"
					},
					{
						"code": 340406000000,
						"value": "潘集区",
						"label": "潘集区"
					},
					{
						"code": 340421000000,
						"value": "凤台县",
						"label": "凤台县"
					},
					{
						"code": 340422000000,
						"value": "寿县",
						"label": "寿县"
					}
				],
				"value": "淮南市",
				"label": "淮南市"
			},
			{
				"code": 340500000000,
				"children": [
					{
						"code": 340501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340503000000,
						"value": "花山区",
						"label": "花山区"
					},
					{
						"code": 340504000000,
						"value": "雨山区",
						"label": "雨山区"
					},
					{
						"code": 340506000000,
						"value": "博望区",
						"label": "博望区"
					},
					{
						"code": 340521000000,
						"value": "当涂县",
						"label": "当涂县"
					},
					{
						"code": 340522000000,
						"value": "含山县",
						"label": "含山县"
					},
					{
						"code": 340523000000,
						"value": "和县",
						"label": "和县"
					}
				],
				"value": "马鞍山市",
				"label": "马鞍山市"
			},
			{
				"code": 340600000000,
				"children": [
					{
						"code": 340601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340602000000,
						"value": "杜集区",
						"label": "杜集区"
					},
					{
						"code": 340603000000,
						"value": "相山区",
						"label": "相山区"
					},
					{
						"code": 340604000000,
						"value": "烈山区",
						"label": "烈山区"
					},
					{
						"code": 340621000000,
						"value": "濉溪县",
						"label": "濉溪县"
					}
				],
				"value": "淮北市",
				"label": "淮北市"
			},
			{
				"code": 340700000000,
				"children": [
					{
						"code": 340701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340705000000,
						"value": "铜官区",
						"label": "铜官区"
					},
					{
						"code": 340706000000,
						"value": "义安区",
						"label": "义安区"
					},
					{
						"code": 340711000000,
						"value": "郊区",
						"label": "郊区"
					},
					{
						"code": 340722000000,
						"value": "枞阳县",
						"label": "枞阳县"
					}
				],
				"value": "铜陵市",
				"label": "铜陵市"
			},
			{
				"code": 340800000000,
				"children": [
					{
						"code": 340801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 340802000000,
						"value": "迎江区",
						"label": "迎江区"
					},
					{
						"code": 340803000000,
						"value": "大观区",
						"label": "大观区"
					},
					{
						"code": 340811000000,
						"value": "宜秀区",
						"label": "宜秀区"
					},
					{
						"code": 340822000000,
						"value": "怀宁县",
						"label": "怀宁县"
					},
					{
						"code": 340825000000,
						"value": "太湖县",
						"label": "太湖县"
					},
					{
						"code": 340826000000,
						"value": "宿松县",
						"label": "宿松县"
					},
					{
						"code": 340827000000,
						"value": "望江县",
						"label": "望江县"
					},
					{
						"code": 340828000000,
						"value": "岳西县",
						"label": "岳西县"
					},
					{
						"code": 340871000000,
						"value": "安徽安庆经济开发区",
						"label": "安徽安庆经济开发区"
					},
					{
						"code": 340881000000,
						"value": "桐城市",
						"label": "桐城市"
					},
					{
						"code": 340882000000,
						"value": "潜山市",
						"label": "潜山市"
					}
				],
				"value": "安庆市",
				"label": "安庆市"
			},
			{
				"code": 341000000000,
				"children": [
					{
						"code": 341001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341002000000,
						"value": "屯溪区",
						"label": "屯溪区"
					},
					{
						"code": 341003000000,
						"value": "黄山区",
						"label": "黄山区"
					},
					{
						"code": 341004000000,
						"value": "徽州区",
						"label": "徽州区"
					},
					{
						"code": 341021000000,
						"value": "歙县",
						"label": "歙县"
					},
					{
						"code": 341022000000,
						"value": "休宁县",
						"label": "休宁县"
					},
					{
						"code": 341023000000,
						"value": "黟县",
						"label": "黟县"
					},
					{
						"code": 341024000000,
						"value": "祁门县",
						"label": "祁门县"
					}
				],
				"value": "黄山市",
				"label": "黄山市"
			},
			{
				"code": 341100000000,
				"children": [
					{
						"code": 341101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341102000000,
						"value": "琅琊区",
						"label": "琅琊区"
					},
					{
						"code": 341103000000,
						"value": "南谯区",
						"label": "南谯区"
					},
					{
						"code": 341122000000,
						"value": "来安县",
						"label": "来安县"
					},
					{
						"code": 341124000000,
						"value": "全椒县",
						"label": "全椒县"
					},
					{
						"code": 341125000000,
						"value": "定远县",
						"label": "定远县"
					},
					{
						"code": 341126000000,
						"value": "凤阳县",
						"label": "凤阳县"
					},
					{
						"code": 341171000000,
						"value": "苏滁现代产业园",
						"label": "苏滁现代产业园"
					},
					{
						"code": 341172000000,
						"value": "滁州经济技术开发区",
						"label": "滁州经济技术开发区"
					},
					{
						"code": 341181000000,
						"value": "天长市",
						"label": "天长市"
					},
					{
						"code": 341182000000,
						"value": "明光市",
						"label": "明光市"
					}
				],
				"value": "滁州市",
				"label": "滁州市"
			},
			{
				"code": 341200000000,
				"children": [
					{
						"code": 341201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341202000000,
						"value": "颍州区",
						"label": "颍州区"
					},
					{
						"code": 341203000000,
						"value": "颍东区",
						"label": "颍东区"
					},
					{
						"code": 341204000000,
						"value": "颍泉区",
						"label": "颍泉区"
					},
					{
						"code": 341221000000,
						"value": "临泉县",
						"label": "临泉县"
					},
					{
						"code": 341222000000,
						"value": "太和县",
						"label": "太和县"
					},
					{
						"code": 341225000000,
						"value": "阜南县",
						"label": "阜南县"
					},
					{
						"code": 341226000000,
						"value": "颍上县",
						"label": "颍上县"
					},
					{
						"code": 341271000000,
						"value": "阜阳合肥现代产业园区",
						"label": "阜阳合肥现代产业园区"
					},
					{
						"code": 341272000000,
						"value": "阜阳经济技术开发区",
						"label": "阜阳经济技术开发区"
					},
					{
						"code": 341282000000,
						"value": "界首市",
						"label": "界首市"
					}
				],
				"value": "阜阳市",
				"label": "阜阳市"
			},
			{
				"code": 341300000000,
				"children": [
					{
						"code": 341301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341302000000,
						"value": "埇桥区",
						"label": "埇桥区"
					},
					{
						"code": 341321000000,
						"value": "砀山县",
						"label": "砀山县"
					},
					{
						"code": 341322000000,
						"value": "萧县",
						"label": "萧县"
					},
					{
						"code": 341323000000,
						"value": "灵璧县",
						"label": "灵璧县"
					},
					{
						"code": 341324000000,
						"value": "泗县",
						"label": "泗县"
					},
					{
						"code": 341371000000,
						"value": "宿州马鞍山现代产业园区",
						"label": "宿州马鞍山现代产业园区"
					},
					{
						"code": 341372000000,
						"value": "宿州经济技术开发区",
						"label": "宿州经济技术开发区"
					}
				],
				"value": "宿州市",
				"label": "宿州市"
			},
			{
				"code": 341500000000,
				"children": [
					{
						"code": 341501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341502000000,
						"value": "金安区",
						"label": "金安区"
					},
					{
						"code": 341503000000,
						"value": "裕安区",
						"label": "裕安区"
					},
					{
						"code": 341504000000,
						"value": "叶集区",
						"label": "叶集区"
					},
					{
						"code": 341522000000,
						"value": "霍邱县",
						"label": "霍邱县"
					},
					{
						"code": 341523000000,
						"value": "舒城县",
						"label": "舒城县"
					},
					{
						"code": 341524000000,
						"value": "金寨县",
						"label": "金寨县"
					},
					{
						"code": 341525000000,
						"value": "霍山县",
						"label": "霍山县"
					}
				],
				"value": "六安市",
				"label": "六安市"
			},
			{
				"code": 341600000000,
				"children": [
					{
						"code": 341601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341602000000,
						"value": "谯城区",
						"label": "谯城区"
					},
					{
						"code": 341621000000,
						"value": "涡阳县",
						"label": "涡阳县"
					},
					{
						"code": 341622000000,
						"value": "蒙城县",
						"label": "蒙城县"
					},
					{
						"code": 341623000000,
						"value": "利辛县",
						"label": "利辛县"
					}
				],
				"value": "亳州市",
				"label": "亳州市"
			},
			{
				"code": 341700000000,
				"children": [
					{
						"code": 341701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341702000000,
						"value": "贵池区",
						"label": "贵池区"
					},
					{
						"code": 341721000000,
						"value": "东至县",
						"label": "东至县"
					},
					{
						"code": 341722000000,
						"value": "石台县",
						"label": "石台县"
					},
					{
						"code": 341723000000,
						"value": "青阳县",
						"label": "青阳县"
					}
				],
				"value": "池州市",
				"label": "池州市"
			},
			{
				"code": 341800000000,
				"children": [
					{
						"code": 341801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 341802000000,
						"value": "宣州区",
						"label": "宣州区"
					},
					{
						"code": 341821000000,
						"value": "郎溪县",
						"label": "郎溪县"
					},
					{
						"code": 341823000000,
						"value": "泾县",
						"label": "泾县"
					},
					{
						"code": 341824000000,
						"value": "绩溪县",
						"label": "绩溪县"
					},
					{
						"code": 341825000000,
						"value": "旌德县",
						"label": "旌德县"
					},
					{
						"code": 341871000000,
						"value": "宣城市经济开发区",
						"label": "宣城市经济开发区"
					},
					{
						"code": 341881000000,
						"value": "宁国市",
						"label": "宁国市"
					},
					{
						"code": 341882000000,
						"value": "广德市",
						"label": "广德市"
					}
				],
				"value": "宣城市",
				"label": "宣城市"
			}
		],
		"value": "安徽省",
		"label": "安徽省"
	},
	{
		"code": 350000000000,
		"children": [
			{
				"code": 350100000000,
				"children": [
					{
						"code": 350101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350102000000,
						"value": "鼓楼区",
						"label": "鼓楼区"
					},
					{
						"code": 350103000000,
						"value": "台江区",
						"label": "台江区"
					},
					{
						"code": 350104000000,
						"value": "仓山区",
						"label": "仓山区"
					},
					{
						"code": 350105000000,
						"value": "马尾区",
						"label": "马尾区"
					},
					{
						"code": 350111000000,
						"value": "晋安区",
						"label": "晋安区"
					},
					{
						"code": 350112000000,
						"value": "长乐区",
						"label": "长乐区"
					},
					{
						"code": 350121000000,
						"value": "闽侯县",
						"label": "闽侯县"
					},
					{
						"code": 350122000000,
						"value": "连江县",
						"label": "连江县"
					},
					{
						"code": 350123000000,
						"value": "罗源县",
						"label": "罗源县"
					},
					{
						"code": 350124000000,
						"value": "闽清县",
						"label": "闽清县"
					},
					{
						"code": 350125000000,
						"value": "永泰县",
						"label": "永泰县"
					},
					{
						"code": 350128000000,
						"value": "平潭县",
						"label": "平潭县"
					},
					{
						"code": 350181000000,
						"value": "福清市",
						"label": "福清市"
					}
				],
				"value": "福州市",
				"label": "福州市"
			},
			{
				"code": 350200000000,
				"children": [
					{
						"code": 350201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350203000000,
						"value": "思明区",
						"label": "思明区"
					},
					{
						"code": 350205000000,
						"value": "海沧区",
						"label": "海沧区"
					},
					{
						"code": 350206000000,
						"value": "湖里区",
						"label": "湖里区"
					},
					{
						"code": 350211000000,
						"value": "集美区",
						"label": "集美区"
					},
					{
						"code": 350212000000,
						"value": "同安区",
						"label": "同安区"
					},
					{
						"code": 350213000000,
						"value": "翔安区",
						"label": "翔安区"
					}
				],
				"value": "厦门市",
				"label": "厦门市"
			},
			{
				"code": 350300000000,
				"children": [
					{
						"code": 350301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350302000000,
						"value": "城厢区",
						"label": "城厢区"
					},
					{
						"code": 350303000000,
						"value": "涵江区",
						"label": "涵江区"
					},
					{
						"code": 350304000000,
						"value": "荔城区",
						"label": "荔城区"
					},
					{
						"code": 350305000000,
						"value": "秀屿区",
						"label": "秀屿区"
					},
					{
						"code": 350322000000,
						"value": "仙游县",
						"label": "仙游县"
					}
				],
				"value": "莆田市",
				"label": "莆田市"
			},
			{
				"code": 350400000000,
				"children": [
					{
						"code": 350401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350402000000,
						"value": "梅列区",
						"label": "梅列区"
					},
					{
						"code": 350403000000,
						"value": "三元区",
						"label": "三元区"
					},
					{
						"code": 350421000000,
						"value": "明溪县",
						"label": "明溪县"
					},
					{
						"code": 350423000000,
						"value": "清流县",
						"label": "清流县"
					},
					{
						"code": 350424000000,
						"value": "宁化县",
						"label": "宁化县"
					},
					{
						"code": 350425000000,
						"value": "大田县",
						"label": "大田县"
					},
					{
						"code": 350426000000,
						"value": "尤溪县",
						"label": "尤溪县"
					},
					{
						"code": 350427000000,
						"value": "沙县",
						"label": "沙县"
					},
					{
						"code": 350428000000,
						"value": "将乐县",
						"label": "将乐县"
					},
					{
						"code": 350429000000,
						"value": "泰宁县",
						"label": "泰宁县"
					},
					{
						"code": 350430000000,
						"value": "建宁县",
						"label": "建宁县"
					},
					{
						"code": 350481000000,
						"value": "永安市",
						"label": "永安市"
					}
				],
				"value": "三明市",
				"label": "三明市"
			},
			{
				"code": 350500000000,
				"children": [
					{
						"code": 350501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350502000000,
						"value": "鲤城区",
						"label": "鲤城区"
					},
					{
						"code": 350503000000,
						"value": "丰泽区",
						"label": "丰泽区"
					},
					{
						"code": 350504000000,
						"value": "洛江区",
						"label": "洛江区"
					},
					{
						"code": 350505000000,
						"value": "泉港区",
						"label": "泉港区"
					},
					{
						"code": 350521000000,
						"value": "惠安县",
						"label": "惠安县"
					},
					{
						"code": 350524000000,
						"value": "安溪县",
						"label": "安溪县"
					},
					{
						"code": 350525000000,
						"value": "永春县",
						"label": "永春县"
					},
					{
						"code": 350526000000,
						"value": "德化县",
						"label": "德化县"
					},
					{
						"code": 350527000000,
						"value": "金门县",
						"label": "金门县"
					},
					{
						"code": 350581000000,
						"value": "石狮市",
						"label": "石狮市"
					},
					{
						"code": 350582000000,
						"value": "晋江市",
						"label": "晋江市"
					},
					{
						"code": 350583000000,
						"value": "南安市",
						"label": "南安市"
					}
				],
				"value": "泉州市",
				"label": "泉州市"
			},
			{
				"code": 350600000000,
				"children": [
					{
						"code": 350601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350602000000,
						"value": "芗城区",
						"label": "芗城区"
					},
					{
						"code": 350603000000,
						"value": "龙文区",
						"label": "龙文区"
					},
					{
						"code": 350622000000,
						"value": "云霄县",
						"label": "云霄县"
					},
					{
						"code": 350623000000,
						"value": "漳浦县",
						"label": "漳浦县"
					},
					{
						"code": 350624000000,
						"value": "诏安县",
						"label": "诏安县"
					},
					{
						"code": 350625000000,
						"value": "长泰县",
						"label": "长泰县"
					},
					{
						"code": 350626000000,
						"value": "东山县",
						"label": "东山县"
					},
					{
						"code": 350627000000,
						"value": "南靖县",
						"label": "南靖县"
					},
					{
						"code": 350628000000,
						"value": "平和县",
						"label": "平和县"
					},
					{
						"code": 350629000000,
						"value": "华安县",
						"label": "华安县"
					},
					{
						"code": 350681000000,
						"value": "龙海市",
						"label": "龙海市"
					}
				],
				"value": "漳州市",
				"label": "漳州市"
			},
			{
				"code": 350700000000,
				"children": [
					{
						"code": 350701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350702000000,
						"value": "延平区",
						"label": "延平区"
					},
					{
						"code": 350703000000,
						"value": "建阳区",
						"label": "建阳区"
					},
					{
						"code": 350721000000,
						"value": "顺昌县",
						"label": "顺昌县"
					},
					{
						"code": 350722000000,
						"value": "浦城县",
						"label": "浦城县"
					},
					{
						"code": 350723000000,
						"value": "光泽县",
						"label": "光泽县"
					},
					{
						"code": 350724000000,
						"value": "松溪县",
						"label": "松溪县"
					},
					{
						"code": 350725000000,
						"value": "政和县",
						"label": "政和县"
					},
					{
						"code": 350781000000,
						"value": "邵武市",
						"label": "邵武市"
					},
					{
						"code": 350782000000,
						"value": "武夷山市",
						"label": "武夷山市"
					},
					{
						"code": 350783000000,
						"value": "建瓯市",
						"label": "建瓯市"
					}
				],
				"value": "南平市",
				"label": "南平市"
			},
			{
				"code": 350800000000,
				"children": [
					{
						"code": 350801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350802000000,
						"value": "新罗区",
						"label": "新罗区"
					},
					{
						"code": 350803000000,
						"value": "永定区",
						"label": "永定区"
					},
					{
						"code": 350821000000,
						"value": "长汀县",
						"label": "长汀县"
					},
					{
						"code": 350823000000,
						"value": "上杭县",
						"label": "上杭县"
					},
					{
						"code": 350824000000,
						"value": "武平县",
						"label": "武平县"
					},
					{
						"code": 350825000000,
						"value": "连城县",
						"label": "连城县"
					},
					{
						"code": 350881000000,
						"value": "漳平市",
						"label": "漳平市"
					}
				],
				"value": "龙岩市",
				"label": "龙岩市"
			},
			{
				"code": 350900000000,
				"children": [
					{
						"code": 350901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 350902000000,
						"value": "蕉城区",
						"label": "蕉城区"
					},
					{
						"code": 350921000000,
						"value": "霞浦县",
						"label": "霞浦县"
					},
					{
						"code": 350922000000,
						"value": "古田县",
						"label": "古田县"
					},
					{
						"code": 350923000000,
						"value": "屏南县",
						"label": "屏南县"
					},
					{
						"code": 350924000000,
						"value": "寿宁县",
						"label": "寿宁县"
					},
					{
						"code": 350925000000,
						"value": "周宁县",
						"label": "周宁县"
					},
					{
						"code": 350926000000,
						"value": "柘荣县",
						"label": "柘荣县"
					},
					{
						"code": 350981000000,
						"value": "福安市",
						"label": "福安市"
					},
					{
						"code": 350982000000,
						"value": "福鼎市",
						"label": "福鼎市"
					}
				],
				"value": "宁德市",
				"label": "宁德市"
			}
		],
		"value": "福建省",
		"label": "福建省"
	},
	{
		"code": 360000000000,
		"children": [
			{
				"code": 360100000000,
				"children": [
					{
						"code": 360101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360102000000,
						"value": "东湖区",
						"label": "东湖区"
					},
					{
						"code": 360103000000,
						"value": "西湖区",
						"label": "西湖区"
					},
					{
						"code": 360104000000,
						"value": "青云谱区",
						"label": "青云谱区"
					},
					{
						"code": 360111000000,
						"value": "青山湖区",
						"label": "青山湖区"
					},
					{
						"code": 360112000000,
						"value": "新建区",
						"label": "新建区"
					},
					{
						"code": 360113000000,
						"value": "红谷滩区",
						"label": "红谷滩区"
					},
					{
						"code": 360121000000,
						"value": "南昌县",
						"label": "南昌县"
					},
					{
						"code": 360123000000,
						"value": "安义县",
						"label": "安义县"
					},
					{
						"code": 360124000000,
						"value": "进贤县",
						"label": "进贤县"
					}
				],
				"value": "南昌市",
				"label": "南昌市"
			},
			{
				"code": 360200000000,
				"children": [
					{
						"code": 360201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360202000000,
						"value": "昌江区",
						"label": "昌江区"
					},
					{
						"code": 360203000000,
						"value": "珠山区",
						"label": "珠山区"
					},
					{
						"code": 360222000000,
						"value": "浮梁县",
						"label": "浮梁县"
					},
					{
						"code": 360281000000,
						"value": "乐平市",
						"label": "乐平市"
					}
				],
				"value": "景德镇市",
				"label": "景德镇市"
			},
			{
				"code": 360300000000,
				"children": [
					{
						"code": 360301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360302000000,
						"value": "安源区",
						"label": "安源区"
					},
					{
						"code": 360313000000,
						"value": "湘东区",
						"label": "湘东区"
					},
					{
						"code": 360321000000,
						"value": "莲花县",
						"label": "莲花县"
					},
					{
						"code": 360322000000,
						"value": "上栗县",
						"label": "上栗县"
					},
					{
						"code": 360323000000,
						"value": "芦溪县",
						"label": "芦溪县"
					}
				],
				"value": "萍乡市",
				"label": "萍乡市"
			},
			{
				"code": 360400000000,
				"children": [
					{
						"code": 360401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360402000000,
						"value": "濂溪区",
						"label": "濂溪区"
					},
					{
						"code": 360403000000,
						"value": "浔阳区",
						"label": "浔阳区"
					},
					{
						"code": 360404000000,
						"value": "柴桑区",
						"label": "柴桑区"
					},
					{
						"code": 360423000000,
						"value": "武宁县",
						"label": "武宁县"
					},
					{
						"code": 360424000000,
						"value": "修水县",
						"label": "修水县"
					},
					{
						"code": 360425000000,
						"value": "永修县",
						"label": "永修县"
					},
					{
						"code": 360426000000,
						"value": "德安县",
						"label": "德安县"
					},
					{
						"code": 360428000000,
						"value": "都昌县",
						"label": "都昌县"
					},
					{
						"code": 360429000000,
						"value": "湖口县",
						"label": "湖口县"
					},
					{
						"code": 360430000000,
						"value": "彭泽县",
						"label": "彭泽县"
					},
					{
						"code": 360481000000,
						"value": "瑞昌市",
						"label": "瑞昌市"
					},
					{
						"code": 360482000000,
						"value": "共青城市",
						"label": "共青城市"
					},
					{
						"code": 360483000000,
						"value": "庐山市",
						"label": "庐山市"
					}
				],
				"value": "九江市",
				"label": "九江市"
			},
			{
				"code": 360500000000,
				"children": [
					{
						"code": 360501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360502000000,
						"value": "渝水区",
						"label": "渝水区"
					},
					{
						"code": 360521000000,
						"value": "分宜县",
						"label": "分宜县"
					}
				],
				"value": "新余市",
				"label": "新余市"
			},
			{
				"code": 360600000000,
				"children": [
					{
						"code": 360601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360602000000,
						"value": "月湖区",
						"label": "月湖区"
					},
					{
						"code": 360603000000,
						"value": "余江区",
						"label": "余江区"
					},
					{
						"code": 360681000000,
						"value": "贵溪市",
						"label": "贵溪市"
					}
				],
				"value": "鹰潭市",
				"label": "鹰潭市"
			},
			{
				"code": 360700000000,
				"children": [
					{
						"code": 360701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360702000000,
						"value": "章贡区",
						"label": "章贡区"
					},
					{
						"code": 360703000000,
						"value": "南康区",
						"label": "南康区"
					},
					{
						"code": 360704000000,
						"value": "赣县区",
						"label": "赣县区"
					},
					{
						"code": 360722000000,
						"value": "信丰县",
						"label": "信丰县"
					},
					{
						"code": 360723000000,
						"value": "大余县",
						"label": "大余县"
					},
					{
						"code": 360724000000,
						"value": "上犹县",
						"label": "上犹县"
					},
					{
						"code": 360725000000,
						"value": "崇义县",
						"label": "崇义县"
					},
					{
						"code": 360726000000,
						"value": "安远县",
						"label": "安远县"
					},
					{
						"code": 360728000000,
						"value": "定南县",
						"label": "定南县"
					},
					{
						"code": 360729000000,
						"value": "全南县",
						"label": "全南县"
					},
					{
						"code": 360730000000,
						"value": "宁都县",
						"label": "宁都县"
					},
					{
						"code": 360731000000,
						"value": "于都县",
						"label": "于都县"
					},
					{
						"code": 360732000000,
						"value": "兴国县",
						"label": "兴国县"
					},
					{
						"code": 360733000000,
						"value": "会昌县",
						"label": "会昌县"
					},
					{
						"code": 360734000000,
						"value": "寻乌县",
						"label": "寻乌县"
					},
					{
						"code": 360735000000,
						"value": "石城县",
						"label": "石城县"
					},
					{
						"code": 360781000000,
						"value": "瑞金市",
						"label": "瑞金市"
					},
					{
						"code": 360783000000,
						"value": "龙南市",
						"label": "龙南市"
					}
				],
				"value": "赣州市",
				"label": "赣州市"
			},
			{
				"code": 360800000000,
				"children": [
					{
						"code": 360801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360802000000,
						"value": "吉州区",
						"label": "吉州区"
					},
					{
						"code": 360803000000,
						"value": "青原区",
						"label": "青原区"
					},
					{
						"code": 360821000000,
						"value": "吉安县",
						"label": "吉安县"
					},
					{
						"code": 360822000000,
						"value": "吉水县",
						"label": "吉水县"
					},
					{
						"code": 360823000000,
						"value": "峡江县",
						"label": "峡江县"
					},
					{
						"code": 360824000000,
						"value": "新干县",
						"label": "新干县"
					},
					{
						"code": 360825000000,
						"value": "永丰县",
						"label": "永丰县"
					},
					{
						"code": 360826000000,
						"value": "泰和县",
						"label": "泰和县"
					},
					{
						"code": 360827000000,
						"value": "遂川县",
						"label": "遂川县"
					},
					{
						"code": 360828000000,
						"value": "万安县",
						"label": "万安县"
					},
					{
						"code": 360829000000,
						"value": "安福县",
						"label": "安福县"
					},
					{
						"code": 360830000000,
						"value": "永新县",
						"label": "永新县"
					},
					{
						"code": 360881000000,
						"value": "井冈山市",
						"label": "井冈山市"
					}
				],
				"value": "吉安市",
				"label": "吉安市"
			},
			{
				"code": 360900000000,
				"children": [
					{
						"code": 360901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 360902000000,
						"value": "袁州区",
						"label": "袁州区"
					},
					{
						"code": 360921000000,
						"value": "奉新县",
						"label": "奉新县"
					},
					{
						"code": 360922000000,
						"value": "万载县",
						"label": "万载县"
					},
					{
						"code": 360923000000,
						"value": "上高县",
						"label": "上高县"
					},
					{
						"code": 360924000000,
						"value": "宜丰县",
						"label": "宜丰县"
					},
					{
						"code": 360925000000,
						"value": "靖安县",
						"label": "靖安县"
					},
					{
						"code": 360926000000,
						"value": "铜鼓县",
						"label": "铜鼓县"
					},
					{
						"code": 360981000000,
						"value": "丰城市",
						"label": "丰城市"
					},
					{
						"code": 360982000000,
						"value": "樟树市",
						"label": "樟树市"
					},
					{
						"code": 360983000000,
						"value": "高安市",
						"label": "高安市"
					}
				],
				"value": "宜春市",
				"label": "宜春市"
			},
			{
				"code": 361000000000,
				"children": [
					{
						"code": 361001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 361002000000,
						"value": "临川区",
						"label": "临川区"
					},
					{
						"code": 361003000000,
						"value": "东乡区",
						"label": "东乡区"
					},
					{
						"code": 361021000000,
						"value": "南城县",
						"label": "南城县"
					},
					{
						"code": 361022000000,
						"value": "黎川县",
						"label": "黎川县"
					},
					{
						"code": 361023000000,
						"value": "南丰县",
						"label": "南丰县"
					},
					{
						"code": 361024000000,
						"value": "崇仁县",
						"label": "崇仁县"
					},
					{
						"code": 361025000000,
						"value": "乐安县",
						"label": "乐安县"
					},
					{
						"code": 361026000000,
						"value": "宜黄县",
						"label": "宜黄县"
					},
					{
						"code": 361027000000,
						"value": "金溪县",
						"label": "金溪县"
					},
					{
						"code": 361028000000,
						"value": "资溪县",
						"label": "资溪县"
					},
					{
						"code": 361030000000,
						"value": "广昌县",
						"label": "广昌县"
					}
				],
				"value": "抚州市",
				"label": "抚州市"
			},
			{
				"code": 361100000000,
				"children": [
					{
						"code": 361101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 361102000000,
						"value": "信州区",
						"label": "信州区"
					},
					{
						"code": 361103000000,
						"value": "广丰区",
						"label": "广丰区"
					},
					{
						"code": 361104000000,
						"value": "广信区",
						"label": "广信区"
					},
					{
						"code": 361123000000,
						"value": "玉山县",
						"label": "玉山县"
					},
					{
						"code": 361124000000,
						"value": "铅山县",
						"label": "铅山县"
					},
					{
						"code": 361125000000,
						"value": "横峰县",
						"label": "横峰县"
					},
					{
						"code": 361126000000,
						"value": "弋阳县",
						"label": "弋阳县"
					},
					{
						"code": 361127000000,
						"value": "余干县",
						"label": "余干县"
					},
					{
						"code": 361128000000,
						"value": "鄱阳县",
						"label": "鄱阳县"
					},
					{
						"code": 361129000000,
						"value": "万年县",
						"label": "万年县"
					},
					{
						"code": 361130000000,
						"value": "婺源县",
						"label": "婺源县"
					},
					{
						"code": 361181000000,
						"value": "德兴市",
						"label": "德兴市"
					}
				],
				"value": "上饶市",
				"label": "上饶市"
			}
		],
		"value": "江西省",
		"label": "江西省"
	},
	{
		"code": 370000000000,
		"children": [
			{
				"code": 370100000000,
				"children": [
					{
						"code": 370101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370102000000,
						"value": "历下区",
						"label": "历下区"
					},
					{
						"code": 370103000000,
						"value": "市中区",
						"label": "市中区"
					},
					{
						"code": 370104000000,
						"value": "槐荫区",
						"label": "槐荫区"
					},
					{
						"code": 370105000000,
						"value": "天桥区",
						"label": "天桥区"
					},
					{
						"code": 370112000000,
						"value": "历城区",
						"label": "历城区"
					},
					{
						"code": 370113000000,
						"value": "长清区",
						"label": "长清区"
					},
					{
						"code": 370114000000,
						"value": "章丘区",
						"label": "章丘区"
					},
					{
						"code": 370115000000,
						"value": "济阳区",
						"label": "济阳区"
					},
					{
						"code": 370116000000,
						"value": "莱芜区",
						"label": "莱芜区"
					},
					{
						"code": 370117000000,
						"value": "钢城区",
						"label": "钢城区"
					},
					{
						"code": 370124000000,
						"value": "平阴县",
						"label": "平阴县"
					},
					{
						"code": 370126000000,
						"value": "商河县",
						"label": "商河县"
					},
					{
						"code": 370171000000,
						"value": "济南高新技术产业开发区",
						"label": "济南高新技术产业开发区"
					}
				],
				"value": "济南市",
				"label": "济南市"
			},
			{
				"code": 370200000000,
				"children": [
					{
						"code": 370201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370202000000,
						"value": "市南区",
						"label": "市南区"
					},
					{
						"code": 370203000000,
						"value": "市北区",
						"label": "市北区"
					},
					{
						"code": 370211000000,
						"value": "黄岛区",
						"label": "黄岛区"
					},
					{
						"code": 370212000000,
						"value": "崂山区",
						"label": "崂山区"
					},
					{
						"code": 370213000000,
						"value": "李沧区",
						"label": "李沧区"
					},
					{
						"code": 370214000000,
						"value": "城阳区",
						"label": "城阳区"
					},
					{
						"code": 370215000000,
						"value": "即墨区",
						"label": "即墨区"
					},
					{
						"code": 370271000000,
						"value": "青岛高新技术产业开发区",
						"label": "青岛高新技术产业开发区"
					},
					{
						"code": 370281000000,
						"value": "胶州市",
						"label": "胶州市"
					},
					{
						"code": 370283000000,
						"value": "平度市",
						"label": "平度市"
					},
					{
						"code": 370285000000,
						"value": "莱西市",
						"label": "莱西市"
					}
				],
				"value": "青岛市",
				"label": "青岛市"
			},
			{
				"code": 370300000000,
				"children": [
					{
						"code": 370301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370302000000,
						"value": "淄川区",
						"label": "淄川区"
					},
					{
						"code": 370303000000,
						"value": "张店区",
						"label": "张店区"
					},
					{
						"code": 370304000000,
						"value": "博山区",
						"label": "博山区"
					},
					{
						"code": 370305000000,
						"value": "临淄区",
						"label": "临淄区"
					},
					{
						"code": 370306000000,
						"value": "周村区",
						"label": "周村区"
					},
					{
						"code": 370321000000,
						"value": "桓台县",
						"label": "桓台县"
					},
					{
						"code": 370322000000,
						"value": "高青县",
						"label": "高青县"
					},
					{
						"code": 370323000000,
						"value": "沂源县",
						"label": "沂源县"
					}
				],
				"value": "淄博市",
				"label": "淄博市"
			},
			{
				"code": 370400000000,
				"children": [
					{
						"code": 370401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370402000000,
						"value": "市中区",
						"label": "市中区"
					},
					{
						"code": 370403000000,
						"value": "薛城区",
						"label": "薛城区"
					},
					{
						"code": 370404000000,
						"value": "峄城区",
						"label": "峄城区"
					},
					{
						"code": 370405000000,
						"value": "台儿庄区",
						"label": "台儿庄区"
					},
					{
						"code": 370406000000,
						"value": "山亭区",
						"label": "山亭区"
					},
					{
						"code": 370481000000,
						"value": "滕州市",
						"label": "滕州市"
					}
				],
				"value": "枣庄市",
				"label": "枣庄市"
			},
			{
				"code": 370500000000,
				"children": [
					{
						"code": 370501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370502000000,
						"value": "东营区",
						"label": "东营区"
					},
					{
						"code": 370503000000,
						"value": "河口区",
						"label": "河口区"
					},
					{
						"code": 370505000000,
						"value": "垦利区",
						"label": "垦利区"
					},
					{
						"code": 370522000000,
						"value": "利津县",
						"label": "利津县"
					},
					{
						"code": 370523000000,
						"value": "广饶县",
						"label": "广饶县"
					},
					{
						"code": 370571000000,
						"value": "东营经济技术开发区",
						"label": "东营经济技术开发区"
					},
					{
						"code": 370572000000,
						"value": "东营港经济开发区",
						"label": "东营港经济开发区"
					}
				],
				"value": "东营市",
				"label": "东营市"
			},
			{
				"code": 370600000000,
				"children": [
					{
						"code": 370601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370602000000,
						"value": "芝罘区",
						"label": "芝罘区"
					},
					{
						"code": 370611000000,
						"value": "福山区",
						"label": "福山区"
					},
					{
						"code": 370612000000,
						"value": "牟平区",
						"label": "牟平区"
					},
					{
						"code": 370613000000,
						"value": "莱山区",
						"label": "莱山区"
					},
					{
						"code": 370614000000,
						"value": "蓬莱区",
						"label": "蓬莱区"
					},
					{
						"code": 370671000000,
						"value": "烟台高新技术产业开发区",
						"label": "烟台高新技术产业开发区"
					},
					{
						"code": 370672000000,
						"value": "烟台经济技术开发区",
						"label": "烟台经济技术开发区"
					},
					{
						"code": 370681000000,
						"value": "龙口市",
						"label": "龙口市"
					},
					{
						"code": 370682000000,
						"value": "莱阳市",
						"label": "莱阳市"
					},
					{
						"code": 370683000000,
						"value": "莱州市",
						"label": "莱州市"
					},
					{
						"code": 370685000000,
						"value": "招远市",
						"label": "招远市"
					},
					{
						"code": 370686000000,
						"value": "栖霞市",
						"label": "栖霞市"
					},
					{
						"code": 370687000000,
						"value": "海阳市",
						"label": "海阳市"
					}
				],
				"value": "烟台市",
				"label": "烟台市"
			},
			{
				"code": 370700000000,
				"children": [
					{
						"code": 370701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370702000000,
						"value": "潍城区",
						"label": "潍城区"
					},
					{
						"code": 370703000000,
						"value": "寒亭区",
						"label": "寒亭区"
					},
					{
						"code": 370704000000,
						"value": "坊子区",
						"label": "坊子区"
					},
					{
						"code": 370705000000,
						"value": "奎文区",
						"label": "奎文区"
					},
					{
						"code": 370724000000,
						"value": "临朐县",
						"label": "临朐县"
					},
					{
						"code": 370725000000,
						"value": "昌乐县",
						"label": "昌乐县"
					},
					{
						"code": 370772000000,
						"value": "潍坊滨海经济技术开发区",
						"label": "潍坊滨海经济技术开发区"
					},
					{
						"code": 370781000000,
						"value": "青州市",
						"label": "青州市"
					},
					{
						"code": 370782000000,
						"value": "诸城市",
						"label": "诸城市"
					},
					{
						"code": 370783000000,
						"value": "寿光市",
						"label": "寿光市"
					},
					{
						"code": 370784000000,
						"value": "安丘市",
						"label": "安丘市"
					},
					{
						"code": 370785000000,
						"value": "高密市",
						"label": "高密市"
					},
					{
						"code": 370786000000,
						"value": "昌邑市",
						"label": "昌邑市"
					}
				],
				"value": "潍坊市",
				"label": "潍坊市"
			},
			{
				"code": 370800000000,
				"children": [
					{
						"code": 370801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370811000000,
						"value": "任城区",
						"label": "任城区"
					},
					{
						"code": 370812000000,
						"value": "兖州区",
						"label": "兖州区"
					},
					{
						"code": 370826000000,
						"value": "微山县",
						"label": "微山县"
					},
					{
						"code": 370827000000,
						"value": "鱼台县",
						"label": "鱼台县"
					},
					{
						"code": 370828000000,
						"value": "金乡县",
						"label": "金乡县"
					},
					{
						"code": 370829000000,
						"value": "嘉祥县",
						"label": "嘉祥县"
					},
					{
						"code": 370830000000,
						"value": "汶上县",
						"label": "汶上县"
					},
					{
						"code": 370831000000,
						"value": "泗水县",
						"label": "泗水县"
					},
					{
						"code": 370832000000,
						"value": "梁山县",
						"label": "梁山县"
					},
					{
						"code": 370871000000,
						"value": "济宁高新技术产业开发区",
						"label": "济宁高新技术产业开发区"
					},
					{
						"code": 370881000000,
						"value": "曲阜市",
						"label": "曲阜市"
					},
					{
						"code": 370883000000,
						"value": "邹城市",
						"label": "邹城市"
					}
				],
				"value": "济宁市",
				"label": "济宁市"
			},
			{
				"code": 370900000000,
				"children": [
					{
						"code": 370901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 370902000000,
						"value": "泰山区",
						"label": "泰山区"
					},
					{
						"code": 370911000000,
						"value": "岱岳区",
						"label": "岱岳区"
					},
					{
						"code": 370921000000,
						"value": "宁阳县",
						"label": "宁阳县"
					},
					{
						"code": 370923000000,
						"value": "东平县",
						"label": "东平县"
					},
					{
						"code": 370982000000,
						"value": "新泰市",
						"label": "新泰市"
					},
					{
						"code": 370983000000,
						"value": "肥城市",
						"label": "肥城市"
					}
				],
				"value": "泰安市",
				"label": "泰安市"
			},
			{
				"code": 371000000000,
				"children": [
					{
						"code": 371001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 371002000000,
						"value": "环翠区",
						"label": "环翠区"
					},
					{
						"code": 371003000000,
						"value": "文登区",
						"label": "文登区"
					},
					{
						"code": 371071000000,
						"value": "威海火炬高技术产业开发区",
						"label": "威海火炬高技术产业开发区"
					},
					{
						"code": 371072000000,
						"value": "威海经济技术开发区",
						"label": "威海经济技术开发区"
					},
					{
						"code": 371073000000,
						"value": "威海临港经济技术开发区",
						"label": "威海临港经济技术开发区"
					},
					{
						"code": 371082000000,
						"value": "荣成市",
						"label": "荣成市"
					},
					{
						"code": 371083000000,
						"value": "乳山市",
						"label": "乳山市"
					}
				],
				"value": "威海市",
				"label": "威海市"
			},
			{
				"code": 371100000000,
				"children": [
					{
						"code": 371101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 371102000000,
						"value": "东港区",
						"label": "东港区"
					},
					{
						"code": 371103000000,
						"value": "岚山区",
						"label": "岚山区"
					},
					{
						"code": 371121000000,
						"value": "五莲县",
						"label": "五莲县"
					},
					{
						"code": 371122000000,
						"value": "莒县",
						"label": "莒县"
					},
					{
						"code": 371171000000,
						"value": "日照经济技术开发区",
						"label": "日照经济技术开发区"
					}
				],
				"value": "日照市",
				"label": "日照市"
			},
			{
				"code": 371300000000,
				"children": [
					{
						"code": 371301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 371302000000,
						"value": "兰山区",
						"label": "兰山区"
					},
					{
						"code": 371311000000,
						"value": "罗庄区",
						"label": "罗庄区"
					},
					{
						"code": 371312000000,
						"value": "河东区",
						"label": "河东区"
					},
					{
						"code": 371321000000,
						"value": "沂南县",
						"label": "沂南县"
					},
					{
						"code": 371322000000,
						"value": "郯城县",
						"label": "郯城县"
					},
					{
						"code": 371323000000,
						"value": "沂水县",
						"label": "沂水县"
					},
					{
						"code": 371324000000,
						"value": "兰陵县",
						"label": "兰陵县"
					},
					{
						"code": 371325000000,
						"value": "费县",
						"label": "费县"
					},
					{
						"code": 371326000000,
						"value": "平邑县",
						"label": "平邑县"
					},
					{
						"code": 371327000000,
						"value": "莒南县",
						"label": "莒南县"
					},
					{
						"code": 371328000000,
						"value": "蒙阴县",
						"label": "蒙阴县"
					},
					{
						"code": 371329000000,
						"value": "临沭县",
						"label": "临沭县"
					},
					{
						"code": 371371000000,
						"value": "临沂高新技术产业开发区",
						"label": "临沂高新技术产业开发区"
					}
				],
				"value": "临沂市",
				"label": "临沂市"
			},
			{
				"code": 371400000000,
				"children": [
					{
						"code": 371401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 371402000000,
						"value": "德城区",
						"label": "德城区"
					},
					{
						"code": 371403000000,
						"value": "陵城区",
						"label": "陵城区"
					},
					{
						"code": 371422000000,
						"value": "宁津县",
						"label": "宁津县"
					},
					{
						"code": 371423000000,
						"value": "庆云县",
						"label": "庆云县"
					},
					{
						"code": 371424000000,
						"value": "临邑县",
						"label": "临邑县"
					},
					{
						"code": 371425000000,
						"value": "齐河县",
						"label": "齐河县"
					},
					{
						"code": 371426000000,
						"value": "平原县",
						"label": "平原县"
					},
					{
						"code": 371427000000,
						"value": "夏津县",
						"label": "夏津县"
					},
					{
						"code": 371428000000,
						"value": "武城县",
						"label": "武城县"
					},
					{
						"code": 371471000000,
						"value": "德州经济技术开发区",
						"label": "德州经济技术开发区"
					},
					{
						"code": 371472000000,
						"value": "德州运河经济开发区",
						"label": "德州运河经济开发区"
					},
					{
						"code": 371481000000,
						"value": "乐陵市",
						"label": "乐陵市"
					},
					{
						"code": 371482000000,
						"value": "禹城市",
						"label": "禹城市"
					}
				],
				"value": "德州市",
				"label": "德州市"
			},
			{
				"code": 371500000000,
				"children": [
					{
						"code": 371501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 371502000000,
						"value": "东昌府区",
						"label": "东昌府区"
					},
					{
						"code": 371503000000,
						"value": "茌平区",
						"label": "茌平区"
					},
					{
						"code": 371521000000,
						"value": "阳谷县",
						"label": "阳谷县"
					},
					{
						"code": 371522000000,
						"value": "莘县",
						"label": "莘县"
					},
					{
						"code": 371524000000,
						"value": "东阿县",
						"label": "东阿县"
					},
					{
						"code": 371525000000,
						"value": "冠县",
						"label": "冠县"
					},
					{
						"code": 371526000000,
						"value": "高唐县",
						"label": "高唐县"
					},
					{
						"code": 371581000000,
						"value": "临清市",
						"label": "临清市"
					}
				],
				"value": "聊城市",
				"label": "聊城市"
			},
			{
				"code": 371600000000,
				"children": [
					{
						"code": 371601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 371602000000,
						"value": "滨城区",
						"label": "滨城区"
					},
					{
						"code": 371603000000,
						"value": "沾化区",
						"label": "沾化区"
					},
					{
						"code": 371621000000,
						"value": "惠民县",
						"label": "惠民县"
					},
					{
						"code": 371622000000,
						"value": "阳信县",
						"label": "阳信县"
					},
					{
						"code": 371623000000,
						"value": "无棣县",
						"label": "无棣县"
					},
					{
						"code": 371625000000,
						"value": "博兴县",
						"label": "博兴县"
					},
					{
						"code": 371681000000,
						"value": "邹平市",
						"label": "邹平市"
					}
				],
				"value": "滨州市",
				"label": "滨州市"
			},
			{
				"code": 371700000000,
				"children": [
					{
						"code": 371701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 371702000000,
						"value": "牡丹区",
						"label": "牡丹区"
					},
					{
						"code": 371703000000,
						"value": "定陶区",
						"label": "定陶区"
					},
					{
						"code": 371721000000,
						"value": "曹县",
						"label": "曹县"
					},
					{
						"code": 371722000000,
						"value": "单县",
						"label": "单县"
					},
					{
						"code": 371723000000,
						"value": "成武县",
						"label": "成武县"
					},
					{
						"code": 371724000000,
						"value": "巨野县",
						"label": "巨野县"
					},
					{
						"code": 371725000000,
						"value": "郓城县",
						"label": "郓城县"
					},
					{
						"code": 371726000000,
						"value": "鄄城县",
						"label": "鄄城县"
					},
					{
						"code": 371728000000,
						"value": "东明县",
						"label": "东明县"
					},
					{
						"code": 371771000000,
						"value": "菏泽经济技术开发区",
						"label": "菏泽经济技术开发区"
					},
					{
						"code": 371772000000,
						"value": "菏泽高新技术开发区",
						"label": "菏泽高新技术开发区"
					}
				],
				"value": "菏泽市",
				"label": "菏泽市"
			}
		],
		"value": "山东省",
		"label": "山东省"
	},
	{
		"code": 410000000000,
		"children": [
			{
				"code": 410100000000,
				"children": [
					{
						"code": 410101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410102000000,
						"value": "中原区",
						"label": "中原区"
					},
					{
						"code": 410103000000,
						"value": "二七区",
						"label": "二七区"
					},
					{
						"code": 410104000000,
						"value": "管城回族区",
						"label": "管城回族区"
					},
					{
						"code": 410105000000,
						"value": "金水区",
						"label": "金水区"
					},
					{
						"code": 410106000000,
						"value": "上街区",
						"label": "上街区"
					},
					{
						"code": 410108000000,
						"value": "惠济区",
						"label": "惠济区"
					},
					{
						"code": 410122000000,
						"value": "中牟县",
						"label": "中牟县"
					},
					{
						"code": 410171000000,
						"value": "郑州经济技术开发区",
						"label": "郑州经济技术开发区"
					},
					{
						"code": 410172000000,
						"value": "郑州高新技术产业开发区",
						"label": "郑州高新技术产业开发区"
					},
					{
						"code": 410173000000,
						"value": "郑州航空港经济综合实验区",
						"label": "郑州航空港经济综合实验区"
					},
					{
						"code": 410181000000,
						"value": "巩义市",
						"label": "巩义市"
					},
					{
						"code": 410182000000,
						"value": "荥阳市",
						"label": "荥阳市"
					},
					{
						"code": 410183000000,
						"value": "新密市",
						"label": "新密市"
					},
					{
						"code": 410184000000,
						"value": "新郑市",
						"label": "新郑市"
					},
					{
						"code": 410185000000,
						"value": "登封市",
						"label": "登封市"
					}
				],
				"value": "郑州市",
				"label": "郑州市"
			},
			{
				"code": 410200000000,
				"children": [
					{
						"code": 410201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410202000000,
						"value": "龙亭区",
						"label": "龙亭区"
					},
					{
						"code": 410203000000,
						"value": "顺河回族区",
						"label": "顺河回族区"
					},
					{
						"code": 410204000000,
						"value": "鼓楼区",
						"label": "鼓楼区"
					},
					{
						"code": 410205000000,
						"value": "禹王台区",
						"label": "禹王台区"
					},
					{
						"code": 410212000000,
						"value": "祥符区",
						"label": "祥符区"
					},
					{
						"code": 410221000000,
						"value": "杞县",
						"label": "杞县"
					},
					{
						"code": 410222000000,
						"value": "通许县",
						"label": "通许县"
					},
					{
						"code": 410223000000,
						"value": "尉氏县",
						"label": "尉氏县"
					},
					{
						"code": 410225000000,
						"value": "兰考县",
						"label": "兰考县"
					}
				],
				"value": "开封市",
				"label": "开封市"
			},
			{
				"code": 410300000000,
				"children": [
					{
						"code": 410301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410302000000,
						"value": "老城区",
						"label": "老城区"
					},
					{
						"code": 410303000000,
						"value": "西工区",
						"label": "西工区"
					},
					{
						"code": 410304000000,
						"value": "瀍河回族区",
						"label": "瀍河回族区"
					},
					{
						"code": 410305000000,
						"value": "涧西区",
						"label": "涧西区"
					},
					{
						"code": 410306000000,
						"value": "吉利区",
						"label": "吉利区"
					},
					{
						"code": 410311000000,
						"value": "洛龙区",
						"label": "洛龙区"
					},
					{
						"code": 410322000000,
						"value": "孟津县",
						"label": "孟津县"
					},
					{
						"code": 410323000000,
						"value": "新安县",
						"label": "新安县"
					},
					{
						"code": 410324000000,
						"value": "栾川县",
						"label": "栾川县"
					},
					{
						"code": 410325000000,
						"value": "嵩县",
						"label": "嵩县"
					},
					{
						"code": 410326000000,
						"value": "汝阳县",
						"label": "汝阳县"
					},
					{
						"code": 410327000000,
						"value": "宜阳县",
						"label": "宜阳县"
					},
					{
						"code": 410328000000,
						"value": "洛宁县",
						"label": "洛宁县"
					},
					{
						"code": 410329000000,
						"value": "伊川县",
						"label": "伊川县"
					},
					{
						"code": 410371000000,
						"value": "洛阳高新技术产业开发区",
						"label": "洛阳高新技术产业开发区"
					},
					{
						"code": 410381000000,
						"value": "偃师市",
						"label": "偃师市"
					}
				],
				"value": "洛阳市",
				"label": "洛阳市"
			},
			{
				"code": 410400000000,
				"children": [
					{
						"code": 410401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410402000000,
						"value": "新华区",
						"label": "新华区"
					},
					{
						"code": 410403000000,
						"value": "卫东区",
						"label": "卫东区"
					},
					{
						"code": 410404000000,
						"value": "石龙区",
						"label": "石龙区"
					},
					{
						"code": 410411000000,
						"value": "湛河区",
						"label": "湛河区"
					},
					{
						"code": 410421000000,
						"value": "宝丰县",
						"label": "宝丰县"
					},
					{
						"code": 410422000000,
						"value": "叶县",
						"label": "叶县"
					},
					{
						"code": 410423000000,
						"value": "鲁山县",
						"label": "鲁山县"
					},
					{
						"code": 410425000000,
						"value": "郏县",
						"label": "郏县"
					},
					{
						"code": 410471000000,
						"value": "平顶山高新技术产业开发区",
						"label": "平顶山高新技术产业开发区"
					},
					{
						"code": 410472000000,
						"value": "平顶山市城乡一体化示范区",
						"label": "平顶山市城乡一体化示范区"
					},
					{
						"code": 410481000000,
						"value": "舞钢市",
						"label": "舞钢市"
					},
					{
						"code": 410482000000,
						"value": "汝州市",
						"label": "汝州市"
					}
				],
				"value": "平顶山市",
				"label": "平顶山市"
			},
			{
				"code": 410500000000,
				"children": [
					{
						"code": 410501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410502000000,
						"value": "文峰区",
						"label": "文峰区"
					},
					{
						"code": 410503000000,
						"value": "北关区",
						"label": "北关区"
					},
					{
						"code": 410505000000,
						"value": "殷都区",
						"label": "殷都区"
					},
					{
						"code": 410506000000,
						"value": "龙安区",
						"label": "龙安区"
					},
					{
						"code": 410522000000,
						"value": "安阳县",
						"label": "安阳县"
					},
					{
						"code": 410523000000,
						"value": "汤阴县",
						"label": "汤阴县"
					},
					{
						"code": 410526000000,
						"value": "滑县",
						"label": "滑县"
					},
					{
						"code": 410527000000,
						"value": "内黄县",
						"label": "内黄县"
					},
					{
						"code": 410571000000,
						"value": "安阳高新技术产业开发区",
						"label": "安阳高新技术产业开发区"
					},
					{
						"code": 410581000000,
						"value": "林州市",
						"label": "林州市"
					}
				],
				"value": "安阳市",
				"label": "安阳市"
			},
			{
				"code": 410600000000,
				"children": [
					{
						"code": 410601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410602000000,
						"value": "鹤山区",
						"label": "鹤山区"
					},
					{
						"code": 410603000000,
						"value": "山城区",
						"label": "山城区"
					},
					{
						"code": 410611000000,
						"value": "淇滨区",
						"label": "淇滨区"
					},
					{
						"code": 410621000000,
						"value": "浚县",
						"label": "浚县"
					},
					{
						"code": 410622000000,
						"value": "淇县",
						"label": "淇县"
					},
					{
						"code": 410671000000,
						"value": "鹤壁经济技术开发区",
						"label": "鹤壁经济技术开发区"
					}
				],
				"value": "鹤壁市",
				"label": "鹤壁市"
			},
			{
				"code": 410700000000,
				"children": [
					{
						"code": 410701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410702000000,
						"value": "红旗区",
						"label": "红旗区"
					},
					{
						"code": 410703000000,
						"value": "卫滨区",
						"label": "卫滨区"
					},
					{
						"code": 410704000000,
						"value": "凤泉区",
						"label": "凤泉区"
					},
					{
						"code": 410711000000,
						"value": "牧野区",
						"label": "牧野区"
					},
					{
						"code": 410721000000,
						"value": "新乡县",
						"label": "新乡县"
					},
					{
						"code": 410724000000,
						"value": "获嘉县",
						"label": "获嘉县"
					},
					{
						"code": 410725000000,
						"value": "原阳县",
						"label": "原阳县"
					},
					{
						"code": 410726000000,
						"value": "延津县",
						"label": "延津县"
					},
					{
						"code": 410727000000,
						"value": "封丘县",
						"label": "封丘县"
					},
					{
						"code": 410771000000,
						"value": "新乡高新技术产业开发区",
						"label": "新乡高新技术产业开发区"
					},
					{
						"code": 410772000000,
						"value": "新乡经济技术开发区",
						"label": "新乡经济技术开发区"
					},
					{
						"code": 410773000000,
						"value": "新乡市平原城乡一体化示范区",
						"label": "新乡市平原城乡一体化示范区"
					},
					{
						"code": 410781000000,
						"value": "卫辉市",
						"label": "卫辉市"
					},
					{
						"code": 410782000000,
						"value": "辉县市",
						"label": "辉县市"
					},
					{
						"code": 410783000000,
						"value": "长垣市",
						"label": "长垣市"
					}
				],
				"value": "新乡市",
				"label": "新乡市"
			},
			{
				"code": 410800000000,
				"children": [
					{
						"code": 410801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410802000000,
						"value": "解放区",
						"label": "解放区"
					},
					{
						"code": 410803000000,
						"value": "中站区",
						"label": "中站区"
					},
					{
						"code": 410804000000,
						"value": "马村区",
						"label": "马村区"
					},
					{
						"code": 410811000000,
						"value": "山阳区",
						"label": "山阳区"
					},
					{
						"code": 410821000000,
						"value": "修武县",
						"label": "修武县"
					},
					{
						"code": 410822000000,
						"value": "博爱县",
						"label": "博爱县"
					},
					{
						"code": 410823000000,
						"value": "武陟县",
						"label": "武陟县"
					},
					{
						"code": 410825000000,
						"value": "温县",
						"label": "温县"
					},
					{
						"code": 410871000000,
						"value": "焦作城乡一体化示范区",
						"label": "焦作城乡一体化示范区"
					},
					{
						"code": 410882000000,
						"value": "沁阳市",
						"label": "沁阳市"
					},
					{
						"code": 410883000000,
						"value": "孟州市",
						"label": "孟州市"
					}
				],
				"value": "焦作市",
				"label": "焦作市"
			},
			{
				"code": 410900000000,
				"children": [
					{
						"code": 410901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 410902000000,
						"value": "华龙区",
						"label": "华龙区"
					},
					{
						"code": 410922000000,
						"value": "清丰县",
						"label": "清丰县"
					},
					{
						"code": 410923000000,
						"value": "南乐县",
						"label": "南乐县"
					},
					{
						"code": 410926000000,
						"value": "范县",
						"label": "范县"
					},
					{
						"code": 410927000000,
						"value": "台前县",
						"label": "台前县"
					},
					{
						"code": 410928000000,
						"value": "濮阳县",
						"label": "濮阳县"
					},
					{
						"code": 410971000000,
						"value": "河南濮阳工业园区",
						"label": "河南濮阳工业园区"
					},
					{
						"code": 410972000000,
						"value": "濮阳经济技术开发区",
						"label": "濮阳经济技术开发区"
					}
				],
				"value": "濮阳市",
				"label": "濮阳市"
			},
			{
				"code": 411000000000,
				"children": [
					{
						"code": 411001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411002000000,
						"value": "魏都区",
						"label": "魏都区"
					},
					{
						"code": 411003000000,
						"value": "建安区",
						"label": "建安区"
					},
					{
						"code": 411024000000,
						"value": "鄢陵县",
						"label": "鄢陵县"
					},
					{
						"code": 411025000000,
						"value": "襄城县",
						"label": "襄城县"
					},
					{
						"code": 411071000000,
						"value": "许昌经济技术开发区",
						"label": "许昌经济技术开发区"
					},
					{
						"code": 411081000000,
						"value": "禹州市",
						"label": "禹州市"
					},
					{
						"code": 411082000000,
						"value": "长葛市",
						"label": "长葛市"
					}
				],
				"value": "许昌市",
				"label": "许昌市"
			},
			{
				"code": 411100000000,
				"children": [
					{
						"code": 411101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411102000000,
						"value": "源汇区",
						"label": "源汇区"
					},
					{
						"code": 411103000000,
						"value": "郾城区",
						"label": "郾城区"
					},
					{
						"code": 411104000000,
						"value": "召陵区",
						"label": "召陵区"
					},
					{
						"code": 411121000000,
						"value": "舞阳县",
						"label": "舞阳县"
					},
					{
						"code": 411122000000,
						"value": "临颍县",
						"label": "临颍县"
					},
					{
						"code": 411171000000,
						"value": "漯河经济技术开发区",
						"label": "漯河经济技术开发区"
					}
				],
				"value": "漯河市",
				"label": "漯河市"
			},
			{
				"code": 411200000000,
				"children": [
					{
						"code": 411201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411202000000,
						"value": "湖滨区",
						"label": "湖滨区"
					},
					{
						"code": 411203000000,
						"value": "陕州区",
						"label": "陕州区"
					},
					{
						"code": 411221000000,
						"value": "渑池县",
						"label": "渑池县"
					},
					{
						"code": 411224000000,
						"value": "卢氏县",
						"label": "卢氏县"
					},
					{
						"code": 411271000000,
						"value": "河南三门峡经济开发区",
						"label": "河南三门峡经济开发区"
					},
					{
						"code": 411281000000,
						"value": "义马市",
						"label": "义马市"
					},
					{
						"code": 411282000000,
						"value": "灵宝市",
						"label": "灵宝市"
					}
				],
				"value": "三门峡市",
				"label": "三门峡市"
			},
			{
				"code": 411300000000,
				"children": [
					{
						"code": 411301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411302000000,
						"value": "宛城区",
						"label": "宛城区"
					},
					{
						"code": 411303000000,
						"value": "卧龙区",
						"label": "卧龙区"
					},
					{
						"code": 411321000000,
						"value": "南召县",
						"label": "南召县"
					},
					{
						"code": 411322000000,
						"value": "方城县",
						"label": "方城县"
					},
					{
						"code": 411323000000,
						"value": "西峡县",
						"label": "西峡县"
					},
					{
						"code": 411324000000,
						"value": "镇平县",
						"label": "镇平县"
					},
					{
						"code": 411325000000,
						"value": "内乡县",
						"label": "内乡县"
					},
					{
						"code": 411326000000,
						"value": "淅川县",
						"label": "淅川县"
					},
					{
						"code": 411327000000,
						"value": "社旗县",
						"label": "社旗县"
					},
					{
						"code": 411328000000,
						"value": "唐河县",
						"label": "唐河县"
					},
					{
						"code": 411329000000,
						"value": "新野县",
						"label": "新野县"
					},
					{
						"code": 411330000000,
						"value": "桐柏县",
						"label": "桐柏县"
					},
					{
						"code": 411371000000,
						"value": "南阳高新技术产业开发区",
						"label": "南阳高新技术产业开发区"
					},
					{
						"code": 411372000000,
						"value": "南阳市城乡一体化示范区",
						"label": "南阳市城乡一体化示范区"
					},
					{
						"code": 411381000000,
						"value": "邓州市",
						"label": "邓州市"
					}
				],
				"value": "南阳市",
				"label": "南阳市"
			},
			{
				"code": 411400000000,
				"children": [
					{
						"code": 411401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411402000000,
						"value": "梁园区",
						"label": "梁园区"
					},
					{
						"code": 411403000000,
						"value": "睢阳区",
						"label": "睢阳区"
					},
					{
						"code": 411421000000,
						"value": "民权县",
						"label": "民权县"
					},
					{
						"code": 411422000000,
						"value": "睢县",
						"label": "睢县"
					},
					{
						"code": 411423000000,
						"value": "宁陵县",
						"label": "宁陵县"
					},
					{
						"code": 411424000000,
						"value": "柘城县",
						"label": "柘城县"
					},
					{
						"code": 411425000000,
						"value": "虞城县",
						"label": "虞城县"
					},
					{
						"code": 411426000000,
						"value": "夏邑县",
						"label": "夏邑县"
					},
					{
						"code": 411471000000,
						"value": "豫东综合物流产业聚集区",
						"label": "豫东综合物流产业聚集区"
					},
					{
						"code": 411472000000,
						"value": "河南商丘经济开发区",
						"label": "河南商丘经济开发区"
					},
					{
						"code": 411481000000,
						"value": "永城市",
						"label": "永城市"
					}
				],
				"value": "商丘市",
				"label": "商丘市"
			},
			{
				"code": 411500000000,
				"children": [
					{
						"code": 411501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411502000000,
						"value": "浉河区",
						"label": "浉河区"
					},
					{
						"code": 411503000000,
						"value": "平桥区",
						"label": "平桥区"
					},
					{
						"code": 411521000000,
						"value": "罗山县",
						"label": "罗山县"
					},
					{
						"code": 411522000000,
						"value": "光山县",
						"label": "光山县"
					},
					{
						"code": 411523000000,
						"value": "新县",
						"label": "新县"
					},
					{
						"code": 411524000000,
						"value": "商城县",
						"label": "商城县"
					},
					{
						"code": 411525000000,
						"value": "固始县",
						"label": "固始县"
					},
					{
						"code": 411526000000,
						"value": "潢川县",
						"label": "潢川县"
					},
					{
						"code": 411527000000,
						"value": "淮滨县",
						"label": "淮滨县"
					},
					{
						"code": 411528000000,
						"value": "息县",
						"label": "息县"
					},
					{
						"code": 411571000000,
						"value": "信阳高新技术产业开发区",
						"label": "信阳高新技术产业开发区"
					}
				],
				"value": "信阳市",
				"label": "信阳市"
			},
			{
				"code": 411600000000,
				"children": [
					{
						"code": 411601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411602000000,
						"value": "川汇区",
						"label": "川汇区"
					},
					{
						"code": 411603000000,
						"value": "淮阳区",
						"label": "淮阳区"
					},
					{
						"code": 411621000000,
						"value": "扶沟县",
						"label": "扶沟县"
					},
					{
						"code": 411622000000,
						"value": "西华县",
						"label": "西华县"
					},
					{
						"code": 411623000000,
						"value": "商水县",
						"label": "商水县"
					},
					{
						"code": 411624000000,
						"value": "沈丘县",
						"label": "沈丘县"
					},
					{
						"code": 411625000000,
						"value": "郸城县",
						"label": "郸城县"
					},
					{
						"code": 411627000000,
						"value": "太康县",
						"label": "太康县"
					},
					{
						"code": 411628000000,
						"value": "鹿邑县",
						"label": "鹿邑县"
					},
					{
						"code": 411671000000,
						"value": "河南周口经济开发区",
						"label": "河南周口经济开发区"
					},
					{
						"code": 411681000000,
						"value": "项城市",
						"label": "项城市"
					}
				],
				"value": "周口市",
				"label": "周口市"
			},
			{
				"code": 411700000000,
				"children": [
					{
						"code": 411701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 411702000000,
						"value": "驿城区",
						"label": "驿城区"
					},
					{
						"code": 411721000000,
						"value": "西平县",
						"label": "西平县"
					},
					{
						"code": 411722000000,
						"value": "上蔡县",
						"label": "上蔡县"
					},
					{
						"code": 411723000000,
						"value": "平舆县",
						"label": "平舆县"
					},
					{
						"code": 411724000000,
						"value": "正阳县",
						"label": "正阳县"
					},
					{
						"code": 411725000000,
						"value": "确山县",
						"label": "确山县"
					},
					{
						"code": 411726000000,
						"value": "泌阳县",
						"label": "泌阳县"
					},
					{
						"code": 411727000000,
						"value": "汝南县",
						"label": "汝南县"
					},
					{
						"code": 411728000000,
						"value": "遂平县",
						"label": "遂平县"
					},
					{
						"code": 411729000000,
						"value": "新蔡县",
						"label": "新蔡县"
					},
					{
						"code": 411771000000,
						"value": "河南驻马店经济开发区",
						"label": "河南驻马店经济开发区"
					}
				],
				"value": "驻马店市",
				"label": "驻马店市"
			},
			{
				"code": 419000000000,
				"children": [
					{
						"code": 419001000000,
						"value": "济源市",
						"label": "济源市"
					}
				],
				"value": "省直辖县级行政区划",
				"label": "省直辖县级行政区划"
			}
		],
		"value": "河南省",
		"label": "河南省"
	},
	{
		"code": 420000000000,
		"children": [
			{
				"code": 420100000000,
				"children": [
					{
						"code": 420101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420102000000,
						"value": "江岸区",
						"label": "江岸区"
					},
					{
						"code": 420103000000,
						"value": "江汉区",
						"label": "江汉区"
					},
					{
						"code": 420104000000,
						"value": "硚口区",
						"label": "硚口区"
					},
					{
						"code": 420105000000,
						"value": "汉阳区",
						"label": "汉阳区"
					},
					{
						"code": 420106000000,
						"value": "武昌区",
						"label": "武昌区"
					},
					{
						"code": 420107000000,
						"value": "青山区",
						"label": "青山区"
					},
					{
						"code": 420111000000,
						"value": "洪山区",
						"label": "洪山区"
					},
					{
						"code": 420112000000,
						"value": "东西湖区",
						"label": "东西湖区"
					},
					{
						"code": 420113000000,
						"value": "汉南区",
						"label": "汉南区"
					},
					{
						"code": 420114000000,
						"value": "蔡甸区",
						"label": "蔡甸区"
					},
					{
						"code": 420115000000,
						"value": "江夏区",
						"label": "江夏区"
					},
					{
						"code": 420116000000,
						"value": "黄陂区",
						"label": "黄陂区"
					},
					{
						"code": 420117000000,
						"value": "新洲区",
						"label": "新洲区"
					}
				],
				"value": "武汉市",
				"label": "武汉市"
			},
			{
				"code": 420200000000,
				"children": [
					{
						"code": 420201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420202000000,
						"value": "黄石港区",
						"label": "黄石港区"
					},
					{
						"code": 420203000000,
						"value": "西塞山区",
						"label": "西塞山区"
					},
					{
						"code": 420204000000,
						"value": "下陆区",
						"label": "下陆区"
					},
					{
						"code": 420205000000,
						"value": "铁山区",
						"label": "铁山区"
					},
					{
						"code": 420222000000,
						"value": "阳新县",
						"label": "阳新县"
					},
					{
						"code": 420281000000,
						"value": "大冶市",
						"label": "大冶市"
					}
				],
				"value": "黄石市",
				"label": "黄石市"
			},
			{
				"code": 420300000000,
				"children": [
					{
						"code": 420301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420302000000,
						"value": "茅箭区",
						"label": "茅箭区"
					},
					{
						"code": 420303000000,
						"value": "张湾区",
						"label": "张湾区"
					},
					{
						"code": 420304000000,
						"value": "郧阳区",
						"label": "郧阳区"
					},
					{
						"code": 420322000000,
						"value": "郧西县",
						"label": "郧西县"
					},
					{
						"code": 420323000000,
						"value": "竹山县",
						"label": "竹山县"
					},
					{
						"code": 420324000000,
						"value": "竹溪县",
						"label": "竹溪县"
					},
					{
						"code": 420325000000,
						"value": "房县",
						"label": "房县"
					},
					{
						"code": 420381000000,
						"value": "丹江口市",
						"label": "丹江口市"
					}
				],
				"value": "十堰市",
				"label": "十堰市"
			},
			{
				"code": 420500000000,
				"children": [
					{
						"code": 420501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420502000000,
						"value": "西陵区",
						"label": "西陵区"
					},
					{
						"code": 420503000000,
						"value": "伍家岗区",
						"label": "伍家岗区"
					},
					{
						"code": 420504000000,
						"value": "点军区",
						"label": "点军区"
					},
					{
						"code": 420505000000,
						"value": "猇亭区",
						"label": "猇亭区"
					},
					{
						"code": 420506000000,
						"value": "夷陵区",
						"label": "夷陵区"
					},
					{
						"code": 420525000000,
						"value": "远安县",
						"label": "远安县"
					},
					{
						"code": 420526000000,
						"value": "兴山县",
						"label": "兴山县"
					},
					{
						"code": 420527000000,
						"value": "秭归县",
						"label": "秭归县"
					},
					{
						"code": 420528000000,
						"value": "长阳土家族自治县",
						"label": "长阳土家族自治县"
					},
					{
						"code": 420529000000,
						"value": "五峰土家族自治县",
						"label": "五峰土家族自治县"
					},
					{
						"code": 420581000000,
						"value": "宜都市",
						"label": "宜都市"
					},
					{
						"code": 420582000000,
						"value": "当阳市",
						"label": "当阳市"
					},
					{
						"code": 420583000000,
						"value": "枝江市",
						"label": "枝江市"
					}
				],
				"value": "宜昌市",
				"label": "宜昌市"
			},
			{
				"code": 420600000000,
				"children": [
					{
						"code": 420601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420602000000,
						"value": "襄城区",
						"label": "襄城区"
					},
					{
						"code": 420606000000,
						"value": "樊城区",
						"label": "樊城区"
					},
					{
						"code": 420607000000,
						"value": "襄州区",
						"label": "襄州区"
					},
					{
						"code": 420624000000,
						"value": "南漳县",
						"label": "南漳县"
					},
					{
						"code": 420625000000,
						"value": "谷城县",
						"label": "谷城县"
					},
					{
						"code": 420626000000,
						"value": "保康县",
						"label": "保康县"
					},
					{
						"code": 420682000000,
						"value": "老河口市",
						"label": "老河口市"
					},
					{
						"code": 420683000000,
						"value": "枣阳市",
						"label": "枣阳市"
					},
					{
						"code": 420684000000,
						"value": "宜城市",
						"label": "宜城市"
					}
				],
				"value": "襄阳市",
				"label": "襄阳市"
			},
			{
				"code": 420700000000,
				"children": [
					{
						"code": 420701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420702000000,
						"value": "梁子湖区",
						"label": "梁子湖区"
					},
					{
						"code": 420703000000,
						"value": "华容区",
						"label": "华容区"
					},
					{
						"code": 420704000000,
						"value": "鄂城区",
						"label": "鄂城区"
					}
				],
				"value": "鄂州市",
				"label": "鄂州市"
			},
			{
				"code": 420800000000,
				"children": [
					{
						"code": 420801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420802000000,
						"value": "东宝区",
						"label": "东宝区"
					},
					{
						"code": 420804000000,
						"value": "掇刀区",
						"label": "掇刀区"
					},
					{
						"code": 420822000000,
						"value": "沙洋县",
						"label": "沙洋县"
					},
					{
						"code": 420881000000,
						"value": "钟祥市",
						"label": "钟祥市"
					},
					{
						"code": 420882000000,
						"value": "京山市",
						"label": "京山市"
					}
				],
				"value": "荆门市",
				"label": "荆门市"
			},
			{
				"code": 420900000000,
				"children": [
					{
						"code": 420901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 420902000000,
						"value": "孝南区",
						"label": "孝南区"
					},
					{
						"code": 420921000000,
						"value": "孝昌县",
						"label": "孝昌县"
					},
					{
						"code": 420922000000,
						"value": "大悟县",
						"label": "大悟县"
					},
					{
						"code": 420923000000,
						"value": "云梦县",
						"label": "云梦县"
					},
					{
						"code": 420981000000,
						"value": "应城市",
						"label": "应城市"
					},
					{
						"code": 420982000000,
						"value": "安陆市",
						"label": "安陆市"
					},
					{
						"code": 420984000000,
						"value": "汉川市",
						"label": "汉川市"
					}
				],
				"value": "孝感市",
				"label": "孝感市"
			},
			{
				"code": 421000000000,
				"children": [
					{
						"code": 421001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 421002000000,
						"value": "沙市区",
						"label": "沙市区"
					},
					{
						"code": 421003000000,
						"value": "荆州区",
						"label": "荆州区"
					},
					{
						"code": 421022000000,
						"value": "公安县",
						"label": "公安县"
					},
					{
						"code": 421023000000,
						"value": "监利县",
						"label": "监利县"
					},
					{
						"code": 421024000000,
						"value": "江陵县",
						"label": "江陵县"
					},
					{
						"code": 421071000000,
						"value": "荆州经济技术开发区",
						"label": "荆州经济技术开发区"
					},
					{
						"code": 421081000000,
						"value": "石首市",
						"label": "石首市"
					},
					{
						"code": 421083000000,
						"value": "洪湖市",
						"label": "洪湖市"
					},
					{
						"code": 421087000000,
						"value": "松滋市",
						"label": "松滋市"
					}
				],
				"value": "荆州市",
				"label": "荆州市"
			},
			{
				"code": 421100000000,
				"children": [
					{
						"code": 421101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 421102000000,
						"value": "黄州区",
						"label": "黄州区"
					},
					{
						"code": 421121000000,
						"value": "团风县",
						"label": "团风县"
					},
					{
						"code": 421122000000,
						"value": "红安县",
						"label": "红安县"
					},
					{
						"code": 421123000000,
						"value": "罗田县",
						"label": "罗田县"
					},
					{
						"code": 421124000000,
						"value": "英山县",
						"label": "英山县"
					},
					{
						"code": 421125000000,
						"value": "浠水县",
						"label": "浠水县"
					},
					{
						"code": 421126000000,
						"value": "蕲春县",
						"label": "蕲春县"
					},
					{
						"code": 421127000000,
						"value": "黄梅县",
						"label": "黄梅县"
					},
					{
						"code": 421171000000,
						"value": "龙感湖管理区",
						"label": "龙感湖管理区"
					},
					{
						"code": 421181000000,
						"value": "麻城市",
						"label": "麻城市"
					},
					{
						"code": 421182000000,
						"value": "武穴市",
						"label": "武穴市"
					}
				],
				"value": "黄冈市",
				"label": "黄冈市"
			},
			{
				"code": 421200000000,
				"children": [
					{
						"code": 421201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 421202000000,
						"value": "咸安区",
						"label": "咸安区"
					},
					{
						"code": 421221000000,
						"value": "嘉鱼县",
						"label": "嘉鱼县"
					},
					{
						"code": 421222000000,
						"value": "通城县",
						"label": "通城县"
					},
					{
						"code": 421223000000,
						"value": "崇阳县",
						"label": "崇阳县"
					},
					{
						"code": 421224000000,
						"value": "通山县",
						"label": "通山县"
					},
					{
						"code": 421281000000,
						"value": "赤壁市",
						"label": "赤壁市"
					}
				],
				"value": "咸宁市",
				"label": "咸宁市"
			},
			{
				"code": 421300000000,
				"children": [
					{
						"code": 421301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 421303000000,
						"value": "曾都区",
						"label": "曾都区"
					},
					{
						"code": 421321000000,
						"value": "随县",
						"label": "随县"
					},
					{
						"code": 421381000000,
						"value": "广水市",
						"label": "广水市"
					}
				],
				"value": "随州市",
				"label": "随州市"
			},
			{
				"code": 422800000000,
				"children": [
					{
						"code": 422801000000,
						"value": "恩施市",
						"label": "恩施市"
					},
					{
						"code": 422802000000,
						"value": "利川市",
						"label": "利川市"
					},
					{
						"code": 422822000000,
						"value": "建始县",
						"label": "建始县"
					},
					{
						"code": 422823000000,
						"value": "巴东县",
						"label": "巴东县"
					},
					{
						"code": 422825000000,
						"value": "宣恩县",
						"label": "宣恩县"
					},
					{
						"code": 422826000000,
						"value": "咸丰县",
						"label": "咸丰县"
					},
					{
						"code": 422827000000,
						"value": "来凤县",
						"label": "来凤县"
					},
					{
						"code": 422828000000,
						"value": "鹤峰县",
						"label": "鹤峰县"
					}
				],
				"value": "恩施土家族苗族自治州",
				"label": "恩施土家族苗族自治州"
			},
			{
				"code": 429000000000,
				"children": [
					{
						"code": 429004000000,
						"value": "仙桃市",
						"label": "仙桃市"
					},
					{
						"code": 429005000000,
						"value": "潜江市",
						"label": "潜江市"
					},
					{
						"code": 429006000000,
						"value": "天门市",
						"label": "天门市"
					},
					{
						"code": 429021000000,
						"value": "神农架林区",
						"label": "神农架林区"
					}
				],
				"value": "省直辖县级行政区划",
				"label": "省直辖县级行政区划"
			}
		],
		"value": "湖北省",
		"label": "湖北省"
	},
	{
		"code": 430000000000,
		"children": [
			{
				"code": 430100000000,
				"children": [
					{
						"code": 430101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430102000000,
						"value": "芙蓉区",
						"label": "芙蓉区"
					},
					{
						"code": 430103000000,
						"value": "天心区",
						"label": "天心区"
					},
					{
						"code": 430104000000,
						"value": "岳麓区",
						"label": "岳麓区"
					},
					{
						"code": 430105000000,
						"value": "开福区",
						"label": "开福区"
					},
					{
						"code": 430111000000,
						"value": "雨花区",
						"label": "雨花区"
					},
					{
						"code": 430112000000,
						"value": "望城区",
						"label": "望城区"
					},
					{
						"code": 430121000000,
						"value": "长沙县",
						"label": "长沙县"
					},
					{
						"code": 430181000000,
						"value": "浏阳市",
						"label": "浏阳市"
					},
					{
						"code": 430182000000,
						"value": "宁乡市",
						"label": "宁乡市"
					}
				],
				"value": "长沙市",
				"label": "长沙市"
			},
			{
				"code": 430200000000,
				"children": [
					{
						"code": 430201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430202000000,
						"value": "荷塘区",
						"label": "荷塘区"
					},
					{
						"code": 430203000000,
						"value": "芦淞区",
						"label": "芦淞区"
					},
					{
						"code": 430204000000,
						"value": "石峰区",
						"label": "石峰区"
					},
					{
						"code": 430211000000,
						"value": "天元区",
						"label": "天元区"
					},
					{
						"code": 430212000000,
						"value": "渌口区",
						"label": "渌口区"
					},
					{
						"code": 430223000000,
						"value": "攸县",
						"label": "攸县"
					},
					{
						"code": 430224000000,
						"value": "茶陵县",
						"label": "茶陵县"
					},
					{
						"code": 430225000000,
						"value": "炎陵县",
						"label": "炎陵县"
					},
					{
						"code": 430271000000,
						"value": "云龙示范区",
						"label": "云龙示范区"
					},
					{
						"code": 430281000000,
						"value": "醴陵市",
						"label": "醴陵市"
					}
				],
				"value": "株洲市",
				"label": "株洲市"
			},
			{
				"code": 430300000000,
				"children": [
					{
						"code": 430301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430302000000,
						"value": "雨湖区",
						"label": "雨湖区"
					},
					{
						"code": 430304000000,
						"value": "岳塘区",
						"label": "岳塘区"
					},
					{
						"code": 430321000000,
						"value": "湘潭县",
						"label": "湘潭县"
					},
					{
						"code": 430371000000,
						"value": "湖南湘潭高新技术产业园区",
						"label": "湖南湘潭高新技术产业园区"
					},
					{
						"code": 430372000000,
						"value": "湘潭昭山示范区",
						"label": "湘潭昭山示范区"
					},
					{
						"code": 430373000000,
						"value": "湘潭九华示范区",
						"label": "湘潭九华示范区"
					},
					{
						"code": 430381000000,
						"value": "湘乡市",
						"label": "湘乡市"
					},
					{
						"code": 430382000000,
						"value": "韶山市",
						"label": "韶山市"
					}
				],
				"value": "湘潭市",
				"label": "湘潭市"
			},
			{
				"code": 430400000000,
				"children": [
					{
						"code": 430401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430405000000,
						"value": "珠晖区",
						"label": "珠晖区"
					},
					{
						"code": 430406000000,
						"value": "雁峰区",
						"label": "雁峰区"
					},
					{
						"code": 430407000000,
						"value": "石鼓区",
						"label": "石鼓区"
					},
					{
						"code": 430408000000,
						"value": "蒸湘区",
						"label": "蒸湘区"
					},
					{
						"code": 430412000000,
						"value": "南岳区",
						"label": "南岳区"
					},
					{
						"code": 430421000000,
						"value": "衡阳县",
						"label": "衡阳县"
					},
					{
						"code": 430422000000,
						"value": "衡南县",
						"label": "衡南县"
					},
					{
						"code": 430423000000,
						"value": "衡山县",
						"label": "衡山县"
					},
					{
						"code": 430424000000,
						"value": "衡东县",
						"label": "衡东县"
					},
					{
						"code": 430426000000,
						"value": "祁东县",
						"label": "祁东县"
					},
					{
						"code": 430471000000,
						"value": "衡阳综合保税区",
						"label": "衡阳综合保税区"
					},
					{
						"code": 430472000000,
						"value": "湖南衡阳高新技术产业园区",
						"label": "湖南衡阳高新技术产业园区"
					},
					{
						"code": 430473000000,
						"value": "湖南衡阳松木经济开发区",
						"label": "湖南衡阳松木经济开发区"
					},
					{
						"code": 430481000000,
						"value": "耒阳市",
						"label": "耒阳市"
					},
					{
						"code": 430482000000,
						"value": "常宁市",
						"label": "常宁市"
					}
				],
				"value": "衡阳市",
				"label": "衡阳市"
			},
			{
				"code": 430500000000,
				"children": [
					{
						"code": 430501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430502000000,
						"value": "双清区",
						"label": "双清区"
					},
					{
						"code": 430503000000,
						"value": "大祥区",
						"label": "大祥区"
					},
					{
						"code": 430511000000,
						"value": "北塔区",
						"label": "北塔区"
					},
					{
						"code": 430522000000,
						"value": "新邵县",
						"label": "新邵县"
					},
					{
						"code": 430523000000,
						"value": "邵阳县",
						"label": "邵阳县"
					},
					{
						"code": 430524000000,
						"value": "隆回县",
						"label": "隆回县"
					},
					{
						"code": 430525000000,
						"value": "洞口县",
						"label": "洞口县"
					},
					{
						"code": 430527000000,
						"value": "绥宁县",
						"label": "绥宁县"
					},
					{
						"code": 430528000000,
						"value": "新宁县",
						"label": "新宁县"
					},
					{
						"code": 430529000000,
						"value": "城步苗族自治县",
						"label": "城步苗族自治县"
					},
					{
						"code": 430581000000,
						"value": "武冈市",
						"label": "武冈市"
					},
					{
						"code": 430582000000,
						"value": "邵东市",
						"label": "邵东市"
					}
				],
				"value": "邵阳市",
				"label": "邵阳市"
			},
			{
				"code": 430600000000,
				"children": [
					{
						"code": 430601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430602000000,
						"value": "岳阳楼区",
						"label": "岳阳楼区"
					},
					{
						"code": 430603000000,
						"value": "云溪区",
						"label": "云溪区"
					},
					{
						"code": 430611000000,
						"value": "君山区",
						"label": "君山区"
					},
					{
						"code": 430621000000,
						"value": "岳阳县",
						"label": "岳阳县"
					},
					{
						"code": 430623000000,
						"value": "华容县",
						"label": "华容县"
					},
					{
						"code": 430624000000,
						"value": "湘阴县",
						"label": "湘阴县"
					},
					{
						"code": 430626000000,
						"value": "平江县",
						"label": "平江县"
					},
					{
						"code": 430671000000,
						"value": "岳阳市屈原管理区",
						"label": "岳阳市屈原管理区"
					},
					{
						"code": 430681000000,
						"value": "汨罗市",
						"label": "汨罗市"
					},
					{
						"code": 430682000000,
						"value": "临湘市",
						"label": "临湘市"
					}
				],
				"value": "岳阳市",
				"label": "岳阳市"
			},
			{
				"code": 430700000000,
				"children": [
					{
						"code": 430701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430702000000,
						"value": "武陵区",
						"label": "武陵区"
					},
					{
						"code": 430703000000,
						"value": "鼎城区",
						"label": "鼎城区"
					},
					{
						"code": 430721000000,
						"value": "安乡县",
						"label": "安乡县"
					},
					{
						"code": 430722000000,
						"value": "汉寿县",
						"label": "汉寿县"
					},
					{
						"code": 430723000000,
						"value": "澧县",
						"label": "澧县"
					},
					{
						"code": 430724000000,
						"value": "临澧县",
						"label": "临澧县"
					},
					{
						"code": 430725000000,
						"value": "桃源县",
						"label": "桃源县"
					},
					{
						"code": 430726000000,
						"value": "石门县",
						"label": "石门县"
					},
					{
						"code": 430771000000,
						"value": "常德市西洞庭管理区",
						"label": "常德市西洞庭管理区"
					},
					{
						"code": 430781000000,
						"value": "津市市",
						"label": "津市市"
					}
				],
				"value": "常德市",
				"label": "常德市"
			},
			{
				"code": 430800000000,
				"children": [
					{
						"code": 430801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430802000000,
						"value": "永定区",
						"label": "永定区"
					},
					{
						"code": 430811000000,
						"value": "武陵源区",
						"label": "武陵源区"
					},
					{
						"code": 430821000000,
						"value": "慈利县",
						"label": "慈利县"
					},
					{
						"code": 430822000000,
						"value": "桑植县",
						"label": "桑植县"
					}
				],
				"value": "张家界市",
				"label": "张家界市"
			},
			{
				"code": 430900000000,
				"children": [
					{
						"code": 430901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 430902000000,
						"value": "资阳区",
						"label": "资阳区"
					},
					{
						"code": 430903000000,
						"value": "赫山区",
						"label": "赫山区"
					},
					{
						"code": 430921000000,
						"value": "南县",
						"label": "南县"
					},
					{
						"code": 430922000000,
						"value": "桃江县",
						"label": "桃江县"
					},
					{
						"code": 430923000000,
						"value": "安化县",
						"label": "安化县"
					},
					{
						"code": 430971000000,
						"value": "益阳市大通湖管理区",
						"label": "益阳市大通湖管理区"
					},
					{
						"code": 430972000000,
						"value": "湖南益阳高新技术产业园区",
						"label": "湖南益阳高新技术产业园区"
					},
					{
						"code": 430981000000,
						"value": "沅江市",
						"label": "沅江市"
					}
				],
				"value": "益阳市",
				"label": "益阳市"
			},
			{
				"code": 431000000000,
				"children": [
					{
						"code": 431001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 431002000000,
						"value": "北湖区",
						"label": "北湖区"
					},
					{
						"code": 431003000000,
						"value": "苏仙区",
						"label": "苏仙区"
					},
					{
						"code": 431021000000,
						"value": "桂阳县",
						"label": "桂阳县"
					},
					{
						"code": 431022000000,
						"value": "宜章县",
						"label": "宜章县"
					},
					{
						"code": 431023000000,
						"value": "永兴县",
						"label": "永兴县"
					},
					{
						"code": 431024000000,
						"value": "嘉禾县",
						"label": "嘉禾县"
					},
					{
						"code": 431025000000,
						"value": "临武县",
						"label": "临武县"
					},
					{
						"code": 431026000000,
						"value": "汝城县",
						"label": "汝城县"
					},
					{
						"code": 431027000000,
						"value": "桂东县",
						"label": "桂东县"
					},
					{
						"code": 431028000000,
						"value": "安仁县",
						"label": "安仁县"
					},
					{
						"code": 431081000000,
						"value": "资兴市",
						"label": "资兴市"
					}
				],
				"value": "郴州市",
				"label": "郴州市"
			},
			{
				"code": 431100000000,
				"children": [
					{
						"code": 431101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 431102000000,
						"value": "零陵区",
						"label": "零陵区"
					},
					{
						"code": 431103000000,
						"value": "冷水滩区",
						"label": "冷水滩区"
					},
					{
						"code": 431121000000,
						"value": "祁阳县",
						"label": "祁阳县"
					},
					{
						"code": 431122000000,
						"value": "东安县",
						"label": "东安县"
					},
					{
						"code": 431123000000,
						"value": "双牌县",
						"label": "双牌县"
					},
					{
						"code": 431124000000,
						"value": "道县",
						"label": "道县"
					},
					{
						"code": 431125000000,
						"value": "江永县",
						"label": "江永县"
					},
					{
						"code": 431126000000,
						"value": "宁远县",
						"label": "宁远县"
					},
					{
						"code": 431127000000,
						"value": "蓝山县",
						"label": "蓝山县"
					},
					{
						"code": 431128000000,
						"value": "新田县",
						"label": "新田县"
					},
					{
						"code": 431129000000,
						"value": "江华瑶族自治县",
						"label": "江华瑶族自治县"
					},
					{
						"code": 431171000000,
						"value": "永州经济技术开发区",
						"label": "永州经济技术开发区"
					},
					{
						"code": 431172000000,
						"value": "永州市金洞管理区",
						"label": "永州市金洞管理区"
					},
					{
						"code": 431173000000,
						"value": "永州市回龙圩管理区",
						"label": "永州市回龙圩管理区"
					}
				],
				"value": "永州市",
				"label": "永州市"
			},
			{
				"code": 431200000000,
				"children": [
					{
						"code": 431201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 431202000000,
						"value": "鹤城区",
						"label": "鹤城区"
					},
					{
						"code": 431221000000,
						"value": "中方县",
						"label": "中方县"
					},
					{
						"code": 431222000000,
						"value": "沅陵县",
						"label": "沅陵县"
					},
					{
						"code": 431223000000,
						"value": "辰溪县",
						"label": "辰溪县"
					},
					{
						"code": 431224000000,
						"value": "溆浦县",
						"label": "溆浦县"
					},
					{
						"code": 431225000000,
						"value": "会同县",
						"label": "会同县"
					},
					{
						"code": 431226000000,
						"value": "麻阳苗族自治县",
						"label": "麻阳苗族自治县"
					},
					{
						"code": 431227000000,
						"value": "新晃侗族自治县",
						"label": "新晃侗族自治县"
					},
					{
						"code": 431228000000,
						"value": "芷江侗族自治县",
						"label": "芷江侗族自治县"
					},
					{
						"code": 431229000000,
						"value": "靖州苗族侗族自治县",
						"label": "靖州苗族侗族自治县"
					},
					{
						"code": 431230000000,
						"value": "通道侗族自治县",
						"label": "通道侗族自治县"
					},
					{
						"code": 431271000000,
						"value": "怀化市洪江管理区",
						"label": "怀化市洪江管理区"
					},
					{
						"code": 431281000000,
						"value": "洪江市",
						"label": "洪江市"
					}
				],
				"value": "怀化市",
				"label": "怀化市"
			},
			{
				"code": 431300000000,
				"children": [
					{
						"code": 431301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 431302000000,
						"value": "娄星区",
						"label": "娄星区"
					},
					{
						"code": 431321000000,
						"value": "双峰县",
						"label": "双峰县"
					},
					{
						"code": 431322000000,
						"value": "新化县",
						"label": "新化县"
					},
					{
						"code": 431381000000,
						"value": "冷水江市",
						"label": "冷水江市"
					},
					{
						"code": 431382000000,
						"value": "涟源市",
						"label": "涟源市"
					}
				],
				"value": "娄底市",
				"label": "娄底市"
			},
			{
				"code": 433100000000,
				"children": [
					{
						"code": 433101000000,
						"value": "吉首市",
						"label": "吉首市"
					},
					{
						"code": 433122000000,
						"value": "泸溪县",
						"label": "泸溪县"
					},
					{
						"code": 433123000000,
						"value": "凤凰县",
						"label": "凤凰县"
					},
					{
						"code": 433124000000,
						"value": "花垣县",
						"label": "花垣县"
					},
					{
						"code": 433125000000,
						"value": "保靖县",
						"label": "保靖县"
					},
					{
						"code": 433126000000,
						"value": "古丈县",
						"label": "古丈县"
					},
					{
						"code": 433127000000,
						"value": "永顺县",
						"label": "永顺县"
					},
					{
						"code": 433130000000,
						"value": "龙山县",
						"label": "龙山县"
					}
				],
				"value": "湘西土家族苗族自治州",
				"label": "湘西土家族苗族自治州"
			}
		],
		"value": "湖南省",
		"label": "湖南省"
	},
	{
		"code": 440000000000,
		"children": [
			{
				"code": 440100000000,
				"children": [
					{
						"code": 440101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440103000000,
						"value": "荔湾区",
						"label": "荔湾区"
					},
					{
						"code": 440104000000,
						"value": "越秀区",
						"label": "越秀区"
					},
					{
						"code": 440105000000,
						"value": "海珠区",
						"label": "海珠区"
					},
					{
						"code": 440106000000,
						"value": "天河区",
						"label": "天河区"
					},
					{
						"code": 440111000000,
						"value": "白云区",
						"label": "白云区"
					},
					{
						"code": 440112000000,
						"value": "黄埔区",
						"label": "黄埔区"
					},
					{
						"code": 440113000000,
						"value": "番禺区",
						"label": "番禺区"
					},
					{
						"code": 440114000000,
						"value": "花都区",
						"label": "花都区"
					},
					{
						"code": 440115000000,
						"value": "南沙区",
						"label": "南沙区"
					},
					{
						"code": 440117000000,
						"value": "从化区",
						"label": "从化区"
					},
					{
						"code": 440118000000,
						"value": "增城区",
						"label": "增城区"
					}
				],
				"value": "广州市",
				"label": "广州市"
			},
			{
				"code": 440200000000,
				"children": [
					{
						"code": 440201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440203000000,
						"value": "武江区",
						"label": "武江区"
					},
					{
						"code": 440204000000,
						"value": "浈江区",
						"label": "浈江区"
					},
					{
						"code": 440205000000,
						"value": "曲江区",
						"label": "曲江区"
					},
					{
						"code": 440222000000,
						"value": "始兴县",
						"label": "始兴县"
					},
					{
						"code": 440224000000,
						"value": "仁化县",
						"label": "仁化县"
					},
					{
						"code": 440229000000,
						"value": "翁源县",
						"label": "翁源县"
					},
					{
						"code": 440232000000,
						"value": "乳源瑶族自治县",
						"label": "乳源瑶族自治县"
					},
					{
						"code": 440233000000,
						"value": "新丰县",
						"label": "新丰县"
					},
					{
						"code": 440281000000,
						"value": "乐昌市",
						"label": "乐昌市"
					},
					{
						"code": 440282000000,
						"value": "南雄市",
						"label": "南雄市"
					}
				],
				"value": "韶关市",
				"label": "韶关市"
			},
			{
				"code": 440300000000,
				"children": [
					{
						"code": 440301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440303000000,
						"value": "罗湖区",
						"label": "罗湖区"
					},
					{
						"code": 440304000000,
						"value": "福田区",
						"label": "福田区"
					},
					{
						"code": 440305000000,
						"value": "南山区",
						"label": "南山区"
					},
					{
						"code": 440306000000,
						"value": "宝安区",
						"label": "宝安区"
					},
					{
						"code": 440307000000,
						"value": "龙岗区",
						"label": "龙岗区"
					},
					{
						"code": 440308000000,
						"value": "盐田区",
						"label": "盐田区"
					},
					{
						"code": 440309000000,
						"value": "龙华区",
						"label": "龙华区"
					},
					{
						"code": 440310000000,
						"value": "坪山区",
						"label": "坪山区"
					},
					{
						"code": 440311000000,
						"value": "光明区",
						"label": "光明区"
					}
				],
				"value": "深圳市",
				"label": "深圳市"
			},
			{
				"code": 440400000000,
				"children": [
					{
						"code": 440401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440402000000,
						"value": "香洲区",
						"label": "香洲区"
					},
					{
						"code": 440403000000,
						"value": "斗门区",
						"label": "斗门区"
					},
					{
						"code": 440404000000,
						"value": "金湾区",
						"label": "金湾区"
					}
				],
				"value": "珠海市",
				"label": "珠海市"
			},
			{
				"code": 440500000000,
				"children": [
					{
						"code": 440501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440507000000,
						"value": "龙湖区",
						"label": "龙湖区"
					},
					{
						"code": 440511000000,
						"value": "金平区",
						"label": "金平区"
					},
					{
						"code": 440512000000,
						"value": "濠江区",
						"label": "濠江区"
					},
					{
						"code": 440513000000,
						"value": "潮阳区",
						"label": "潮阳区"
					},
					{
						"code": 440514000000,
						"value": "潮南区",
						"label": "潮南区"
					},
					{
						"code": 440515000000,
						"value": "澄海区",
						"label": "澄海区"
					},
					{
						"code": 440523000000,
						"value": "南澳县",
						"label": "南澳县"
					}
				],
				"value": "汕头市",
				"label": "汕头市"
			},
			{
				"code": 440600000000,
				"children": [
					{
						"code": 440601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440604000000,
						"value": "禅城区",
						"label": "禅城区"
					},
					{
						"code": 440605000000,
						"value": "南海区",
						"label": "南海区"
					},
					{
						"code": 440606000000,
						"value": "顺德区",
						"label": "顺德区"
					},
					{
						"code": 440607000000,
						"value": "三水区",
						"label": "三水区"
					},
					{
						"code": 440608000000,
						"value": "高明区",
						"label": "高明区"
					}
				],
				"value": "佛山市",
				"label": "佛山市"
			},
			{
				"code": 440700000000,
				"children": [
					{
						"code": 440701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440703000000,
						"value": "蓬江区",
						"label": "蓬江区"
					},
					{
						"code": 440704000000,
						"value": "江海区",
						"label": "江海区"
					},
					{
						"code": 440705000000,
						"value": "新会区",
						"label": "新会区"
					},
					{
						"code": 440781000000,
						"value": "台山市",
						"label": "台山市"
					},
					{
						"code": 440783000000,
						"value": "开平市",
						"label": "开平市"
					},
					{
						"code": 440784000000,
						"value": "鹤山市",
						"label": "鹤山市"
					},
					{
						"code": 440785000000,
						"value": "恩平市",
						"label": "恩平市"
					}
				],
				"value": "江门市",
				"label": "江门市"
			},
			{
				"code": 440800000000,
				"children": [
					{
						"code": 440801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440802000000,
						"value": "赤坎区",
						"label": "赤坎区"
					},
					{
						"code": 440803000000,
						"value": "霞山区",
						"label": "霞山区"
					},
					{
						"code": 440804000000,
						"value": "坡头区",
						"label": "坡头区"
					},
					{
						"code": 440811000000,
						"value": "麻章区",
						"label": "麻章区"
					},
					{
						"code": 440823000000,
						"value": "遂溪县",
						"label": "遂溪县"
					},
					{
						"code": 440825000000,
						"value": "徐闻县",
						"label": "徐闻县"
					},
					{
						"code": 440881000000,
						"value": "廉江市",
						"label": "廉江市"
					},
					{
						"code": 440882000000,
						"value": "雷州市",
						"label": "雷州市"
					},
					{
						"code": 440883000000,
						"value": "吴川市",
						"label": "吴川市"
					}
				],
				"value": "湛江市",
				"label": "湛江市"
			},
			{
				"code": 440900000000,
				"children": [
					{
						"code": 440901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 440902000000,
						"value": "茂南区",
						"label": "茂南区"
					},
					{
						"code": 440904000000,
						"value": "电白区",
						"label": "电白区"
					},
					{
						"code": 440981000000,
						"value": "高州市",
						"label": "高州市"
					},
					{
						"code": 440982000000,
						"value": "化州市",
						"label": "化州市"
					},
					{
						"code": 440983000000,
						"value": "信宜市",
						"label": "信宜市"
					}
				],
				"value": "茂名市",
				"label": "茂名市"
			},
			{
				"code": 441200000000,
				"children": [
					{
						"code": 441201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 441202000000,
						"value": "端州区",
						"label": "端州区"
					},
					{
						"code": 441203000000,
						"value": "鼎湖区",
						"label": "鼎湖区"
					},
					{
						"code": 441204000000,
						"value": "高要区",
						"label": "高要区"
					},
					{
						"code": 441223000000,
						"value": "广宁县",
						"label": "广宁县"
					},
					{
						"code": 441224000000,
						"value": "怀集县",
						"label": "怀集县"
					},
					{
						"code": 441225000000,
						"value": "封开县",
						"label": "封开县"
					},
					{
						"code": 441226000000,
						"value": "德庆县",
						"label": "德庆县"
					},
					{
						"code": 441284000000,
						"value": "四会市",
						"label": "四会市"
					}
				],
				"value": "肇庆市",
				"label": "肇庆市"
			},
			{
				"code": 441300000000,
				"children": [
					{
						"code": 441301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 441302000000,
						"value": "惠城区",
						"label": "惠城区"
					},
					{
						"code": 441303000000,
						"value": "惠阳区",
						"label": "惠阳区"
					},
					{
						"code": 441322000000,
						"value": "博罗县",
						"label": "博罗县"
					},
					{
						"code": 441323000000,
						"value": "惠东县",
						"label": "惠东县"
					},
					{
						"code": 441324000000,
						"value": "龙门县",
						"label": "龙门县"
					}
				],
				"value": "惠州市",
				"label": "惠州市"
			},
			{
				"code": 441400000000,
				"children": [
					{
						"code": 441401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 441402000000,
						"value": "梅江区",
						"label": "梅江区"
					},
					{
						"code": 441403000000,
						"value": "梅县区",
						"label": "梅县区"
					},
					{
						"code": 441422000000,
						"value": "大埔县",
						"label": "大埔县"
					},
					{
						"code": 441423000000,
						"value": "丰顺县",
						"label": "丰顺县"
					},
					{
						"code": 441424000000,
						"value": "五华县",
						"label": "五华县"
					},
					{
						"code": 441426000000,
						"value": "平远县",
						"label": "平远县"
					},
					{
						"code": 441427000000,
						"value": "蕉岭县",
						"label": "蕉岭县"
					},
					{
						"code": 441481000000,
						"value": "兴宁市",
						"label": "兴宁市"
					}
				],
				"value": "梅州市",
				"label": "梅州市"
			},
			{
				"code": 441500000000,
				"children": [
					{
						"code": 441501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 441502000000,
						"value": "城区",
						"label": "城区"
					},
					{
						"code": 441521000000,
						"value": "海丰县",
						"label": "海丰县"
					},
					{
						"code": 441523000000,
						"value": "陆河县",
						"label": "陆河县"
					},
					{
						"code": 441581000000,
						"value": "陆丰市",
						"label": "陆丰市"
					}
				],
				"value": "汕尾市",
				"label": "汕尾市"
			},
			{
				"code": 441600000000,
				"children": [
					{
						"code": 441601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 441602000000,
						"value": "源城区",
						"label": "源城区"
					},
					{
						"code": 441621000000,
						"value": "紫金县",
						"label": "紫金县"
					},
					{
						"code": 441622000000,
						"value": "龙川县",
						"label": "龙川县"
					},
					{
						"code": 441623000000,
						"value": "连平县",
						"label": "连平县"
					},
					{
						"code": 441624000000,
						"value": "和平县",
						"label": "和平县"
					},
					{
						"code": 441625000000,
						"value": "东源县",
						"label": "东源县"
					}
				],
				"value": "河源市",
				"label": "河源市"
			},
			{
				"code": 441700000000,
				"children": [
					{
						"code": 441701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 441702000000,
						"value": "江城区",
						"label": "江城区"
					},
					{
						"code": 441704000000,
						"value": "阳东区",
						"label": "阳东区"
					},
					{
						"code": 441721000000,
						"value": "阳西县",
						"label": "阳西县"
					},
					{
						"code": 441781000000,
						"value": "阳春市",
						"label": "阳春市"
					}
				],
				"value": "阳江市",
				"label": "阳江市"
			},
			{
				"code": 441800000000,
				"children": [
					{
						"code": 441801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 441802000000,
						"value": "清城区",
						"label": "清城区"
					},
					{
						"code": 441803000000,
						"value": "清新区",
						"label": "清新区"
					},
					{
						"code": 441821000000,
						"value": "佛冈县",
						"label": "佛冈县"
					},
					{
						"code": 441823000000,
						"value": "阳山县",
						"label": "阳山县"
					},
					{
						"code": 441825000000,
						"value": "连山壮族瑶族自治县",
						"label": "连山壮族瑶族自治县"
					},
					{
						"code": 441826000000,
						"value": "连南瑶族自治县",
						"label": "连南瑶族自治县"
					},
					{
						"code": 441881000000,
						"value": "英德市",
						"label": "英德市"
					},
					{
						"code": 441882000000,
						"value": "连州市",
						"label": "连州市"
					}
				],
				"value": "清远市",
				"label": "清远市"
			},
			{
				"code": 441900000000,
				"children": [
					{
						"code": 441900003000,
						"value": "东城街道",
						"label": "东城街道"
					},
					{
						"code": 441900004000,
						"value": "南城街道",
						"label": "南城街道"
					},
					{
						"code": 441900005000,
						"value": "万江街道",
						"label": "万江街道"
					},
					{
						"code": 441900006000,
						"value": "莞城街道",
						"label": "莞城街道"
					},
					{
						"code": 441900101000,
						"value": "石碣镇",
						"label": "石碣镇"
					},
					{
						"code": 441900102000,
						"value": "石龙镇",
						"label": "石龙镇"
					},
					{
						"code": 441900103000,
						"value": "茶山镇",
						"label": "茶山镇"
					},
					{
						"code": 441900104000,
						"value": "石排镇",
						"label": "石排镇"
					},
					{
						"code": 441900105000,
						"value": "企石镇",
						"label": "企石镇"
					},
					{
						"code": 441900106000,
						"value": "横沥镇",
						"label": "横沥镇"
					},
					{
						"code": 441900107000,
						"value": "桥头镇",
						"label": "桥头镇"
					},
					{
						"code": 441900108000,
						"value": "谢岗镇",
						"label": "谢岗镇"
					},
					{
						"code": 441900109000,
						"value": "东坑镇",
						"label": "东坑镇"
					},
					{
						"code": 441900110000,
						"value": "常平镇",
						"label": "常平镇"
					},
					{
						"code": 441900111000,
						"value": "寮步镇",
						"label": "寮步镇"
					},
					{
						"code": 441900112000,
						"value": "樟木头镇",
						"label": "樟木头镇"
					},
					{
						"code": 441900113000,
						"value": "大朗镇",
						"label": "大朗镇"
					},
					{
						"code": 441900114000,
						"value": "黄江镇",
						"label": "黄江镇"
					},
					{
						"code": 441900115000,
						"value": "清溪镇",
						"label": "清溪镇"
					},
					{
						"code": 441900116000,
						"value": "塘厦镇",
						"label": "塘厦镇"
					},
					{
						"code": 441900117000,
						"value": "凤岗镇",
						"label": "凤岗镇"
					},
					{
						"code": 441900118000,
						"value": "大岭山镇",
						"label": "大岭山镇"
					},
					{
						"code": 441900119000,
						"value": "长安镇",
						"label": "长安镇"
					},
					{
						"code": 441900121000,
						"value": "虎门镇",
						"label": "虎门镇"
					},
					{
						"code": 441900122000,
						"value": "厚街镇",
						"label": "厚街镇"
					},
					{
						"code": 441900123000,
						"value": "沙田镇",
						"label": "沙田镇"
					},
					{
						"code": 441900124000,
						"value": "道滘镇",
						"label": "道滘镇"
					},
					{
						"code": 441900125000,
						"value": "洪梅镇",
						"label": "洪梅镇"
					},
					{
						"code": 441900126000,
						"value": "麻涌镇",
						"label": "麻涌镇"
					},
					{
						"code": 441900127000,
						"value": "望牛墩镇",
						"label": "望牛墩镇"
					},
					{
						"code": 441900128000,
						"value": "中堂镇",
						"label": "中堂镇"
					},
					{
						"code": 441900129000,
						"value": "高埗镇",
						"label": "高埗镇"
					},
					{
						"code": 441900401000,
						"value": "松山湖",
						"label": "松山湖"
					},
					{
						"code": 441900402000,
						"value": "东莞港",
						"label": "东莞港"
					},
					{
						"code": 441900403000,
						"value": "东莞生态园",
						"label": "东莞生态园"
					}
				],
				"value": "东莞市",
				"label": "东莞市"
			},
			{
				"code": 442000000000,
				"children": [
					{
						"code": 442000001000,
						"value": "石岐街道",
						"label": "石岐街道"
					},
					{
						"code": 442000002000,
						"value": "东区街道",
						"label": "东区街道"
					},
					{
						"code": 442000003000,
						"value": "中山港街道",
						"label": "中山港街道"
					},
					{
						"code": 442000004000,
						"value": "西区街道",
						"label": "西区街道"
					},
					{
						"code": 442000005000,
						"value": "南区街道",
						"label": "南区街道"
					},
					{
						"code": 442000006000,
						"value": "五桂山街道",
						"label": "五桂山街道"
					},
					{
						"code": 442000100000,
						"value": "小榄镇",
						"label": "小榄镇"
					},
					{
						"code": 442000101000,
						"value": "黄圃镇",
						"label": "黄圃镇"
					},
					{
						"code": 442000102000,
						"value": "民众镇",
						"label": "民众镇"
					},
					{
						"code": 442000103000,
						"value": "东凤镇",
						"label": "东凤镇"
					},
					{
						"code": 442000104000,
						"value": "东升镇",
						"label": "东升镇"
					},
					{
						"code": 442000105000,
						"value": "古镇镇",
						"label": "古镇镇"
					},
					{
						"code": 442000106000,
						"value": "沙溪镇",
						"label": "沙溪镇"
					},
					{
						"code": 442000107000,
						"value": "坦洲镇",
						"label": "坦洲镇"
					},
					{
						"code": 442000108000,
						"value": "港口镇",
						"label": "港口镇"
					},
					{
						"code": 442000109000,
						"value": "三角镇",
						"label": "三角镇"
					},
					{
						"code": 442000110000,
						"value": "横栏镇",
						"label": "横栏镇"
					},
					{
						"code": 442000111000,
						"value": "南头镇",
						"label": "南头镇"
					},
					{
						"code": 442000112000,
						"value": "阜沙镇",
						"label": "阜沙镇"
					},
					{
						"code": 442000113000,
						"value": "南朗镇",
						"label": "南朗镇"
					},
					{
						"code": 442000114000,
						"value": "三乡镇",
						"label": "三乡镇"
					},
					{
						"code": 442000115000,
						"value": "板芙镇",
						"label": "板芙镇"
					},
					{
						"code": 442000116000,
						"value": "大涌镇",
						"label": "大涌镇"
					},
					{
						"code": 442000117000,
						"value": "神湾镇",
						"label": "神湾镇"
					}
				],
				"value": "中山市",
				"label": "中山市"
			},
			{
				"code": 445100000000,
				"children": [
					{
						"code": 445101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 445102000000,
						"value": "湘桥区",
						"label": "湘桥区"
					},
					{
						"code": 445103000000,
						"value": "潮安区",
						"label": "潮安区"
					},
					{
						"code": 445122000000,
						"value": "饶平县",
						"label": "饶平县"
					}
				],
				"value": "潮州市",
				"label": "潮州市"
			},
			{
				"code": 445200000000,
				"children": [
					{
						"code": 445201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 445202000000,
						"value": "榕城区",
						"label": "榕城区"
					},
					{
						"code": 445203000000,
						"value": "揭东区",
						"label": "揭东区"
					},
					{
						"code": 445222000000,
						"value": "揭西县",
						"label": "揭西县"
					},
					{
						"code": 445224000000,
						"value": "惠来县",
						"label": "惠来县"
					},
					{
						"code": 445281000000,
						"value": "普宁市",
						"label": "普宁市"
					}
				],
				"value": "揭阳市",
				"label": "揭阳市"
			},
			{
				"code": 445300000000,
				"children": [
					{
						"code": 445301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 445302000000,
						"value": "云城区",
						"label": "云城区"
					},
					{
						"code": 445303000000,
						"value": "云安区",
						"label": "云安区"
					},
					{
						"code": 445321000000,
						"value": "新兴县",
						"label": "新兴县"
					},
					{
						"code": 445322000000,
						"value": "郁南县",
						"label": "郁南县"
					},
					{
						"code": 445381000000,
						"value": "罗定市",
						"label": "罗定市"
					}
				],
				"value": "云浮市",
				"label": "云浮市"
			}
		],
		"value": "广东省",
		"label": "广东省"
	},
	{
		"code": 450000000000,
		"children": [
			{
				"code": 450100000000,
				"children": [
					{
						"code": 450101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450102000000,
						"value": "兴宁区",
						"label": "兴宁区"
					},
					{
						"code": 450103000000,
						"value": "青秀区",
						"label": "青秀区"
					},
					{
						"code": 450105000000,
						"value": "江南区",
						"label": "江南区"
					},
					{
						"code": 450107000000,
						"value": "西乡塘区",
						"label": "西乡塘区"
					},
					{
						"code": 450108000000,
						"value": "良庆区",
						"label": "良庆区"
					},
					{
						"code": 450109000000,
						"value": "邕宁区",
						"label": "邕宁区"
					},
					{
						"code": 450110000000,
						"value": "武鸣区",
						"label": "武鸣区"
					},
					{
						"code": 450123000000,
						"value": "隆安县",
						"label": "隆安县"
					},
					{
						"code": 450124000000,
						"value": "马山县",
						"label": "马山县"
					},
					{
						"code": 450125000000,
						"value": "上林县",
						"label": "上林县"
					},
					{
						"code": 450126000000,
						"value": "宾阳县",
						"label": "宾阳县"
					},
					{
						"code": 450127000000,
						"value": "横县",
						"label": "横县"
					}
				],
				"value": "南宁市",
				"label": "南宁市"
			},
			{
				"code": 450200000000,
				"children": [
					{
						"code": 450201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450202000000,
						"value": "城中区",
						"label": "城中区"
					},
					{
						"code": 450203000000,
						"value": "鱼峰区",
						"label": "鱼峰区"
					},
					{
						"code": 450204000000,
						"value": "柳南区",
						"label": "柳南区"
					},
					{
						"code": 450205000000,
						"value": "柳北区",
						"label": "柳北区"
					},
					{
						"code": 450206000000,
						"value": "柳江区",
						"label": "柳江区"
					},
					{
						"code": 450222000000,
						"value": "柳城县",
						"label": "柳城县"
					},
					{
						"code": 450223000000,
						"value": "鹿寨县",
						"label": "鹿寨县"
					},
					{
						"code": 450224000000,
						"value": "融安县",
						"label": "融安县"
					},
					{
						"code": 450225000000,
						"value": "融水苗族自治县",
						"label": "融水苗族自治县"
					},
					{
						"code": 450226000000,
						"value": "三江侗族自治县",
						"label": "三江侗族自治县"
					}
				],
				"value": "柳州市",
				"label": "柳州市"
			},
			{
				"code": 450300000000,
				"children": [
					{
						"code": 450301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450302000000,
						"value": "秀峰区",
						"label": "秀峰区"
					},
					{
						"code": 450303000000,
						"value": "叠彩区",
						"label": "叠彩区"
					},
					{
						"code": 450304000000,
						"value": "象山区",
						"label": "象山区"
					},
					{
						"code": 450305000000,
						"value": "七星区",
						"label": "七星区"
					},
					{
						"code": 450311000000,
						"value": "雁山区",
						"label": "雁山区"
					},
					{
						"code": 450312000000,
						"value": "临桂区",
						"label": "临桂区"
					},
					{
						"code": 450321000000,
						"value": "阳朔县",
						"label": "阳朔县"
					},
					{
						"code": 450323000000,
						"value": "灵川县",
						"label": "灵川县"
					},
					{
						"code": 450324000000,
						"value": "全州县",
						"label": "全州县"
					},
					{
						"code": 450325000000,
						"value": "兴安县",
						"label": "兴安县"
					},
					{
						"code": 450326000000,
						"value": "永福县",
						"label": "永福县"
					},
					{
						"code": 450327000000,
						"value": "灌阳县",
						"label": "灌阳县"
					},
					{
						"code": 450328000000,
						"value": "龙胜各族自治县",
						"label": "龙胜各族自治县"
					},
					{
						"code": 450329000000,
						"value": "资源县",
						"label": "资源县"
					},
					{
						"code": 450330000000,
						"value": "平乐县",
						"label": "平乐县"
					},
					{
						"code": 450332000000,
						"value": "恭城瑶族自治县",
						"label": "恭城瑶族自治县"
					},
					{
						"code": 450381000000,
						"value": "荔浦市",
						"label": "荔浦市"
					}
				],
				"value": "桂林市",
				"label": "桂林市"
			},
			{
				"code": 450400000000,
				"children": [
					{
						"code": 450401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450403000000,
						"value": "万秀区",
						"label": "万秀区"
					},
					{
						"code": 450405000000,
						"value": "长洲区",
						"label": "长洲区"
					},
					{
						"code": 450406000000,
						"value": "龙圩区",
						"label": "龙圩区"
					},
					{
						"code": 450421000000,
						"value": "苍梧县",
						"label": "苍梧县"
					},
					{
						"code": 450422000000,
						"value": "藤县",
						"label": "藤县"
					},
					{
						"code": 450423000000,
						"value": "蒙山县",
						"label": "蒙山县"
					},
					{
						"code": 450481000000,
						"value": "岑溪市",
						"label": "岑溪市"
					}
				],
				"value": "梧州市",
				"label": "梧州市"
			},
			{
				"code": 450500000000,
				"children": [
					{
						"code": 450501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450502000000,
						"value": "海城区",
						"label": "海城区"
					},
					{
						"code": 450503000000,
						"value": "银海区",
						"label": "银海区"
					},
					{
						"code": 450512000000,
						"value": "铁山港区",
						"label": "铁山港区"
					},
					{
						"code": 450521000000,
						"value": "合浦县",
						"label": "合浦县"
					}
				],
				"value": "北海市",
				"label": "北海市"
			},
			{
				"code": 450600000000,
				"children": [
					{
						"code": 450601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450602000000,
						"value": "港口区",
						"label": "港口区"
					},
					{
						"code": 450603000000,
						"value": "防城区",
						"label": "防城区"
					},
					{
						"code": 450621000000,
						"value": "上思县",
						"label": "上思县"
					},
					{
						"code": 450681000000,
						"value": "东兴市",
						"label": "东兴市"
					}
				],
				"value": "防城港市",
				"label": "防城港市"
			},
			{
				"code": 450700000000,
				"children": [
					{
						"code": 450701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450702000000,
						"value": "钦南区",
						"label": "钦南区"
					},
					{
						"code": 450703000000,
						"value": "钦北区",
						"label": "钦北区"
					},
					{
						"code": 450721000000,
						"value": "灵山县",
						"label": "灵山县"
					},
					{
						"code": 450722000000,
						"value": "浦北县",
						"label": "浦北县"
					}
				],
				"value": "钦州市",
				"label": "钦州市"
			},
			{
				"code": 450800000000,
				"children": [
					{
						"code": 450801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450802000000,
						"value": "港北区",
						"label": "港北区"
					},
					{
						"code": 450803000000,
						"value": "港南区",
						"label": "港南区"
					},
					{
						"code": 450804000000,
						"value": "覃塘区",
						"label": "覃塘区"
					},
					{
						"code": 450821000000,
						"value": "平南县",
						"label": "平南县"
					},
					{
						"code": 450881000000,
						"value": "桂平市",
						"label": "桂平市"
					}
				],
				"value": "贵港市",
				"label": "贵港市"
			},
			{
				"code": 450900000000,
				"children": [
					{
						"code": 450901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 450902000000,
						"value": "玉州区",
						"label": "玉州区"
					},
					{
						"code": 450903000000,
						"value": "福绵区",
						"label": "福绵区"
					},
					{
						"code": 450921000000,
						"value": "容县",
						"label": "容县"
					},
					{
						"code": 450922000000,
						"value": "陆川县",
						"label": "陆川县"
					},
					{
						"code": 450923000000,
						"value": "博白县",
						"label": "博白县"
					},
					{
						"code": 450924000000,
						"value": "兴业县",
						"label": "兴业县"
					},
					{
						"code": 450981000000,
						"value": "北流市",
						"label": "北流市"
					}
				],
				"value": "玉林市",
				"label": "玉林市"
			},
			{
				"code": 451000000000,
				"children": [
					{
						"code": 451001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 451002000000,
						"value": "右江区",
						"label": "右江区"
					},
					{
						"code": 451003000000,
						"value": "田阳区",
						"label": "田阳区"
					},
					{
						"code": 451022000000,
						"value": "田东县",
						"label": "田东县"
					},
					{
						"code": 451024000000,
						"value": "德保县",
						"label": "德保县"
					},
					{
						"code": 451026000000,
						"value": "那坡县",
						"label": "那坡县"
					},
					{
						"code": 451027000000,
						"value": "凌云县",
						"label": "凌云县"
					},
					{
						"code": 451028000000,
						"value": "乐业县",
						"label": "乐业县"
					},
					{
						"code": 451029000000,
						"value": "田林县",
						"label": "田林县"
					},
					{
						"code": 451030000000,
						"value": "西林县",
						"label": "西林县"
					},
					{
						"code": 451031000000,
						"value": "隆林各族自治县",
						"label": "隆林各族自治县"
					},
					{
						"code": 451081000000,
						"value": "靖西市",
						"label": "靖西市"
					},
					{
						"code": 451082000000,
						"value": "平果市",
						"label": "平果市"
					}
				],
				"value": "百色市",
				"label": "百色市"
			},
			{
				"code": 451100000000,
				"children": [
					{
						"code": 451101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 451102000000,
						"value": "八步区",
						"label": "八步区"
					},
					{
						"code": 451103000000,
						"value": "平桂区",
						"label": "平桂区"
					},
					{
						"code": 451121000000,
						"value": "昭平县",
						"label": "昭平县"
					},
					{
						"code": 451122000000,
						"value": "钟山县",
						"label": "钟山县"
					},
					{
						"code": 451123000000,
						"value": "富川瑶族自治县",
						"label": "富川瑶族自治县"
					}
				],
				"value": "贺州市",
				"label": "贺州市"
			},
			{
				"code": 451200000000,
				"children": [
					{
						"code": 451201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 451202000000,
						"value": "金城江区",
						"label": "金城江区"
					},
					{
						"code": 451203000000,
						"value": "宜州区",
						"label": "宜州区"
					},
					{
						"code": 451221000000,
						"value": "南丹县",
						"label": "南丹县"
					},
					{
						"code": 451222000000,
						"value": "天峨县",
						"label": "天峨县"
					},
					{
						"code": 451223000000,
						"value": "凤山县",
						"label": "凤山县"
					},
					{
						"code": 451224000000,
						"value": "东兰县",
						"label": "东兰县"
					},
					{
						"code": 451225000000,
						"value": "罗城仫佬族自治县",
						"label": "罗城仫佬族自治县"
					},
					{
						"code": 451226000000,
						"value": "环江毛南族自治县",
						"label": "环江毛南族自治县"
					},
					{
						"code": 451227000000,
						"value": "巴马瑶族自治县",
						"label": "巴马瑶族自治县"
					},
					{
						"code": 451228000000,
						"value": "都安瑶族自治县",
						"label": "都安瑶族自治县"
					},
					{
						"code": 451229000000,
						"value": "大化瑶族自治县",
						"label": "大化瑶族自治县"
					}
				],
				"value": "河池市",
				"label": "河池市"
			},
			{
				"code": 451300000000,
				"children": [
					{
						"code": 451301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 451302000000,
						"value": "兴宾区",
						"label": "兴宾区"
					},
					{
						"code": 451321000000,
						"value": "忻城县",
						"label": "忻城县"
					},
					{
						"code": 451322000000,
						"value": "象州县",
						"label": "象州县"
					},
					{
						"code": 451323000000,
						"value": "武宣县",
						"label": "武宣县"
					},
					{
						"code": 451324000000,
						"value": "金秀瑶族自治县",
						"label": "金秀瑶族自治县"
					},
					{
						"code": 451381000000,
						"value": "合山市",
						"label": "合山市"
					}
				],
				"value": "来宾市",
				"label": "来宾市"
			},
			{
				"code": 451400000000,
				"children": [
					{
						"code": 451401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 451402000000,
						"value": "江州区",
						"label": "江州区"
					},
					{
						"code": 451421000000,
						"value": "扶绥县",
						"label": "扶绥县"
					},
					{
						"code": 451422000000,
						"value": "宁明县",
						"label": "宁明县"
					},
					{
						"code": 451423000000,
						"value": "龙州县",
						"label": "龙州县"
					},
					{
						"code": 451424000000,
						"value": "大新县",
						"label": "大新县"
					},
					{
						"code": 451425000000,
						"value": "天等县",
						"label": "天等县"
					},
					{
						"code": 451481000000,
						"value": "凭祥市",
						"label": "凭祥市"
					}
				],
				"value": "崇左市",
				"label": "崇左市"
			}
		],
		"value": "广西壮族自治区",
		"label": "广西壮族自治区"
	},
	{
		"code": 460000000000,
		"children": [
			{
				"code": 460100000000,
				"children": [
					{
						"code": 460101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 460105000000,
						"value": "秀英区",
						"label": "秀英区"
					},
					{
						"code": 460106000000,
						"value": "龙华区",
						"label": "龙华区"
					},
					{
						"code": 460107000000,
						"value": "琼山区",
						"label": "琼山区"
					},
					{
						"code": 460108000000,
						"value": "美兰区",
						"label": "美兰区"
					}
				],
				"value": "海口市",
				"label": "海口市"
			},
			{
				"code": 460200000000,
				"children": [
					{
						"code": 460201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 460202000000,
						"value": "海棠区",
						"label": "海棠区"
					},
					{
						"code": 460203000000,
						"value": "吉阳区",
						"label": "吉阳区"
					},
					{
						"code": 460204000000,
						"value": "天涯区",
						"label": "天涯区"
					},
					{
						"code": 460205000000,
						"value": "崖州区",
						"label": "崖州区"
					}
				],
				"value": "三亚市",
				"label": "三亚市"
			},
			{
				"code": 460300000000,
				"children": [
					{
						"code": 460321000000,
						"value": "西沙群岛",
						"label": "西沙群岛"
					},
					{
						"code": 460322000000,
						"value": "南沙群岛",
						"label": "南沙群岛"
					},
					{
						"code": 460323000000,
						"value": "中沙群岛的岛礁及其海域",
						"label": "中沙群岛的岛礁及其海域"
					}
				],
				"value": "三沙市",
				"label": "三沙市"
			},
			{
				"code": 460400000000,
				"children": [
					{
						"code": 460400100000,
						"value": "那大镇",
						"label": "那大镇"
					},
					{
						"code": 460400101000,
						"value": "和庆镇",
						"label": "和庆镇"
					},
					{
						"code": 460400102000,
						"value": "南丰镇",
						"label": "南丰镇"
					},
					{
						"code": 460400103000,
						"value": "大成镇",
						"label": "大成镇"
					},
					{
						"code": 460400104000,
						"value": "雅星镇",
						"label": "雅星镇"
					},
					{
						"code": 460400105000,
						"value": "兰洋镇",
						"label": "兰洋镇"
					},
					{
						"code": 460400106000,
						"value": "光村镇",
						"label": "光村镇"
					},
					{
						"code": 460400107000,
						"value": "木棠镇",
						"label": "木棠镇"
					},
					{
						"code": 460400108000,
						"value": "海头镇",
						"label": "海头镇"
					},
					{
						"code": 460400109000,
						"value": "峨蔓镇",
						"label": "峨蔓镇"
					},
					{
						"code": 460400111000,
						"value": "王五镇",
						"label": "王五镇"
					},
					{
						"code": 460400112000,
						"value": "白马井镇",
						"label": "白马井镇"
					},
					{
						"code": 460400113000,
						"value": "中和镇",
						"label": "中和镇"
					},
					{
						"code": 460400114000,
						"value": "排浦镇",
						"label": "排浦镇"
					},
					{
						"code": 460400115000,
						"value": "东成镇",
						"label": "东成镇"
					},
					{
						"code": 460400116000,
						"value": "新州镇",
						"label": "新州镇"
					},
					{
						"code": 460400499000,
						"value": "洋浦经济开发区",
						"label": "洋浦经济开发区"
					},
					{
						"code": 460400500000,
						"value": "华南热作学院",
						"label": "华南热作学院"
					}
				],
				"value": "儋州市",
				"label": "儋州市"
			},
			{
				"code": 469000000000,
				"children": [
					{
						"code": 469001000000,
						"value": "五指山市",
						"label": "五指山市"
					},
					{
						"code": 469002000000,
						"value": "琼海市",
						"label": "琼海市"
					},
					{
						"code": 469005000000,
						"value": "文昌市",
						"label": "文昌市"
					},
					{
						"code": 469006000000,
						"value": "万宁市",
						"label": "万宁市"
					},
					{
						"code": 469007000000,
						"value": "东方市",
						"label": "东方市"
					},
					{
						"code": 469021000000,
						"value": "定安县",
						"label": "定安县"
					},
					{
						"code": 469022000000,
						"value": "屯昌县",
						"label": "屯昌县"
					},
					{
						"code": 469023000000,
						"value": "澄迈县",
						"label": "澄迈县"
					},
					{
						"code": 469024000000,
						"value": "临高县",
						"label": "临高县"
					},
					{
						"code": 469025000000,
						"value": "白沙黎族自治县",
						"label": "白沙黎族自治县"
					},
					{
						"code": 469026000000,
						"value": "昌江黎族自治县",
						"label": "昌江黎族自治县"
					},
					{
						"code": 469027000000,
						"value": "乐东黎族自治县",
						"label": "乐东黎族自治县"
					},
					{
						"code": 469028000000,
						"value": "陵水黎族自治县",
						"label": "陵水黎族自治县"
					},
					{
						"code": 469029000000,
						"value": "保亭黎族苗族自治县",
						"label": "保亭黎族苗族自治县"
					},
					{
						"code": 469030000000,
						"value": "琼中黎族苗族自治县",
						"label": "琼中黎族苗族自治县"
					}
				],
				"value": "省直辖县级行政区划",
				"label": "省直辖县级行政区划"
			}
		],
		"value": "海南省",
		"label": "海南省"
	},
	{
		"code": 500000000000,
		"children": [
			{
				"code": 500100000000,
				"children": [
					{
						"code": 500101000000,
						"value": "万州区",
						"label": "万州区"
					},
					{
						"code": 500102000000,
						"value": "涪陵区",
						"label": "涪陵区"
					},
					{
						"code": 500103000000,
						"value": "渝中区",
						"label": "渝中区"
					},
					{
						"code": 500104000000,
						"value": "大渡口区",
						"label": "大渡口区"
					},
					{
						"code": 500105000000,
						"value": "江北区",
						"label": "江北区"
					},
					{
						"code": 500106000000,
						"value": "沙坪坝区",
						"label": "沙坪坝区"
					},
					{
						"code": 500107000000,
						"value": "九龙坡区",
						"label": "九龙坡区"
					},
					{
						"code": 500108000000,
						"value": "南岸区",
						"label": "南岸区"
					},
					{
						"code": 500109000000,
						"value": "北碚区",
						"label": "北碚区"
					},
					{
						"code": 500110000000,
						"value": "綦江区",
						"label": "綦江区"
					},
					{
						"code": 500111000000,
						"value": "大足区",
						"label": "大足区"
					},
					{
						"code": 500112000000,
						"value": "渝北区",
						"label": "渝北区"
					},
					{
						"code": 500113000000,
						"value": "巴南区",
						"label": "巴南区"
					},
					{
						"code": 500114000000,
						"value": "黔江区",
						"label": "黔江区"
					},
					{
						"code": 500115000000,
						"value": "长寿区",
						"label": "长寿区"
					},
					{
						"code": 500116000000,
						"value": "江津区",
						"label": "江津区"
					},
					{
						"code": 500117000000,
						"value": "合川区",
						"label": "合川区"
					},
					{
						"code": 500118000000,
						"value": "永川区",
						"label": "永川区"
					},
					{
						"code": 500119000000,
						"value": "南川区",
						"label": "南川区"
					},
					{
						"code": 500120000000,
						"value": "璧山区",
						"label": "璧山区"
					},
					{
						"code": 500151000000,
						"value": "铜梁区",
						"label": "铜梁区"
					},
					{
						"code": 500152000000,
						"value": "潼南区",
						"label": "潼南区"
					},
					{
						"code": 500153000000,
						"value": "荣昌区",
						"label": "荣昌区"
					},
					{
						"code": 500154000000,
						"value": "开州区",
						"label": "开州区"
					},
					{
						"code": 500155000000,
						"value": "梁平区",
						"label": "梁平区"
					},
					{
						"code": 500156000000,
						"value": "武隆区",
						"label": "武隆区"
					}
				],
				"value": "市辖区",
				"label": "市辖区"
			},
			{
				"code": 500200000000,
				"children": [
					{
						"code": 500229000000,
						"value": "城口县",
						"label": "城口县"
					},
					{
						"code": 500230000000,
						"value": "丰都县",
						"label": "丰都县"
					},
					{
						"code": 500231000000,
						"value": "垫江县",
						"label": "垫江县"
					},
					{
						"code": 500233000000,
						"value": "忠县",
						"label": "忠县"
					},
					{
						"code": 500235000000,
						"value": "云阳县",
						"label": "云阳县"
					},
					{
						"code": 500236000000,
						"value": "奉节县",
						"label": "奉节县"
					},
					{
						"code": 500237000000,
						"value": "巫山县",
						"label": "巫山县"
					},
					{
						"code": 500238000000,
						"value": "巫溪县",
						"label": "巫溪县"
					},
					{
						"code": 500240000000,
						"value": "石柱土家族自治县",
						"label": "石柱土家族自治县"
					},
					{
						"code": 500241000000,
						"value": "秀山土家族苗族自治县",
						"label": "秀山土家族苗族自治县"
					},
					{
						"code": 500242000000,
						"value": "酉阳土家族苗族自治县",
						"label": "酉阳土家族苗族自治县"
					},
					{
						"code": 500243000000,
						"value": "彭水苗族土家族自治县",
						"label": "彭水苗族土家族自治县"
					}
				],
				"value": "县",
				"label": "县"
			}
		],
		"value": "重庆市",
		"label": "重庆市"
	},
	{
		"code": 510000000000,
		"children": [
			{
				"code": 510100000000,
				"children": [
					{
						"code": 510101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510104000000,
						"value": "锦江区",
						"label": "锦江区"
					},
					{
						"code": 510105000000,
						"value": "青羊区",
						"label": "青羊区"
					},
					{
						"code": 510106000000,
						"value": "金牛区",
						"label": "金牛区"
					},
					{
						"code": 510107000000,
						"value": "武侯区",
						"label": "武侯区"
					},
					{
						"code": 510108000000,
						"value": "成华区",
						"label": "成华区"
					},
					{
						"code": 510112000000,
						"value": "龙泉驿区",
						"label": "龙泉驿区"
					},
					{
						"code": 510113000000,
						"value": "青白江区",
						"label": "青白江区"
					},
					{
						"code": 510114000000,
						"value": "新都区",
						"label": "新都区"
					},
					{
						"code": 510115000000,
						"value": "温江区",
						"label": "温江区"
					},
					{
						"code": 510116000000,
						"value": "双流区",
						"label": "双流区"
					},
					{
						"code": 510117000000,
						"value": "郫都区",
						"label": "郫都区"
					},
					{
						"code": 510118000000,
						"value": "新津区",
						"label": "新津区"
					},
					{
						"code": 510121000000,
						"value": "金堂县",
						"label": "金堂县"
					},
					{
						"code": 510129000000,
						"value": "大邑县",
						"label": "大邑县"
					},
					{
						"code": 510131000000,
						"value": "蒲江县",
						"label": "蒲江县"
					},
					{
						"code": 510181000000,
						"value": "都江堰市",
						"label": "都江堰市"
					},
					{
						"code": 510182000000,
						"value": "彭州市",
						"label": "彭州市"
					},
					{
						"code": 510183000000,
						"value": "邛崃市",
						"label": "邛崃市"
					},
					{
						"code": 510184000000,
						"value": "崇州市",
						"label": "崇州市"
					},
					{
						"code": 510185000000,
						"value": "简阳市",
						"label": "简阳市"
					}
				],
				"value": "成都市",
				"label": "成都市"
			},
			{
				"code": 510300000000,
				"children": [
					{
						"code": 510301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510302000000,
						"value": "自流井区",
						"label": "自流井区"
					},
					{
						"code": 510303000000,
						"value": "贡井区",
						"label": "贡井区"
					},
					{
						"code": 510304000000,
						"value": "大安区",
						"label": "大安区"
					},
					{
						"code": 510311000000,
						"value": "沿滩区",
						"label": "沿滩区"
					},
					{
						"code": 510321000000,
						"value": "荣县",
						"label": "荣县"
					},
					{
						"code": 510322000000,
						"value": "富顺县",
						"label": "富顺县"
					}
				],
				"value": "自贡市",
				"label": "自贡市"
			},
			{
				"code": 510400000000,
				"children": [
					{
						"code": 510401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510402000000,
						"value": "东区",
						"label": "东区"
					},
					{
						"code": 510403000000,
						"value": "西区",
						"label": "西区"
					},
					{
						"code": 510411000000,
						"value": "仁和区",
						"label": "仁和区"
					},
					{
						"code": 510421000000,
						"value": "米易县",
						"label": "米易县"
					},
					{
						"code": 510422000000,
						"value": "盐边县",
						"label": "盐边县"
					}
				],
				"value": "攀枝花市",
				"label": "攀枝花市"
			},
			{
				"code": 510500000000,
				"children": [
					{
						"code": 510501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510502000000,
						"value": "江阳区",
						"label": "江阳区"
					},
					{
						"code": 510503000000,
						"value": "纳溪区",
						"label": "纳溪区"
					},
					{
						"code": 510504000000,
						"value": "龙马潭区",
						"label": "龙马潭区"
					},
					{
						"code": 510521000000,
						"value": "泸县",
						"label": "泸县"
					},
					{
						"code": 510522000000,
						"value": "合江县",
						"label": "合江县"
					},
					{
						"code": 510524000000,
						"value": "叙永县",
						"label": "叙永县"
					},
					{
						"code": 510525000000,
						"value": "古蔺县",
						"label": "古蔺县"
					}
				],
				"value": "泸州市",
				"label": "泸州市"
			},
			{
				"code": 510600000000,
				"children": [
					{
						"code": 510601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510603000000,
						"value": "旌阳区",
						"label": "旌阳区"
					},
					{
						"code": 510604000000,
						"value": "罗江区",
						"label": "罗江区"
					},
					{
						"code": 510623000000,
						"value": "中江县",
						"label": "中江县"
					},
					{
						"code": 510681000000,
						"value": "广汉市",
						"label": "广汉市"
					},
					{
						"code": 510682000000,
						"value": "什邡市",
						"label": "什邡市"
					},
					{
						"code": 510683000000,
						"value": "绵竹市",
						"label": "绵竹市"
					}
				],
				"value": "德阳市",
				"label": "德阳市"
			},
			{
				"code": 510700000000,
				"children": [
					{
						"code": 510701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510703000000,
						"value": "涪城区",
						"label": "涪城区"
					},
					{
						"code": 510704000000,
						"value": "游仙区",
						"label": "游仙区"
					},
					{
						"code": 510705000000,
						"value": "安州区",
						"label": "安州区"
					},
					{
						"code": 510722000000,
						"value": "三台县",
						"label": "三台县"
					},
					{
						"code": 510723000000,
						"value": "盐亭县",
						"label": "盐亭县"
					},
					{
						"code": 510725000000,
						"value": "梓潼县",
						"label": "梓潼县"
					},
					{
						"code": 510726000000,
						"value": "北川羌族自治县",
						"label": "北川羌族自治县"
					},
					{
						"code": 510727000000,
						"value": "平武县",
						"label": "平武县"
					},
					{
						"code": 510781000000,
						"value": "江油市",
						"label": "江油市"
					}
				],
				"value": "绵阳市",
				"label": "绵阳市"
			},
			{
				"code": 510800000000,
				"children": [
					{
						"code": 510801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510802000000,
						"value": "利州区",
						"label": "利州区"
					},
					{
						"code": 510811000000,
						"value": "昭化区",
						"label": "昭化区"
					},
					{
						"code": 510812000000,
						"value": "朝天区",
						"label": "朝天区"
					},
					{
						"code": 510821000000,
						"value": "旺苍县",
						"label": "旺苍县"
					},
					{
						"code": 510822000000,
						"value": "青川县",
						"label": "青川县"
					},
					{
						"code": 510823000000,
						"value": "剑阁县",
						"label": "剑阁县"
					},
					{
						"code": 510824000000,
						"value": "苍溪县",
						"label": "苍溪县"
					}
				],
				"value": "广元市",
				"label": "广元市"
			},
			{
				"code": 510900000000,
				"children": [
					{
						"code": 510901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 510903000000,
						"value": "船山区",
						"label": "船山区"
					},
					{
						"code": 510904000000,
						"value": "安居区",
						"label": "安居区"
					},
					{
						"code": 510921000000,
						"value": "蓬溪县",
						"label": "蓬溪县"
					},
					{
						"code": 510923000000,
						"value": "大英县",
						"label": "大英县"
					},
					{
						"code": 510981000000,
						"value": "射洪市",
						"label": "射洪市"
					}
				],
				"value": "遂宁市",
				"label": "遂宁市"
			},
			{
				"code": 511000000000,
				"children": [
					{
						"code": 511001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511002000000,
						"value": "市中区",
						"label": "市中区"
					},
					{
						"code": 511011000000,
						"value": "东兴区",
						"label": "东兴区"
					},
					{
						"code": 511024000000,
						"value": "威远县",
						"label": "威远县"
					},
					{
						"code": 511025000000,
						"value": "资中县",
						"label": "资中县"
					},
					{
						"code": 511071000000,
						"value": "内江经济开发区",
						"label": "内江经济开发区"
					},
					{
						"code": 511083000000,
						"value": "隆昌市",
						"label": "隆昌市"
					}
				],
				"value": "内江市",
				"label": "内江市"
			},
			{
				"code": 511100000000,
				"children": [
					{
						"code": 511101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511102000000,
						"value": "市中区",
						"label": "市中区"
					},
					{
						"code": 511111000000,
						"value": "沙湾区",
						"label": "沙湾区"
					},
					{
						"code": 511112000000,
						"value": "五通桥区",
						"label": "五通桥区"
					},
					{
						"code": 511113000000,
						"value": "金口河区",
						"label": "金口河区"
					},
					{
						"code": 511123000000,
						"value": "犍为县",
						"label": "犍为县"
					},
					{
						"code": 511124000000,
						"value": "井研县",
						"label": "井研县"
					},
					{
						"code": 511126000000,
						"value": "夹江县",
						"label": "夹江县"
					},
					{
						"code": 511129000000,
						"value": "沐川县",
						"label": "沐川县"
					},
					{
						"code": 511132000000,
						"value": "峨边彝族自治县",
						"label": "峨边彝族自治县"
					},
					{
						"code": 511133000000,
						"value": "马边彝族自治县",
						"label": "马边彝族自治县"
					},
					{
						"code": 511181000000,
						"value": "峨眉山市",
						"label": "峨眉山市"
					}
				],
				"value": "乐山市",
				"label": "乐山市"
			},
			{
				"code": 511300000000,
				"children": [
					{
						"code": 511301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511302000000,
						"value": "顺庆区",
						"label": "顺庆区"
					},
					{
						"code": 511303000000,
						"value": "高坪区",
						"label": "高坪区"
					},
					{
						"code": 511304000000,
						"value": "嘉陵区",
						"label": "嘉陵区"
					},
					{
						"code": 511321000000,
						"value": "南部县",
						"label": "南部县"
					},
					{
						"code": 511322000000,
						"value": "营山县",
						"label": "营山县"
					},
					{
						"code": 511323000000,
						"value": "蓬安县",
						"label": "蓬安县"
					},
					{
						"code": 511324000000,
						"value": "仪陇县",
						"label": "仪陇县"
					},
					{
						"code": 511325000000,
						"value": "西充县",
						"label": "西充县"
					},
					{
						"code": 511381000000,
						"value": "阆中市",
						"label": "阆中市"
					}
				],
				"value": "南充市",
				"label": "南充市"
			},
			{
				"code": 511400000000,
				"children": [
					{
						"code": 511401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511402000000,
						"value": "东坡区",
						"label": "东坡区"
					},
					{
						"code": 511403000000,
						"value": "彭山区",
						"label": "彭山区"
					},
					{
						"code": 511421000000,
						"value": "仁寿县",
						"label": "仁寿县"
					},
					{
						"code": 511423000000,
						"value": "洪雅县",
						"label": "洪雅县"
					},
					{
						"code": 511424000000,
						"value": "丹棱县",
						"label": "丹棱县"
					},
					{
						"code": 511425000000,
						"value": "青神县",
						"label": "青神县"
					}
				],
				"value": "眉山市",
				"label": "眉山市"
			},
			{
				"code": 511500000000,
				"children": [
					{
						"code": 511501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511502000000,
						"value": "翠屏区",
						"label": "翠屏区"
					},
					{
						"code": 511503000000,
						"value": "南溪区",
						"label": "南溪区"
					},
					{
						"code": 511504000000,
						"value": "叙州区",
						"label": "叙州区"
					},
					{
						"code": 511523000000,
						"value": "江安县",
						"label": "江安县"
					},
					{
						"code": 511524000000,
						"value": "长宁县",
						"label": "长宁县"
					},
					{
						"code": 511525000000,
						"value": "高县",
						"label": "高县"
					},
					{
						"code": 511526000000,
						"value": "珙县",
						"label": "珙县"
					},
					{
						"code": 511527000000,
						"value": "筠连县",
						"label": "筠连县"
					},
					{
						"code": 511528000000,
						"value": "兴文县",
						"label": "兴文县"
					},
					{
						"code": 511529000000,
						"value": "屏山县",
						"label": "屏山县"
					}
				],
				"value": "宜宾市",
				"label": "宜宾市"
			},
			{
				"code": 511600000000,
				"children": [
					{
						"code": 511601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511602000000,
						"value": "广安区",
						"label": "广安区"
					},
					{
						"code": 511603000000,
						"value": "前锋区",
						"label": "前锋区"
					},
					{
						"code": 511621000000,
						"value": "岳池县",
						"label": "岳池县"
					},
					{
						"code": 511622000000,
						"value": "武胜县",
						"label": "武胜县"
					},
					{
						"code": 511623000000,
						"value": "邻水县",
						"label": "邻水县"
					},
					{
						"code": 511681000000,
						"value": "华蓥市",
						"label": "华蓥市"
					}
				],
				"value": "广安市",
				"label": "广安市"
			},
			{
				"code": 511700000000,
				"children": [
					{
						"code": 511701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511702000000,
						"value": "通川区",
						"label": "通川区"
					},
					{
						"code": 511703000000,
						"value": "达川区",
						"label": "达川区"
					},
					{
						"code": 511722000000,
						"value": "宣汉县",
						"label": "宣汉县"
					},
					{
						"code": 511723000000,
						"value": "开江县",
						"label": "开江县"
					},
					{
						"code": 511724000000,
						"value": "大竹县",
						"label": "大竹县"
					},
					{
						"code": 511725000000,
						"value": "渠县",
						"label": "渠县"
					},
					{
						"code": 511771000000,
						"value": "达州经济开发区",
						"label": "达州经济开发区"
					},
					{
						"code": 511781000000,
						"value": "万源市",
						"label": "万源市"
					}
				],
				"value": "达州市",
				"label": "达州市"
			},
			{
				"code": 511800000000,
				"children": [
					{
						"code": 511801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511802000000,
						"value": "雨城区",
						"label": "雨城区"
					},
					{
						"code": 511803000000,
						"value": "名山区",
						"label": "名山区"
					},
					{
						"code": 511822000000,
						"value": "荥经县",
						"label": "荥经县"
					},
					{
						"code": 511823000000,
						"value": "汉源县",
						"label": "汉源县"
					},
					{
						"code": 511824000000,
						"value": "石棉县",
						"label": "石棉县"
					},
					{
						"code": 511825000000,
						"value": "天全县",
						"label": "天全县"
					},
					{
						"code": 511826000000,
						"value": "芦山县",
						"label": "芦山县"
					},
					{
						"code": 511827000000,
						"value": "宝兴县",
						"label": "宝兴县"
					}
				],
				"value": "雅安市",
				"label": "雅安市"
			},
			{
				"code": 511900000000,
				"children": [
					{
						"code": 511901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 511902000000,
						"value": "巴州区",
						"label": "巴州区"
					},
					{
						"code": 511903000000,
						"value": "恩阳区",
						"label": "恩阳区"
					},
					{
						"code": 511921000000,
						"value": "通江县",
						"label": "通江县"
					},
					{
						"code": 511922000000,
						"value": "南江县",
						"label": "南江县"
					},
					{
						"code": 511923000000,
						"value": "平昌县",
						"label": "平昌县"
					},
					{
						"code": 511971000000,
						"value": "巴中经济开发区",
						"label": "巴中经济开发区"
					}
				],
				"value": "巴中市",
				"label": "巴中市"
			},
			{
				"code": 512000000000,
				"children": [
					{
						"code": 512001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 512002000000,
						"value": "雁江区",
						"label": "雁江区"
					},
					{
						"code": 512021000000,
						"value": "安岳县",
						"label": "安岳县"
					},
					{
						"code": 512022000000,
						"value": "乐至县",
						"label": "乐至县"
					}
				],
				"value": "资阳市",
				"label": "资阳市"
			},
			{
				"code": 513200000000,
				"children": [
					{
						"code": 513201000000,
						"value": "马尔康市",
						"label": "马尔康市"
					},
					{
						"code": 513221000000,
						"value": "汶川县",
						"label": "汶川县"
					},
					{
						"code": 513222000000,
						"value": "理县",
						"label": "理县"
					},
					{
						"code": 513223000000,
						"value": "茂县",
						"label": "茂县"
					},
					{
						"code": 513224000000,
						"value": "松潘县",
						"label": "松潘县"
					},
					{
						"code": 513225000000,
						"value": "九寨沟县",
						"label": "九寨沟县"
					},
					{
						"code": 513226000000,
						"value": "金川县",
						"label": "金川县"
					},
					{
						"code": 513227000000,
						"value": "小金县",
						"label": "小金县"
					},
					{
						"code": 513228000000,
						"value": "黑水县",
						"label": "黑水县"
					},
					{
						"code": 513230000000,
						"value": "壤塘县",
						"label": "壤塘县"
					},
					{
						"code": 513231000000,
						"value": "阿坝县",
						"label": "阿坝县"
					},
					{
						"code": 513232000000,
						"value": "若尔盖县",
						"label": "若尔盖县"
					},
					{
						"code": 513233000000,
						"value": "红原县",
						"label": "红原县"
					}
				],
				"value": "阿坝藏族羌族自治州",
				"label": "阿坝藏族羌族自治州"
			},
			{
				"code": 513300000000,
				"children": [
					{
						"code": 513301000000,
						"value": "康定市",
						"label": "康定市"
					},
					{
						"code": 513322000000,
						"value": "泸定县",
						"label": "泸定县"
					},
					{
						"code": 513323000000,
						"value": "丹巴县",
						"label": "丹巴县"
					},
					{
						"code": 513324000000,
						"value": "九龙县",
						"label": "九龙县"
					},
					{
						"code": 513325000000,
						"value": "雅江县",
						"label": "雅江县"
					},
					{
						"code": 513326000000,
						"value": "道孚县",
						"label": "道孚县"
					},
					{
						"code": 513327000000,
						"value": "炉霍县",
						"label": "炉霍县"
					},
					{
						"code": 513328000000,
						"value": "甘孜县",
						"label": "甘孜县"
					},
					{
						"code": 513329000000,
						"value": "新龙县",
						"label": "新龙县"
					},
					{
						"code": 513330000000,
						"value": "德格县",
						"label": "德格县"
					},
					{
						"code": 513331000000,
						"value": "白玉县",
						"label": "白玉县"
					},
					{
						"code": 513332000000,
						"value": "石渠县",
						"label": "石渠县"
					},
					{
						"code": 513333000000,
						"value": "色达县",
						"label": "色达县"
					},
					{
						"code": 513334000000,
						"value": "理塘县",
						"label": "理塘县"
					},
					{
						"code": 513335000000,
						"value": "巴塘县",
						"label": "巴塘县"
					},
					{
						"code": 513336000000,
						"value": "乡城县",
						"label": "乡城县"
					},
					{
						"code": 513337000000,
						"value": "稻城县",
						"label": "稻城县"
					},
					{
						"code": 513338000000,
						"value": "得荣县",
						"label": "得荣县"
					}
				],
				"value": "甘孜藏族自治州",
				"label": "甘孜藏族自治州"
			},
			{
				"code": 513400000000,
				"children": [
					{
						"code": 513401000000,
						"value": "西昌市",
						"label": "西昌市"
					},
					{
						"code": 513422000000,
						"value": "木里藏族自治县",
						"label": "木里藏族自治县"
					},
					{
						"code": 513423000000,
						"value": "盐源县",
						"label": "盐源县"
					},
					{
						"code": 513424000000,
						"value": "德昌县",
						"label": "德昌县"
					},
					{
						"code": 513425000000,
						"value": "会理县",
						"label": "会理县"
					},
					{
						"code": 513426000000,
						"value": "会东县",
						"label": "会东县"
					},
					{
						"code": 513427000000,
						"value": "宁南县",
						"label": "宁南县"
					},
					{
						"code": 513428000000,
						"value": "普格县",
						"label": "普格县"
					},
					{
						"code": 513429000000,
						"value": "布拖县",
						"label": "布拖县"
					},
					{
						"code": 513430000000,
						"value": "金阳县",
						"label": "金阳县"
					},
					{
						"code": 513431000000,
						"value": "昭觉县",
						"label": "昭觉县"
					},
					{
						"code": 513432000000,
						"value": "喜德县",
						"label": "喜德县"
					},
					{
						"code": 513433000000,
						"value": "冕宁县",
						"label": "冕宁县"
					},
					{
						"code": 513434000000,
						"value": "越西县",
						"label": "越西县"
					},
					{
						"code": 513435000000,
						"value": "甘洛县",
						"label": "甘洛县"
					},
					{
						"code": 513436000000,
						"value": "美姑县",
						"label": "美姑县"
					},
					{
						"code": 513437000000,
						"value": "雷波县",
						"label": "雷波县"
					}
				],
				"value": "凉山彝族自治州",
				"label": "凉山彝族自治州"
			}
		],
		"value": "四川省",
		"label": "四川省"
	},
	{
		"code": 520000000000,
		"children": [
			{
				"code": 520100000000,
				"children": [
					{
						"code": 520101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 520102000000,
						"value": "南明区",
						"label": "南明区"
					},
					{
						"code": 520103000000,
						"value": "云岩区",
						"label": "云岩区"
					},
					{
						"code": 520111000000,
						"value": "花溪区",
						"label": "花溪区"
					},
					{
						"code": 520112000000,
						"value": "乌当区",
						"label": "乌当区"
					},
					{
						"code": 520113000000,
						"value": "白云区",
						"label": "白云区"
					},
					{
						"code": 520115000000,
						"value": "观山湖区",
						"label": "观山湖区"
					},
					{
						"code": 520121000000,
						"value": "开阳县",
						"label": "开阳县"
					},
					{
						"code": 520122000000,
						"value": "息烽县",
						"label": "息烽县"
					},
					{
						"code": 520123000000,
						"value": "修文县",
						"label": "修文县"
					},
					{
						"code": 520181000000,
						"value": "清镇市",
						"label": "清镇市"
					}
				],
				"value": "贵阳市",
				"label": "贵阳市"
			},
			{
				"code": 520200000000,
				"children": [
					{
						"code": 520201000000,
						"value": "钟山区",
						"label": "钟山区"
					},
					{
						"code": 520203000000,
						"value": "六枝特区",
						"label": "六枝特区"
					},
					{
						"code": 520221000000,
						"value": "水城县",
						"label": "水城县"
					},
					{
						"code": 520281000000,
						"value": "盘州市",
						"label": "盘州市"
					}
				],
				"value": "六盘水市",
				"label": "六盘水市"
			},
			{
				"code": 520300000000,
				"children": [
					{
						"code": 520301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 520302000000,
						"value": "红花岗区",
						"label": "红花岗区"
					},
					{
						"code": 520303000000,
						"value": "汇川区",
						"label": "汇川区"
					},
					{
						"code": 520304000000,
						"value": "播州区",
						"label": "播州区"
					},
					{
						"code": 520322000000,
						"value": "桐梓县",
						"label": "桐梓县"
					},
					{
						"code": 520323000000,
						"value": "绥阳县",
						"label": "绥阳县"
					},
					{
						"code": 520324000000,
						"value": "正安县",
						"label": "正安县"
					},
					{
						"code": 520325000000,
						"value": "道真仡佬族苗族自治县",
						"label": "道真仡佬族苗族自治县"
					},
					{
						"code": 520326000000,
						"value": "务川仡佬族苗族自治县",
						"label": "务川仡佬族苗族自治县"
					},
					{
						"code": 520327000000,
						"value": "凤冈县",
						"label": "凤冈县"
					},
					{
						"code": 520328000000,
						"value": "湄潭县",
						"label": "湄潭县"
					},
					{
						"code": 520329000000,
						"value": "余庆县",
						"label": "余庆县"
					},
					{
						"code": 520330000000,
						"value": "习水县",
						"label": "习水县"
					},
					{
						"code": 520381000000,
						"value": "赤水市",
						"label": "赤水市"
					},
					{
						"code": 520382000000,
						"value": "仁怀市",
						"label": "仁怀市"
					}
				],
				"value": "遵义市",
				"label": "遵义市"
			},
			{
				"code": 520400000000,
				"children": [
					{
						"code": 520401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 520402000000,
						"value": "西秀区",
						"label": "西秀区"
					},
					{
						"code": 520403000000,
						"value": "平坝区",
						"label": "平坝区"
					},
					{
						"code": 520422000000,
						"value": "普定县",
						"label": "普定县"
					},
					{
						"code": 520423000000,
						"value": "镇宁布依族苗族自治县",
						"label": "镇宁布依族苗族自治县"
					},
					{
						"code": 520424000000,
						"value": "关岭布依族苗族自治县",
						"label": "关岭布依族苗族自治县"
					},
					{
						"code": 520425000000,
						"value": "紫云苗族布依族自治县",
						"label": "紫云苗族布依族自治县"
					}
				],
				"value": "安顺市",
				"label": "安顺市"
			},
			{
				"code": 520500000000,
				"children": [
					{
						"code": 520501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 520502000000,
						"value": "七星关区",
						"label": "七星关区"
					},
					{
						"code": 520521000000,
						"value": "大方县",
						"label": "大方县"
					},
					{
						"code": 520522000000,
						"value": "黔西县",
						"label": "黔西县"
					},
					{
						"code": 520523000000,
						"value": "金沙县",
						"label": "金沙县"
					},
					{
						"code": 520524000000,
						"value": "织金县",
						"label": "织金县"
					},
					{
						"code": 520525000000,
						"value": "纳雍县",
						"label": "纳雍县"
					},
					{
						"code": 520526000000,
						"value": "威宁彝族回族苗族自治县",
						"label": "威宁彝族回族苗族自治县"
					},
					{
						"code": 520527000000,
						"value": "赫章县",
						"label": "赫章县"
					}
				],
				"value": "毕节市",
				"label": "毕节市"
			},
			{
				"code": 520600000000,
				"children": [
					{
						"code": 520601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 520602000000,
						"value": "碧江区",
						"label": "碧江区"
					},
					{
						"code": 520603000000,
						"value": "万山区",
						"label": "万山区"
					},
					{
						"code": 520621000000,
						"value": "江口县",
						"label": "江口县"
					},
					{
						"code": 520622000000,
						"value": "玉屏侗族自治县",
						"label": "玉屏侗族自治县"
					},
					{
						"code": 520623000000,
						"value": "石阡县",
						"label": "石阡县"
					},
					{
						"code": 520624000000,
						"value": "思南县",
						"label": "思南县"
					},
					{
						"code": 520625000000,
						"value": "印江土家族苗族自治县",
						"label": "印江土家族苗族自治县"
					},
					{
						"code": 520626000000,
						"value": "德江县",
						"label": "德江县"
					},
					{
						"code": 520627000000,
						"value": "沿河土家族自治县",
						"label": "沿河土家族自治县"
					},
					{
						"code": 520628000000,
						"value": "松桃苗族自治县",
						"label": "松桃苗族自治县"
					}
				],
				"value": "铜仁市",
				"label": "铜仁市"
			},
			{
				"code": 522300000000,
				"children": [
					{
						"code": 522301000000,
						"value": "兴义市",
						"label": "兴义市"
					},
					{
						"code": 522302000000,
						"value": "兴仁市",
						"label": "兴仁市"
					},
					{
						"code": 522323000000,
						"value": "普安县",
						"label": "普安县"
					},
					{
						"code": 522324000000,
						"value": "晴隆县",
						"label": "晴隆县"
					},
					{
						"code": 522325000000,
						"value": "贞丰县",
						"label": "贞丰县"
					},
					{
						"code": 522326000000,
						"value": "望谟县",
						"label": "望谟县"
					},
					{
						"code": 522327000000,
						"value": "册亨县",
						"label": "册亨县"
					},
					{
						"code": 522328000000,
						"value": "安龙县",
						"label": "安龙县"
					}
				],
				"value": "黔西南布依族苗族自治州",
				"label": "黔西南布依族苗族自治州"
			},
			{
				"code": 522600000000,
				"children": [
					{
						"code": 522601000000,
						"value": "凯里市",
						"label": "凯里市"
					},
					{
						"code": 522622000000,
						"value": "黄平县",
						"label": "黄平县"
					},
					{
						"code": 522623000000,
						"value": "施秉县",
						"label": "施秉县"
					},
					{
						"code": 522624000000,
						"value": "三穗县",
						"label": "三穗县"
					},
					{
						"code": 522625000000,
						"value": "镇远县",
						"label": "镇远县"
					},
					{
						"code": 522626000000,
						"value": "岑巩县",
						"label": "岑巩县"
					},
					{
						"code": 522627000000,
						"value": "天柱县",
						"label": "天柱县"
					},
					{
						"code": 522628000000,
						"value": "锦屏县",
						"label": "锦屏县"
					},
					{
						"code": 522629000000,
						"value": "剑河县",
						"label": "剑河县"
					},
					{
						"code": 522630000000,
						"value": "台江县",
						"label": "台江县"
					},
					{
						"code": 522631000000,
						"value": "黎平县",
						"label": "黎平县"
					},
					{
						"code": 522632000000,
						"value": "榕江县",
						"label": "榕江县"
					},
					{
						"code": 522633000000,
						"value": "从江县",
						"label": "从江县"
					},
					{
						"code": 522634000000,
						"value": "雷山县",
						"label": "雷山县"
					},
					{
						"code": 522635000000,
						"value": "麻江县",
						"label": "麻江县"
					},
					{
						"code": 522636000000,
						"value": "丹寨县",
						"label": "丹寨县"
					}
				],
				"value": "黔东南苗族侗族自治州",
				"label": "黔东南苗族侗族自治州"
			},
			{
				"code": 522700000000,
				"children": [
					{
						"code": 522701000000,
						"value": "都匀市",
						"label": "都匀市"
					},
					{
						"code": 522702000000,
						"value": "福泉市",
						"label": "福泉市"
					},
					{
						"code": 522722000000,
						"value": "荔波县",
						"label": "荔波县"
					},
					{
						"code": 522723000000,
						"value": "贵定县",
						"label": "贵定县"
					},
					{
						"code": 522725000000,
						"value": "瓮安县",
						"label": "瓮安县"
					},
					{
						"code": 522726000000,
						"value": "独山县",
						"label": "独山县"
					},
					{
						"code": 522727000000,
						"value": "平塘县",
						"label": "平塘县"
					},
					{
						"code": 522728000000,
						"value": "罗甸县",
						"label": "罗甸县"
					},
					{
						"code": 522729000000,
						"value": "长顺县",
						"label": "长顺县"
					},
					{
						"code": 522730000000,
						"value": "龙里县",
						"label": "龙里县"
					},
					{
						"code": 522731000000,
						"value": "惠水县",
						"label": "惠水县"
					},
					{
						"code": 522732000000,
						"value": "三都水族自治县",
						"label": "三都水族自治县"
					}
				],
				"value": "黔南布依族苗族自治州",
				"label": "黔南布依族苗族自治州"
			}
		],
		"value": "贵州省",
		"label": "贵州省"
	},
	{
		"code": 530000000000,
		"children": [
			{
				"code": 530100000000,
				"children": [
					{
						"code": 530101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530102000000,
						"value": "五华区",
						"label": "五华区"
					},
					{
						"code": 530103000000,
						"value": "盘龙区",
						"label": "盘龙区"
					},
					{
						"code": 530111000000,
						"value": "官渡区",
						"label": "官渡区"
					},
					{
						"code": 530112000000,
						"value": "西山区",
						"label": "西山区"
					},
					{
						"code": 530113000000,
						"value": "东川区",
						"label": "东川区"
					},
					{
						"code": 530114000000,
						"value": "呈贡区",
						"label": "呈贡区"
					},
					{
						"code": 530115000000,
						"value": "晋宁区",
						"label": "晋宁区"
					},
					{
						"code": 530124000000,
						"value": "富民县",
						"label": "富民县"
					},
					{
						"code": 530125000000,
						"value": "宜良县",
						"label": "宜良县"
					},
					{
						"code": 530126000000,
						"value": "石林彝族自治县",
						"label": "石林彝族自治县"
					},
					{
						"code": 530127000000,
						"value": "嵩明县",
						"label": "嵩明县"
					},
					{
						"code": 530128000000,
						"value": "禄劝彝族苗族自治县",
						"label": "禄劝彝族苗族自治县"
					},
					{
						"code": 530129000000,
						"value": "寻甸回族彝族自治县",
						"label": "寻甸回族彝族自治县"
					},
					{
						"code": 530181000000,
						"value": "安宁市",
						"label": "安宁市"
					}
				],
				"value": "昆明市",
				"label": "昆明市"
			},
			{
				"code": 530300000000,
				"children": [
					{
						"code": 530301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530302000000,
						"value": "麒麟区",
						"label": "麒麟区"
					},
					{
						"code": 530303000000,
						"value": "沾益区",
						"label": "沾益区"
					},
					{
						"code": 530304000000,
						"value": "马龙区",
						"label": "马龙区"
					},
					{
						"code": 530322000000,
						"value": "陆良县",
						"label": "陆良县"
					},
					{
						"code": 530323000000,
						"value": "师宗县",
						"label": "师宗县"
					},
					{
						"code": 530324000000,
						"value": "罗平县",
						"label": "罗平县"
					},
					{
						"code": 530325000000,
						"value": "富源县",
						"label": "富源县"
					},
					{
						"code": 530326000000,
						"value": "会泽县",
						"label": "会泽县"
					},
					{
						"code": 530381000000,
						"value": "宣威市",
						"label": "宣威市"
					}
				],
				"value": "曲靖市",
				"label": "曲靖市"
			},
			{
				"code": 530400000000,
				"children": [
					{
						"code": 530401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530402000000,
						"value": "红塔区",
						"label": "红塔区"
					},
					{
						"code": 530403000000,
						"value": "江川区",
						"label": "江川区"
					},
					{
						"code": 530423000000,
						"value": "通海县",
						"label": "通海县"
					},
					{
						"code": 530424000000,
						"value": "华宁县",
						"label": "华宁县"
					},
					{
						"code": 530425000000,
						"value": "易门县",
						"label": "易门县"
					},
					{
						"code": 530426000000,
						"value": "峨山彝族自治县",
						"label": "峨山彝族自治县"
					},
					{
						"code": 530427000000,
						"value": "新平彝族傣族自治县",
						"label": "新平彝族傣族自治县"
					},
					{
						"code": 530428000000,
						"value": "元江哈尼族彝族傣族自治县",
						"label": "元江哈尼族彝族傣族自治县"
					},
					{
						"code": 530481000000,
						"value": "澄江市",
						"label": "澄江市"
					}
				],
				"value": "玉溪市",
				"label": "玉溪市"
			},
			{
				"code": 530500000000,
				"children": [
					{
						"code": 530501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530502000000,
						"value": "隆阳区",
						"label": "隆阳区"
					},
					{
						"code": 530521000000,
						"value": "施甸县",
						"label": "施甸县"
					},
					{
						"code": 530523000000,
						"value": "龙陵县",
						"label": "龙陵县"
					},
					{
						"code": 530524000000,
						"value": "昌宁县",
						"label": "昌宁县"
					},
					{
						"code": 530581000000,
						"value": "腾冲市",
						"label": "腾冲市"
					}
				],
				"value": "保山市",
				"label": "保山市"
			},
			{
				"code": 530600000000,
				"children": [
					{
						"code": 530601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530602000000,
						"value": "昭阳区",
						"label": "昭阳区"
					},
					{
						"code": 530621000000,
						"value": "鲁甸县",
						"label": "鲁甸县"
					},
					{
						"code": 530622000000,
						"value": "巧家县",
						"label": "巧家县"
					},
					{
						"code": 530623000000,
						"value": "盐津县",
						"label": "盐津县"
					},
					{
						"code": 530624000000,
						"value": "大关县",
						"label": "大关县"
					},
					{
						"code": 530625000000,
						"value": "永善县",
						"label": "永善县"
					},
					{
						"code": 530626000000,
						"value": "绥江县",
						"label": "绥江县"
					},
					{
						"code": 530627000000,
						"value": "镇雄县",
						"label": "镇雄县"
					},
					{
						"code": 530628000000,
						"value": "彝良县",
						"label": "彝良县"
					},
					{
						"code": 530629000000,
						"value": "威信县",
						"label": "威信县"
					},
					{
						"code": 530681000000,
						"value": "水富市",
						"label": "水富市"
					}
				],
				"value": "昭通市",
				"label": "昭通市"
			},
			{
				"code": 530700000000,
				"children": [
					{
						"code": 530701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530702000000,
						"value": "古城区",
						"label": "古城区"
					},
					{
						"code": 530721000000,
						"value": "玉龙纳西族自治县",
						"label": "玉龙纳西族自治县"
					},
					{
						"code": 530722000000,
						"value": "永胜县",
						"label": "永胜县"
					},
					{
						"code": 530723000000,
						"value": "华坪县",
						"label": "华坪县"
					},
					{
						"code": 530724000000,
						"value": "宁蒗彝族自治县",
						"label": "宁蒗彝族自治县"
					}
				],
				"value": "丽江市",
				"label": "丽江市"
			},
			{
				"code": 530800000000,
				"children": [
					{
						"code": 530801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530802000000,
						"value": "思茅区",
						"label": "思茅区"
					},
					{
						"code": 530821000000,
						"value": "宁洱哈尼族彝族自治县",
						"label": "宁洱哈尼族彝族自治县"
					},
					{
						"code": 530822000000,
						"value": "墨江哈尼族自治县",
						"label": "墨江哈尼族自治县"
					},
					{
						"code": 530823000000,
						"value": "景东彝族自治县",
						"label": "景东彝族自治县"
					},
					{
						"code": 530824000000,
						"value": "景谷傣族彝族自治县",
						"label": "景谷傣族彝族自治县"
					},
					{
						"code": 530825000000,
						"value": "镇沅彝族哈尼族拉祜族自治县",
						"label": "镇沅彝族哈尼族拉祜族自治县"
					},
					{
						"code": 530826000000,
						"value": "江城哈尼族彝族自治县",
						"label": "江城哈尼族彝族自治县"
					},
					{
						"code": 530827000000,
						"value": "孟连傣族拉祜族佤族自治县",
						"label": "孟连傣族拉祜族佤族自治县"
					},
					{
						"code": 530828000000,
						"value": "澜沧拉祜族自治县",
						"label": "澜沧拉祜族自治县"
					},
					{
						"code": 530829000000,
						"value": "西盟佤族自治县",
						"label": "西盟佤族自治县"
					}
				],
				"value": "普洱市",
				"label": "普洱市"
			},
			{
				"code": 530900000000,
				"children": [
					{
						"code": 530901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 530902000000,
						"value": "临翔区",
						"label": "临翔区"
					},
					{
						"code": 530921000000,
						"value": "凤庆县",
						"label": "凤庆县"
					},
					{
						"code": 530922000000,
						"value": "云县",
						"label": "云县"
					},
					{
						"code": 530923000000,
						"value": "永德县",
						"label": "永德县"
					},
					{
						"code": 530924000000,
						"value": "镇康县",
						"label": "镇康县"
					},
					{
						"code": 530925000000,
						"value": "双江拉祜族佤族布朗族傣族自治县",
						"label": "双江拉祜族佤族布朗族傣族自治县"
					},
					{
						"code": 530926000000,
						"value": "耿马傣族佤族自治县",
						"label": "耿马傣族佤族自治县"
					},
					{
						"code": 530927000000,
						"value": "沧源佤族自治县",
						"label": "沧源佤族自治县"
					}
				],
				"value": "临沧市",
				"label": "临沧市"
			},
			{
				"code": 532300000000,
				"children": [
					{
						"code": 532301000000,
						"value": "楚雄市",
						"label": "楚雄市"
					},
					{
						"code": 532322000000,
						"value": "双柏县",
						"label": "双柏县"
					},
					{
						"code": 532323000000,
						"value": "牟定县",
						"label": "牟定县"
					},
					{
						"code": 532324000000,
						"value": "南华县",
						"label": "南华县"
					},
					{
						"code": 532325000000,
						"value": "姚安县",
						"label": "姚安县"
					},
					{
						"code": 532326000000,
						"value": "大姚县",
						"label": "大姚县"
					},
					{
						"code": 532327000000,
						"value": "永仁县",
						"label": "永仁县"
					},
					{
						"code": 532328000000,
						"value": "元谋县",
						"label": "元谋县"
					},
					{
						"code": 532329000000,
						"value": "武定县",
						"label": "武定县"
					},
					{
						"code": 532331000000,
						"value": "禄丰县",
						"label": "禄丰县"
					}
				],
				"value": "楚雄彝族自治州",
				"label": "楚雄彝族自治州"
			},
			{
				"code": 532500000000,
				"children": [
					{
						"code": 532501000000,
						"value": "个旧市",
						"label": "个旧市"
					},
					{
						"code": 532502000000,
						"value": "开远市",
						"label": "开远市"
					},
					{
						"code": 532503000000,
						"value": "蒙自市",
						"label": "蒙自市"
					},
					{
						"code": 532504000000,
						"value": "弥勒市",
						"label": "弥勒市"
					},
					{
						"code": 532523000000,
						"value": "屏边苗族自治县",
						"label": "屏边苗族自治县"
					},
					{
						"code": 532524000000,
						"value": "建水县",
						"label": "建水县"
					},
					{
						"code": 532525000000,
						"value": "石屏县",
						"label": "石屏县"
					},
					{
						"code": 532527000000,
						"value": "泸西县",
						"label": "泸西县"
					},
					{
						"code": 532528000000,
						"value": "元阳县",
						"label": "元阳县"
					},
					{
						"code": 532529000000,
						"value": "红河县",
						"label": "红河县"
					},
					{
						"code": 532530000000,
						"value": "金平苗族瑶族傣族自治县",
						"label": "金平苗族瑶族傣族自治县"
					},
					{
						"code": 532531000000,
						"value": "绿春县",
						"label": "绿春县"
					},
					{
						"code": 532532000000,
						"value": "河口瑶族自治县",
						"label": "河口瑶族自治县"
					}
				],
				"value": "红河哈尼族彝族自治州",
				"label": "红河哈尼族彝族自治州"
			},
			{
				"code": 532600000000,
				"children": [
					{
						"code": 532601000000,
						"value": "文山市",
						"label": "文山市"
					},
					{
						"code": 532622000000,
						"value": "砚山县",
						"label": "砚山县"
					},
					{
						"code": 532623000000,
						"value": "西畴县",
						"label": "西畴县"
					},
					{
						"code": 532624000000,
						"value": "麻栗坡县",
						"label": "麻栗坡县"
					},
					{
						"code": 532625000000,
						"value": "马关县",
						"label": "马关县"
					},
					{
						"code": 532626000000,
						"value": "丘北县",
						"label": "丘北县"
					},
					{
						"code": 532627000000,
						"value": "广南县",
						"label": "广南县"
					},
					{
						"code": 532628000000,
						"value": "富宁县",
						"label": "富宁县"
					}
				],
				"value": "文山壮族苗族自治州",
				"label": "文山壮族苗族自治州"
			},
			{
				"code": 532800000000,
				"children": [
					{
						"code": 532801000000,
						"value": "景洪市",
						"label": "景洪市"
					},
					{
						"code": 532822000000,
						"value": "勐海县",
						"label": "勐海县"
					},
					{
						"code": 532823000000,
						"value": "勐腊县",
						"label": "勐腊县"
					}
				],
				"value": "西双版纳傣族自治州",
				"label": "西双版纳傣族自治州"
			},
			{
				"code": 532900000000,
				"children": [
					{
						"code": 532901000000,
						"value": "大理市",
						"label": "大理市"
					},
					{
						"code": 532922000000,
						"value": "漾濞彝族自治县",
						"label": "漾濞彝族自治县"
					},
					{
						"code": 532923000000,
						"value": "祥云县",
						"label": "祥云县"
					},
					{
						"code": 532924000000,
						"value": "宾川县",
						"label": "宾川县"
					},
					{
						"code": 532925000000,
						"value": "弥渡县",
						"label": "弥渡县"
					},
					{
						"code": 532926000000,
						"value": "南涧彝族自治县",
						"label": "南涧彝族自治县"
					},
					{
						"code": 532927000000,
						"value": "巍山彝族回族自治县",
						"label": "巍山彝族回族自治县"
					},
					{
						"code": 532928000000,
						"value": "永平县",
						"label": "永平县"
					},
					{
						"code": 532929000000,
						"value": "云龙县",
						"label": "云龙县"
					},
					{
						"code": 532930000000,
						"value": "洱源县",
						"label": "洱源县"
					},
					{
						"code": 532931000000,
						"value": "剑川县",
						"label": "剑川县"
					},
					{
						"code": 532932000000,
						"value": "鹤庆县",
						"label": "鹤庆县"
					}
				],
				"value": "大理白族自治州",
				"label": "大理白族自治州"
			},
			{
				"code": 533100000000,
				"children": [
					{
						"code": 533102000000,
						"value": "瑞丽市",
						"label": "瑞丽市"
					},
					{
						"code": 533103000000,
						"value": "芒市",
						"label": "芒市"
					},
					{
						"code": 533122000000,
						"value": "梁河县",
						"label": "梁河县"
					},
					{
						"code": 533123000000,
						"value": "盈江县",
						"label": "盈江县"
					},
					{
						"code": 533124000000,
						"value": "陇川县",
						"label": "陇川县"
					}
				],
				"value": "德宏傣族景颇族自治州",
				"label": "德宏傣族景颇族自治州"
			},
			{
				"code": 533300000000,
				"children": [
					{
						"code": 533301000000,
						"value": "泸水市",
						"label": "泸水市"
					},
					{
						"code": 533323000000,
						"value": "福贡县",
						"label": "福贡县"
					},
					{
						"code": 533324000000,
						"value": "贡山独龙族怒族自治县",
						"label": "贡山独龙族怒族自治县"
					},
					{
						"code": 533325000000,
						"value": "兰坪白族普米族自治县",
						"label": "兰坪白族普米族自治县"
					}
				],
				"value": "怒江傈僳族自治州",
				"label": "怒江傈僳族自治州"
			},
			{
				"code": 533400000000,
				"children": [
					{
						"code": 533401000000,
						"value": "香格里拉市",
						"label": "香格里拉市"
					},
					{
						"code": 533422000000,
						"value": "德钦县",
						"label": "德钦县"
					},
					{
						"code": 533423000000,
						"value": "维西傈僳族自治县",
						"label": "维西傈僳族自治县"
					}
				],
				"value": "迪庆藏族自治州",
				"label": "迪庆藏族自治州"
			}
		],
		"value": "云南省",
		"label": "云南省"
	},
	{
		"code": 540000000000,
		"children": [
			{
				"code": 540100000000,
				"children": [
					{
						"code": 540101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 540102000000,
						"value": "城关区",
						"label": "城关区"
					},
					{
						"code": 540103000000,
						"value": "堆龙德庆区",
						"label": "堆龙德庆区"
					},
					{
						"code": 540104000000,
						"value": "达孜区",
						"label": "达孜区"
					},
					{
						"code": 540121000000,
						"value": "林周县",
						"label": "林周县"
					},
					{
						"code": 540122000000,
						"value": "当雄县",
						"label": "当雄县"
					},
					{
						"code": 540123000000,
						"value": "尼木县",
						"label": "尼木县"
					},
					{
						"code": 540124000000,
						"value": "曲水县",
						"label": "曲水县"
					},
					{
						"code": 540127000000,
						"value": "墨竹工卡县",
						"label": "墨竹工卡县"
					},
					{
						"code": 540171000000,
						"value": "格尔木藏青工业园区",
						"label": "格尔木藏青工业园区"
					},
					{
						"code": 540172000000,
						"value": "拉萨经济技术开发区",
						"label": "拉萨经济技术开发区"
					},
					{
						"code": 540173000000,
						"value": "西藏文化旅游创意园区",
						"label": "西藏文化旅游创意园区"
					},
					{
						"code": 540174000000,
						"value": "达孜工业园区",
						"label": "达孜工业园区"
					}
				],
				"value": "拉萨市",
				"label": "拉萨市"
			},
			{
				"code": 540200000000,
				"children": [
					{
						"code": 540202000000,
						"value": "桑珠孜区",
						"label": "桑珠孜区"
					},
					{
						"code": 540221000000,
						"value": "南木林县",
						"label": "南木林县"
					},
					{
						"code": 540222000000,
						"value": "江孜县",
						"label": "江孜县"
					},
					{
						"code": 540223000000,
						"value": "定日县",
						"label": "定日县"
					},
					{
						"code": 540224000000,
						"value": "萨迦县",
						"label": "萨迦县"
					},
					{
						"code": 540225000000,
						"value": "拉孜县",
						"label": "拉孜县"
					},
					{
						"code": 540226000000,
						"value": "昂仁县",
						"label": "昂仁县"
					},
					{
						"code": 540227000000,
						"value": "谢通门县",
						"label": "谢通门县"
					},
					{
						"code": 540228000000,
						"value": "白朗县",
						"label": "白朗县"
					},
					{
						"code": 540229000000,
						"value": "仁布县",
						"label": "仁布县"
					},
					{
						"code": 540230000000,
						"value": "康马县",
						"label": "康马县"
					},
					{
						"code": 540231000000,
						"value": "定结县",
						"label": "定结县"
					},
					{
						"code": 540232000000,
						"value": "仲巴县",
						"label": "仲巴县"
					},
					{
						"code": 540233000000,
						"value": "亚东县",
						"label": "亚东县"
					},
					{
						"code": 540234000000,
						"value": "吉隆县",
						"label": "吉隆县"
					},
					{
						"code": 540235000000,
						"value": "聂拉木县",
						"label": "聂拉木县"
					},
					{
						"code": 540236000000,
						"value": "萨嘎县",
						"label": "萨嘎县"
					},
					{
						"code": 540237000000,
						"value": "岗巴县",
						"label": "岗巴县"
					}
				],
				"value": "日喀则市",
				"label": "日喀则市"
			},
			{
				"code": 540300000000,
				"children": [
					{
						"code": 540302000000,
						"value": "卡若区",
						"label": "卡若区"
					},
					{
						"code": 540321000000,
						"value": "江达县",
						"label": "江达县"
					},
					{
						"code": 540322000000,
						"value": "贡觉县",
						"label": "贡觉县"
					},
					{
						"code": 540323000000,
						"value": "类乌齐县",
						"label": "类乌齐县"
					},
					{
						"code": 540324000000,
						"value": "丁青县",
						"label": "丁青县"
					},
					{
						"code": 540325000000,
						"value": "察雅县",
						"label": "察雅县"
					},
					{
						"code": 540326000000,
						"value": "八宿县",
						"label": "八宿县"
					},
					{
						"code": 540327000000,
						"value": "左贡县",
						"label": "左贡县"
					},
					{
						"code": 540328000000,
						"value": "芒康县",
						"label": "芒康县"
					},
					{
						"code": 540329000000,
						"value": "洛隆县",
						"label": "洛隆县"
					},
					{
						"code": 540330000000,
						"value": "边坝县",
						"label": "边坝县"
					}
				],
				"value": "昌都市",
				"label": "昌都市"
			},
			{
				"code": 540400000000,
				"children": [
					{
						"code": 540402000000,
						"value": "巴宜区",
						"label": "巴宜区"
					},
					{
						"code": 540421000000,
						"value": "工布江达县",
						"label": "工布江达县"
					},
					{
						"code": 540422000000,
						"value": "米林县",
						"label": "米林县"
					},
					{
						"code": 540423000000,
						"value": "墨脱县",
						"label": "墨脱县"
					},
					{
						"code": 540424000000,
						"value": "波密县",
						"label": "波密县"
					},
					{
						"code": 540425000000,
						"value": "察隅县",
						"label": "察隅县"
					},
					{
						"code": 540426000000,
						"value": "朗县",
						"label": "朗县"
					}
				],
				"value": "林芝市",
				"label": "林芝市"
			},
			{
				"code": 540500000000,
				"children": [
					{
						"code": 540501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 540502000000,
						"value": "乃东区",
						"label": "乃东区"
					},
					{
						"code": 540521000000,
						"value": "扎囊县",
						"label": "扎囊县"
					},
					{
						"code": 540522000000,
						"value": "贡嘎县",
						"label": "贡嘎县"
					},
					{
						"code": 540523000000,
						"value": "桑日县",
						"label": "桑日县"
					},
					{
						"code": 540524000000,
						"value": "琼结县",
						"label": "琼结县"
					},
					{
						"code": 540525000000,
						"value": "曲松县",
						"label": "曲松县"
					},
					{
						"code": 540526000000,
						"value": "措美县",
						"label": "措美县"
					},
					{
						"code": 540527000000,
						"value": "洛扎县",
						"label": "洛扎县"
					},
					{
						"code": 540528000000,
						"value": "加查县",
						"label": "加查县"
					},
					{
						"code": 540529000000,
						"value": "隆子县",
						"label": "隆子县"
					},
					{
						"code": 540530000000,
						"value": "错那县",
						"label": "错那县"
					},
					{
						"code": 540531000000,
						"value": "浪卡子县",
						"label": "浪卡子县"
					}
				],
				"value": "山南市",
				"label": "山南市"
			},
			{
				"code": 540600000000,
				"children": [
					{
						"code": 540602000000,
						"value": "色尼区",
						"label": "色尼区"
					},
					{
						"code": 540621000000,
						"value": "嘉黎县",
						"label": "嘉黎县"
					},
					{
						"code": 540622000000,
						"value": "比如县",
						"label": "比如县"
					},
					{
						"code": 540623000000,
						"value": "聂荣县",
						"label": "聂荣县"
					},
					{
						"code": 540624000000,
						"value": "安多县",
						"label": "安多县"
					},
					{
						"code": 540625000000,
						"value": "申扎县",
						"label": "申扎县"
					},
					{
						"code": 540626000000,
						"value": "索县",
						"label": "索县"
					},
					{
						"code": 540627000000,
						"value": "班戈县",
						"label": "班戈县"
					},
					{
						"code": 540628000000,
						"value": "巴青县",
						"label": "巴青县"
					},
					{
						"code": 540629000000,
						"value": "尼玛县",
						"label": "尼玛县"
					},
					{
						"code": 540630000000,
						"value": "双湖县",
						"label": "双湖县"
					}
				],
				"value": "那曲市",
				"label": "那曲市"
			},
			{
				"code": 542500000000,
				"children": [
					{
						"code": 542521000000,
						"value": "普兰县",
						"label": "普兰县"
					},
					{
						"code": 542522000000,
						"value": "札达县",
						"label": "札达县"
					},
					{
						"code": 542523000000,
						"value": "噶尔县",
						"label": "噶尔县"
					},
					{
						"code": 542524000000,
						"value": "日土县",
						"label": "日土县"
					},
					{
						"code": 542525000000,
						"value": "革吉县",
						"label": "革吉县"
					},
					{
						"code": 542526000000,
						"value": "改则县",
						"label": "改则县"
					},
					{
						"code": 542527000000,
						"value": "措勤县",
						"label": "措勤县"
					}
				],
				"value": "阿里地区",
				"label": "阿里地区"
			}
		],
		"value": "西藏自治区",
		"label": "西藏自治区"
	},
	{
		"code": 610000000000,
		"children": [
			{
				"code": 610100000000,
				"children": [
					{
						"code": 610101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610102000000,
						"value": "新城区",
						"label": "新城区"
					},
					{
						"code": 610103000000,
						"value": "碑林区",
						"label": "碑林区"
					},
					{
						"code": 610104000000,
						"value": "莲湖区",
						"label": "莲湖区"
					},
					{
						"code": 610111000000,
						"value": "灞桥区",
						"label": "灞桥区"
					},
					{
						"code": 610112000000,
						"value": "未央区",
						"label": "未央区"
					},
					{
						"code": 610113000000,
						"value": "雁塔区",
						"label": "雁塔区"
					},
					{
						"code": 610114000000,
						"value": "阎良区",
						"label": "阎良区"
					},
					{
						"code": 610115000000,
						"value": "临潼区",
						"label": "临潼区"
					},
					{
						"code": 610116000000,
						"value": "长安区",
						"label": "长安区"
					},
					{
						"code": 610117000000,
						"value": "高陵区",
						"label": "高陵区"
					},
					{
						"code": 610118000000,
						"value": "鄠邑区",
						"label": "鄠邑区"
					},
					{
						"code": 610122000000,
						"value": "蓝田县",
						"label": "蓝田县"
					},
					{
						"code": 610124000000,
						"value": "周至县",
						"label": "周至县"
					}
				],
				"value": "西安市",
				"label": "西安市"
			},
			{
				"code": 610200000000,
				"children": [
					{
						"code": 610201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610202000000,
						"value": "王益区",
						"label": "王益区"
					},
					{
						"code": 610203000000,
						"value": "印台区",
						"label": "印台区"
					},
					{
						"code": 610204000000,
						"value": "耀州区",
						"label": "耀州区"
					},
					{
						"code": 610222000000,
						"value": "宜君县",
						"label": "宜君县"
					}
				],
				"value": "铜川市",
				"label": "铜川市"
			},
			{
				"code": 610300000000,
				"children": [
					{
						"code": 610301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610302000000,
						"value": "渭滨区",
						"label": "渭滨区"
					},
					{
						"code": 610303000000,
						"value": "金台区",
						"label": "金台区"
					},
					{
						"code": 610304000000,
						"value": "陈仓区",
						"label": "陈仓区"
					},
					{
						"code": 610322000000,
						"value": "凤翔县",
						"label": "凤翔县"
					},
					{
						"code": 610323000000,
						"value": "岐山县",
						"label": "岐山县"
					},
					{
						"code": 610324000000,
						"value": "扶风县",
						"label": "扶风县"
					},
					{
						"code": 610326000000,
						"value": "眉县",
						"label": "眉县"
					},
					{
						"code": 610327000000,
						"value": "陇县",
						"label": "陇县"
					},
					{
						"code": 610328000000,
						"value": "千阳县",
						"label": "千阳县"
					},
					{
						"code": 610329000000,
						"value": "麟游县",
						"label": "麟游县"
					},
					{
						"code": 610330000000,
						"value": "凤县",
						"label": "凤县"
					},
					{
						"code": 610331000000,
						"value": "太白县",
						"label": "太白县"
					}
				],
				"value": "宝鸡市",
				"label": "宝鸡市"
			},
			{
				"code": 610400000000,
				"children": [
					{
						"code": 610401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610402000000,
						"value": "秦都区",
						"label": "秦都区"
					},
					{
						"code": 610403000000,
						"value": "杨陵区",
						"label": "杨陵区"
					},
					{
						"code": 610404000000,
						"value": "渭城区",
						"label": "渭城区"
					},
					{
						"code": 610422000000,
						"value": "三原县",
						"label": "三原县"
					},
					{
						"code": 610423000000,
						"value": "泾阳县",
						"label": "泾阳县"
					},
					{
						"code": 610424000000,
						"value": "乾县",
						"label": "乾县"
					},
					{
						"code": 610425000000,
						"value": "礼泉县",
						"label": "礼泉县"
					},
					{
						"code": 610426000000,
						"value": "永寿县",
						"label": "永寿县"
					},
					{
						"code": 610428000000,
						"value": "长武县",
						"label": "长武县"
					},
					{
						"code": 610429000000,
						"value": "旬邑县",
						"label": "旬邑县"
					},
					{
						"code": 610430000000,
						"value": "淳化县",
						"label": "淳化县"
					},
					{
						"code": 610431000000,
						"value": "武功县",
						"label": "武功县"
					},
					{
						"code": 610481000000,
						"value": "兴平市",
						"label": "兴平市"
					},
					{
						"code": 610482000000,
						"value": "彬州市",
						"label": "彬州市"
					}
				],
				"value": "咸阳市",
				"label": "咸阳市"
			},
			{
				"code": 610500000000,
				"children": [
					{
						"code": 610501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610502000000,
						"value": "临渭区",
						"label": "临渭区"
					},
					{
						"code": 610503000000,
						"value": "华州区",
						"label": "华州区"
					},
					{
						"code": 610522000000,
						"value": "潼关县",
						"label": "潼关县"
					},
					{
						"code": 610523000000,
						"value": "大荔县",
						"label": "大荔县"
					},
					{
						"code": 610524000000,
						"value": "合阳县",
						"label": "合阳县"
					},
					{
						"code": 610525000000,
						"value": "澄城县",
						"label": "澄城县"
					},
					{
						"code": 610526000000,
						"value": "蒲城县",
						"label": "蒲城县"
					},
					{
						"code": 610527000000,
						"value": "白水县",
						"label": "白水县"
					},
					{
						"code": 610528000000,
						"value": "富平县",
						"label": "富平县"
					},
					{
						"code": 610581000000,
						"value": "韩城市",
						"label": "韩城市"
					},
					{
						"code": 610582000000,
						"value": "华阴市",
						"label": "华阴市"
					}
				],
				"value": "渭南市",
				"label": "渭南市"
			},
			{
				"code": 610600000000,
				"children": [
					{
						"code": 610601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610602000000,
						"value": "宝塔区",
						"label": "宝塔区"
					},
					{
						"code": 610603000000,
						"value": "安塞区",
						"label": "安塞区"
					},
					{
						"code": 610621000000,
						"value": "延长县",
						"label": "延长县"
					},
					{
						"code": 610622000000,
						"value": "延川县",
						"label": "延川县"
					},
					{
						"code": 610625000000,
						"value": "志丹县",
						"label": "志丹县"
					},
					{
						"code": 610626000000,
						"value": "吴起县",
						"label": "吴起县"
					},
					{
						"code": 610627000000,
						"value": "甘泉县",
						"label": "甘泉县"
					},
					{
						"code": 610628000000,
						"value": "富县",
						"label": "富县"
					},
					{
						"code": 610629000000,
						"value": "洛川县",
						"label": "洛川县"
					},
					{
						"code": 610630000000,
						"value": "宜川县",
						"label": "宜川县"
					},
					{
						"code": 610631000000,
						"value": "黄龙县",
						"label": "黄龙县"
					},
					{
						"code": 610632000000,
						"value": "黄陵县",
						"label": "黄陵县"
					},
					{
						"code": 610681000000,
						"value": "子长市",
						"label": "子长市"
					}
				],
				"value": "延安市",
				"label": "延安市"
			},
			{
				"code": 610700000000,
				"children": [
					{
						"code": 610701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610702000000,
						"value": "汉台区",
						"label": "汉台区"
					},
					{
						"code": 610703000000,
						"value": "南郑区",
						"label": "南郑区"
					},
					{
						"code": 610722000000,
						"value": "城固县",
						"label": "城固县"
					},
					{
						"code": 610723000000,
						"value": "洋县",
						"label": "洋县"
					},
					{
						"code": 610724000000,
						"value": "西乡县",
						"label": "西乡县"
					},
					{
						"code": 610725000000,
						"value": "勉县",
						"label": "勉县"
					},
					{
						"code": 610726000000,
						"value": "宁强县",
						"label": "宁强县"
					},
					{
						"code": 610727000000,
						"value": "略阳县",
						"label": "略阳县"
					},
					{
						"code": 610728000000,
						"value": "镇巴县",
						"label": "镇巴县"
					},
					{
						"code": 610729000000,
						"value": "留坝县",
						"label": "留坝县"
					},
					{
						"code": 610730000000,
						"value": "佛坪县",
						"label": "佛坪县"
					}
				],
				"value": "汉中市",
				"label": "汉中市"
			},
			{
				"code": 610800000000,
				"children": [
					{
						"code": 610801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610802000000,
						"value": "榆阳区",
						"label": "榆阳区"
					},
					{
						"code": 610803000000,
						"value": "横山区",
						"label": "横山区"
					},
					{
						"code": 610822000000,
						"value": "府谷县",
						"label": "府谷县"
					},
					{
						"code": 610824000000,
						"value": "靖边县",
						"label": "靖边县"
					},
					{
						"code": 610825000000,
						"value": "定边县",
						"label": "定边县"
					},
					{
						"code": 610826000000,
						"value": "绥德县",
						"label": "绥德县"
					},
					{
						"code": 610827000000,
						"value": "米脂县",
						"label": "米脂县"
					},
					{
						"code": 610828000000,
						"value": "佳县",
						"label": "佳县"
					},
					{
						"code": 610829000000,
						"value": "吴堡县",
						"label": "吴堡县"
					},
					{
						"code": 610830000000,
						"value": "清涧县",
						"label": "清涧县"
					},
					{
						"code": 610831000000,
						"value": "子洲县",
						"label": "子洲县"
					},
					{
						"code": 610881000000,
						"value": "神木市",
						"label": "神木市"
					}
				],
				"value": "榆林市",
				"label": "榆林市"
			},
			{
				"code": 610900000000,
				"children": [
					{
						"code": 610901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 610902000000,
						"value": "汉滨区",
						"label": "汉滨区"
					},
					{
						"code": 610921000000,
						"value": "汉阴县",
						"label": "汉阴县"
					},
					{
						"code": 610922000000,
						"value": "石泉县",
						"label": "石泉县"
					},
					{
						"code": 610923000000,
						"value": "宁陕县",
						"label": "宁陕县"
					},
					{
						"code": 610924000000,
						"value": "紫阳县",
						"label": "紫阳县"
					},
					{
						"code": 610925000000,
						"value": "岚皋县",
						"label": "岚皋县"
					},
					{
						"code": 610926000000,
						"value": "平利县",
						"label": "平利县"
					},
					{
						"code": 610927000000,
						"value": "镇坪县",
						"label": "镇坪县"
					},
					{
						"code": 610928000000,
						"value": "旬阳县",
						"label": "旬阳县"
					},
					{
						"code": 610929000000,
						"value": "白河县",
						"label": "白河县"
					}
				],
				"value": "安康市",
				"label": "安康市"
			},
			{
				"code": 611000000000,
				"children": [
					{
						"code": 611001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 611002000000,
						"value": "商州区",
						"label": "商州区"
					},
					{
						"code": 611021000000,
						"value": "洛南县",
						"label": "洛南县"
					},
					{
						"code": 611022000000,
						"value": "丹凤县",
						"label": "丹凤县"
					},
					{
						"code": 611023000000,
						"value": "商南县",
						"label": "商南县"
					},
					{
						"code": 611024000000,
						"value": "山阳县",
						"label": "山阳县"
					},
					{
						"code": 611025000000,
						"value": "镇安县",
						"label": "镇安县"
					},
					{
						"code": 611026000000,
						"value": "柞水县",
						"label": "柞水县"
					}
				],
				"value": "商洛市",
				"label": "商洛市"
			}
		],
		"value": "陕西省",
		"label": "陕西省"
	},
	{
		"code": 620000000000,
		"children": [
			{
				"code": 620100000000,
				"children": [
					{
						"code": 620101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620102000000,
						"value": "城关区",
						"label": "城关区"
					},
					{
						"code": 620103000000,
						"value": "七里河区",
						"label": "七里河区"
					},
					{
						"code": 620104000000,
						"value": "西固区",
						"label": "西固区"
					},
					{
						"code": 620105000000,
						"value": "安宁区",
						"label": "安宁区"
					},
					{
						"code": 620111000000,
						"value": "红古区",
						"label": "红古区"
					},
					{
						"code": 620121000000,
						"value": "永登县",
						"label": "永登县"
					},
					{
						"code": 620122000000,
						"value": "皋兰县",
						"label": "皋兰县"
					},
					{
						"code": 620123000000,
						"value": "榆中县",
						"label": "榆中县"
					},
					{
						"code": 620171000000,
						"value": "兰州新区",
						"label": "兰州新区"
					}
				],
				"value": "兰州市",
				"label": "兰州市"
			},
			{
				"code": 620200000000,
				"children": [
					{
						"code": 620201000000,
						"value": "市辖区",
						"label": "市辖区"
					}
				],
				"value": "嘉峪关市",
				"label": "嘉峪关市"
			},
			{
				"code": 620300000000,
				"children": [
					{
						"code": 620301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620302000000,
						"value": "金川区",
						"label": "金川区"
					},
					{
						"code": 620321000000,
						"value": "永昌县",
						"label": "永昌县"
					}
				],
				"value": "金昌市",
				"label": "金昌市"
			},
			{
				"code": 620400000000,
				"children": [
					{
						"code": 620401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620402000000,
						"value": "白银区",
						"label": "白银区"
					},
					{
						"code": 620403000000,
						"value": "平川区",
						"label": "平川区"
					},
					{
						"code": 620421000000,
						"value": "靖远县",
						"label": "靖远县"
					},
					{
						"code": 620422000000,
						"value": "会宁县",
						"label": "会宁县"
					},
					{
						"code": 620423000000,
						"value": "景泰县",
						"label": "景泰县"
					}
				],
				"value": "白银市",
				"label": "白银市"
			},
			{
				"code": 620500000000,
				"children": [
					{
						"code": 620501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620502000000,
						"value": "秦州区",
						"label": "秦州区"
					},
					{
						"code": 620503000000,
						"value": "麦积区",
						"label": "麦积区"
					},
					{
						"code": 620521000000,
						"value": "清水县",
						"label": "清水县"
					},
					{
						"code": 620522000000,
						"value": "秦安县",
						"label": "秦安县"
					},
					{
						"code": 620523000000,
						"value": "甘谷县",
						"label": "甘谷县"
					},
					{
						"code": 620524000000,
						"value": "武山县",
						"label": "武山县"
					},
					{
						"code": 620525000000,
						"value": "张家川回族自治县",
						"label": "张家川回族自治县"
					}
				],
				"value": "天水市",
				"label": "天水市"
			},
			{
				"code": 620600000000,
				"children": [
					{
						"code": 620601000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620602000000,
						"value": "凉州区",
						"label": "凉州区"
					},
					{
						"code": 620621000000,
						"value": "民勤县",
						"label": "民勤县"
					},
					{
						"code": 620622000000,
						"value": "古浪县",
						"label": "古浪县"
					},
					{
						"code": 620623000000,
						"value": "天祝藏族自治县",
						"label": "天祝藏族自治县"
					}
				],
				"value": "武威市",
				"label": "武威市"
			},
			{
				"code": 620700000000,
				"children": [
					{
						"code": 620701000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620702000000,
						"value": "甘州区",
						"label": "甘州区"
					},
					{
						"code": 620721000000,
						"value": "肃南裕固族自治县",
						"label": "肃南裕固族自治县"
					},
					{
						"code": 620722000000,
						"value": "民乐县",
						"label": "民乐县"
					},
					{
						"code": 620723000000,
						"value": "临泽县",
						"label": "临泽县"
					},
					{
						"code": 620724000000,
						"value": "高台县",
						"label": "高台县"
					},
					{
						"code": 620725000000,
						"value": "山丹县",
						"label": "山丹县"
					}
				],
				"value": "张掖市",
				"label": "张掖市"
			},
			{
				"code": 620800000000,
				"children": [
					{
						"code": 620801000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620802000000,
						"value": "崆峒区",
						"label": "崆峒区"
					},
					{
						"code": 620821000000,
						"value": "泾川县",
						"label": "泾川县"
					},
					{
						"code": 620822000000,
						"value": "灵台县",
						"label": "灵台县"
					},
					{
						"code": 620823000000,
						"value": "崇信县",
						"label": "崇信县"
					},
					{
						"code": 620825000000,
						"value": "庄浪县",
						"label": "庄浪县"
					},
					{
						"code": 620826000000,
						"value": "静宁县",
						"label": "静宁县"
					},
					{
						"code": 620881000000,
						"value": "华亭市",
						"label": "华亭市"
					}
				],
				"value": "平凉市",
				"label": "平凉市"
			},
			{
				"code": 620900000000,
				"children": [
					{
						"code": 620901000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 620902000000,
						"value": "肃州区",
						"label": "肃州区"
					},
					{
						"code": 620921000000,
						"value": "金塔县",
						"label": "金塔县"
					},
					{
						"code": 620922000000,
						"value": "瓜州县",
						"label": "瓜州县"
					},
					{
						"code": 620923000000,
						"value": "肃北蒙古族自治县",
						"label": "肃北蒙古族自治县"
					},
					{
						"code": 620924000000,
						"value": "阿克塞哈萨克族自治县",
						"label": "阿克塞哈萨克族自治县"
					},
					{
						"code": 620981000000,
						"value": "玉门市",
						"label": "玉门市"
					},
					{
						"code": 620982000000,
						"value": "敦煌市",
						"label": "敦煌市"
					}
				],
				"value": "酒泉市",
				"label": "酒泉市"
			},
			{
				"code": 621000000000,
				"children": [
					{
						"code": 621001000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 621002000000,
						"value": "西峰区",
						"label": "西峰区"
					},
					{
						"code": 621021000000,
						"value": "庆城县",
						"label": "庆城县"
					},
					{
						"code": 621022000000,
						"value": "环县",
						"label": "环县"
					},
					{
						"code": 621023000000,
						"value": "华池县",
						"label": "华池县"
					},
					{
						"code": 621024000000,
						"value": "合水县",
						"label": "合水县"
					},
					{
						"code": 621025000000,
						"value": "正宁县",
						"label": "正宁县"
					},
					{
						"code": 621026000000,
						"value": "宁县",
						"label": "宁县"
					},
					{
						"code": 621027000000,
						"value": "镇原县",
						"label": "镇原县"
					}
				],
				"value": "庆阳市",
				"label": "庆阳市"
			},
			{
				"code": 621100000000,
				"children": [
					{
						"code": 621101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 621102000000,
						"value": "安定区",
						"label": "安定区"
					},
					{
						"code": 621121000000,
						"value": "通渭县",
						"label": "通渭县"
					},
					{
						"code": 621122000000,
						"value": "陇西县",
						"label": "陇西县"
					},
					{
						"code": 621123000000,
						"value": "渭源县",
						"label": "渭源县"
					},
					{
						"code": 621124000000,
						"value": "临洮县",
						"label": "临洮县"
					},
					{
						"code": 621125000000,
						"value": "漳县",
						"label": "漳县"
					},
					{
						"code": 621126000000,
						"value": "岷县",
						"label": "岷县"
					}
				],
				"value": "定西市",
				"label": "定西市"
			},
			{
				"code": 621200000000,
				"children": [
					{
						"code": 621201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 621202000000,
						"value": "武都区",
						"label": "武都区"
					},
					{
						"code": 621221000000,
						"value": "成县",
						"label": "成县"
					},
					{
						"code": 621222000000,
						"value": "文县",
						"label": "文县"
					},
					{
						"code": 621223000000,
						"value": "宕昌县",
						"label": "宕昌县"
					},
					{
						"code": 621224000000,
						"value": "康县",
						"label": "康县"
					},
					{
						"code": 621225000000,
						"value": "西和县",
						"label": "西和县"
					},
					{
						"code": 621226000000,
						"value": "礼县",
						"label": "礼县"
					},
					{
						"code": 621227000000,
						"value": "徽县",
						"label": "徽县"
					},
					{
						"code": 621228000000,
						"value": "两当县",
						"label": "两当县"
					}
				],
				"value": "陇南市",
				"label": "陇南市"
			},
			{
				"code": 622900000000,
				"children": [
					{
						"code": 622901000000,
						"value": "临夏市",
						"label": "临夏市"
					},
					{
						"code": 622921000000,
						"value": "临夏县",
						"label": "临夏县"
					},
					{
						"code": 622922000000,
						"value": "康乐县",
						"label": "康乐县"
					},
					{
						"code": 622923000000,
						"value": "永靖县",
						"label": "永靖县"
					},
					{
						"code": 622924000000,
						"value": "广河县",
						"label": "广河县"
					},
					{
						"code": 622925000000,
						"value": "和政县",
						"label": "和政县"
					},
					{
						"code": 622926000000,
						"value": "东乡族自治县",
						"label": "东乡族自治县"
					},
					{
						"code": 622927000000,
						"value": "积石山保安族东乡族撒拉族自治县",
						"label": "积石山保安族东乡族撒拉族自治县"
					}
				],
				"value": "临夏回族自治州",
				"label": "临夏回族自治州"
			},
			{
				"code": 623000000000,
				"children": [
					{
						"code": 623001000000,
						"value": "合作市",
						"label": "合作市"
					},
					{
						"code": 623021000000,
						"value": "临潭县",
						"label": "临潭县"
					},
					{
						"code": 623022000000,
						"value": "卓尼县",
						"label": "卓尼县"
					},
					{
						"code": 623023000000,
						"value": "舟曲县",
						"label": "舟曲县"
					},
					{
						"code": 623024000000,
						"value": "迭部县",
						"label": "迭部县"
					},
					{
						"code": 623025000000,
						"value": "玛曲县",
						"label": "玛曲县"
					},
					{
						"code": 623026000000,
						"value": "碌曲县",
						"label": "碌曲县"
					},
					{
						"code": 623027000000,
						"value": "夏河县",
						"label": "夏河县"
					}
				],
				"value": "甘南藏族自治州",
				"label": "甘南藏族自治州"
			}
		],
		"value": "甘肃省",
		"label": "甘肃省"
	},
	{
		"code": 630000000000,
		"children": [
			{
				"code": 630100000000,
				"children": [
					{
						"code": 630101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 630102000000,
						"value": "城东区",
						"label": "城东区"
					},
					{
						"code": 630103000000,
						"value": "城中区",
						"label": "城中区"
					},
					{
						"code": 630104000000,
						"value": "城西区",
						"label": "城西区"
					},
					{
						"code": 630105000000,
						"value": "城北区",
						"label": "城北区"
					},
					{
						"code": 630106000000,
						"value": "湟中区",
						"label": "湟中区"
					},
					{
						"code": 630121000000,
						"value": "大通回族土族自治县",
						"label": "大通回族土族自治县"
					},
					{
						"code": 630123000000,
						"value": "湟源县",
						"label": "湟源县"
					}
				],
				"value": "西宁市",
				"label": "西宁市"
			},
			{
				"code": 630200000000,
				"children": [
					{
						"code": 630202000000,
						"value": "乐都区",
						"label": "乐都区"
					},
					{
						"code": 630203000000,
						"value": "平安区",
						"label": "平安区"
					},
					{
						"code": 630222000000,
						"value": "民和回族土族自治县",
						"label": "民和回族土族自治县"
					},
					{
						"code": 630223000000,
						"value": "互助土族自治县",
						"label": "互助土族自治县"
					},
					{
						"code": 630224000000,
						"value": "化隆回族自治县",
						"label": "化隆回族自治县"
					},
					{
						"code": 630225000000,
						"value": "循化撒拉族自治县",
						"label": "循化撒拉族自治县"
					}
				],
				"value": "海东市",
				"label": "海东市"
			},
			{
				"code": 632200000000,
				"children": [
					{
						"code": 632221000000,
						"value": "门源回族自治县",
						"label": "门源回族自治县"
					},
					{
						"code": 632222000000,
						"value": "祁连县",
						"label": "祁连县"
					},
					{
						"code": 632223000000,
						"value": "海晏县",
						"label": "海晏县"
					},
					{
						"code": 632224000000,
						"value": "刚察县",
						"label": "刚察县"
					}
				],
				"value": "海北藏族自治州",
				"label": "海北藏族自治州"
			},
			{
				"code": 632300000000,
				"children": [
					{
						"code": 632321000000,
						"value": "同仁县",
						"label": "同仁县"
					},
					{
						"code": 632322000000,
						"value": "尖扎县",
						"label": "尖扎县"
					},
					{
						"code": 632323000000,
						"value": "泽库县",
						"label": "泽库县"
					},
					{
						"code": 632324000000,
						"value": "河南蒙古族自治县",
						"label": "河南蒙古族自治县"
					}
				],
				"value": "黄南藏族自治州",
				"label": "黄南藏族自治州"
			},
			{
				"code": 632500000000,
				"children": [
					{
						"code": 632521000000,
						"value": "共和县",
						"label": "共和县"
					},
					{
						"code": 632522000000,
						"value": "同德县",
						"label": "同德县"
					},
					{
						"code": 632523000000,
						"value": "贵德县",
						"label": "贵德县"
					},
					{
						"code": 632524000000,
						"value": "兴海县",
						"label": "兴海县"
					},
					{
						"code": 632525000000,
						"value": "贵南县",
						"label": "贵南县"
					}
				],
				"value": "海南藏族自治州",
				"label": "海南藏族自治州"
			},
			{
				"code": 632600000000,
				"children": [
					{
						"code": 632621000000,
						"value": "玛沁县",
						"label": "玛沁县"
					},
					{
						"code": 632622000000,
						"value": "班玛县",
						"label": "班玛县"
					},
					{
						"code": 632623000000,
						"value": "甘德县",
						"label": "甘德县"
					},
					{
						"code": 632624000000,
						"value": "达日县",
						"label": "达日县"
					},
					{
						"code": 632625000000,
						"value": "久治县",
						"label": "久治县"
					},
					{
						"code": 632626000000,
						"value": "玛多县",
						"label": "玛多县"
					}
				],
				"value": "果洛藏族自治州",
				"label": "果洛藏族自治州"
			},
			{
				"code": 632700000000,
				"children": [
					{
						"code": 632701000000,
						"value": "玉树市",
						"label": "玉树市"
					},
					{
						"code": 632722000000,
						"value": "杂多县",
						"label": "杂多县"
					},
					{
						"code": 632723000000,
						"value": "称多县",
						"label": "称多县"
					},
					{
						"code": 632724000000,
						"value": "治多县",
						"label": "治多县"
					},
					{
						"code": 632725000000,
						"value": "囊谦县",
						"label": "囊谦县"
					},
					{
						"code": 632726000000,
						"value": "曲麻莱县",
						"label": "曲麻莱县"
					}
				],
				"value": "玉树藏族自治州",
				"label": "玉树藏族自治州"
			},
			{
				"code": 632800000000,
				"children": [
					{
						"code": 632801000000,
						"value": "格尔木市",
						"label": "格尔木市"
					},
					{
						"code": 632802000000,
						"value": "德令哈市",
						"label": "德令哈市"
					},
					{
						"code": 632803000000,
						"value": "茫崖市",
						"label": "茫崖市"
					},
					{
						"code": 632821000000,
						"value": "乌兰县",
						"label": "乌兰县"
					},
					{
						"code": 632822000000,
						"value": "都兰县",
						"label": "都兰县"
					},
					{
						"code": 632823000000,
						"value": "天峻县",
						"label": "天峻县"
					},
					{
						"code": 632857000000,
						"value": "大柴旦行政委员会",
						"label": "大柴旦行政委员会"
					}
				],
				"value": "海西蒙古族藏族自治州",
				"label": "海西蒙古族藏族自治州"
			}
		],
		"value": "青海省",
		"label": "青海省"
	},
	{
		"code": 640000000000,
		"children": [
			{
				"code": 640100000000,
				"children": [
					{
						"code": 640101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 640104000000,
						"value": "兴庆区",
						"label": "兴庆区"
					},
					{
						"code": 640105000000,
						"value": "西夏区",
						"label": "西夏区"
					},
					{
						"code": 640106000000,
						"value": "金凤区",
						"label": "金凤区"
					},
					{
						"code": 640121000000,
						"value": "永宁县",
						"label": "永宁县"
					},
					{
						"code": 640122000000,
						"value": "贺兰县",
						"label": "贺兰县"
					},
					{
						"code": 640181000000,
						"value": "灵武市",
						"label": "灵武市"
					}
				],
				"value": "银川市",
				"label": "银川市"
			},
			{
				"code": 640200000000,
				"children": [
					{
						"code": 640201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 640202000000,
						"value": "大武口区",
						"label": "大武口区"
					},
					{
						"code": 640205000000,
						"value": "惠农区",
						"label": "惠农区"
					},
					{
						"code": 640221000000,
						"value": "平罗县",
						"label": "平罗县"
					}
				],
				"value": "石嘴山市",
				"label": "石嘴山市"
			},
			{
				"code": 640300000000,
				"children": [
					{
						"code": 640301000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 640302000000,
						"value": "利通区",
						"label": "利通区"
					},
					{
						"code": 640303000000,
						"value": "红寺堡区",
						"label": "红寺堡区"
					},
					{
						"code": 640323000000,
						"value": "盐池县",
						"label": "盐池县"
					},
					{
						"code": 640324000000,
						"value": "同心县",
						"label": "同心县"
					},
					{
						"code": 640381000000,
						"value": "青铜峡市",
						"label": "青铜峡市"
					}
				],
				"value": "吴忠市",
				"label": "吴忠市"
			},
			{
				"code": 640400000000,
				"children": [
					{
						"code": 640401000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 640402000000,
						"value": "原州区",
						"label": "原州区"
					},
					{
						"code": 640422000000,
						"value": "西吉县",
						"label": "西吉县"
					},
					{
						"code": 640423000000,
						"value": "隆德县",
						"label": "隆德县"
					},
					{
						"code": 640424000000,
						"value": "泾源县",
						"label": "泾源县"
					},
					{
						"code": 640425000000,
						"value": "彭阳县",
						"label": "彭阳县"
					}
				],
				"value": "固原市",
				"label": "固原市"
			},
			{
				"code": 640500000000,
				"children": [
					{
						"code": 640501000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 640502000000,
						"value": "沙坡头区",
						"label": "沙坡头区"
					},
					{
						"code": 640521000000,
						"value": "中宁县",
						"label": "中宁县"
					},
					{
						"code": 640522000000,
						"value": "海原县",
						"label": "海原县"
					}
				],
				"value": "中卫市",
				"label": "中卫市"
			}
		],
		"value": "宁夏回族自治区",
		"label": "宁夏回族自治区"
	},
	{
		"code": 650000000000,
		"children": [
			{
				"code": 650100000000,
				"children": [
					{
						"code": 650101000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 650102000000,
						"value": "天山区",
						"label": "天山区"
					},
					{
						"code": 650103000000,
						"value": "沙依巴克区",
						"label": "沙依巴克区"
					},
					{
						"code": 650104000000,
						"value": "新市区",
						"label": "新市区"
					},
					{
						"code": 650105000000,
						"value": "水磨沟区",
						"label": "水磨沟区"
					},
					{
						"code": 650106000000,
						"value": "头屯河区",
						"label": "头屯河区"
					},
					{
						"code": 650107000000,
						"value": "达坂城区",
						"label": "达坂城区"
					},
					{
						"code": 650109000000,
						"value": "米东区",
						"label": "米东区"
					},
					{
						"code": 650121000000,
						"value": "乌鲁木齐县",
						"label": "乌鲁木齐县"
					}
				],
				"value": "乌鲁木齐市",
				"label": "乌鲁木齐市"
			},
			{
				"code": 650200000000,
				"children": [
					{
						"code": 650201000000,
						"value": "市辖区",
						"label": "市辖区"
					},
					{
						"code": 650202000000,
						"value": "独山子区",
						"label": "独山子区"
					},
					{
						"code": 650203000000,
						"value": "克拉玛依区",
						"label": "克拉玛依区"
					},
					{
						"code": 650204000000,
						"value": "白碱滩区",
						"label": "白碱滩区"
					},
					{
						"code": 650205000000,
						"value": "乌尔禾区",
						"label": "乌尔禾区"
					}
				],
				"value": "克拉玛依市",
				"label": "克拉玛依市"
			},
			{
				"code": 650400000000,
				"children": [
					{
						"code": 650402000000,
						"value": "高昌区",
						"label": "高昌区"
					},
					{
						"code": 650421000000,
						"value": "鄯善县",
						"label": "鄯善县"
					},
					{
						"code": 650422000000,
						"value": "托克逊县",
						"label": "托克逊县"
					}
				],
				"value": "吐鲁番市",
				"label": "吐鲁番市"
			},
			{
				"code": 650500000000,
				"children": [
					{
						"code": 650502000000,
						"value": "伊州区",
						"label": "伊州区"
					},
					{
						"code": 650521000000,
						"value": "巴里坤哈萨克自治县",
						"label": "巴里坤哈萨克自治县"
					},
					{
						"code": 650522000000,
						"value": "伊吾县",
						"label": "伊吾县"
					}
				],
				"value": "哈密市",
				"label": "哈密市"
			},
			{
				"code": 652300000000,
				"children": [
					{
						"code": 652301000000,
						"value": "昌吉市",
						"label": "昌吉市"
					},
					{
						"code": 652302000000,
						"value": "阜康市",
						"label": "阜康市"
					},
					{
						"code": 652323000000,
						"value": "呼图壁县",
						"label": "呼图壁县"
					},
					{
						"code": 652324000000,
						"value": "玛纳斯县",
						"label": "玛纳斯县"
					},
					{
						"code": 652325000000,
						"value": "奇台县",
						"label": "奇台县"
					},
					{
						"code": 652327000000,
						"value": "吉木萨尔县",
						"label": "吉木萨尔县"
					},
					{
						"code": 652328000000,
						"value": "木垒哈萨克自治县",
						"label": "木垒哈萨克自治县"
					}
				],
				"value": "昌吉回族自治州",
				"label": "昌吉回族自治州"
			},
			{
				"code": 652700000000,
				"children": [
					{
						"code": 652701000000,
						"value": "博乐市",
						"label": "博乐市"
					},
					{
						"code": 652702000000,
						"value": "阿拉山口市",
						"label": "阿拉山口市"
					},
					{
						"code": 652722000000,
						"value": "精河县",
						"label": "精河县"
					},
					{
						"code": 652723000000,
						"value": "温泉县",
						"label": "温泉县"
					}
				],
				"value": "博尔塔拉蒙古自治州",
				"label": "博尔塔拉蒙古自治州"
			},
			{
				"code": 652800000000,
				"children": [
					{
						"code": 652801000000,
						"value": "库尔勒市",
						"label": "库尔勒市"
					},
					{
						"code": 652822000000,
						"value": "轮台县",
						"label": "轮台县"
					},
					{
						"code": 652823000000,
						"value": "尉犁县",
						"label": "尉犁县"
					},
					{
						"code": 652824000000,
						"value": "若羌县",
						"label": "若羌县"
					},
					{
						"code": 652825000000,
						"value": "且末县",
						"label": "且末县"
					},
					{
						"code": 652826000000,
						"value": "焉耆回族自治县",
						"label": "焉耆回族自治县"
					},
					{
						"code": 652827000000,
						"value": "和静县",
						"label": "和静县"
					},
					{
						"code": 652828000000,
						"value": "和硕县",
						"label": "和硕县"
					},
					{
						"code": 652829000000,
						"value": "博湖县",
						"label": "博湖县"
					},
					{
						"code": 652871000000,
						"value": "库尔勒经济技术开发区",
						"label": "库尔勒经济技术开发区"
					}
				],
				"value": "巴音郭楞蒙古自治州",
				"label": "巴音郭楞蒙古自治州"
			},
			{
				"code": 652900000000,
				"children": [
					{
						"code": 652901000000,
						"value": "阿克苏市",
						"label": "阿克苏市"
					},
					{
						"code": 652902000000,
						"value": "库车市",
						"label": "库车市"
					},
					{
						"code": 652922000000,
						"value": "温宿县",
						"label": "温宿县"
					},
					{
						"code": 652924000000,
						"value": "沙雅县",
						"label": "沙雅县"
					},
					{
						"code": 652925000000,
						"value": "新和县",
						"label": "新和县"
					},
					{
						"code": 652926000000,
						"value": "拜城县",
						"label": "拜城县"
					},
					{
						"code": 652927000000,
						"value": "乌什县",
						"label": "乌什县"
					},
					{
						"code": 652928000000,
						"value": "阿瓦提县",
						"label": "阿瓦提县"
					},
					{
						"code": 652929000000,
						"value": "柯坪县",
						"label": "柯坪县"
					}
				],
				"value": "阿克苏地区",
				"label": "阿克苏地区"
			},
			{
				"code": 653000000000,
				"children": [
					{
						"code": 653001000000,
						"value": "阿图什市",
						"label": "阿图什市"
					},
					{
						"code": 653022000000,
						"value": "阿克陶县",
						"label": "阿克陶县"
					},
					{
						"code": 653023000000,
						"value": "阿合奇县",
						"label": "阿合奇县"
					},
					{
						"code": 653024000000,
						"value": "乌恰县",
						"label": "乌恰县"
					}
				],
				"value": "克孜勒苏柯尔克孜自治州",
				"label": "克孜勒苏柯尔克孜自治州"
			},
			{
				"code": 653100000000,
				"children": [
					{
						"code": 653101000000,
						"value": "喀什市",
						"label": "喀什市"
					},
					{
						"code": 653121000000,
						"value": "疏附县",
						"label": "疏附县"
					},
					{
						"code": 653122000000,
						"value": "疏勒县",
						"label": "疏勒县"
					},
					{
						"code": 653123000000,
						"value": "英吉沙县",
						"label": "英吉沙县"
					},
					{
						"code": 653124000000,
						"value": "泽普县",
						"label": "泽普县"
					},
					{
						"code": 653125000000,
						"value": "莎车县",
						"label": "莎车县"
					},
					{
						"code": 653126000000,
						"value": "叶城县",
						"label": "叶城县"
					},
					{
						"code": 653127000000,
						"value": "麦盖提县",
						"label": "麦盖提县"
					},
					{
						"code": 653128000000,
						"value": "岳普湖县",
						"label": "岳普湖县"
					},
					{
						"code": 653129000000,
						"value": "伽师县",
						"label": "伽师县"
					},
					{
						"code": 653130000000,
						"value": "巴楚县",
						"label": "巴楚县"
					},
					{
						"code": 653131000000,
						"value": "塔什库尔干塔吉克自治县",
						"label": "塔什库尔干塔吉克自治县"
					}
				],
				"value": "喀什地区",
				"label": "喀什地区"
			},
			{
				"code": 653200000000,
				"children": [
					{
						"code": 653201000000,
						"value": "和田市",
						"label": "和田市"
					},
					{
						"code": 653221000000,
						"value": "和田县",
						"label": "和田县"
					},
					{
						"code": 653222000000,
						"value": "墨玉县",
						"label": "墨玉县"
					},
					{
						"code": 653223000000,
						"value": "皮山县",
						"label": "皮山县"
					},
					{
						"code": 653224000000,
						"value": "洛浦县",
						"label": "洛浦县"
					},
					{
						"code": 653225000000,
						"value": "策勒县",
						"label": "策勒县"
					},
					{
						"code": 653226000000,
						"value": "于田县",
						"label": "于田县"
					},
					{
						"code": 653227000000,
						"value": "民丰县",
						"label": "民丰县"
					}
				],
				"value": "和田地区",
				"label": "和田地区"
			},
			{
				"code": 654000000000,
				"children": [
					{
						"code": 654002000000,
						"value": "伊宁市",
						"label": "伊宁市"
					},
					{
						"code": 654003000000,
						"value": "奎屯市",
						"label": "奎屯市"
					},
					{
						"code": 654004000000,
						"value": "霍尔果斯市",
						"label": "霍尔果斯市"
					},
					{
						"code": 654021000000,
						"value": "伊宁县",
						"label": "伊宁县"
					},
					{
						"code": 654022000000,
						"value": "察布查尔锡伯自治县",
						"label": "察布查尔锡伯自治县"
					},
					{
						"code": 654023000000,
						"value": "霍城县",
						"label": "霍城县"
					},
					{
						"code": 654024000000,
						"value": "巩留县",
						"label": "巩留县"
					},
					{
						"code": 654025000000,
						"value": "新源县",
						"label": "新源县"
					},
					{
						"code": 654026000000,
						"value": "昭苏县",
						"label": "昭苏县"
					},
					{
						"code": 654027000000,
						"value": "特克斯县",
						"label": "特克斯县"
					},
					{
						"code": 654028000000,
						"value": "尼勒克县",
						"label": "尼勒克县"
					}
				],
				"value": "伊犁哈萨克自治州",
				"label": "伊犁哈萨克自治州"
			},
			{
				"code": 654200000000,
				"children": [
					{
						"code": 654201000000,
						"value": "塔城市",
						"label": "塔城市"
					},
					{
						"code": 654202000000,
						"value": "乌苏市",
						"label": "乌苏市"
					},
					{
						"code": 654221000000,
						"value": "额敏县",
						"label": "额敏县"
					},
					{
						"code": 654223000000,
						"value": "沙湾县",
						"label": "沙湾县"
					},
					{
						"code": 654224000000,
						"value": "托里县",
						"label": "托里县"
					},
					{
						"code": 654225000000,
						"value": "裕民县",
						"label": "裕民县"
					},
					{
						"code": 654226000000,
						"value": "和布克赛尔蒙古自治县",
						"label": "和布克赛尔蒙古自治县"
					}
				],
				"value": "塔城地区",
				"label": "塔城地区"
			},
			{
				"code": 654300000000,
				"children": [
					{
						"code": 654301000000,
						"value": "阿勒泰市",
						"label": "阿勒泰市"
					},
					{
						"code": 654321000000,
						"value": "布尔津县",
						"label": "布尔津县"
					},
					{
						"code": 654322000000,
						"value": "富蕴县",
						"label": "富蕴县"
					},
					{
						"code": 654323000000,
						"value": "福海县",
						"label": "福海县"
					},
					{
						"code": 654324000000,
						"value": "哈巴河县",
						"label": "哈巴河县"
					},
					{
						"code": 654325000000,
						"value": "青河县",
						"label": "青河县"
					},
					{
						"code": 654326000000,
						"value": "吉木乃县",
						"label": "吉木乃县"
					}
				],
				"value": "阿勒泰地区",
				"label": "阿勒泰地区"
			},
			{
				"code": 659000000000,
				"children": [
					{
						"code": 659001000000,
						"value": "石河子市",
						"label": "石河子市"
					},
					{
						"code": 659002000000,
						"value": "阿拉尔市",
						"label": "阿拉尔市"
					},
					{
						"code": 659003000000,
						"value": "图木舒克市",
						"label": "图木舒克市"
					},
					{
						"code": 659004000000,
						"value": "五家渠市",
						"label": "五家渠市"
					},
					{
						"code": 659005000000,
						"value": "北屯市",
						"label": "北屯市"
					},
					{
						"code": 659006000000,
						"value": "铁门关市",
						"label": "铁门关市"
					},
					{
						"code": 659007000000,
						"value": "双河市",
						"label": "双河市"
					},
					{
						"code": 659008000000,
						"value": "可克达拉市",
						"label": "可克达拉市"
					},
					{
						"code": 659009000000,
						"value": "昆玉市",
						"label": "昆玉市"
					},
					{
						"code": 659010000000,
						"value": "胡杨河市",
						"label": "胡杨河市"
					}
				],
				"value": "自治区直辖县级行政区划",
				"label": "自治区直辖县级行政区划"
			}
		],
		"value": "新疆维吾尔自治区",
		"label": "新疆维吾尔自治区"
	}

];
export default Position;