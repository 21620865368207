import React, {Component} from "react";
import {Badge, Divider, message, Popover, Space, Table, Typography} from "antd";
import {inject, observer} from "mobx-react";
import {axios} from "../../Managers";
import {AlertTwoTone, LockTwoTone} from "@ant-design/icons"

@inject("Operator")
@observer
class PositionTable extends Component {
	constructor(props) {
		super(props);
		let slots = [];
		let productmaps = [];
		if (this.props.data.template.config.hasOwnProperty("slotsmap")) {
			slots = this.props.data.template.config.slotsmap.slots;
			productmaps = this.props.data.template.config.slotsmap.productsmap;
		}
		this.state = {
			slots: slots,
			productsmap: productmaps,
			planedslot: this.props.data.MTORecord.map(p => {return p.position["slot"];}),
			editRow: null
		};
		this.printContent = React.createRef()
	}

	deleteMTORecord = (planId, recordId) => {
		axios.post("/mto/mtoplan/", {
			action: "deleteRecord", data: {
				planId, recordId
			}
		}).then(res => {
			if (res.data.status === 200) {
				message.success(res.data.message);
			} else {
				message.error(res.data.message);
			}
			this.props.getDetail();
		});
	};

	render() {
		let ES_columns = [
			{
				title: "序号", dataIndex: "seq", align: "center", width: 60, render: (text, record) => {
					return <span key={Math.random()}>{record.seq}</span>;
				}
			},
			{title: "记录编号", dataIndex: "recordId", align: "center", width: 200},
			{
				title: "订单行号", dataIndex: "orderLine", align: "center", width: 190, render: (text, record) => {
					return <span key={Math.random()}>{record.orderLine.lineId}</span>;
				}
			},
			{
				title: "父单参照", key: "parentCustomerRef", align: "center", width: 150, ellipsis: true, render: (text, record) => {
					return <span key={Math.random()}>{record.orderLine.parentCustomerRef}
						<div className={"flotRight"}> {record.orderLine.parentLockCase
							? <AlertTwoTone/>
							: ""}
							{record.orderLine.parentStatus ===
							"locked" ? <LockTwoTone/> : ""}</div></span>

				}
			},
			{
				title: "制版图", key: "layoutImage", align: "center", width: 90, responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					return (
						<Popover content={<img src={record.orderLine.layoutImage} key={Math.random()} alt={"版下图"} style={{height: "100%"}}/>}
						         title="版下图">
							<img src={record.orderLine.layoutImage} key={Math.random()} alt={"layoutImage"} style={{maxWidth: 90, maxHeight: 30}}/>
						</Popover>
					);
				}
			},
			{
				title: "产品型号", key: "product", align: "center", width: 150, render: (text, record) => {
					return <span key={Math.random()}>{record.orderLine.product.productName}</span>;
				}
			},

			{
				title: "备注信息", key: "note", align: "center", responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					return record.orderLine.note;
				}
			},
			{
				title: "位置信息", key: "position", align: "center", responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					if (record.position.hasOwnProperty("slot")) {
						return record.position["slot"];
					}
					return "";
				}
			},
			// {
			// 	title: "状态", dataIndex: "status", align: "center", width: 90, render: (text, record) => {
			// 		// return text;
			// 		if (this.props.data) {
			// 			return <span key={Math.random()}>{this.props.data.template.config.flow[text].displayName}</span>;
			// 		}
			//
			// 	},
			// },
			{
				title: "操作", key: "opts", align: "center", render: (text, record) => {
					return <Space split={<Divider type={"vertical"}/>}>
						<Typography.Link key={Math.random()} onClick={() => this.deleteMTORecord(this.props.data.planId, record.recordId)}
						                 disabled={this.props.data.status !== "start"}
						>删除</Typography.Link>

					</Space>;
				}
			}
		];
		let columns = [
			{
				title: "序号", dataIndex: "seq", align: "center", width: 60, render: (text, record) => {
					return <span key={Math.random()}>{record.seq}</span>;
				}
			},
			{title: "记录编号", dataIndex: "recordId", align: "center", width: 200},
			{
				title: "订单行号", dataIndex: "orderLine", align: "center", width: 190, render: (text, record) => {
					return <span key={Math.random()}>{record.orderLine.lineId}</span>;
				}
			},
			{
				title: "父单参照", key: "parentCustomerRef", align: "center", width: 150, ellipsis: true, render: (text, record) => {
					return <span key={Math.random()}>{record.orderLine.parentCustomerRef}{record.orderLine.parentLockCase
						? <Badge dot={true} title={"有紧急工单未关闭"} offset={[5, 5]}/>
						: ""}</span>;
				}
			},
			{
				title: "制版图", key: "layoutImage", align: "center", width: 100, responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					return (
						<Popover content={<img src={record.orderLine.layoutImage} key={Math.random()} alt={"版下图"} style={{height: "100%"}}/>}
						         title="版下图">
							<img src={record.orderLine.layoutImage} key={Math.random()} alt={"layoutImage"} style={{width: 90}}/>
						</Popover>
					);
				}
			},
			{
				title: "产品型号", key: "product", align: "center", width: 150, render: (text, record) => {
					return <span key={Math.random()}>{record.orderLine.product.productName}</span>;
				}
			},

			{
				title: "备注信息", key: "note", align: "center", responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					return record.orderLine.note;
				}
			}
			// {
			// 	title: "状态", dataIndex: "status", align: "center", width: 90, render: (text, record) => {
			// 		// return text;
			// 		if (this.props.data) {
			// 			return <span key={Math.random()}>{this.props.data.template.config.flow[text].displayName}</span>;
			// 		}
			//
			// 	},
			// },
			// {
			// 	title: "操作", key: "opts", align: "center", render: (text, record) => {
			// 		return <Space split={<Divider type={"vertical"}/>}>
			// 			{this.state.editRow === record.recordId ? <Typography.Link key={Math.random()} onClick={() => this.saveRecord()}>保存</Typography.Link> :
			// 				<Typography.Link key={Math.random()} onClick={() => this.setState({editRow: record.recordId})}
			// 								 disabled={this.props.data.status !== "start"}
			// 				>编辑</Typography.Link>}
			//
			// 		</Space>;
			// 	},
			// },
		];
		return (

			<Table
				columns={["ES", "HM"].indexOf(this.props.data.template.craft) !== -1 ? ES_columns : columns}
				bordered
				rowKey={"recordId"}
				size={this.props.Operator.componentSize}
				dataSource={this.props.data.MTORecord}
				pagination={false}
			/>
		);
	}
}

export default PositionTable;