import React, {Component} from "react";
import {Form, Input, Cascader, Button, Row, Col} from "antd";
import Position from "./regions";

class Address extends Component {
	form = React.createRef();

	componentDidMount() {
		let currentAddress = this.props.currentAddress;
		console.log(currentAddress)
		if (currentAddress) {
			this.form.current.setFieldsValue(currentAddress);
			this.form.current.setFieldsValue({
				threeCode: [currentAddress.province, currentAddress.city, currentAddress.district]
			});
		}
	}

	filterAddress = (inputValue, path) => {
		return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
	};

	render() {
		const layout = {
			labelCol: {span: 4},
			wrapperCol: {span: 20}
		};
		return (
			<div className={"innerContent"}>
				<Form size={"small"} {...layout} ref={this.form} labelAlign={"left"} onFinish={this.props.onFinished}>
					<Form.Item name={"pk"} hidden={true}><Input/></Form.Item>
					<Form.Item name={"uuid"} hidden={true}><Input/></Form.Item>
					<Form.Item name={"createOwner"} hidden={true}><Input/></Form.Item>
					<Form.Item name={"name"} label={"姓名"} rules={[
						{
							required: true,
							message: "姓名为必填项"
						}
					]}>
						<Input/>
					</Form.Item>
					<Form.Item name={"phoneNumber"} label={"电话"}>
						<Input/>
					</Form.Item>
					<Form.Item name={"mobileNumber"} label={"手机"} rules={[
						{
							required: true,
							message: "手机为必填项"
						}
					]}>
						<Input/>
					</Form.Item>
					<Form.Item name={"threeCode"} label={"省,市,区"} rules={[
						{
							required: true,
							type: "array",
							message: "省市区为必选项"
						}
					]}>
						<Cascader
							options={Position} showSearch={this.filterAddress}/>
					</Form.Item>
					<Form.Item name={"address"} label={"详细地址"} rules={[
						{
							required: true,
							message: "详细地址为必填项",
							min: 3
						}
					]}>
						<Input.TextArea/>
					</Form.Item>
					<Form.Item name={"note"} label={"地址备注"}>
						<Input.TextArea/>
					</Form.Item>
					<Row align={"end"}>
						<Col span={4}><Button size="default" type={"primary"} htmlType={"submit"}>提交</Button></Col>
						<Col span={3}><Button size="default" onClick={this.props.onCancel}>取消</Button></Col>
					</Row>
				</Form>
			</div>
		);
	}
}

export default Address;