import React from "react";
import {Modal} from "antd";
import LogisticDetail from "./LogisticDetail"
import {Provider} from "mobx-react";
import stores from "../../Managers/store";

const PopLogisticDetail = (logisticUUID) => {
	Modal.info({
		centered: true,
		maskClosable: true,
		closable: true,
		icon: null,
		width: "90vw",
		content: <Provider {...stores}>
			<LogisticDetail logisticUUID={logisticUUID}/>
		</Provider>,
		okText: "关闭"
	});
}

export default PopLogisticDetail;