import React, {Component} from "react";
import {Layout, Menu, Row, Col, Button, Dropdown} from "antd";
import {Route, Switch, Link, Redirect} from "react-router-dom";
import Order from "../Order";
import Service from "../Service";
import ProductManager from "../Product";
import ElementManager from "../Element";
import TagManager from "../Tag";
import DesignTemplateManager from "../DesignTemplate";
import Mto from "../MTO";
import PartnerManager from "../Partner";
import MTOFactory from "../MTOFactory";
import SCM from "../SCM";
import Dashboard from "../Dashboard";

import {
	DashboardTwoTone, AccountBookTwoTone, SettingTwoTone, CalculatorTwoTone, BellTwoTone, CheckOutlined
} from "@ant-design/icons";

import {inject, observer} from "mobx-react";
import GlobalSearch from "./globalSearch";
import Report from "../Report";
import WareHouse from "../WareHouse";

const {Footer, Content, Header} = Layout;

@inject("Operator") @observer
class MainLayout extends Component {
	state = {
		collapsed: false, defaultSelectedKeys: ["数据看板"]
	};

	onCollapse = collapsed => {
		this.setState({collapsed});
	};

	logoutCallBack = () => {
		this.props.history.push("/login/");
	};

	updateSize = (size) => {
		this.props.Operator.changeSize(size);
		this.forceUpdate();
	};

	render() {
		let size = this.props.Operator.componentSize
		let menu = (<Menu>
			<Menu.Item><span>{this.props.Operator.currentCompanyName}-{this.props.Operator.displayName}</span></Menu.Item>
			<Menu.SubMenu title="切换专户">
				{this.props.Operator.companies.map(citem => {
					if (citem.current) {
						return <Menu.Item title={citem.companyName} key={citem.companyUUID}
						                  onClick={() => this.props.Operator.switchCompany(citem.companyUUID)}>
							<CheckOutlined/><span>{citem.companyName}</span>
						</Menu.Item>
					} else {
						return <Menu.Item title={citem.companyName} key={citem.companyUUID}
						                  onClick={() => this.props.Operator.switchCompany(citem.companyUUID)}>
									<span style={{textAlign: "right"}}
									>{citem.companyName}</span>
						</Menu.Item>
					}
				})}
			</Menu.SubMenu>
			<Menu.SubMenu title={"组件大小"}>
				<Menu.Item title={"紧凑"} onClick={() => {this.updateSize("small")}}>{size === "small" ? <CheckOutlined/> : ""}紧凑</Menu.Item>
				<Menu.Item title={"正常"} onClick={() => {this.updateSize("default")}}>{size === "middle" ? <CheckOutlined/> : ""}正常</Menu.Item>
				<Menu.Item title={"加大"} onClick={() => {this.updateSize("default")}}>{size === "large" ? <CheckOutlined/> : ""}加大</Menu.Item>
			</Menu.SubMenu>
			<Menu.Item>
						<span onClick={() => this.props.Operator.logout(this.logoutCallBack)}>
						退出登录
						</span>
			</Menu.Item>
		</Menu>);

		// const Order = React.lazy(() => import("../Order"));
		// const Service = React.lazy(() => import("../Service"));
		// const ProductManager = React.lazy(() => import("../Product"));
		// const ElementManager = React.lazy(() => import("../Element"));
		// const TagManager = React.lazy(() => import("../Tag"));
		// const DesignTemplateManager = React.lazy(() => import("../DesignTemplate"));
		// const Mto = React.lazy(() => import("../MTO"));
		// const PartnerManager = React.lazy(() => import("../Partner"));
		// const MTOFactory = React.lazy(() => import("../MTOFactory"));
		// const SCM = React.lazy(() => import("../SCM"));

		if (!this.props.Operator.logged) {
			return (<Redirect to={{pathname: "/login/", state: {from: this.props.location.pathname}}}/>);
		} else {
			return (<Layout style={{minHeight: "100vh"}} hasSider={true}>
				<Layout.Sider theme={"light"} width={140} collapsible collapsed={this.state.collapsed}
				              onCollapse={this.onCollapse} collapsedWidth={50}
				              breakpoint={"xxl"}
				>

					{this.state.collapsed ? <div className="logomin"><img alt="logo" src={"/images/logomin.png"}/></div> : <div className="logo"><img
						alt="logo" src={"/images/logo.png"}/></div>}

					<Menu mode={"inline"} defaultSelectedKeys={this.state.defaultSelectedKeys}>
						<Menu.Item title={"数据看板"} key={"数据看板"}>
							<div className="ma">
								<Link to={"/dashboard"}><DashboardTwoTone/></Link>
								{this.state.collapsed ? "" : <Link to={"/dashboard"}>数据看板</Link>}
							</div>
						</Menu.Item>
						<Menu.Item title={"服务工单"}>
							<div className="ma">
								<Link to={"/service"}><BellTwoTone/></Link>
								{this.state.collapsed ? "" : <Link to={"/service"}>服务工单</Link>}
							</div>
						</Menu.Item>
						{this.props.Operator.role.indexOf("sale") !== -1 ? <Menu.Item title={"订单管理"}>
							<div className="ma">
								<Link to={"/saleorder"}><AccountBookTwoTone/></Link>
								{this.state.collapsed ? "" : <Link to={"/saleorder"}>订单管理</Link>}
							</div>
						</Menu.Item> : ""}
						{this.props.Operator.role.indexOf("mto") !== -1 ? <Menu.Item title={"生产管理"}>
							<div className="ma">
								<Link to={"/mto"}><img src={"/images/mto.png"} alt={"mto"} className={"menuIcon"}/></Link>
								{this.state.collapsed ? "" : <Link to={"/mto"}>生产计划</Link>}
							</div>
						</Menu.Item> : ""}

						{this.props.Operator.role.indexOf("scm") !== -1 ? <Menu.Item title={"物流管理"}>
							<div className="ma">
								<Link to={"/scm"}><img src={"/images/logistics.png"} alt="logistics" className={"menuIcon"}/></Link>
								{this.state.collapsed ? "" : <Link to={"/scm"}>物流管理</Link>}
							</div>
						</Menu.Item> : ""}


						{/*<Menu.Item title={"客户管理"}>*/}
						{/*    <div className="ma">*/}
						{/*        <Link to={"/crm"}><PhoneTwoTone/></Link>*/}
						{/*        {this.state.collapsed ? "" : <Link to={"/crm"}>客户管理</Link>}*/}
						{/*    </div>*/}
						{/*</Menu.Item>*/}
						{this.props.Operator.role.indexOf("report") !== -1 ? <Menu.Item title={"报表管理"}>
							<div className="ma">
								<Link to={"/report"}><CalculatorTwoTone/></Link>
								{this.state.collapsed ? "" : <Link to={"/report"}>报表管理</Link>}
							</div>
						</Menu.Item> : ""}

						{this.props.Operator.role.indexOf("admin") !== -1 ? <Menu.SubMenu title={"系统配置"} key={"system"} icon={<SettingTwoTone/>}>
							<Menu.Item>
								<Link to={"/config/warehouse"}>仓库管理</Link>
							</Menu.Item>

							<Menu.Item>
								<Link to={"/config/factory"}>产线管理</Link>
							</Menu.Item>
							<Menu.Item>
								<Link to={"/config/tag"}>标签管理</Link>
							</Menu.Item>
							<Menu.Item>
								<Link to={"/config/element"}>素材管理</Link>
							</Menu.Item>
							<Menu.Item>
								<Link to={"/config/partner"}>渠道管理</Link>
							</Menu.Item>
							<Menu.Item>
								<Link to={"/config/template"}>印面模板</Link>
							</Menu.Item>
							<Menu.Item>
								<Link to={"/config/product"}>产品管理</Link>
							</Menu.Item>
						</Menu.SubMenu> : ""}
					</Menu>
				</Layout.Sider>
				<Layout>
					<Header className="myHeader">
						<Row justify="space-between"
						     style={{maxWidth: "98vw"}}>
							<Col span={15} offset={2}>
								<GlobalSearch/>
							</Col>
							<Col>
								<Dropdown overlay={menu}>
									<Button shape="circle">
										<img src={"/images/user.png"} alt={"user"} className={"avatar"}/>
									</Button>
								</Dropdown>
							</Col>
						</Row>
					</Header>
					<Content className={"content"}>
						<Switch>
							<Route path={"/service"} component={Service}/>
							<Route path={"/saleorder"} component={Order}/>
							<Route path={"/mto"} component={Mto}/>
							<Route path={"/scm"} component={SCM}/>
							<Route path={"/report"} component={Report}/>
							<Route path={"/config/product"} component={ProductManager}/>
							<Route path={"/config/warehouse"} component={WareHouse}/>
							<Route path={"/config/factory"} component={MTOFactory}/>
							<Route path={"/config/partner"} component={PartnerManager}/>
							<Route path={"/config/element"} component={ElementManager}/>
							<Route path={"/config/template"} component={DesignTemplateManager}/>
							<Route path={"/config/tag"} component={TagManager}/>
							<Route path={"/"} component={Dashboard}/>
						</Switch>
					</Content>
					<Footer style={{background: "#f0f2f5", padding: "0 0"}}>
						<div className="footer">
							©Baoz Networks
						</div>
					</Footer>
				</Layout>
			</Layout>);
		}
	}
}

export default MainLayout;
