import React, {Component} from "react";
import {Button, Divider, Form, Input, Popover, Space, Table, Tag} from "antd";
import {SwatchesPicker} from "react-color";
import {inject, observer} from "mobx-react";
import {axios, Log} from "../../Managers";
import Modal from "antd/es/modal/Modal";

@inject("Operator")
@observer
class TagManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			visible: false,
		};
		this.form = React.createRef();
	}

	componentDidMount() {
		this.onRefresh();
	}

	onRefresh = () => {
		this.setState({loading: true});
		axios.post("/api/tag/", {action: "list"}).then(response => {
			if (response.data.status === 200) {
				this.setState({data: response.data.data, loading: false});
			}
		});
	};

	changeColor = (color, pk, config) => {

		config["color"] = color.hex;
		Log(color.hex);
		axios.post("/api/tag/", {action: "updateConfig", data: {pk: pk, config: config}}).then(response => {
			if (response.data.status === 200) {
				this.onRefresh();
			}
		});
	};

	onCreate = () => {
		this.form.current.validateFields().then(values => {
			axios.post("/api/tag/", {
				action: "create", data: {
					tagName: values.tagName,
					tagDescription: values.tagDescription,
					config: {color: values.color.hex},
				},
			}).then(response => {
				if (response.data.status === 200) {
					this.setState({visible: false});
					this.onRefresh();
				}
			});
		});
	};

	render() {
		let columns = [
			{
				title: "标签名称", key: "tagName", align: "center", editable: true, render: (text, record) => {
					return <Tag color={record.config.color}>{record.tagName}</Tag>;
				},
			},
			{
				title: "标签说明", dataIndex: "tagDescription", align: "center", editable: true,
			},
			{
				title: "标签颜色", key: "opts", align: "center", render: (text, record) => {
					if (record.config.hasOwnProperty("color")) {
						return <Popover content={<SwatchesPicker onChangeComplete={(color, event) => this.changeColor(color, record.pk, record.config)}/>}
										title="选色板">
							<div style={{background: record.config.color, width: 30, height: 30}}/>
						</Popover>;
					} else {
						return <Popover content={<SwatchesPicker onChangeComplete={(color, event) => this.changeColor(color, record.pk, record.config)}/>}
										title="选色板"><span>未配置颜色</span></Popover>;
					}

				},
			},
		];
		let _operator = this.props.Operator;
		return (
			<div>
				<Modal visible={this.state.visible} destroyOnClose={true} width={"60vw"} title={"标签详情"} onOk={this.onCreate}
					   onCancel={() => {this.setState({visible: false});}}>
					<Form ref={this.form}>
						<Form.Item name={"tagName"} label={"标签名称"} rules={[
							{required: true},
						]}>
							<Input/>
						</Form.Item>
						<Form.Item name={"tagDescription"} label={"标签说明"} rules={[
							{required: true},
						]}>
							<Input.TextArea/>
						</Form.Item>
						<Form.Item name={"color"} label={"标签颜色"} rules={[
							{required: true},
						]}>
							<SwatchesPicker/>
						</Form.Item>
					</Form>
				</Modal>
				<Table
					columns={columns}
					dataSource={this.state.data}
					loading={this.state.loading}
					bordered
					size={_operator.componentSize}
					title={() => {
						return (
							<div>
								<Input.Search allowClear={true} style={{maxWidth: "80vw"}} size={_operator.componentSize}/>
								<Space spit={<Divider type={"vertical"}/>} style={{marginLeft: 10}}>
									<Button type={"primary"} onClick={this.onRefresh} size={_operator.componentSize}>刷新</Button>
									<Button onClick={() => {this.setState({visible: true});}} size={_operator.componentSize}>创建</Button>
								</Space>
							</div>
						);
					}}
				/>
			</div>
		);
	}
}

export default TagManager;