import React, {Component} from "react";
import ReactJson from "react-json-view";
import {Button, Card, Divider, Form, Input, InputNumber, message, Select, Upload} from "antd";
import {inject, observer} from "mobx-react";
import {axios, getCsrfToken, Log} from "../../Managers/index";
import {PlusOutlined} from "@ant-design/icons";


@inject("ProductsManager", "Operator")
@observer
class ProductInfo extends Component {
	constructor(props) {
		super(props);
		this.Form = React.createRef();
		this.state = {
			edit: false,
			productInfo: null,
			action: "update"
		};
	}

	componentDidMount() {
		if (this.props.productPk) {
			axios.get("/api/product?pk=" + this.props.productPk).then(response => {
				this.setState({productInfo: response.data.data[0]});
			});
		} else {
			this.setState({productInfo: {}, action: "create", edit: true});
		}
	}

	onFinish = (values) => {
		axios.post("/api/product/", {action: this.state.action, data: values}).then(response => {
			// let data = response.data
			// this.setState({cate: data.data[0], edit: false})
			if (response.data.status === 200) {
				message.success("产品信息更新成功!");
				this.setState({edit: false});
				this.props.onRefreshCate();
			} else {
				message.error(response.data.message);
			}
		});

	};

	changeJSON = (values) => {
		Log(values);
		if (!this.state.edit) {
			this.setState({edit: true});
		}
		this.Form.current.setFieldsValue({config: values.updated_src});
	};

	render() {
		let productInfo = this.state.productInfo;
		let edit = this.state.edit;
		let defaultSize = this.props.Operator.componentSize;
		return (
			<div>
				{productInfo ?
					<Form ref={this.Form} size={defaultSize} initialValues={productInfo} onFinish={this.onFinish}>
						<Card title={productInfo.productName} bordered={false} type="inner"
							  size={defaultSize}
							  extra=
								  {
									  <div>
										  <Button htmlType={"submit"} disabled={!edit} size={defaultSize}>保存</Button>
										  <Divider type="vertical"/>
										  <Button onClick={() => {
											  this.setState({edit: true});
										  }} disabled={edit} size={defaultSize}>编辑</Button>
									  </div>

								  }
						>
							<Form.Item name={"pk"} hidden={true}>
								<Input disabled={!edit}/>
							</Form.Item>
							<Form.Item name={"productName"} label={"产品名称"}>
								<Input disabled={!edit}/>
							</Form.Item>
							<Form.Item name={"productDescription"} label={"产品说明"}>
								<Input.TextArea disabled={!edit}/>
							</Form.Item>
							<Form.Item name={"productImageUUID"} hidden={true}>
								<Input disabled={!edit}/>
							</Form.Item>
							<Form.Item name={"productImage"} label={"产品图片"} valuePropName={"src"}>
								{!edit ? <img src={productInfo.productImage} alt="productImage"
											  style={{width: "100%", maxWidth: 200}}/> :
									<Upload
										action="/api/imagestore/"
										disabled={!edit}
										listType="picture-card"
										showUploadList={false}
										withCredentials={true}
										accept={"image/*"}
										multiple={false}
										data={(f) => {
											return {
												"csrfmiddlewaretoken": getCsrfToken()
											};
										}}
										onChange={
											(event) => {
												if (event.file.status === "done") {
													this.Form.current.setFieldsValue({
														productImage: event.file.response.data[0].url,
														productImageUUID: event.file.response.data[0].name
													});
													productInfo.productImage = event.file.response.data[0].url;
													productInfo.productImageUUID = event.file.response.data[0].name;
													this.setState({productInfo});
												}
											}
										}
									>
										{productInfo ? <img src={productInfo.productImage} alt="点击上传"
															style={{width: "100%", maxWidth: 200}}/> :
											<div>
												<PlusOutlined/>
												<div style={{marginTop: 8}}>上传</div>
											</div>
										}
									</Upload>
								}
							</Form.Item>
							<Form.Item name={"productPrice"} label={"产品单价"}>
								<InputNumber
									precision={2}
									style={{width: "100%"}}
									disabled={!edit}
								/>
							</Form.Item>
							<Form.Item name={"productCate"} label={"产品分类"}>
								<Select disabled={!edit} mode={"multiple"}>
									{this.props.ProductsManager.Cates.map(cate => {
										return <Select.Option value={cate.pk}
															  key={cate.pk}>{cate.cateName}</Select.Option>;
									})}
								</Select>
							</Form.Item>
							<Form.Item name={"productType"} label={"产品类型"}>
								<Select disabled={!edit}>
									<Select.Option value={"cmp"}>定制品</Select.Option>
									<Select.Option value={"product"}>制成品</Select.Option>
									<Select.Option value={"smp"}>半成品</Select.Option>
									<Select.Option value={"consumable"}>耗材</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item name={"modeOfSupply"} label={"供货方式"}>
								<Select disabled={!edit}>
									<Select.Option value={"stock"}>仓库现货</Select.Option>
									<Select.Option value={"internal"}>国内定制</Select.Option>
									<Select.Option value={"foreign"}>国外定制</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item name={"config"} label={"产品配置"} valuePropName={"src"}>
								<ReactJson collapsed={true} onEdit={this.changeJSON} onAdd={this.changeJSON}
										   name={null} enableClipboard={false}/>
							</Form.Item>
						</Card> </Form> : ""}
			</div>
		);
	}
}

export default ProductInfo;