import React, {Component} from "react";
import {Button, Divider, Input, Space, Table, Modal, Typography, message} from "antd";
import {inject, observer} from "mobx-react";
import CreateMtoPlan from "./createMTOPlan";
import {axios} from "../../Managers";
import moment from "moment";

// import PopMTOPlanDetail from "./PopMTOPlanDetail";

@inject("Operator")
@observer
class MtoTable extends Component {
	constructor(props) {
		super();
		this.state = {
			visible: false,
			loading: false,
			data: [],
			...props.Operator.PaginationConfig
		};
	}

	componentDidMount() {
		this.onRefresh();
	}

	onRefresh = () => {
		this.setState({loading: true});
		axios.post("/mto/mtoplan/", {action: "list", data: {status: this.props.status, current: this.state.current, pageSize: this.state.pageSize}})
			.then(response => {
				if (response.data.status === 200) {
					this.setState({
						loading: false,
						data: response.data.data,
						current: response.data.current,
						pageSize: response.data.pageSize,
						total: response.data.total
					});
				}
			});
	};

	closeModal = () => {
		this.setState({visible: false});
		this.onRefresh();
	};

	onChangePage = async (page, pageSize) => {
		await this.setState({
			loading: true, current: page,
			pageSize: pageSize
		});
		this.onRefresh();
	};

	finishPlan = (planId) => {
		this.setState({loading: true});
		// this.goToFlow(planId, "end")
		axios.post("/mto/mtoplan/", {action: "finishPlan", data: {planId: planId}}).then(res => {
			if (res.data.status === 200) {
				message.success(res.data.message);
				this.onRefresh();
			} else {
				message.error(res.data.message);
				this.state({loading: false})
			}

		});
	};

	render() {
		let columns = [
			{
				title: "计划编号", dataIndex: "planId", align: "center", render: (text, record) => {
					return <Typography.Link key={Math.random()} onClick={() => {this.props.showDetail(record.planId);}}>{text}</Typography.Link>;
					// return <Typography.Link onClick={() => {PopMTOPlanDetail(record.planId);}}>{text}</Typography.Link>;
				}
			},
			{
				title: "产线名称", key: "mtoFactory", align: "center", render: (text, record) => {
					return <span>{record.mtoFactory.factoryName}</span>;
					// return <Typography.Link onClick={() => {PopMTOPlanDetail(record.planId);}}>{text}</Typography.Link>;
				}
			},
			{
				title: "计划模板", key: "template", align: "center", render: (text, record) => {
					return record.template.name;
				}
			},
			{
				title: "计划数量", key: "subMTORecordCount", align: "center", render: (text, record) => {
					if (record.MTORecordCount !== record.template.maxCap) {
						return <Typography.Text key={Math.random()} mark>{record.MTORecordCount}/{record.template.maxCap}</Typography.Text>;
					}
					return `${record.MTORecordCount}/${record.template.maxCap}`;
				}
			},
			{title: "备注", dataIndex: "note", align: "center", responsive: ["xl", "xxl"], ellipsis: true},
			{
				title: "创建人", key: "owner", align: "center", render: (text, record) => {
					return record.owner.displayName;
				}
			},
			{
				title: "创建时间",
				dataIndex: "createTime",
				align: "center",
				defaultSortOrder: "descend",
				render: ((text, record) => {return moment(record.createTime).format("YYYY-MM-DD HH:mm:ss")}),
				sorter: (a, b) => {
					let atime = new Date(Date.parse(a.createTime));
					let btime = new Date(Date.parse(b.createTime));
					return atime.getTime() - btime.getTime();
				}
			},
			{
				title: "当前状态", dataIndex: "status", align: "center", render: (text, record) => {
					if (record.template.config.flow.hasOwnProperty(record.status)) {
						return record.template.config.flow[record.status].displayName;
					}
					return record.status;
				}
			},
			{
				title: "操作", key: "opts", align: "center", render: (text, record) => {
					return <Space split={<Divider type={"vertical"}/>}>
						<Typography.Link key={Math.random()} onClick={() => {this.props.showDetail(record.planId);}}>详情</Typography.Link>
						<Typography.Link key={Math.random()} disabled={record.finished === true || record.status !== "finish"}
						                 onClick={() => {this.finishPlan(record.planId);}}>完结</Typography.Link>
					</Space>;
				}
			}
		];
		let size = this.props.Operator.componentSize
		return (
			<div className={"innerContent"}>
				<Modal footer={null} title={"创建生产计划"} width={"90vw"} visible={this.state.visible} destroyOnClose={true}
				       onCancel={() => {this.setState({visible: false});}}>
					<CreateMtoPlan closeModal={this.closeModal} Operator={this.props.Operator}/>
				</Modal>
				<Table
					columns={columns}
					bordered
					rowKey={"planId"}
					loading={this.state.loading}
					dataSource={this.state.data}
					size={size}
					pagination={{
						defaultPageSize: this.props.Operator.PaginationConfig.pageSize,
						pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
						onChange: this.onChangePage,
						onShowSizeChange: this.onChangePage,
						total: this.state.total,
						current: this.state.current,
						showSizeChanger: true,
						style: {marginRight: 10},
						size: size,
						showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
					}}
					title={() => {
						return (
							<div className={"headerButton"}>
								<Input.Search onChange={this.onFilter} allowClear={true} size={size}/>
								<Space spit={<Divider type={"vertical"}/>} style={{marginLeft: 10}}>
									<Button type={"primary"} onClick={this.onRefresh} size={size}>刷新</Button>
									<Button onClick={() => this.setState({visible: true})} size={size}>创建</Button>
								</Space>
							</div>
						);
					}}
				/>
			</div>
		);
	}
}

export default MtoTable;