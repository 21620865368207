import React, {Component} from "react";
import {Tabs} from "element-react";
import ServiceCaseTable from "./serviceCaseTable";
import ServiceCaseDetail from "./serviceCaseDetail";
import {inject, observer} from "mobx-react";
import "element-theme-default";

@inject("ServiceCaseManager", "Operator")
@observer
class Service extends Component {
	state = {
		details: [],
		active: "当前工单",
		lastActive: "当前工单"
	};

	showDetail = (record) => {
		if (this.state.details.indexOf(record) === -1) {
			let od = this.state.details;
			od.push(record);
			this.setState({details: od, active: record.caseId});
		} else {
			this.setState({active: record.caseId});
		}

	};

	removeTab = (obj) => {
		let od = [];
		for (let i of this.state.details) {
			if (i.caseId !== obj.props.label) {
				od.push(i);
			}
		}
		this.setState({details: od, active: this.state.lastActive});
	};

	changeTab = (obj) => {
		this.setState({
			lastActive: obj.props.label
		});
	};

	render() {
		return (
			<Tabs type="border-card" activeName={this.state.active} onTabRemove={this.removeTab}
				  onTabClick={this.changeTab}
				  style={{height: "100%"}}>
				<Tabs.Pane label={"当前工单"} name={"当前工单"}>
					<ServiceCaseTable status={["新工单", "处理中"]} showDetail={this.showDetail}/>
				</Tabs.Pane>
				<Tabs.Pane label="历史工单" name={"历史工单"}>
					<ServiceCaseTable status={["已取消", "已完成"]} showDetail={this.showDetail}/>
				</Tabs.Pane>

				{this.state.details.map(record => {
					return (
						<Tabs.Pane label={record.caseId} name={record.caseId} closable={true} key={record.caseId}>
							<ServiceCaseDetail record={record} key={record.caseId}/>
						</Tabs.Pane>
					);
				})}
			</Tabs>
		);
	}
}

export default Service;
