import React, {Component} from "react";
import {Tag, Table, Button, Divider, Input, Space, Modal} from "antd";
import {inject, observer} from "mobx-react";
import {Typography} from "antd";
import ServiceCaseCreator from "./serviceCaseCreator";
import PopOrderDetail from "../Order/PopOrderDetail";

const {Text, Link} = Typography;

@inject("ServiceCaseManager", "Operator")
@observer
class serviceCaseTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			current: 1,
			loading: false,
			Modal: false,
			fullData: []
		};
	}

	componentDidMount() {
		this.onChangePage(1, this.props.Operator.PaginationConfig.pageSize);
	}

	onRefresh = () => {
		this.onChangePage(this.state.current, this.state.pageSize);
	};

	onDestroyModal = () => {
		this.setState({Modal: false});
	};

	onChangePage = (page, pageSize) => {
		this.setState({loading: true});
		this.props.ServiceCaseManager.listServiceCase({
			current: page,
			pageSize: pageSize,
			status: this.props.status
		}).then(response => {
			let _data = response.data;
			if (_data.status === 200) {
				this.setState({
					data: _data.data,
					fullData: _data.data,
					total: _data.total,
					pages: _data.pages,
					pageSize: _data.pageSize,
					current: _data.current,
					loading: false
				});
			}
		});
	};

	onFilter = (event) => {
		let keyword = new RegExp(event.target.value, "i");
		let _data = [];
		if (event.target.value !== "" && !this.state.loading) {
			this.setState({loading: true});
			for (let x of this.state.fullData) {
				if (x.caseId.search(keyword) !== -1 ||
					x.title.search(keyword) !== -1 ||
					x.content.search(keyword) !== -1) {
					_data.push(x);
				}
			}
			this.setState({data: _data, loading: false});
		} else {
			this.setState({data: this.state.fullData, loading: false});
		}
	};

	tableHeader = () => {
		return (
			<div className="headerButton">
				<Input.Search size={this.props.Operator.componentSize} onChange={this.onFilter}/>
				<Space spit={<Divider type={"vertical"}/>} style={{marginLeft: 10}}>
					<Button type={"primary"} size={this.props.Operator.componentSize} onClick={this.onRefresh}>刷新</Button>
					<Button size={this.props.Operator.componentSize} onClick={() => {this.setState({Modal: true});}}>创建</Button>
				</Space>
			</div>
		);
	};

	render() {
		let size = this.props.Operator.componentSize
		const columns = [
			{
				title: "工单编号",
				align: "center",
				dataIndex: "caseId",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"],
				key: "caseId",
				render: (text, record) => {
					return <Link onClick={() => this.props.showDetail(record)}>{text}</Link>;
				}
			},
			{
				title: "工单标题",
				align: "center",
				dataIndex: "title",
				key: "caseId",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"],
				render: (text, record) => {
					if (record.status === "新工单") {
						return <Text strong>{text}</Text>;
					} else {
						return <Text>{text}</Text>;
					}
				}
			},
			{
				title: "紧急程度", align: "center", dataIndex: "urgency", key: "caseId", responsive: ["xs", "sm", "md", "lg", "xl", "xxl"], render: (text) => {
					if (text === "一般") {
						return <Tag color="orange">{text}</Tag>;
					}
					return <Tag color="#f50">{text}</Tag>;
				}
			},
			{title: "工单类型", align: "center", dataIndex: "caseType", key: "caseId", responsive: ["sm", "md", "lg", "xl", "xxl"]},
			{title: "当前状态", align: "center", dataIndex: "status", responsive: ["md", "lg", "xl", "xxl"], key: "status"},
			{title: "创建人", align: "center", dataIndex: "owner", key: "caseId", responsive: ["md", "lg", "xl", "xxl"]},
			{
				title: "关联订单", align: "center", dataIndex: "relatedOrder", key: "relatedOrder", responsive: ["md", "lg", "xl", "xxl"],
				render: (text, record) => {
					return <Space split={<Divider type={"vertical"}/>}>
						{record.relatedOrder !== null && record.relatedOrder.map(order => {
							return <Typography.Link key={order.orderId}
							                        onClick={() => PopOrderDetail(order.orderId)}>{order.orderId}[{order.statusDisplay}]</Typography.Link>;
						})}
					</Space>;
				}
			},
			{title: "创建时间", align: "center", dataIndex: "createTime", responsive: ["lg", "xl", "xxl"], key: "createTime"},
			{title: "上次更新", align: "center", dataIndex: "lastUpdate", responsive: ["lg", "xl", "xxl"], key: "lastUpdate"}
		];

		return (
			<div className={"innerContent"}>
				<Table
					rowKey={"caseId"}
					className={"innerTable"}
					bordered
					columns={columns}
					size={size}
					dataSource={this.state.data}
					loading={this.state.loading}
					title={this.tableHeader}
					pagination={{
						defaultPageSize: this.props.Operator.PaginationConfig.pageSize,
						pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
						onChange: this.onChangePage,
						onShowSizeChange: this.onChangePage,
						total: this.state.total,
						current: this.state.current,
						showSizeChanger: true,
						size: size,
						style: {marginRight: 10},
						showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
					}}
					onRow={record => {
						return {
							onDoubleClick: event => {
								this.props.showDetail(record);
							}
						};
					}}
				/>
				<Modal visible={this.state.Modal} width={"60vw"} title={"创建工单"} destroyOnClose={true} footer={null} onCancel={this.onDestroyModal}>
					<ServiceCaseCreator onDestroyModal={this.onDestroyModal}/>
				</Modal>
			</div>
		);
	}
}

export default serviceCaseTable;