import React, {Component} from "react";
import {Tabs} from "element-react";
import {
	Input,
	Divider,
	Button,
	Modal,
	Upload,
	Table,
	message,
	Space,
	Typography,
	Tag,
	Tooltip,
	Row, Col,
} from "antd";
import {axios, getCsrfToken} from "../../Managers";
import {inject, observer} from "mobx-react";
import {InboxOutlined} from "@ant-design/icons";

class ElementComponent extends Component {

	render() {
		let record = this.props.record;
		return (
			<img src={record.elementFile} alt={record.uuid} className={"elementImage"}/>
		);
	}
}

@inject("Operator")
@observer
class Elements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.Operator.PaginationConfig,
			data: [],
			fullData: [],
			selectedKeys: [],
			loading: false,
		};
	}

	componentDidMount() {
		this.onRefresh();
	}

	onUpload = () => {
		Modal.confirm({
			title: "请选择图片",
			onOk: this.onRefresh,
			content: <Upload.Dragger name={"elementPath"}
									 accept={"image/*"}
									 listType={"picture"}
									 multiple={true}
									 action={"/api/element/"}
									 withCredentials={true}
									 data={(f) => {
										 return {
											 "elementName": f.name,
											 "elementDescription": f.uid,
											 "elementType": this.props.type,
											 "config": "{w:0, h:0}",
											 "csrfmiddlewaretoken": getCsrfToken(),
										 };
									 }}
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined/>
				</p>
				<p className="ant-upload-text">点击上传文件</p>
			</Upload.Dragger>,
		});
	};

	fillElementFuncation = (data) => {
		let _data = [];
		for (let x of data) {
			x.updateElementName = (value) => {
				return new Promise((resolve, reject) => {
					axios.post("/api/element/", {
						action: "updateElement",
						data: {elementName: value, uuid: x.uuid},
					}).then(response => {
						if (response.data.status === 200) {
							resolve(response.data);
						} else {
							message.error(response.data.message, 3);
							reject();
						}
					});
				});
			};
			x.publicElement = (uuid) => {
				return new Promise((resolve, reject) => {
					axios.post("/api/element/", {
						action: "updateElement",
						data: {uuid: x.uuid, public: true},
					}).then(response => {
						if (response.data.status === 200) {
							resolve(response.data);
						} else {
							message.error(response.data.message, 3);
							reject();
						}
					});
				});
			};
			x.revokeElement = (uuid) => {
				return new Promise((resolve, reject) => {
					axios.post("/api/element/", {
						action: "updateElement",
						data: {uuid: x.uuid, public: false},
					}).then(response => {
						if (response.data.status === 200) {
							resolve(response.data);
						} else {
							message.error(response.data.message, 3);
							reject();
						}
					});
				});
			};
			x.deActiveElement = (uuid) => {
				return new Promise((resolve, reject) => {
					axios.post("/api/element/", {
						action: "updateElement",
						data: {uuid: x.uuid, isActive: false, public: false},
					}).then(response => {
						if (response.data.status === 200) {
							resolve(response.data);
						} else {
							message.error(response.data.message, 3);
							reject();
						}
					});
				});
			};
			_data.push(x);
		}
		return _data;
	};

	onRefresh = () => {
		this.setState({loading: true});
		axios.post("/api/element/", {
			"action": "list", "data":
				{pageSize: this.state.pageSize, current: this.state.current, type: this.props.type},
		}).then(response => {
			if (response.data.status === 200) {
				let _data = this.fillElementFuncation(response.data.data);
				this.setState({
					data: _data,
					fullData: _data,
					current: response.data.current,
					pageSize: response.data.pageSize,
					total: response.data.total,
					loading: false,
				});
			}
		});
	};

	onFilter = (event) => {
		let value = event.target.value;
		if (value === "") {
			this.setState({data: this.state.fullData});
		} else {
			let _data = [];
			for (let x of this.state.fullData) {
				if (x.elementName.search(value) !== -1) {
					_data.push(x);
				}
			}
			this.setState({data: _data});
		}

	};

	changePage = async (current, pageSize) => {
		await this.setState({current: current, pageSize: pageSize});
		this.onRefresh();
	};

	onChangeSelect = (selectedRowKeys, selectedRows) => {
		this.setState({
			selectedKeys: selectedRowKeys,
		});
	};

	batchUpdate = (action) => {
		axios.post("/api/element/", {action: "batchUpdate", data: {action: action, keys: this.state.selectedKeys}}).then(response => {
			if (response.status === 200) {
				message.success(response.data.message);
				Modal.destroyAll();
				this.onRefresh();
			}
		});
	};

	batchModify = () => {
		Modal.confirm({
			title: "批量修改",
			content: <div>
				<span>批量修改共: {this.state.selectedKeys.length} 项</span>
				<Space split={<Divider type={"vertical"}/>} style={{marginTop: 40}}>
					<Button type={"primary"} onClick={() => {this.batchUpdate("public");}}>批量发布</Button>
					<Button type={"danger"} onClick={() => {this.batchUpdate("revoke");}}>批量回收</Button>
					<Button type={"danger"} onClick={() => {this.batchUpdate("delete");}}>批量删除</Button>
				</Space>
			</div>,
			okButtonProps: null,
		});
	};

	updateElementName = (record) => {
		Modal.confirm({
			title: "修改元素名称",
			content: <Input defaultValue={record.elementName} onChange={event => {
				this.setState({newName: event.target.value});
			}}/>,
			onOk: () => record.updateElementName(this.state.newName).then(data => {
				message.success(data.message, 3);
				this.onRefresh();
				Modal.destroyAll();
			}),
			onCancel: () => Modal.destroyAll(),
		});
	};

	render() {
		const columns = [
			{
				title: "名称", dataIndex: "elementName", align: "center", editable: true, render: (text, record) => {
					return <Tooltip placement="top" title={"双击可修改"}>
                        <span onClick={() => {
							this.updateElementName(record);
						}}>{text}</span>
					</Tooltip>;
				},
			},
			{
				title: "预览", dataIndex: "elementFile", align: "center", render: (text, record) => {
					return <ElementComponent record={record}/>;
				},
			},
			{
				title: "标签", align: "center", dataIndex: "tags", render: (text, record) => {
					return record.tags.map(t => {
						return (<Tag key={`${record.uuid}-${t.tagName}`}>{t.tagName}</Tag>);
					});

				},
			},
			{
				title: "已发布", align: "center", dataIndex: "public", render: (text, record) => {
					if (record.public) {
						return <Typography.Text type="success">已发布</Typography.Text>;
					}
					return <Typography.Text type="secondary">未发布</Typography.Text>;
				},
				filters: [
					{text: "已发布", value: true},
					{text: "未发布", value: false},
				],
				onFilter: (value, record) => {
					return value === record.public;
				},

			},
			{
				title: "操作", align: "center", render: (text, record) => {
					return <Space split={<Divider type="vertical"/>}>
						{
							record.public ?
								<Typography.Link onClick={() => {
									record.revokeElement(record.uuid).then(data => {
										message.success(data.message, 3);
										this.onRefresh();
									});
								}
								}>回收</Typography.Link>
								:
								<Typography.Link onClick={() => {
									record.publicElement(record.uuid).then(data => {
										message.success(data.message, 3);
										this.onRefresh();
									});
								}}>发布</Typography.Link>
						}
						<Typography.Link onClick={() => {
							record.deActiveElement(record.uuid).then(data => {
									message.success(data.message, 3);
									this.onRefresh();
								},
							);
						}}>删除</Typography.Link>
					</Space>;
				},
			},
		];
		return (
			<Table
				bordered
				title={() => {
					return (
						<Row justify={"space-between"}>
							<Col xs={10} sm={12} md={14} lg={16} xl={18} xxl={18}><Input.Search onChange={this.onFilter}
																								size={this.props.Operator.componentSize}/></Col>
							<Col xs={4} sm={6} md={6} lg={6} xl={6} xxl={6}>
								<Divider type="vertical"/>
								<Space split={<Divider type="vertical"/>} size={"small"}>
									<Button type={"primary"} size={this.props.Operator.componentSize} onClick={() => {
										this.setState({...this.props.Operator.PaginationConfig}, this.onRefresh());
									}
									}>刷新</Button>
									<Button size={this.props.Operator.componentSize} onClick={this.onUpload}>批量导入</Button>
									<Button size={this.props.Operator.componentSize} onClick={this.batchModify}
											disabled={this.state.selectedKeys.length === 0}>批量修改</Button>
								</Space>
							</Col>
						</Row>

					);
				}}
				dataSource={this.state.data}
				columns={columns}
				loading={this.state.loading}
				rowKey={"uuid"}
				size={this.props.Operator.componentSize}
				rowSelection={{
					onChange: this.onChangeSelect,
					selectedRowKeys: this.state.selectedKeys,
				}}
				pagination={{
					size: this.props.Operator.componentSize,
					showQuickJumper: true,
					showSizeChanger: true,
					defaultCurrent: this.state.current,
					defaultPageSize: this.state.pageSize,
					total: this.state.total,
					onChange: this.changePage,
					showTotal: (total, range) => `${range[0]} 至 ${range[1]} 总 ${total}`,
				}}
			/>
		);
	}

}

class ElementManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
		};
	}

	render() {
		return (
			<div>
				<Tabs type="border-card" style={{height: "100%"}}>
					<Tabs.Pane label={"徽标素材"} name={"徽标素材"}>
						<Elements type={"flag"} typeDisplay={"徽标素材"}/>
					</Tabs.Pane>
					<Tabs.Pane label={"边框素材"} name={"边框素材"}>
						<Elements type={"border"} typeDisplay={"边框素材"}/>
					</Tabs.Pane>
				</Tabs>
			</div>

		);
	}
}

export default ElementManager;