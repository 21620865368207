import React, {Component} from "react";
import {Tag} from "element-react";
import {Typography, Descriptions, Divider, Space, Table, Popover, Input, Button} from "antd";
import {inject, observer} from "mobx-react";
import {axios, Log} from "../../Managers";

@inject("SaleOrderManager", "Operator", "CustomerManager", "ProductsManager")
@observer
class DesignTemplateManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			filterCates: [],
			filterProducts: [],
			...this.props.Operator.PaginationConfig,
			keyword: null,
		};
	}

	componentDidMount() {
		this.getDTList();
	}

	getDTList = () => {
		let plist = [];
		for (let x of this.state.filterProducts) {
			plist.push(x.pk);
		}
		axios.post("/api/designtemplate/", {
			action: "list", data: {
				pageSize: this.state.pageSize,
				current: this.state.current,
				filterProducts: this.state.keyword === null ? plist : [],
				keyword: this.state.keyword,
			},
		}).then(response => {
			if (response.status === 200) {
				Log(response.data);
				this.setState({data: response.data.data});
			}
		});
	};

	removeFilter = async (target, type = "cate") => {
		let _filter = [];
		if (type === "cate") {
			for (let x of this.state.filterCates) {
				if (x !== target) {
					_filter.push(x);
				}
			}
			this.setState({filterCates: _filter});
		} else {
			for (let x of this.state.filterProducts) {
				if (x !== target) {
					_filter.push(x);
				}
			}
			await this.setState({filterProducts: _filter});
			this.getDTList();
		}

	};

	addFilter = (target, type = "cate") => {
		if (type === "cate") {
			let _filter = this.state.filterCates;
			_filter.push(target);
			this.setState({filterCates: _filter});
		} else {
			let _filter = this.state.filterProducts;
			_filter.push(target);
			this.setState({filterProducts: _filter}, this.getDTList());
		}
	};

	changePage = async (current, pageSize) => {
		await this.setState({current: current, pageSize: pageSize});
		this.getDTList();
	};

	searchDT = async (value, event) => {
		await this.setState({keyword: value, filterCates: [], filterProducts: []});
		this.getDTList();
	};

	onRefresh = async () => {
		await this.setState({keyword: null, filterCates: [], filterProducts: [], current: 1});
		this.getDTList();
	};

	render() {
		let Cates = this.props.ProductsManager.Cates;
		// let Products = this.props.ProductsManager.Products;
		let columns = [
			{title: "模板名称", dataIndex: "name", align: "center"},
			{title: "模板说明", dataIndex: "description", align: "center"},
			{
				title: "预览图片", dataIndex: "previewImageUrl", align: "center", render: (text, record) => {
					return <Popover title={"印面预览"}
									content={<img src={record.previewImageUrl} alt={record.uuid} width={"400"}/>}>
						<img src={record.previewImageUrl} alt={record.uuid} width={100}/>
					</Popover>;
				},
			},
			{
				title: "标签", dataIndex: "tags", align: "center", render: (text, record) => {
					return record.tags.map(t => {
						return <Tag pk={t.pk}>{t.tagName}</Tag>;
					});
				},
			},
			{title: "状态", dataIndex: "statusDisplay", align: "center"},
			{title: "操作", align: "center"},
		];
		return (
			<div style={{padding: 5}}>
				<Descriptions bordered column={2} size={this.props.Operator.componentSize}>
					<Descriptions.Item label={"分类"} labelStyle={{width: "5vw"}} span={2}>
						<Space split={<Divider type="vertical"/>} wrap={true}>
							{Cates.map(c => {
								if (this.state.filterCates.indexOf(c) === -1) {
									return <Typography.Link key={c.pk} onClick={() => {
										this.addFilter(c);
									}}>{c.cateName}</Typography.Link>;
								}
								return false;
							})}
						</Space>
					</Descriptions.Item>
					<Descriptions.Item label={"产品"} span={2}>
						<Space split={<Divider type="vertical"/>} wrap={true}>
							{this.state.filterCates.map(c => {
								return c.ProductList.map(p => {
									if (this.state.filterProducts.indexOf(p) === -1) {
										if (p.productType === "smp" || p.productType === "cmp") {
											return <Typography.Link key={`${c.pk}-${p.productUUID}`} onClick={() => {
												this.addFilter(p, "product");
											}}>{p.productName}</Typography.Link>;
										}
										return false;
									}
									return false;
								});
							})
							}
						</Space>
					</Descriptions.Item>
					<Descriptions.Item label={"筛选"} span={1} contentStyle={{width: "40vw"}}>
						<Space split={<Divider type="vertical"/>} wrap={true}>
							{this.state.filterCates.map(c => {
								return <Tag closable={true} onClose={() => this.removeFilter(c)} key={c.pk}>
									{c.cateName}</Tag>;
							})}
							{this.state.filterProducts.map(p => {
								return <Tag closable={true} onClose={() => this.removeFilter(p, "product")} key={p.pk}>
									{p.productName}</Tag>;
							})}
						</Space>
					</Descriptions.Item>
					<Descriptions.Item span={1} label={"搜索"}>
						<Space split={<Divider type={"vertical"}/>}>
							<Input.Search size={this.props.Operator.componentSize} onSearch={this.searchDT}/>
							<Button size={this.props.Operator.componentSize} onClick={this.onRefresh}>刷新</Button>
						</Space>

					</Descriptions.Item>
				</Descriptions>
				<Table
					rowKey={"uuid"}
					columns={columns}
					bordered
					dataSource={this.state.data}
					pagination={{
						size: this.props.Operator.componentSize,
						showQuickJumper: true,
						showSizeChanger: true,
						defaultCurrent: this.state.current,
						defaultPageSize: this.state.pageSize,
						total: this.state.total,
						onChange: this.changePage,
						showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`,
					}}
				/>
			</div>
		);
	}
}

export default DesignTemplateManager;