import React, {Component} from "react";
import {Tabs} from "element-react";
import {inject, observer} from "mobx-react";
import AcceptedPool from "./acceptedPool";
import MtoTable from "./MTOTable";
import MtoPlanDetail from "./MTOPlanDetail";
import {WareHouseCard} from "../WareHouse";

@inject("Operator")
@observer
class Mto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			active: "当前计划",
			lastActive: "当前计划"
		};

	}

	showDetail = (planId) => {
		if (this.state.details.indexOf(planId) === -1) {
			let od = this.state.details;
			od.push(planId);
			this.setState({details: od, active: planId});
		} else {
			this.setState({active: planId});
		}

	};

	removeTab = (obj) => {
		let od = [];
		for (let i of this.state.details) {
			if (i !== obj.props.label) {
				od.push(i);
			}
		}
		this.setState({details: od, active: this.state.lastActive});
	};

	changeTab = (obj) => {
		this.setState({
			lastActive: obj.props.label
		});
	};

	render() {
		let wh = undefined

		for (let x of this.props.Operator.warehousies) {
			if (x.company === this.props.Operator.company.companyUUID) {
				wh = x
			}
		}

		return (
			<div>
				<Tabs type="border-card" activeName={this.state.active}
				      onTabClick={this.changeTab}
				      onTabRemove={this.removeTab} style={{height: "100%"}}>

					<Tabs.Pane label={"物料库"} name={"物料库"}>
						{wh !== undefined ? <WareHouseCard wh={wh}/> : ""}
					</Tabs.Pane>
					<Tabs.Pane label={"预产池"} name={"预产池"}>
						<AcceptedPool/>
					</Tabs.Pane>
					<Tabs.Pane label={"当前计划"} name={"当前计划"}>
						<MtoTable status={"current"} key={"current"} showDetail={this.showDetail}/>
					</Tabs.Pane>
					<Tabs.Pane label={"历史计划"} name={"历史计划"}>
						<MtoTable status={"finished"} key={"finished"} showDetail={this.showDetail}/>
					</Tabs.Pane>
					{this.state.details.map(planId => {
						return (
							<Tabs.Pane label={planId} name={planId} closable={true} key={planId}>
								<MtoPlanDetail planId={planId} key={planId} removeTab={this.removeTab}/>
							</Tabs.Pane>
						);
					})}
				</Tabs>
			</div>
		);

	}
}

export default Mto;