import React, {Component} from "react";
import {
	Descriptions,
	PageHeader,
	Button,
	message,
	Typography,
	Table,
	Modal, Tag, Popover, Divider, Space, Form, Input, Spin, DatePicker, Select
} from "antd";
import {inject, observer} from "mobx-react";
import OrderCommentForm from "./OrderCommentForm";
import OrderlinesTable from "./OrderlinesTable";
import {axios} from "../../Managers";
import PopLogisticDetail from "../SCM/PopLogisticDetail";
import Address from "../Address";
import moment from "moment";
import PopServiceCaseDetail from "../Service/PopServiceCaseDetail"
import CreateLogistic from "../SCM/createLogistic"

class CommentCard extends Component {

	render() {
		let comment = this.props.comment;
		return <div className="commentCard">
			<div className="commentCardHeader">
				<Space split={<Divider type={"vertical"}/>}>
					<span className={"commentCardTitle"}>{comment.title}</span>
					<span className={"operator"}>{comment.sender.displayName}</span>
					<span className={"createTime"}>{comment.createTime}</span>
				</Space>
			</div>
			<hr/>
			<span className={"commentCardContent"}>{comment.content}</span>
		</div>;
	}
}

@inject("SaleOrderManager", "Operator", "CustomerManager")
@observer
class SaleOrderDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logs: [],
			comments: [],
			visible: false,
			isModalVisible: false,
			data: null,
			loading: true,
			logistics: [],
			editState: false,
			editAddress: false,
			shippers: [],
			service_cases: [],
			pauseMessage: "",
			createLogisticForm: false
		};
		this.Form = React.createRef();
		this.orderForm = React.createRef()
	}

	componentDidMount() {
		this.onRefreshData();
		axios.get("/scm/shipper/").then(res => {
			this.setState({shippers: res.data.data});
		});
	}

	onRefreshData = () => {
		this.setState({loading: true});
		this.props.SaleOrderManager.listSaleOrder({orderId: this.props.orderId}).then(response => {
			if (response.status === 200) {
				let data = response.data;
				this.setState({data: data.data[0], loading: false});
			}
		});
		this.getLogs();
		this.getComments();
		this.getLogisticByOrder();
		this.getServiceCases();
	};

	getLogisticByOrder = () => {
		axios.post("/scm/logistic/", {
			"action": "getLogisticByOrder", "data": {
				"orderId": this.props.orderId
			}
		}).then(res => {
			this.setState({logistics: res.data.data})
		})
	}

	getServiceCases = () => {
		this.setState({loading: true})
		axios.post("/api/servicecase/", {action: "list", data: {orderId: this.props.orderId}}).then(res => {
			if (res.data.status === 200) {
				this.setState({service_cases: res.data.data, loading: false})
			}
		})
	}

	getLogs = () => {
		this.setState({loading: true});
		this.props.SaleOrderManager.getOrderLogs(this.props.orderId).then(response => {
			if (response.data.status === 200) {
				this.setState({logs: response.data.data, loading: false});
			} else {
				message.error(response.data.message, 3);
			}
		});
	};

	getComments = () => {
		this.setState({loading: true});
		this.props.SaleOrderManager.getOrderComments(this.props.orderId).then(data => {
			if (data.status === 200) {
				this.setState({comments: data.data, loading: false});
			} else {
				message.error(data.message, 3);
			}
		});
	};

	onAppendOrderLines = () => {
		this.Form.current.validateFields().then(values => {
			axios.post("/api/saleorder/", {
				action: "appendOrderLines", data: {
					...values
				}
			}).then(response => {
				if (response.data.status === 200) {
					message.success(response.data.message, 3);
					this.setState({data: response.data.data[0], isModalVisible: false});
					this.getLogs();
					this.getComments();
				} else {
					message.error(response.data.message, 3);
					this.setState({data: response.data.data[0], isModalVisible: false});
				}
			});
		});
	};

	onRemoveOrderLine = (orderId, lineId) => {
		axios.post("/api/saleorder/", {
			action: "removeOrderLine", data: {
				orderId, lineId
			}
		}).then(response => {
			if (response.data.status === 200) {
				message.success(response.data.message, 3);
				this.setState({data: response.data.data[0], isModalVisible: false});
				this.getLogs();
				this.getComments();
			} else {
				message.error(response.data.message, 3);
				this.setState({data: response.data.data[0], isModalVisible: false});
			}
		});
	};

	pauseOrder = (orderId, status) => {
		let title = "锁定订单"
		switch (status) {
			case "unlock":
				title = "解锁订单"
				break
			case "rejected":
				title = "拒绝订单"
				break
			case "cancelled":
				title = "取消订单"
				break
			default:
				break
		}
		let message = ""
		Modal.confirm({
			title: title,
			centered: true,
			content: <div>
				<Form>
					<Form.Item name={"message"} label={"注记消息"} required={true}><Input.TextArea
						onChange={(e) => message = e.target.value}/></Form.Item>
				</Form>
			</div>,
			onOk: () => this.onToggleStatus(orderId, status, message),
			onCancel: () => Modal.destroyAll()
		})
	}

	onCreateLogistic = (data) => {
		this.setState({createLogisticForm: true})
	}

	onToggleStatus = (orderId, status, message = "") => {
		this.setState({loading: true});
		axios.post("/api/saleorder/", {
			action: "toggleStatus",
			data: {orderId: orderId, status: status, message: message}
		}).then(response => {
			if (response.data.status === 200) {
				this.onRefreshData();
				Modal.destroyAll()
			}
			this.setState({loading: false});
		})
	}

	reComputeStatus = (orderId) => {
		this.setState({loading: true});
		axios.post("/api/saleorder/", {
			action: "recomputeStatus",
			data: {orderId: orderId}
		}).then(response => {
			if (response.data.status === 200) {
				this.onRefreshData();
				Modal.destroyAll()
			}
			this.setState({loading: false});
		})
	}
	pickUp = (orderId) => {
		this.setState({loading: true});
		axios.post("/api/saleorder/", {
			action: "pickUp",
			data: {orderId: orderId}
		}).then(response => {
			if (response.data.status === 200) {
				this.onRefreshData();
				Modal.destroyAll()
			}
			this.setState({loading: false});
		})
	}

	reSignPermission = (orderId) => {
		this.setState({loading: true});
		axios.post("/api/saleorder/", {
			action: "resignPermission",
			data: {orderId: orderId}
		}).then(response => {
			if (response.data.status === 200) {
				this.onRefreshData();
				Modal.destroyAll()
			}
			this.setState({loading: false});
		})
	}

	pushLogistics = (orderId) => {
		this.setState({loading: true});
		axios.post("/api/saleorder/", {
			action: "pushLogistics",
			data: {orderId: orderId}
		}).then(response => {
			if (response.data.status === 200) {
				this.onRefreshData();
				Modal.destroyAll()
			}
			this.setState({loading: false});
		})
	}

	onFinishEditAddress = (values) => {
		let threeCode = values.threeCode;
		delete values["threeCode"];
		values["province"] = threeCode[0];
		values["city"] = threeCode[1];
		values["district"] = threeCode[2];
		values["orderId"] = this.state.data.orderId
		this.props.CustomerManager.createCustomer(values).then(data => {
			if (data.status === 200) {
				message.success("更新成功")
			}
			this.setState({editAddress: false})
			this.onRefreshData();
		});
	};

	setEditAddress = () => {
		/*
		修改地址弹窗
		 */
		this.setState({editAddress: !this.state.editAddress})
	}

	modifyOrderInfo = (values) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/saleorder/", {action: "modifyOrderInfo", data: values}).then(res => {
				console.log(res)
				if (res.data.status === 200) {
					resolve("信息更新成功!")
				} else {
					reject(res.message)
				}
			})
		})
	}

	setEdit = () => {
		if (this.state.editState) {
			this.orderForm.current.validateFields().then(values => {
				values.expectedDate = values.expectedDate.format("YYYY-MM-DD")
				this.setState({loading: true})
				this.modifyOrderInfo(values).then(msg => {
					message.success(msg)
					this.onRefreshData()
				}).catch(error => {
					message.error(error)
				})
			})
		}
		this.setState({editState: !this.state.editState})
	}

	render() {
		const linesColumns = [
			{
				title: "子单号", dataIndex: "lineId", align: "center", width: "20%",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"]
			},
			{
				title: "商品", key: "product", render: (text, record) => {
					return <span key={record.product.productUUID}>{record.product.productName}</span>;
				}, align: "center", width: "15%",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"]
			},
			{
				title: "状态", dataIndex: "statusDisplay", align: "center", width: "5%",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"]
			},

			{
				title: "数量", dataIndex: "quantity", align: "center", width: "5%",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"]
			},
			{
				title: "单价", dataIndex: "price", align: "center", width: "5%",
				responsive: ["xs", "lg", "xl", "xxl"]
			},
			{
				title: "折扣", dataIndex: "discount", align: "center", width: "5%",
				responsive: ["xl", "xxl"], render: ((text, record) => {
					return 100 - record.discount;
				})
			},
			{
				title: "小计", dataIndex: "summary", align: "center", width: "5%",
				responsive: ["xs", "sm", "md", "lg", "xl", "xxl"]
			},
			{
				title: "制版图", dataIndex: "layoutImage", align: "center", width: "10%",
				responsive: ["md", "lg", "xl", "xxl"], render: (text, record) => {
					if (record.product.productType === "cmp") {
						return (
							<Popover content={<img src={record.layoutImage} alt={"版下图"} style={{height: 300}}/>}
							         title="版下图">
								<img src={record.layoutImage} alt={"layoutImage"} style={{height: 30}}/>
							</Popover>
						);
					}
					return <span>{record.product.modOfSupplyDisplay}</span>;
				}
			},
			{
				title: "备注", dataIndex: "note", align: "center",
				responsive: ["xl", "xxl"]
			},
			{
				title: "操作", dataIndex: "opts", align: "center", responsive: ["xl", "xxl"], render: (text, record) => {
					return <Typography.Link onClick={() => {
						this.onRemoveOrderLine(data.orderId, record.lineId);
					}}
					                        disabled={["draft", "locked"].indexOf(data.status) === -1}>删除子单</Typography.Link>;
				}
			}
		];
		let data = this.state.data;
		let size = this.props.Operator.componentSize
		let btns = [
			<Button key="0" type="primary" onClick={this.onRefreshData}
			        size={this.props.Operator.componentSize}>刷新订单</Button>
		];
		if (this.props.Operator.role.indexOf("admin") !== -1) {
			btns.push(<Button key={"recompute"} type={"primary"} danger onClick={() => this.reComputeStatus(data.orderId)}
			                  size={this.props.Operator.componentSize}>重算状态</Button>
			)
			btns.push(<Button key={"resign"} type={"primary"} danger onClick={() => this.reSignPermission(data.orderId)}
			                  size={this.props.Operator.componentSize}>重新授权</Button>
			)
			btns.push(<Button key={"pushlogistics"} type={"primary"} onClick={() => this.pushLogistics(data.orderId)}
			                  size={this.props.Operator.componentSize}>重推物流</Button>
			)
		}
		const cancelButton = <Button key={Math.random()} type="danger" size={this.props.Operator.componentSize}
		                             disabled={data && data.permissions.indexOf("cancel_order") === -1}
		                             onClick={() => {
			                             this.pauseOrder(data.orderId, "cancelled")
		                             }}>取消订单</Button>
		const modifyAddressButton = <Button key={Math.random()} size={this.props.Operator.componentSize}
		                                    disabled={data && data.permissions.indexOf("view_masksaleorderinfo") === -1} onClick={() => {
			this.setEditAddress();
		}}>修改收件人</Button>
		const editOrderButton = <Button key={Math.random()} size={this.props.Operator.componentSize}
		                                disabled={data && data.permissions.indexOf("view_masksaleorderinfo") === -1} onClick={() => {
			this.setEdit();
		}}>{this.state.editState ? "保存订单信息" : "修改订单信息"}</Button>
		if (data) {
			switch (data.status) {
				case "draft":
					btns.push([
						<Button key={Math.random()} type="primary" size={this.props.Operator.componentSize}
						        disabled={data && data.permissions.indexOf("accept_order") === -1}
						        onClick={() => {
							        this.onToggleStatus(data.orderId, "accepted");
						        }}>接受订单</Button>,
						<Button key={Math.random()} type="danger" size={this.props.Operator.componentSize}
						        disabled={data && data.permissions.indexOf("reject_order") === -1}
						        onClick={() => {
							        this.pauseOrder(data.orderId, "rejected")
						        }}>拒绝订单</Button>,
						modifyAddressButton,
						editOrderButton,
						cancelButton,
						<Button key={Math.random()} type="primary" size={this.props.Operator.componentSize} onClick={() => {
							this.pauseOrder(data.orderId, "lock");
						}}>锁定订单</Button>
					]);
					break;
				case "accepted":
					btns.push([
						// <Button key="4" type="primary" size={this.props.Operator.componentSize}>订单排产</Button>,
						modifyAddressButton,
						editOrderButton,
						cancelButton,
						<Button key={Math.random()} type="primary" size={this.props.Operator.componentSize} onClick={() => {
							this.pauseOrder(data.orderId, "lock");
						}}>锁定订单</Button>
					]);
					break;
				case "planned":
				case "producing":
					btns.push(<Button key={Math.random()} type="primary" size={this.props.Operator.componentSize} onClick={() => {
						this.pauseOrder(data.orderId, "lock");
					}}>锁定订单</Button>)
					break
				case "waitdeliver":
					btns.push([
						modifyAddressButton,
						editOrderButton,
						<Button key={Math.random()} type="primary" size={this.props.Operator.componentSize} onClick={() => {
							this.onCreateLogistic(data)
						}}>订单发货</Button>,
						<Button key={Math.random()} type="primary" size={this.props.Operator.componentSize} onClick={() => {
							this.pickUp(data.orderId)
						}}>现场提货</Button>
					]);
					break;
				case "locked":
					btns.push([
						modifyAddressButton,
						editOrderButton,
						<Button key={Math.random()} type="danger" size={this.props.Operator.componentSize} onClick={() => {
							this.pauseOrder(data.orderId, "unlock");
						}}>解锁订单</Button>
					]);
					break;
				default:
					break;
			}

			let deliver = "未指定"
			switch (data.config?.deliver) {
				case "ZTO":
					deliver =
						<Tag key={Math.random()} color={"blue"}>中通快递</Tag>
					break
				case "STO":
					deliver =
						<Tag key={Math.random()} color={"purple"}>申通快递</Tag>
					break
				case "SF":
					deliver = <Tag key={Math.random()} color={"green"}>顺丰速运</Tag>
					break
				case "PICK-UP":
					deliver = <Tag key={Math.random()} color={"cyan"}>现场取货</Tag>
					break
				default:
					deliver = data.config?.deliver?.toUpperCase()
			}

			return (
				<Spin tip="拉取订单信息..." spinning={this.state.loading}>
					<Modal title={"创建发货"} visible={this.state.createLogisticForm} width={"60vw"} footer={null}>
						<CreateLogistic operator={this.props.operator} order={data} closeLogisticForm={() => {this.setState({createLogisticForm: false})}}/>
					</Modal>
					<div className={"innerContent"} key={Math.random()}>
						<Modal title={"联系人信息修改"} visible={this.state.editAddress} width={"50vw"} footer={null}><Address
							currentAddress={this.state.data.address}
							onFinished={this.onFinishEditAddress}
							onCancel={this.setEditAddress}/></Modal>
						<Modal title="添加子单" visible={this.state.isModalVisible} width={"60vw"}
						       onOk={this.onAppendOrderLines}
						       onCancel={() => {
							       this.setState({isModalVisible: false});
						       }}
						>
							<Form ref={this.Form}>
								<Form.Item name={"orderId"} hidden={true} initialValue={data.orderId}>
									<Input/>
								</Form.Item>
								<Form.Item name={"orderlines"} label={"订单明细"} rules={[
									{
										required: true,
										message: "必须至少包含一个订单行",
										type: "array"
									},
									{
										validator: async (rule, values) => {
											for (let x of values) {
												if (x.product !== null) {
													if (x.productInfo.productType === "cmp") {
														if (x.layoutImage === null) {
															throw new Error(`第${values.indexOf(x) + 1} 行未设置版下图`);
														}
													}
												} else {
													throw new Error(`第${values.indexOf(x) + 1} 行未选择产品`);
												}
											}
										}
									}
								]}>
									<OrderlinesTable/>
								</Form.Item>
							</Form>
						</Modal>
						<Form ref={this.orderForm}>
							<Form.Item name={"orderId"} hidden={true} initialValue={data.orderId}>
								<Input/>
							</Form.Item>
							<PageHeader key={Math.random()}
							            title={data.orderId}
							            extra={[
								            <Button key="addComment" onClick={() => {
									            this.setState({visible: true});
								            }} size={this.props.Operator.componentSize}>添加注记</Button>,
								            ...btns

							            ]}
							>
								<Descriptions
									key={Math.random()}
									bordered
									labelStyle={{minWidth: 90, textAlign: "center"}}
									size={this.props.Operator.componentSize}
									column={{xxl: 5, xl: 5, lg: 5, md: 2, sm: 1, xs: 1}}>

									<Descriptions.Item label="订单号">{data.orderId}</Descriptions.Item>
									<Descriptions.Item label="客户参照">{data.customerRef}</Descriptions.Item>
									<Descriptions.Item label="订单状态">
										{data.status === "draft" ? <Tag size={size} color={"gray"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "accepted" ? <Tag size={size} color={"lime"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "rejected" ? <Tag size={size} color={"black"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "cancelled" ? <Tag size={size} color={"black"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "planned" ? <Tag size={size} color={"blue"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "producing" ? <Tag size={size} color={"blue"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "waitdeliver" ? <Tag size={size} color={"purple"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "sent" ? <Tag size={size} color={"purple"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "transport" ?
											<Tag size={size} color={"purple"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "received" ?
											<Tag size={size} color={"geekblue"}>{data.statusDisplay}</Tag> : ""}
										{data.status === "locked" ? <Tag size={size} color={"red"}>{data.statusDisplay}</Tag> : ""}
									</Descriptions.Item>
									<Descriptions.Item label="创建人">{data.createOwner}</Descriptions.Item>
									<Descriptions.Item label="订单渠道">{data.partner}</Descriptions.Item>
									<Descriptions.Item label="创建时间">{moment(data.createTime).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
									<Descriptions.Item label="期望交期">
										{this.state.editState
											? <Form.Item noStyle name={"expectedDate"}
											             initialValue={moment(data.expectedDate)}><DatePicker size={size} format="YYYY-MM-DD"/></Form.Item>
											: data.expectedDate}
									</Descriptions.Item>
									<Descriptions.Item label="子单数量">{data.orderlines.length}</Descriptions.Item>
									<Descriptions.Item label="加急标记">{
										this.state.editState ? <Form.Item noStyle name={"urgent"} initialValue={data.urgent}><Select
												size={size}><Select.Option
												value={true}>加急</Select.Option><Select.Option
												value={false}>正常</Select.Option></Select></Form.Item> :
											data.urgent ? <Tag color={"red"}>加急</Tag> : <Tag
												color={"green"}>正常</Tag>}</Descriptions.Item>
									<Descriptions.Item label="指定物流">{this.state.editState ? <Form.Item noStyle name={["config", "deliver"]}
									                                                                       initialValue={data.config.deliver}><Select>{this.state.shippers.map(
										s => {
											return <Select.Option value={s.shipperCode}>{s.shipperName}</Select.Option>
										})}</Select></Form.Item> : <span>{deliver}</span>}</Descriptions.Item>
									<Descriptions.Item label="收件人" span={3}>
										{data.address.province}
										{data.address.city}
										{data.address.district}
										{data.address.address}-
										{data.address.name}({data.address.mobileNumber === "" ? data.address.phoneNumber : data.address.mobileNumber})
									</Descriptions.Item>
									<Descriptions.Item label="订单备注" span={3}>{this.state.editState
										? <Form.Item noStyle name={"note"} initialValue={data.note}><Input.TextArea/></Form.Item>
										: data.note}</Descriptions.Item>
									<Descriptions.Item label="相关工单" span={6}><Space split={<Divider type={"vertical"}/>}>{this.state.service_cases.map(
										sc => {

											return <Typography.Link key={Math.random()} onClick={() => PopServiceCaseDetail(sc.caseId)}
											                        type={sc.urgency === "紧急"
												                        ? "danger"
												                        : "warning"}>{sc.caseId}[{sc.status}]</Typography.Link>

										})}</Space></Descriptions.Item>


									<Descriptions.Item label="子单信息" span={6}>
										<Table
											size={size}
											rowKey={"lineId"}
											bordered
											columns={linesColumns}
											dataSource={data.orderlines}
											pagination={false}
											summary={pageData => {
												let total = 0;
												for (let i of pageData) {
													total += i.summary;
												}
												return (
													<>
														<Table.Summary.Row>

															<Table.Summary.Cell colSpan={6} align={"right"}>
																<Typography.Text strong>合计</Typography.Text>
															</Table.Summary.Cell>
															<Table.Summary.Cell colSpan={1}
															                    align={"center"}>{total}</Table.Summary.Cell>
															<Table.Summary.Cell colSpan={3} align={"center"}>
																<Space split={<Divider/>}>
																	<Typography.Link key={Math.random()}
																	                 disabled={["draft", "locked"].indexOf(data.status) === -1}
																	                 onClick={() => this.setState(
																		                 {isModalVisible: true})}>添加子单</Typography.Link>
																</Space>
															</Table.Summary.Cell>

														</Table.Summary.Row>
													</>
												);
											}}
										/>
									</Descriptions.Item>
									<Descriptions.Item label="物流信息" span={6}>
										<Space direction={"horizontal"} split={<Divider type={"vertical"}/>}>
											{this.state.logistics.map(l => {
												return (
													<Typography.Link key={Math.random()} onClick={() => PopLogisticDetail(
														l.logisticUUID)}>{l.shipper.shipperName}-{l.logisticCode}({l.lastStateDisplay})</Typography.Link>
												);
											})}
										</Space>
									</Descriptions.Item>
									<Descriptions.Item label="订单注记" span={6}>
										{this.state.comments.map(comment => {
											return (
												<CommentCard key={Math.random()} comment={comment}/>
											);
										})}
									</Descriptions.Item>
									<Descriptions.Item label="订单日志" span={6}>
										<div className="logs">
											{this.state.logs.map(log => {
												return (
													<Typography.Text code key={Math.random()}>{log.createTime} |
														[{log.message}]</Typography.Text>
												);
											})}
										</div>
									</Descriptions.Item>
								</Descriptions>
								<Modal title={"添加注记"} footer={null} visible={this.state.visible}
								       width={"50vw"}
								       destroyOnClose={true}
								       onCancel={() => {this.setState({visible: false});}}
								>
									<OrderCommentForm close={() => {
										this.onRefreshData();
										this.setState({visible: false});
									}} orderId={data.orderId} status={data.status}/>
								</Modal>
							</PageHeader>
						</Form>
					</div>
				</Spin>
			);
		} else {
			return "";
		}

	}
}

export default SaleOrderDetail;