import React from "react";
import {Modal} from "antd";
import ServiceCaseDetail from "./serviceCaseDetail";
import {Provider} from "mobx-react";
import stores from "../../Managers/store";
import {axios} from "../../Managers"

const PopServiceCaseDetail = (caseId) => {
	axios.post("/api/servicecase/", {action: "list", data: {caseId: caseId}}).then(res => {
		if (res.data.status === 200) {
			Modal.info({
				centered: true,
				maskClosable: true,
				closable: true,
				icon: null,
				width: "90vw",
				content: <Provider {...stores}><ServiceCaseDetail caseId={caseId} record={res.data.data[0]}/></Provider>,
				okText: "关闭"
			});
		}
	})

};

export default PopServiceCaseDetail;