import React, {Component} from "react";
import {Spin, Table, message, Descriptions, Space, Divider, Typography} from "antd";
import {axios} from "../../Managers"
import {inject, observer} from "mobx-react";

@inject("Operator")
@observer
class PlanStockMove extends Component {
	constructor(props) {
		super(props);
		this.state = {stockmove: undefined}
	}

	componentDidMount() {
		this.getStockMove()
	}

	getStockMove = () => {
		axios.post("/mto/mtoplan/", {action: "stockmove", data: {planId: this.props.data.planId}}).then(res => {
			if (res.data.status === 200) {
				this.setState({stockmove: res.data.data[0]})
			} else {
				message.error(res.data.message)
			}
		})
	}

	render() {
		let size = this.props.Operator.componentSize
		let columns = [
			{title: "拣货ID", dataIndex: "pickingId", align: "center"},
			{title: "物料名称", dataIndex: "material", align: "center"},
			{title: "拣货数量", dataIndex: "quantity", align: "center"},
			{title: "触发源", dataIndex: "trigger", align: "center"}
			// {
			// 	title: "操作", key: "opts", align: "center", render: ((text, record) => {
			// 		return <Space split={<Divider type={"vertical"}/>}>
			// 			<Typography.Link key={Math.random()}>删除</Typography.Link>
			// 		</Space>
			// 	})
			// }
		]
		let stockmove = this.state.stockmove
		if (stockmove) {
			return (
				<div>
					<Descriptions bordered={true} key={Math.random()}
					              labelStyle={{minWidth: 90, textAlign: "center"}}
					              column={{xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1}}
					              size={size}
					              title={stockmove.moveId}
					              extra={[
						              <Typography.Link key={Math.random()}>新增物料需求< /Typography.Link>,
						              <Divider type={"vertical"}/>,
						              <Typography.Link key={Math.random()}>打印领料单</Typography.Link>
					              ]}
					>
						<Descriptions.Item key={Math.random()} label={"调拨单号"} span={3}>
							{stockmove.moveId}
						</Descriptions.Item>
						<Descriptions.Item key={Math.random()} label={"调拨状态"} span={3}>
							{stockmove.statusDisplay}
						</Descriptions.Item>
						<Descriptions.Item key={Math.random()} label={"物料汇总"} span={3}>
							<Space split={<Divider type="horizontal" dashed size={size}/>} direction={"vertical"}>
								{stockmove.summary.map(m => {
									return <span key={Math.random()}>{m.material__displayName} {m.quantity__sum}</span>
								})}
							</Space>
						</Descriptions.Item>

						<Descriptions.Item key={Math.random()} label={"调拨明细"} span={3}>
							<Table columns={columns} dataSource={stockmove.pickings} pagination={false}
							       size={size} rowKey={"pickingId"}/>
						</Descriptions.Item>
					</Descriptions>


				</div>
			);
		}
		return <Spin spinning={true}/>
	}
}

export default PlanStockMove;