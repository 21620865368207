import React, {Component} from 'react';
import {Card, Button, Form, Input, Divider, message} from 'antd';
import {axios} from '../../Managers'



class CateInfo extends Component {
    constructor(props) {
        super(props);
        this.state ={
            cate: false,
            edit: false,
            action: 'update'
        }
    }

    componentDidMount() {
        if (this.props.catePK){
            axios.get('/api/productcate/?pk=' + this.props.catePK).then(response=>{
                let data = response.data
                if (data.status === 200){
                    this.setState({cate: data.data[0]})
                }
            })
        }
        else{
            this.setState({cate: {}, edit: true, action: 'create'})
        }
    }


    onFinish = (values)=>{
        axios.post('/api/productcate/', {action: this.state.action, data: values}).then(response=>{
            let data=response.data
            this.setState({cate: data.data[0], edit: false})
            message.success('分类信息更新成功!')
            this.setState({edit: false})
            this.props.onRefreshCate()
        })
    }

    render() {
        let cate = this.state.cate
        let edit = this.state.edit
        return (
            <div>
                {cate ?
                    <Form initialValues={this.state.cate} onFinish={this.onFinish}>
                    <Card title={cate.cateName} bordered={false} type="inner"
                          size={"small"}
                          extra=
                              {
                                  <div>
                                      <Button htmlType={"submit"} disabled={!edit} size={"small"}>保存</Button>
                                      <Divider type="vertical" />
                                      <Button onClick={()=>{this.setState({edit: true})}} disabled={edit} size={"small"}>编辑</Button>
                                  </div>

                              }
                    >

                            <Form.Item name={'pk'} hidden>
                                <Input/>
                            </Form.Item>
                            <Form.Item name={'cateName'} label={"分类名称"}>
                                <Input disabled={!edit}/>
                            </Form.Item>
                            <Form.Item name={'cateDescription'} label={"分类说明"}>
                                <Input.TextArea disabled={!edit}/>
                            </Form.Item>
                    </Card>
                    </Form>
                    :
                    ''
                }
            </div>
        );
    }
}

export default CateInfo;