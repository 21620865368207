import React, {Component} from "react";
import {Collapse, Form, Button, Select, DatePicker, Row, Col, Spin, Checkbox} from "antd";
import {inject, observer} from "mobx-react";
import {axios, DownFile} from "../../Managers";

@inject("Operator", "ProductsManager")
@observer
class Report extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			shippers: []
		}
	}

	componentDidMount() {
		this.props.ProductsManager.getProductCate()
		axios.get("/scm/shipper/").then(res => {
			this.setState({shippers: res.data.data});
		});
	}

	getSaleReport = (values) => {
		this.setState({loading: true})
		const partners = values["partners"]
		let dr = []
		dr[0] = values["dateRange"][0].format("YYYY-MM-DD")
		dr[1] = values["dateRange"][1].format("YYYY-MM-DD")
		axios.post("/analyze/report/", {"type": "sale", "data": {partners, dateRange: dr, exclude: values["exclude"]}}, {responseType: "blob"}).then(res => {
			if (res.status === 200) {
				let blob = new Blob([res.data])
				DownFile(blob, `业务报表(${dr[0]}-${dr[1]}).xlsx`)
			}

			this.setState({loading: false})
		})
	}

	getLogisticReport = (values) => {
		this.setState({loading: true})
		const shippers = values["shippers"]
		let dr = []
		dr[0] = values["dateRange"][0].format("YYYY-MM-DD")
		dr[1] = values["dateRange"][1].format("YYYY-MM-DD")
		axios.post("/analyze/report/", {"type": "logistics", "data": {shippers, dateRange: dr}}, {responseType: "blob"}).then(res => {
			if (res.status === 200) {
				let blob = new Blob([res.data])
				DownFile(blob, `物流报表(${dr[0]}-${dr[1]}).xlsx`)
			}

			this.setState({loading: false})
		})
	}

	render() {
		return (
			<div className={"main"}>
				<Spin spinning={this.state.loading}>
					<Collapse defaultActiveKey={"1"} size={this.props.Operator.componentSize} accordion>
						<Collapse.Panel header={"业务报表"} size={this.props.Operator.componentSize} key={1}>
							<Form onFinish={this.getSaleReport}>
								<Form.Item label={"业务伙伴"} name={"partners"} initialValue={this.props.Operator.partners.map(p => {return p.shortCode})} required
								           rules={[
									           {
										           required: true,
										           message: "至少选定至少一个业务伙伴"
									           }]}>
									<Select allowClear bordered mode={"multiple"}>
										{this.props.Operator.partners.map(p => {
											return <Select.Option key={Math.random()} value={p.shortCode}>{p.displayName}</Select.Option>
										})}
									</Select>
								</Form.Item>
								<Form.Item label={"统计周期"} name={"dateRange"} required rules={[
									{
										required: true,
										message: "必须选定统计周期"
									}]}>
									<DatePicker.RangePicker/>
								</Form.Item>
								{/*<Form.Item label={"产品范围"} name={"productpks"} initialValue={this.props.ProductsManager.Products.map(p => {return p.productUUID})}*/}
								{/*		   required rules={[*/}
								{/*	{*/}
								{/*		required: true,*/}
								{/*		message: "至少选定一个产品"*/}
								{/*	}]}>*/}
								{/*	<Select allowClear bordered mode={"multiple"}>*/}
								{/*		{this.props.ProductsManager.Cates.map(c => {*/}
								{/*			return <Select.OptGroup label={c.cateName}>*/}
								{/*				{c.ProductList.map(p => {*/}
								{/*					return <Select.Option value={p.productUUID}>{p.productName}</Select.Option>*/}
								{/*				})}*/}
								{/*			</Select.OptGroup>*/}
								{/*		})}*/}
								{/*	</Select>*/}
								{/*</Form.Item>*/}
								<Form.Item label={"排除项"} name={"exclude"} extra={"代码功能没完成!"}>
									<Checkbox.Group>
										<Checkbox value={"canceled"}>
											排除已取消订单
										</Checkbox>
									</Checkbox.Group>
									<Checkbox.Group>
										<Checkbox value={"locked"}>
											排除锁定中订单
										</Checkbox>
									</Checkbox.Group>
								</Form.Item>
								<Row justify={"end"}>
									<Col span={3}>
										<Button type={"primary"} block htmlType={"submit"}>提交</Button>
									</Col>
								</Row>
							</Form>
						</Collapse.Panel>
						<Collapse.Panel header={"物流报表"} size={this.props.Operator.componentSize} key={2}>
							<Form onFinish={this.getLogisticReport}>
								<Form.Item label={"物流商"} name={"shippers"} initialValue={this.state.shippers.map(s => {return s.shipperCode})} required
								           rules={[
									           {
										           required: true,
										           message: "至少选定至少一个统计目标"
									           }]}>
									<Select allowClear bordered mode={"multiple"}>
										{this.state.shippers.map(s => {
											return <Select.Option key={Math.random()} value={s.shipperCode}>{s.shipperName}</Select.Option>
										})}
									</Select>
								</Form.Item>
								<Form.Item label={"统计周期"} name={"dateRange"} required rules={[
									{
										required: true,
										message: "必须选定统计周期"
									}]}>
									<DatePicker.RangePicker/>
								</Form.Item>
								{/*<Form.Item label={"产品范围"} name={"productpks"} initialValue={this.props.ProductsManager.Products.map(p => {return p.productUUID})}*/}
								{/*		   required rules={[*/}
								{/*	{*/}
								{/*		required: true,*/}
								{/*		message: "至少选定一个产品"*/}
								{/*	}]}>*/}
								{/*	<Select allowClear bordered mode={"multiple"}>*/}
								{/*		{this.props.ProductsManager.Cates.map(c => {*/}
								{/*			return <Select.OptGroup label={c.cateName}>*/}
								{/*				{c.ProductList.map(p => {*/}
								{/*					return <Select.Option value={p.productUUID}>{p.productName}</Select.Option>*/}
								{/*				})}*/}
								{/*			</Select.OptGroup>*/}
								{/*		})}*/}
								{/*	</Select>*/}
								{/*</Form.Item>*/}

								<Row justify={"end"}>
									<Col span={3}>
										<Button type={"primary"} block htmlType={"submit"}>提交</Button>
									</Col>
								</Row>
							</Form>
						</Collapse.Panel>
					</Collapse>
				</Spin>
			</div>
		);
	}
}

export default Report;