import {observable, action} from "mobx";
import {axios} from "./index";
import Cookie from "js-cookie"

class OperatorModel {
	@observable pk;
	@observable username;
	@observable displayName;
	@observable groups = [];
	@observable logged = false;
	@observable parent;
	@observable config;
	@observable company;
	@observable companies = [];
	@observable defaultAddress;
	@observable addressBook = [];
	@observable partners = [];
	@observable PaginationConfig = {
		pageSize: 50,
		current: 1,
		total: 0,
		pageSizeOptions: [50, 100, 150, 200, 250, 300, 500]
	};
	@observable warehousies = [];
	@observable componentSize = "middle";
	@observable role = [];
	@action changeSize = (size) => {
		this.componentSize = size;
	};

	@action list_warehousies = () => {
		axios.post("/mto/warehouse/", {action: "list"}).then(res => {
			if (res.data.status === 200) {
				this.warehousies = res.data.data
			}
		})
	}

	@action switchCompany = (companyUUID) => {
		console.log(companyUUID)
		Cookie.set("companyUUID", companyUUID)
		window.location.reload()
	}

	@action getPartners = () => {
		axios.get(`/api/partner/`).then(res => {
			this.partners = res.data.data
		})
	}

	@action setUserInfo = (data) => {
		this.pk = data.pk;
		this.username = data.username;
		this.defaultAddress = data.defaultAddress;
		this.displayName = data.displayName;
		this.groups = data.groups;
		this.parent = data.parent;
		// this.partners = data.partners;
		this.logged = true;
		this.company = data.company
		this.getPartners()
		this.list_warehousies()
		this.companies = [];

		for (let x of data.companies) {
			x["current"] = x.companyUUID === Cookie.get("companyUUID")
			if (x["current"]) {
				this.currentCompanyName = x.companyName
			}
			this.companies.push(x)
		}
		if (data.config.hasOwnProperty("role")) {
			this.role = data.config.role;
		}
		if (data.config.hasOwnProperty("componentSize")) {
			this.componentSize = data.config.componentSize;
		}
		if (data.config.hasOwnProperty("defaultSender")) {
			this.defaultSender = data.config.defaultSender;
		}
		if (data.config.hasOwnProperty("defaultShipper")) {
			this.defaultShipper = data.config.defaultShipper;
		} else {
			this.defaultShipper = "ZTO";
		}
	};

	@action getPartnerDefaultAddress = (pk) => {
		for (let x of this.partners) {
			if (x.pk === pk) {
				return x.defaultAddress;
			}
		}
	};

	@action logout = (cb) => {
		axios.get("/api/apiLogout").then(response => {
			this.pk = null;
			this.username = null;
			this.defaultAddress = null;
			this.displayName = null;
			this.groups = null;
			this.parent = null;
			this.partners = null;
			this.logged = false;
			Cookie.remove("companyUUID")
			cb();
		});
	};

}

class Customer {
	@action createCustomer = (data) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/customer/", {action: "create", data: data}).then(response => {
				resolve(response.data);
			}).catch(error => {
				reject(error);
			});
		});
	};

	@action listCustomer = (data) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/customer/", {action: "list", data: data}).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		});
	};
}

class SaleOrder {
	@observable SaleOrderList = [];

	@action
	createSaleOrder = (data) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/saleorder/", {action: "create", data: data}).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		});
	};

	@action
	listSaleOrder = (config) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/saleorder/", {action: "list", data: config}).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		});
	};

	@action
	getOrderLogs = (orderId) => {
		return new Promise((resolve, reject) => {
			axios.get("/api/saleorder/?action=logs&orderId=" + orderId).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		});
	};

	@action
	getOrderComments = (orderId) => {
		return new Promise((resolve => {
				axios.post("/api/comment/", {action: "list", data: {orderId: orderId}}).then(response => {
					resolve(response.data);
				});
			}
		));
	};

	@action
	createOrderComment = (data) => {
		return new Promise(resolve => {
			axios.post("/api/comment/", {action: "create", data: data}).then(response => {
				resolve(response.data);
			});
		});
	};

	@action
	toggleStatus = (orderId, status) => {
		return new Promise(resolve => {
			axios.post("/api/saleorder/", {
				action: "toggleStatus",
				data: {orderId: orderId, status: status}
			}).then(response => {
				resolve(response);
			});
		});
	};

	@action
	orderAction = (orderId, action) => {
		return new Promise(resolve => {
			axios.post("/api/saleorder/", {
				action: action,
				data: {orderId: orderId}
			}).then(response => {
				resolve(response);
			});
		});
	}

	@action
	clearList = () => {
		this.SaleOrderList = [];
		this.loading = false;
	};
}

class ServiceCase {
	@observable SerivceCaseList = [];
	@observable loading = false;

	@action createServiceCase = (data) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/servicecase/", {action: "create", data: data}).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		});
	};

	@action finishCase = (data) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/servicecase/", {action: "close", data: data}).then(response => {
				resolve(response.data);
			}).catch(error => reject(error));
		});
	};

	@action commentCase = (data) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/servicecase/", {action: "comment", data: data}).then(response => {
				resolve(response.data);
			}).catch(error => reject(error));
		});

	};

	@action listServiceCase = (data) => {
		return new Promise((resolve, reject) => {
			axios.post("/api/servicecase/", {action: "list", data: data}).then(response => {
				resolve(response);
			}).catch(error => {
				reject(error);
			});
		});
	};
}

class ProductCate {

	@observable Cates = [];
	@observable Products = [];
	@observable pks = [];

	@action getProductCate = () => {
		axios.post("/api/productcate/", {action: "list", data: {}}).then(response => {
			if (response.data.status === 200) {
				this.Cates = response.data.data;
			}

			for (let c of this.Cates) {
				for (let p of c.ProductList) {
					if (this.pks.indexOf(p.pk) === -1) {
						this.Products.push(p);
						this.pks.push(p.pk);
					}
				}
			}
		});
	};

	@action getProductByPK = (pk) => {
		return new Promise((resolve, reject) => {
			for (let x of this.Products) {
				if (x.pk === Number(pk)) {
					resolve(x);
				}
			}
			reject("没找到相关产品");
		});

	};

	@action
	listProductCates = (config) => {
		return new Promise((resolv, reject) => {
			axios.post("/api/productcate/", {action: "list", data: config}).then(response => {
				resolv(response.data);
			}).catch(error => {
				reject(error);
			});
		});
	};

}

let ProductsManager = new ProductCate();
let Operator = new OperatorModel();
let SaleOrderManager = new SaleOrder();
let ServiceCaseManager = new ServiceCase();
let CustomerManager = new Customer();
let stores = {Operator, SaleOrderManager, ServiceCaseManager, CustomerManager, ProductsManager};
export default stores;