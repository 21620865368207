import React, {Component} from "react";
import {Divider, Space, Table, Typography, Input, Button, Modal, Form, Select, Checkbox, message, Tag} from "antd";
import {inject, observer} from "mobx-react";
import {axios} from "../../Managers";
import Address from "../Address";

@inject("SaleOrderManager", "Operator", "CustomerManager", "ProductsManager")
@observer
class PartnerManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [], loading: false, visible: false, addressBook: [this.props.Operator.parent.defaultAddress],
			isModalVisible: false, currentAddress: null, PayTypes: [], PartnerLevels: [], current: {},
			selectPayType: [], ...this.props.Operator.PaginationConfig
		};
		this.form = React.createRef();
	}

	componentDidMount() {
		this.onRefresh();
	}

	onRefresh = () => {
		this.setState({loading: true});
		console.log(this.state);
		axios.post("/api/partner/", {
			action: "list", data: {
				pageSize: this.state.pageSize,
				current: this.state.current
			}
		}).then(response => {
			if (response.data.status === 200) {
				this.setState({data: response.data.data, total: response.data.total, pageSize: response.data.pageSize, current: response.data.current});
				axios.post("/api/partner/", {action: "metaInfo"}).then(response => {
					if (response.data.status === 200) {
						this.setState({
							PayTypes: response.data.data.PayTypes,
							PartnerLevels: response.data.data.PartnerLevels,
							loading: false
						});
					}
				});
			}
		});

	};

	onEditAddress = (record) => {
		this.setState({
			currentAddress: record,
			isModalVisible: true
		});
	};

	onFinishEditAddress = (values) => {
		let threeCode = values.threeCode;
		delete values["threeCode"];
		values["province"] = threeCode[0];
		values["city"] = threeCode[1];
		values["district"] = threeCode[2];
		this.setState({loading: true});
		this.props.CustomerManager.createCustomer(values).then(data => {
			let _book = [data.data[0]];
			for (let i of this.state.addressBook) {
				if (i.pk !== data.data[0].pk) {
					_book.push(i);
				}
			}
			this.setState({isModalVisible: false, currentAddress: null, loading: false, addressBook: _book},
				this.form.current.setFieldsValue({
					defaultAddress: data.data[0].pk
				})
			);
		});
	};

	onSearch = (value) => {
		if (!this.state.loading) {
			this.setState({loading: true});
			this.props.CustomerManager.listCustomer({keywords: value}).then(response => {
				if (response.data.status === 200 && response.data.total >= 1) {
					this.setState({addressBook: response.data.data, loading: false},
						this.form.current.setFieldsValue({
							address: response.data.data[0].pk
						}));
				} else {
					this.setState({loading: false});
				}
			});
		}
	};

	onFinish = () => {
		this.form.current.validateFields().then(values => {
			axios.post("/api/partner/", {action: "create", data: values}).then(response => {
				if (response.data.status === 200) {
					this.setState({visible: false});
					this.onRefresh();
				} else {
					message.error(response.data.message, 3);
				}
			});
		});
	};

	fixShortCode = (event) => {
		let value = event.target.value;
		this.form.current.setFieldsValue({shortCode: value.toUpperCase().substr(0, 4)});
	};

	onEditPartner = async (record) => {
		await this.setState({visible: true, addressBook: [record.defaultAddress]});
		this.changeActivePayType(record.activePayType.map(t => {return t.pk;}));
		this.form.current.setFieldsValue({
			pk: record.pk,
			displayName: record.displayName,
			shortCode: record.shortCode,
			level: record.level.pk,
			availableGoods: record.availableGoods.map(p => {return p.pk;}),
			defaultAddress: record.defaultAddress.pk,
			activePayType: record.activePayType.map(t => {return t.pk;}),
			defaultPayType: record.defaultPayType
		});
	};

	onToggleActiveStatus = (pk, status) => {
		axios.post("/api/partner/", {action: "active", data: {pk: pk, status: status}}).then(response => {
			if (response.data.status === 200) {
				message.success(response.data.message, 3);
				this.onRefresh();
			} else {
				message.success(response.data.message, 3);
			}
		});
	};

	changeActivePayType = (valueArray) => {
		this.setState({selectPayType: valueArray});
	};

	changePage = async (page, pageSize) => {
		await this.setState({loading: true, current: page, pageSize: pageSize});
		this.onRefresh();
	};

	render() {
		const columns = [
			{title: "渠道名称", dataIndex: "displayName", align: "center"},
			{title: "前缀代码", dataIndex: "shortCode", align: "center"},
			{
				title: "渠道级别", key: "level", align: "center", render: (text, record) => {
					return record.level.name;
				}
			},
			{
				title: "管理员", key: "primaryOperator", align: "center", render: (text, record) => {
					return <Space split={"、"}>
						{record.adminOperator.map(operator => {
							return <span key={Math.random()}>{operator.displayName}</span>;
						})}
					</Space>;
				}
			},
			{
				title: "共享用户数", key: "userCount", align: "center", render: (text, record) => {
					return <Space split={"、"}>
						{record.memberOperator.map(operator => {
							return <span key={Math.random()}>{operator.displayName}</span>;
						})}
					</Space>;
				}
			},
			{
				title: "默认地址", key: "defaultAddress", align: "center", render: (text, record) => {
					return <span>{record.defaultAddress.province}-{record.defaultAddress.name}({record.defaultAddress.mobileNumber})</span>;
				}
			},
			{
				title: "可用状态", key: "active", align: "center", render: (text, record) => {
					return record.isActive ? <Tag color="success">可用</Tag> : <Tag color="default">不可用</Tag>;
				}
			},
			{
				title: "操作", key: "opts", align: "center", render: (text, record) => {
					return <Space split={<Divider type={"vertical"}/>}>
						{record.isActive ? <Typography.Link
							onClick={() => {this.onToggleActiveStatus(record.pk, false);}}>禁用</Typography.Link> : <Typography.Link
							onClick={() => {this.onToggleActiveStatus(record.pk, true);}}>启用</Typography.Link>}
						<Typography.Link onClick={() => {this.onEditPartner(record);}}>编辑</Typography.Link>
					</Space>;
				}
			}
		];
		let _operator = this.props.Operator;
		const layout = {
			labelCol: {span: 3},
			wrapperCol: {span: 21}
		};
		return (
			<div>
				<Modal title={"联系人信息"} visible={this.state.isModalVisible}
					   destroyOnClose={true}
					   footer={null} closable={false}
					   onOk={this.onFinishEditAddress}
				>
					<Address onFinished={this.onFinishEditAddress}
							 currentAddress={this.state.currentAddress}
							 onCancel={() => {
								 this.setState({isModalVisible: false});
							 }}/>
				</Modal>

				<Modal title={"渠道详情"} width={"60vw"} visible={this.state.visible} onOk={this.onFinish} closable={true}
					   onCancel={() => {this.setState({visible: false});}}
				>
					<Form ref={this.form} {...layout}>
						<Form.Item name={"pk"} hidden={true}>
							<Input/>
						</Form.Item>
						<Form.Item label={"渠道名称"} name={"displayName"}>
							<Input/>
						</Form.Item>
						<Form.Item label={"前缀编码"} name={"shortCode"}>
							<Input maxLength={4} allowClear onChange={this.fixShortCode}/>
						</Form.Item>
						<Form.Item label={"渠道级别"} name={"level"}>
							<Select>
								{this.state.PartnerLevels.map(l => {
									return <Select.Option value={l.pk}>{l.name}</Select.Option>;
								})}
							</Select>
						</Form.Item>
						<Form.Item label={"可用商品"} name={"availableGoods"}>
							<Checkbox.Group>
								<Space wrap>
									{this.props.ProductsManager.Products.map(p => {
										return <Checkbox value={p.pk}>{p.productName}</Checkbox>;
									})}
								</Space>
							</Checkbox.Group>
						</Form.Item>
						<Form.Item label={"默认地址"} name={"defaultAddress"}>
							<Select optionLabelProp="label"
									loading={this.state.loading}
									dropdownRender={menu => (
										<div>
											{menu}
											<div className="addressOption">
												<Input.Search size={this.props.Operator.componentSize} addonBefore={"手机号获取信息:"}
															  onSearch={this.onSearch}
															  onChange={event => this.onSearch(event.target.value)}/>
												<Button type={"primary"} size={_operator.componentSize}
														onClick={() => this.onEditAddress(null)}>添加新联系人</Button>
											</div>
										</div>
									)}
									onSelect={(value, option) => {
										if (value === "new") {
											this.onEditAddress(null);
										}
									}}>
								{this.state.addressBook.map(address => {
									return (
										<Select.Option value={address.pk}
													   key={address.pk}
													   label={`${address.province}
                                                   ${address.city}
                                                   ${address.district}
                                                   ${address.address}-
                                                   ${address.name}
                                                       (${address.mobileNumber})`}
										>
											<div className="addressOption">
							<span>
						{address.province}
								{address.city}
								{address.district}
								{address.address}-
								{address.name}
								({address.mobileNumber})
							</span>
												<Typography.Link
													onClick={() => this.onEditAddress(address)}>修改</Typography.Link>
											</div>
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						<Form.Item label={"支付方式"} name={"activePayType"}>
							<Checkbox.Group onChange={this.changeActivePayType}>
								<Space wrap>
									{this.state.PayTypes.map(p => {
										return <Checkbox value={p.pk}>{p.name}</Checkbox>;
									})}
								</Space>
							</Checkbox.Group>
						</Form.Item>
						<Form.Item label={"默认支付"} name={"defaultPayType"}>
							<Select>
								{this.state.PayTypes.map(p => {
									return this.state.selectPayType.indexOf(p.pk) === -1
										? <Select.Option value={p.pk} disabled={true}>{p.name}</Select.Option>
										:
										<Select.Option value={p.pk}>{p.name}</Select.Option>;
								})}
							</Select>
						</Form.Item>
					</Form>
				</Modal>

				<Table
					size={_operator.componentSize}
					rowKey={"uuid"}
					columns={columns}
					bordered
					dataSource={this.state.data}
					loading={this.state.loading}
					pagination={{
						size: this.props.Operator.componentSize,
						showQuickJumper: true,
						showSizeChanger: true,
						defaultCurrent: this.state.current,
						defaultPageSize: this.state.pageSize,
						total: this.state.total,
						onChange: this.changePage,
						showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
					}}
					title={() => {
						return (
							<div>
								<Input.Search allowClear={true} style={{maxWidth: "80vw"}} size={_operator.componentSize}/>
								<Space spit={<Divider type={"vertical"}/>} style={{marginLeft: 10}}>
									<Button type={"primary"} onClick={this.onRefresh} size={_operator.componentSize}>刷新</Button>
									<Button onClick={() => {this.setState({visible: true});}} size={_operator.componentSize}>创建</Button>
								</Space>
							</div>
						);
					}}
				/>
			</div>
		);
	}
}

export default PartnerManager;
;