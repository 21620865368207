import React, {Component} from "react";
import {Gauge} from "@ant-design/plots";
import {Progress} from "antd"

class MaterialPlot extends Component {
	constructor(props) {
		super(props);

		this.state = {
			config: {

				percent: props.material.estimated / props.material.config.maxVolume, type: "meter", innerRadius: 0.75, range: {
					ticks: [0, props.material.config.lowest / props.material.config.maxVolume, 0.9, 1], color: ["#F4664A", "#FAAD14", "#30BF78"]
				}, meter: {
					steps: 100, stepRatio: 0.4
				}, indicator: {
					pointer: {
						style: {
							stroke: "#D0D0D0"
						}
					}, pin: {
						style: {
							stroke: "#D0D0D0"
						}
					}
				}, statistic: {
					title: {
						style: {
							fontSize: "14px"
						}, formatter: () => {
							return props.material.material
						}
					}, content: {
						offsetY: 25, style: ({percent}) => {
							if (percent >= 0.8) {
								return {fontSize: "16px", color: "#30BF78"}
							}
							if (props.material.estimated < props.material.config.lowest) {
								return {fontSize: "16px", color: "#F4664A"}
							}
							if (percent >= props.material.config.lowest / props.material.config.maxVolume) {
								return {fontSize: "16px", color: "#FAAD14"}
							}
							return {fontSize: "16px"}

						}, formatter: () => {
							return `${props.material.estimated} / ${props.material.config.maxVolume}`
						}
					}
				}
			}
		}
	}

	render() {
		return (<Gauge {...this.state.config} style={{maxHeight: 200, minWidth: 200}}/>);
	}
}

class MaterialLine extends Component {
	render() {
		let m = this.props.material
		let _strokeColor = {"0%": "#F4664A", "80%": "#30BF78"}
		_strokeColor[`${(m.lowest / m.config.maxVolume) * 100 + 1}%`] = "#FAAD14"
		return <Progress
			style={{maxWidth: "80%"}}
			strokeColor={_strokeColor}
			strokeLinecap={"round"}
			percent={Math.round((m.estimated / m.config.maxVolume) * 100)}
			type={"line"}
			showInfo={true}
			status={m.config.lowest >= m.estimated ? "exception" : "active"}
			// successPercent={(m.config.lowest / m.config.maxVolume) * 100}
		/>

	}
}

export {MaterialLine, MaterialPlot as default}