import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.css";
import {message, Spin} from "antd";
import {inject, observer} from "mobx-react";
import {axios} from "../../Managers";

@inject("Operator")
@observer
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
		this.username = React.createRef();
		this.password = React.createRef();
		this.token = React.createRef();
		this.goto = "/dashboard";
		if (this.props.location.state) {
			if (this.props.location.state.hasOwnProperty("from")) {
				this.goto = this.props.location.state.from;
			}
		}
	}

	login = (e) => {
		this.setState({loading: true});
		if (e) {
			e.preventDefault();
		}
		let username = this.username.current.value;
		let password = this.password.current.value;
		let key = this.token.current.value;
		axios.post("/api/apiLogin", {username: username, password: password, key: key}).then(response => {
			let data = response.data;
			if (data.status !== 200) {
				message.error(data.message, 3);
				this.setState({loading: false});
				this.token.current.value = "";
			} else {
				message.info(data.message, 3);
				this.setState({loading: false});
				this.props.Operator.setUserInfo(data.data);
				this.props.history.push(this.goto);
			}
		});

	};

	handleEnterKey = (event, next) => {
		if (event.nativeEvent.keyCode === 13) {
			event.preventDefault();
			if (next === undefined) {
				this.login();
			} else {
				next.current.focus();
			}
		}
	};

	componentDidMount() {
		/*
			页面加载后,先通过get方法尝试是否存在未过期的session,如果存在则自动登录
		 */
		this.setState({loading: true});
		axios.get("/api/apiLogin").then(response => {
			let data = response.data;
			if (data.status !== 200) {
				// message.error(data.message, 3)
				this.setState({loading: false});
				this.token.current.value = "";
			} else {
				message.info(data.message, 3);
				this.props.Operator.setUserInfo(data.data);
				this.props.history.push(this.goto);
				// this.setState({loading: false});
			}
		});
		// this.setQRCode();
	}

	// setQRCode = () => {
	// 	var obj = new WxLogin({
	// 		id: "", // 需要显示的容器id
	// 		appid: "", // 公众号appid wx*******
	// 		scope: "snsapi_login", // 网页默认即可
	// 		redirect_uri: encodeURIComponent(""), // 授权成功后回调的url
	// 		state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
	// 		style: "black", // 提供"black"、"white"可选。二维码的样式
	// 		href: "" // 外部css文件url，需要https
	// 	});
	//
	// };

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.Operator.logged) {
			this.props.history.push(this.goto);
		}
	}

	render() {
		return <div className="login-bg">
			<Spin spinning={this.state.loading} tip={"系统模块加载中..."}>
				<div className="d-flex align-items-center justify-content-center">
					<div className="row justify-content-center loginMain">
						<form>
							<div className="d-flex justify-content-center">
								<img src="/images/logo.png" alt="" className="loginLogo"/>
							</div>
							<div className="mt-3 d-flex flex-row align-items-center">
								<label className="col col-4">用户:</label>
								<input type="text" id="username" className="form-control col" placeholder="用户名"
									   ref={this.username}
									   onKeyPress={(event) => this.handleEnterKey(event, this.password)}
									   required autoFocus/>
							</div>
							<div className="mt-3 d-flex align-items-center">
								<label className="col col-4">密码:</label>
								<input type="password" id="password" className="form-control col" placeholder="账户密码"
									   ref={this.password}
									   autoComplete="on"
									   onKeyPress={(event) => this.handleEnterKey(event, this.token)}
									   required/>
							</div>
							<div className="mt-3 d-flex align-items-center">
								<label className="col col-4">密钥:</label>
								<input id="token" className="form-control col" placeholder="6位数字动态口令"
									   maxLength="6" autoComplete={"off"} ref={this.token}
									   onKeyPress={(event) => this.handleEnterKey(event, undefined)} required/>
							</div>
							<div className="justify-content-center mt-3 ml-3">
								<button className="btn btn-primary btn-block" type="submit"
										onClick={this.login}>登录
								</button>
							</div>
						</form>
						<div className="col col-12">
							<p className="text-muted d-flex justify-content-center">NGX 1.0</p>
							<p className="text-muted d-flex justify-content-center">&copy; Baoz（粤ICP备16084937号）</p>
						</div>
					</div>
				</div>
			</Spin>
		</div>;
	}
}

export default Login;