import React, {Component} from "react";
import {Table, Input, Space, Divider, Button, Select, message, Popover} from "antd"
import {inject, observer} from "mobx-react";
import PopOrderDetail from "../Order/PopOrderDetail";
import {axios, DownFile} from "../../Managers";
// import html2pdf from "html2pdf-jspdf2";
import * as ExcelJS from "exceljs"

@inject("Operator", "SaleOrderManager")
@observer
class StockPool extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			loading: true,
			shippers: [],
			senders: [],
			selectedRows: [],
			selectedRowKeys: [],
			...this.props.Operator.PaginationConfig
		}
	}

	componentDidMount() {
		this.getMetaInfo()
		this.onChangePage(1, 200)
	}

	getMetaInfo = () => {
		axios.get("/scm/shipper/").then(res => {
			this.setState({shippers: res.data.data});
		});
		axios.get("/scm/sender/").then(res => {
			this.setState({senders: res.data.data});
		});
	};

	send = (record) => {
		return new Promise((resolve, reject) => {
			axios.post("/scm/logistic/",
				{
					action: "sendLogistic",
					data: {
						orderId: record.orderId,
						shipper: record.deliver,
						sender: this.props.Operator.defaultSender
					}
				}).then(res => {
				if (res.data.status === 200) {
					record.logisticCode = res.data.data[0].logisticCode;
					record.logisticUUID = res.data.data[0].logisticUUID;
					record.sent = true;
					this.forceUpdate()
					resolve();
				} else {
					message.error(res.data.message);
					reject(res.data.message);
				}
			});
		});

	};

	sendSelected = () => {
		this.setState({loading: true})
		let present = [];
		for (let x of this.state.selectedRows) {
			present.push(x.send(x));
		}
		Promise.all(present).then((rets => {
				this.setState({sent: true, selectedRowKeys: [], selectedRows: [], loading: false});
			})
		).catch(rets => {
			this.setState({sent: true, selectedRowKeys: [], selectedRows: [], loading: false});
		});

	};

	printSelected = () => {
		let logisticUUIDS = [];
		for (let x of this.state.selectedRows) {
			logisticUUIDS.push(x.logisticUUID);
		}
		this.printLogisticPaper(logisticUUIDS);

	};

	printLogisticPaper = (logisticUUIDS) => {
		this.setState({loading: true});
		axios.post("/scm/logistic/", {action: "printLogisticPaper", data: {logisticUUIDS: logisticUUIDS}}).then(res => {
			if (res.data.status === 200) {
				for (let x of res.data.data) {
					window.open("http://ngx-dev.baoz.info/scm/logistic/?path=" + x, "_blank");
				}
				this.setState({loading: false});
			} else {
				message.error(res.data.message);
			}
		});
	};

	onExportXlsx = () => {
		/*
		* 导出Excel格式的发货清单
		* */
		let data = this.state.selectedRows
		if (this.state.selectedRows.length === 0) {data = this.state.data}

		let workbook = new ExcelJS.Workbook()
		workbook.creator = "NGX Think"
		workbook.description = "NGX系统导出,请以线上数据为准"
		workbook.properties.date1904 = true
		const sheet = workbook.addWorksheet("发货清单")

		sheet.columns = [
			{header: "订单号", key: "orderId", width: 20, bold: true, alignment: {vertical: "middle", horizontal: "center"}},
			{header: "商品明细", key: "products", width: 30, bold: true, alignment: {vertical: "middle", horizontal: "center"}},
			{header: "商品数量", key: "qty", width: 10, bold: true, alignment: {vertical: "middle", horizontal: "center"}},
			{header: "数量合计", key: "count", width: 10, bold: true, alignment: {vertical: "middle", horizontal: "center"}},
			{header: "收件人", key: "receiver", width: 40, bold: true, alignment: {vertical: "middle", horizontal: "center"}},
			{header: "订单备注", key: "note", width: 30, bold: true, alignment: {vertical: "middle", horizontal: "center"}},
			{header: "物流商", key: "shipper", width: 20, bold: true, alignment: {vertical: "middle", horizontal: "center"}},
			{header: "物流单号", key: "logisticCode", width: 20, bold: true, alignment: {vertical: "middle", horizontal: "center"}}
		]
		sheet.getColumn(1).alignment = {vertical: "middle", horizontal: "center"}
		sheet.getColumn(2).alignment = {vertical: "middle", horizontal: "center"}
		sheet.getColumn(3).alignment = {vertical: "middle", horizontal: "center"}
		sheet.getColumn(4).alignment = {vertical: "middle", horizontal: "center"}
		sheet.getColumn(5).alignment = {vertical: "middle", horizontal: "center"}
		sheet.getColumn(6).alignment = {vertical: "middle", horizontal: "center"}
		sheet.getColumn(7).alignment = {vertical: "middle", horizontal: "center"}
		sheet.getColumn(8).alignment = {vertical: "middle", horizontal: "center"}
		let r = 2
		for (let rowData of data) {
			let _merge = 0
			for (let line of rowData.orderlines) {
				sheet.addRow({
					products: line.product.productName,
					qty: line.quantity
				}).commit()
				_merge += 1
			}
			sheet.mergeCells(`A${r}:A${_merge + r - 1}`)
			sheet.mergeCells(`D${r}:D${_merge + r - 1}`)
			sheet.mergeCells(`E${r}:E${_merge + r - 1}`)
			sheet.mergeCells(`F${r}:F${_merge + r - 1}`)
			sheet.mergeCells(`G${r}:G${_merge + r - 1}`)
			sheet.mergeCells(`H${r}:H${_merge + r - 1}`)
			sheet.getCell(`A${r}`).value = rowData.orderId
			sheet.getCell(`D${r}`).value = {formula: `=SUM(C${r}:C${_merge + r - 1})`}
			sheet.getCell(
				`E${r}`).value = `${rowData.address.province}${rowData.address.city}${rowData.address.district}-${rowData.address.name}(${rowData.address.mobileNumber.slice(
				0, 3)}****${rowData.address.mobileNumber.slice(-3)})`
			sheet.getCell(`F${r}`).value = rowData.note
			sheet.getCell(`G${r}`).value = rowData.shipper
			sheet.getCell(`H${r}`).value = rowData.logisticCode

			r += _merge
		}

		let today = new Date()
		let save_name = `成品发货清单${today.getFullYear()}${today.getMonth()}${today.getDate()}.xlsx`

		workbook.xlsx.writeBuffer().then(buff => {
			DownFile(new Blob([buff]), save_name)
		})
	}

	// onPrint = () => {
	// 	let obj = window.document.getElementsByClassName("ant-table-content")
	// 	let worker = new html2pdf.Worker();
	// 	let opt = {
	// 		margin: 0,
	// 		filename: "组货清单.pdf",
	// 		html2canvas: {
	// 			unit: "mm",
	// 			scale: 5,
	// 			windowWidth: 2970,
	// 			width: 297 * 5,
	// 			windowHeight: 2100,
	// 			height: 210 * 5,
	// 			scrollX: -297 * 2
	// 			// scrollY: -(210 * 5 / 2)
	// 		},
	// 		// jsPDF: {unit: "mm", width: 297, height: 210}
	// 		jsPDF: {unit: "in", pageFormats: "a4", orientation: "landscape"}
	// 	};
	// 	worker.set(opt)
	// 	worker.from(obj[0]).save()
	//
	// }

	onChangePage = (page, pageSize) => {
		this.setState({loading: true});
		this.props.SaleOrderManager.listSaleOrder({
			current: page,
			pageSize: pageSize,
			stockpool: true
		}).then(response => {
			let _data = response.data;
			if (_data.status === 200) {
				let loads = []
				for (let x of _data.data) {
					x.send = this.send
					x.sent = false
					loads.push(x)
				}
				this.setState({
					data: loads,
					fullData: _data.data,
					total: _data.total,
					pages: _data.pages,
					pageSize: _data.pageSize,
					current: _data.current,
					loading: false
				});
				this.props.setCount("stockPoolCount", loads.length)
			}
		});

	};

	changeSelect = (keys, rows) => {
		this.setState({
			selectedRowKeys: keys,
			selectedRows: rows
		})
	}

	render() {
		const columns = [
			{
				title: "序号", key: "seq", align: "center", width: 50, render: (text, record, index) => {
					return index + 1
				}
			},
			{title: "订单号", dataIndex: "orderId", align: "center", width: 180},
			{
				title: "商品明细", key: "products", align: "center", width: 200, render: (text, record) => {
					if (record.orderlines.length > 1) {
						return <ol style={{marginBottom: 0, marginLeft: -20}} key={Math.random()}>{record.orderlines.map(line => {
							return <li key={Math.random()}>
								<div key={Math.random()} style={{display: "flex", justifyContent: "space-between", borderBottom: "1px #e5eeff solid"}}>
									<span key={Math.random()}>{line.product.productName}</span><span key={Math.random()}>* {line.quantity}#</span></div>
							</li>
						})
						}</ol>
					} else {
						return <div style={{display: "flex", justifyContent: "space-between", marginLeft: 5}}>
							<span>{record.orderlines[0].product.productName}</span><span>* {record.orderlines[0].quantity}#</span></div>
					}
				}
			},

			{
				title: "商品合计", key: "count", align: "center", width: 100, render: (text, record) => {
					let c = 0;
					for (let l of record.orderlines) {
						c += l.quantity
					}
					return <span>共{c}件</span>
				}
			},
			{/* {`${record.address.province}${record.address.city}${record.address.district}-${record.address.name}(${record.address.mobileNumber.slice(
							0, 3)}****${record.address.mobileNumber.slice(-3)})`}*/
				title: "收件人", key: "receiver", align: "center", width: 180, ellipsis: true, render: (text, record) => {
					return <Popover title={"收件人"}
					                content={`${record.address.province}${record.address.city}${record.address.district}-${record.address.name}(${record.address.mobileNumber ===
					                "" ? record.address.phoneNumber : record.address.mobileNumber.slice(
						                0, 3) + "****" + record.address.mobileNumber.slice(-3)})`}><span
						key={Math.random()}>
						{`${record.address.province.substring(0, 2)}-${record.address.name}`}
					</span></Popover>
				}
			},
			{title: "订单备注", dataIndex: "note", align: "center", width: 120},
			{
				title: "发货人", key: "sender", align: "center", render: (text, record) => {

					return <Select defaultValue={this.props.Operator.defaultSender} disabled={record.sent} key={Math.random()}
					               size={this.props.Operator.componentSize}
					               onChange={value => {
						               record.sender = value;
					               }}
					>
						{this.state.senders.map(sender => {
							return <Select.Option key={Math.random()} value={sender.pk}>{sender.senderName}</Select.Option>;
						})}
					</Select>;
				}
			},
			{
				title: "物流商", key: "shipper", align: "center", render: (text, record) => {
					record.deliver = record.config.deliver
					return <Select defaultValue={record.config.deliver} key={Math.random()} disabled={record.sent} size={this.props.Operator.componentSize}
					               onChange={value => {
						               record.deliver = value;
					               }}
					>
						{this.state.shippers.map(shipper => {
							return <Select.Option key={Math.random()} value={shipper.shipperCode}>{shipper.shipperName}</Select.Option>;
						})}
					</Select>;
				}
			},

			{
				title: "物流单号", key: "logisticCode", align: "center", width: 180, render: (text, record) => {
					if (record.hasOwnProperty("logisticCode")) {
						return record.logisticCode
					} else {
						return "待发货"
					}
				}
			}
			// {title: "操作", key: "opts", align: "center"}

		]
		const header = () => {
			return <div className="headerButton">
				<Input.Search size={this.props.Operator.componentSize} onChange={this.onFilter}/>
				<Space split={<Divider type={"vertical"}/>} style={{marginLeft: 10}}>
					<Button type={"primary"} size={this.props.Operator.componentSize} onClick={() => {this.onChangePage(1, 200)}}>刷新</Button>
					<Button type={"primary"} size={this.props.Operator.componentSize} onClick={this.onExportXlsx}
					        disabled={this.state.selectedRowKeys.length === 0}>导出发货清单</Button>
					<Button type={"primary"} size={this.props.Operator.componentSize} onClick={this.sendSelected}
					        disabled={this.state.selectedRowKeys.length === 0}>申请电子面单</Button>
					<Button type={"primary"} size={this.props.Operator.componentSize} onClick={this.printSelected}
					        disabled={this.state.selectedRowKeys.length === 0}>打印电子面单</Button>
				</Space>
			</div>
		}
		return (
			<div className={"innerContent"}>
				<Table
					rowKey={"orderId"}
					bordered
					columns={columns}
					size={this.props.Operator.componentSize}
					dataSource={this.state.data}
					loading={this.state.loading}
					title={header}
					pagination={{
						defaultPageSize: this.props.Operator.PaginationConfig.pageSize,
						pageSizeOptions: this.props.Operator.PaginationConfig.pageSizeOptions,
						onChange: this.onChangePage,
						onShowSizeChange: this.onChangePage,
						total: this.state.total,
						current: this.state.current,
						showSizeChanger: true,
						size: this.props.Operator.componentSize,
						style: {marginRight: 10},
						showTotal: (total, range) => `当前显示:${range[0]}-${range[1]}条, 总 ${total} 条`
					}}
					onRow={record => {
						return {
							onDoubleClick: event => {
								PopOrderDetail(record.orderId);
							}
						};
					}}
					rowSelection={{
						selectedRowKeys: this.state.selectedRowKeys,
						onChange: this.changeSelect
					}}
				/>
			</div>
		);
	}
}

export default StockPool;