import React, {Component} from "react";
import {Row, Col, Tree, Button, Space, Divider} from "antd";
import {inject, observer} from "mobx-react";
import CateInfo from "./CateInfo";
import ProductInfo from "./ProductInfo";

@inject("ProductsManager")
@observer
class ProductManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			current: false,
		};
	}

	componentDidMount() {
		this.onRefreshCate();
	}

	onRefreshCate = () => {
		this.setState({current: false});
		this.props.ProductsManager.getProductCate();
		this.props.ProductsManager.listProductCates({}).then(data => {
			let _data = [];
			for (let i of data.data) {
				let p = {
					title: i.cateName,
					key: i.pk,
				};
				let children = [];
				for (let c of i.ProductList) {
					children.push({
						// icon: <img src={c.productImage} alt={c.productName} style={{width: 10}}/>,
						title: c.productName,
						key: `${i.pk}-${c.pk}`,
						isLeaf: true,
					});
				}
				p.children = children;
				_data.push(p);
			}
			this.setState({data: _data});
		});
	};

	onCreateProduct = () => {
		let current = <ProductInfo onRefreshCate={this.onRefreshCate}/>;
		this.setState({current});
	};

	onCreateCate = () => {
		let current = <CateInfo onRefreshCate={this.onRefreshCate}/>;
		this.setState({current});
	};

	onSelect = (selectedKeys) => {
		if (selectedKeys.length !== 0) {
			let pk = selectedKeys[0];
			let current = false;
			if (String(pk).length === 1) {
				current = <CateInfo catePK={pk} key={pk} onRefreshCate={this.onRefreshCate}/>;
			} else {
				let _pk = String(pk).split("-").pop();
				current = <ProductInfo key={_pk} productPk={_pk} onRefreshCate={this.onRefreshCate}/>;
			}
			this.setState({current});
		} else {
			this.setState({current: false});
		}
	};

	render() {

		return (
			<div className={"main"}>

				<Row>
					<Col span={8} className={"treeBorder"}>
						<div>
							<div style={{margin: 5}}>
								<Space split={<Divider type="vertical"/>}>
									<Button onClick={this.onRefreshCate}>刷新列表</Button>
									<Button onClick={this.onCreateCate}>新建分类</Button>
									<Button onClick={this.onCreateProduct}>添加产品</Button>
								</Space>
							</div>
							<Tree
								showLine
								blockNode
								defaultExpandAll={true}
								treeData={this.state.data}
								onSelect={this.onSelect}
							/>
						</div>
					</Col>
					<Col span={14} className={"treeBorder"}>
						<div style={{marginLeft: 5}}>
							{this.state.current ? this.state.current : ""}
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ProductManager;